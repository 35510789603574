import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';
import { ImportSpecificationRepository } from '../../../../Repositories';
import { BusyIndicatorMessageManager } from '../../../../../Busy-Indicator/busyIndicatorMessageManager';
import { RestrictService, Roles } from '../../../../../Common/Permissions/restrict.service';
import { IWeissmanModalComponent } from '../../../../WeissmanModalService';

export interface ImportSpecificationMappingParams {
    importFileId: number;
    companyId?: number;
    importFileSpecificationId: number;
    importFileSpecificationName: string;
    allowNoSave: boolean;
};

@Component({
    selector: 'entity-import-specification-mapping',
    templateUrl: './importSpecificationMapping.component.html'
})
export class EntityImportSpecificationMappingComponent implements OnInit, IWeissmanModalComponent<ImportSpecificationMappingParams, Compliance.ImportFileSpecificationModel> {
    constructor(
        private readonly _modalRef: BsModalRef,
        private readonly _entityImportSpecificationRepository: ImportSpecificationRepository,
        private readonly _restrictService: RestrictService) {
    }

    params: ImportSpecificationMappingParams;
    result: Compliance.ImportFileSpecificationModel;

    specificationName: string;
    viewType: string = '';
    saveType: string;
    busyIndicatorMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();

    get canSaveSharedSpecs(): boolean {
        return this._restrictService.isInRole(Roles.SYSTEMSEARCHESEDIT);
    }

    ngOnInit(): void {
        this.saveType = this.params.importFileSpecificationId ? 'update' : 'insert';
    }

    cancel(): void {
        this._modalRef.hide();
    }

    async saveSpecification() : Promise<void> {
        if (!this.viewType && this.saveType === 'insert') {
            return;
        }

        let existingFileSpecificationId: number = null;
        let companyId: number = null;
        let isShared: boolean = false;
        let specificationName: string;

        switch (this.saveType) {
            case 'update':
                existingFileSpecificationId = this.params.importFileSpecificationId;
                specificationName = this.params.importFileSpecificationName;
                break;
            case 'nosave':
                this.cancel();
                return;
            default: //insert
                specificationName = this.specificationName;
                break;
        }

        const fromImportFileId = this.params.importFileId;

        switch (this.viewType) {
            case 'shared':
                isShared = true;
                break;
            case 'company':
                companyId = this.params.companyId;
                break;
            default:
                break; //If private, defaults should suffice
        }

        const spec: Compliance.ImportFileSpecificationImportUpdateModel = {
            displayName: specificationName,
            companyId: companyId,
            importFileSpecificationId: existingFileSpecificationId,
            importFileId: fromImportFileId,
            isShared: isShared
        };

        this.busyIndicatorMessageManager.add('Saving', 'saving');

        try {
            this.result = await lastValueFrom(this._entityImportSpecificationRepository.createOrUpdateFromImport(spec));

            this._modalRef.hide();
        } finally {
            this.busyIndicatorMessageManager.remove('saving');
        }
    }
}
