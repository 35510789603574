<div class="modal-header">
    <h4 class="modal-title">Assessor Exceptions: {{params.taxYear}} {{params.dateName}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
</div>
<div class="modal-body" style="padding: 20px 10px;">
    <table class="table table-striped" style="padding: 10px 0px;" ng-if="params.exceptions.length > 0">
        <thead>
        <tr>
            <th class="col-5" style="padding-left: 10px;">Assessor Abbr</th>
            <th class="col-5" style="padding-left: 20px;"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let exception of exceptions">
            <td class="col-5">
                <div class="btn btn-link pointer" (click)="showAssessor(exception.assessorId)">{{exception.assessorAbbr + ' ' + exception.municipalityName}}</div>
            </td>
            <td class="col-5">
                <span>{{params.dateInfo(params.taxYear, exception)}}</span>
            </td>
        </tr>
        </tbody>
    </table>
</div>
