import { Component, Input, OnInit } from '@angular/core';
import { CompanyModel, CompanyService } from '../company.service';
import * as _ from 'lodash';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CompanyEntity } from '../../entity.model';
import { RestrictData, RestrictService } from '../../../Common/Permissions/restrict.service';
import { TaskService } from '../../../Task/task.service.upgrade';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import { UserInstanceService } from '../../../User/userInstance.service';
import { NavigationService } from '../../../Layout/Navigation.Service.upgrade';
import { Address } from '../../../Common/Models/common.model';
import { FeatureFlagsService } from '../../../Common/FeatureFlags/feature-flags-service';
import { ValidatorService } from '../../../UI-Lib/Utilities';

export interface ParentCompanyModel extends CompanyModel {
    noPermission: boolean;
}
@Component({
    selector: 'company-info-panel',
    templateUrl: 'companyInfoPanel.component.html',
    styles: [`
        .header-button-container button {
            margin-bottom: 0;
        }
    `]
})

export class CompanyInfoPanelComponent implements OnInit {
    constructor(private readonly _restrictService: RestrictService,
        private readonly _fb: UntypedFormBuilder,
        private readonly _toastr: ToastrService,
        private readonly _taskService: TaskService,
        private readonly _messageModalService: MessageModalService,
        private readonly _userInstanceService: UserInstanceService,
        private readonly _navigationService: NavigationService,
        private readonly _companyService: CompanyService,
        private readonly _featureFlagsService: FeatureFlagsService,
        private readonly _validationService: ValidatorService) { }

    @Input() company: CompanyModel;
    @Input() entity: CompanyEntity;

    editMode: boolean = false;
    hasEditPermission: boolean = false;
    serverAction: boolean = false;
    parentCompany: ParentCompanyModel;
    infoForm: UntypedFormGroup = new UntypedFormGroup({});
    isIAMEnabled: boolean = false;

    get isTopLevel(): boolean {
        return !this.company.parentId;
    }

    get defaultAddress(): Address {
        const defaultAddress = _.find(this.company.entityAddresses, 'isDefault');
        return defaultAddress?.address as unknown as Address;
    }

    get saveFunc() {
        return this.save.bind(this);
    }

    async ngOnInit() {
        const restrictionData = {
            isCompany: true,
            entityId: this.company.companyID,
            flag: Core.AccessRightsEnum.Write
        } as RestrictData;

        const permissionResult = this._restrictService.hasPermission(restrictionData);
        this.hasEditPermission = permissionResult && this.company.canEditCompany;
        this.isIAMEnabled = this._featureFlagsService.featureFlags.enableIAM;

        if(!this.isTopLevel) {
            this.parentCompany = await this._companyService.loadCompanyParent(this.company.companyID, this.company.parentId);
        }
    }


    edit(): void {
        const isImplicitAccess = !this._userInstanceService.isInstanceExplicit(this.company.instanceID);
        const companyCode = [this.company.companyCode] as any[];
        if(this.company.ppReturnPreparationAllowed) {
            companyCode.push(Validators.required);
        }
        this.infoForm = this._fb.group({
            companyName: [this.company.companyName, Validators.required],
            companyCode,
            activityStatusID: [{
                value: this.company.activityStatusID,
                disabled: this.company.isCustomer || isImplicitAccess
            }, Validators.required],
            url: [this.company.address.url],
            crmNumber: [this.company.crmNumber],
            iamTenantId: [this.company.iamTenantId],
            entityAddresses: [this.company.entityAddresses]
        });

        this._navigationService.enableNavWarning('Editing is in progress. Are you sure you want to leave?');
        this.editMode = true;
    }

    cancel(): void {
        this._navigationService.disableNavWarning();
        this.editMode = false;
    }

    async save(): Promise<void> {
        const isCompanyNameNonAscii = !(/^[ -~\t\n\r]+$/.test(this.infoForm.value.companyName));
        if (isCompanyNameNonAscii) {
            this._toastr.error('Company Name contains invalid character(s)');
            return;
        }

        if (this.isIAMEnabled && this.infoForm.value.iamTenantId) {
            if (!this._validationService.isGuid(this.infoForm.value.iamTenantId)) {
                this._toastr.error('tax.com Tenant must have a valid UUID/GUID value');
                return;
            }
        }

        if(this.infoForm.controls['activityStatusID'].value!== this.company.activityStatusID) {
            if(this.infoForm.value.activityStatusID === Core.ActivityStatuses.Inactive) {
                const canBeDeactivatedResult = await this._taskService.checkIfEntityMayBeDeactivated(this.company.companyID, Core.EntityTypes.Company);

                if(canBeDeactivatedResult.canBeDeactivated) {
                    try {
                        let msg = 'Changing the company\'s status to Inactive will result in all client service responsibilities being changed to N/A on the company and all of its subsidiaries, sites and parcels. ';
                        msg += ` This will result in ${canBeDeactivatedResult.openFilingTaskCount} filing task(s) `;
                        msg += ` and ${canBeDeactivatedResult.openAssessmentTaskCount} assessment task(s) `;
                        msg += ` and ${canBeDeactivatedResult.openTaxBillTaskCount} tax bill task(s) to no longer be scheduled. `;
                        msg += ' NO FURTHER WORK OF ANY KIND WILL BE SCHEDULED FOR THIS ENTITY. ';

                        await this._messageModalService.confirm(msg, 'Are you sure you want to proceed?');
                        this._saveToServer();

                    } catch (e3) {
                        this._resetActivityStatus();

                    }
                } else {
                    let message = '';
                    if (canBeDeactivatedResult.openPaymentBatchTaskCount > 0)
                    {
                        message = 'This company\'s status cannot be changed to Inactive because at least some of its batches have open payments.';
                    }
                    else
                    {
                        message = 'This company\'s status cannot be changed to Inactive because at least some of its parcels have open appeals or refunds.';
                    }

                    try {
                        await this._messageModalService.alert(message);
                    } catch(e) {

                    }
                    finally {
                        this._resetActivityStatus();
                    }

                }
            } else {
                if(this.company.activityStatusID !== Core.ActivityStatuses.Inactive) {
                    try {
                        await this._messageModalService.confirm('Changing the company\'s status will cascade to all subsidiaries, sites and parcels.', 'Are you sure you want to proceed?');
                        this._saveToServer();
                    } catch {
                        this._resetActivityStatus();

                    }
                } else {
                    this._saveToServer();
                }
            }
        } else {
            this._saveToServer();
        }
    }

    private async _saveToServer(): Promise<void> {
        const companyToSave = _.cloneDeep(this.company);
        _.assign(companyToSave, _.omit(this.infoForm.value, 'url'));
        companyToSave.address.url = this.infoForm.value.url;

        this.serverAction = true;
        try {
            const savedCompany = await this._companyService.update(companyToSave);
            _.assign(this.company, savedCompany);

            this.entity.isInactive = this.company.activityStatusID === Core.ActivityStatuses.Inactive;
            this.editMode = false;
            this._navigationService.disableNavWarning();

        } finally {
            this.serverAction = false;
        }
    }

    private _resetActivityStatus(): void {
        const { activityStatusID } = this.company;
        this.infoForm.patchValue({activityStatusID});
    }
}
