import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ReturnSettingsRepository } from '../../../../Repositories';
import { GeneralReturnSettingLookupEnum, GeneralReturnSettingLookupTypeEnum, ReturnSettingsEntityTypeEnum, ReturnSettingsLookupType, ReturnSettingsSettingTypeEnum, ReturnSettingsViewModelEnum } from "../../../Models/enums";
import { ToastrService } from 'ngx-toastr';
import { IComponentRef } from '../../../Models/iComponentRef';
import { ExtendedContactModel } from '../../../Models/extendedContactModel';
import { RETURN_SETTINGS_HELP } from './returnSettings.component.help';
import { HelpService } from '../../../../../UI-Lib/Help-Tooltip';

@Component({
    selector: 'return-settings',
    templateUrl: './returnSettings.component.html'
})
export class ReturnSettingsComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _returnSettingsRepository: ReturnSettingsRepository,
        private readonly _toastsManager: ToastrService,
        private readonly _helpService: HelpService) { }

    @Input() entityId: number;
    @Input() entityType: ReturnSettingsEntityTypeEnum;

    @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onInitialized: EventEmitter<IComponentRef> = new EventEmitter<IComponentRef>();

    settings: Compliance.GeneralReturnSettingsEntityModel;

    LookupTypeEnum: typeof GeneralReturnSettingLookupTypeEnum = GeneralReturnSettingLookupTypeEnum;

    ViewModelEnum: typeof ReturnSettingsViewModelEnum = ReturnSettingsViewModelEnum;
    viewMode: ReturnSettingsViewModelEnum = ReturnSettingsViewModelEnum.ReturnValues;
    ReturnSettingsLookupType: typeof ReturnSettingsLookupType = ReturnSettingsLookupType;
    ReturnSettingsSettingTypeEnum: typeof ReturnSettingsSettingTypeEnum = ReturnSettingsSettingTypeEnum;
    GeneralReturnSettingLookupEnum: typeof GeneralReturnSettingLookupEnum = GeneralReturnSettingLookupEnum;

    private _lookupOptions: Compliance.GeneralReturnSettingLookupModel[] = [];
    private _editMode: boolean = false;
    private _currentSettingsCopy:  Compliance.GeneralReturnSettingModel;
    private _ownerContactName: string;
    private _accountingContactName: string;
    private _preparerContactName: string;
    private _signerContactName: string;
    private _isDisabled: boolean = false;

    async ngOnInit(): Promise<void> {
        await this._loadSettings();
        this._helpService.setContent(RETURN_SETTINGS_HELP);

        const ref: IComponentRef = {
            save: this.save.bind(this),
            cancel: this.cancel.bind(this),
            edit: this.edit.bind(this),
            getSaveModel: this._getSaveModel.bind(this),
            load: this._loadSettings.bind(this),
            isDisabled: this.isDisabled.bind(this)
        };

        this.onInitialized.emit(ref);
    }

    ngOnDestroy(): void { }

    async save(): Promise<void> {
        switch(this.entityType) {
            case ReturnSettingsEntityTypeEnum.Company:
                this.settings = await lastValueFrom(this._returnSettingsRepository.saveByCompany(this.entityId, this.settings));
                break;
            case ReturnSettingsEntityTypeEnum.Parcel:
                this.settings = await lastValueFrom(this._returnSettingsRepository.saveByParcel(this.entityId, this.settings));
                break;
            case ReturnSettingsEntityTypeEnum.Return:
                this.settings = await lastValueFrom(this._returnSettingsRepository.saveByReturn(this.entityId, this.settings));
                break;
        }
        this._currentSettingsCopy = null;
        this.hasChanges = false;
        this.editMode = false;
    }

    getLookupOptions(lookupType: GeneralReturnSettingLookupTypeEnum){
        const lookupTypeId = +lookupType as Compliance.GeneralReturnSettingLookupTypeEnum;

        let options = this._lookupOptions.filter(i => i.lookupTypeId === lookupTypeId);

        if (this.entityType === ReturnSettingsEntityTypeEnum.Return){
            options.unshift(<Compliance.GeneralReturnSettingLookupModel>{lookupId: null, name: "", lookupTypeId: lookupTypeId});
        }

        return options;
    }

    set hasChanges(value: boolean){
        this.changed.emit(value);
    }

    async cancel(): Promise<void> {
        this.settings.currentGeneralReturnSetting = this._currentSettingsCopy;
        this._currentSettingsCopy = null;
        this.editMode = false;
    }

    async edit(): Promise<void>{
        this._currentSettingsCopy = {... this.settings.currentGeneralReturnSetting};
        this.editMode = true;
    }

    get editMode(): boolean{
        return this._editMode;
    }

    set editMode(value: boolean){
        this._editMode = value;

        if (value) {
            this.viewMode = ReturnSettingsViewModelEnum.Settings;
        }
    }

    private _getSaveModel(): Compliance.GeneralReturnSettingsEntityModel{
        return this.settings;
    }

    private async _loadSettings(): Promise<void> {
        let response: Compliance.GeneralReturnSettingsEntityModel = {} as Compliance.GeneralReturnSettingsEntityModel;

        try {
            let promises: Promise<any>[] = [
                lastValueFrom(this._returnSettingsRepository.getLookupOptions({
                    entityType: +this.entityType,
                    entityId: this.entityId
                } as Compliance.GeneralReturnSettingsGetOptionsModel)),
            ];

            switch (this.entityType) {
                case ReturnSettingsEntityTypeEnum.Company:
                    promises.push(lastValueFrom(this._returnSettingsRepository.getByCompany(this.entityId)));
                    break;
                case ReturnSettingsEntityTypeEnum.Parcel:
                    promises.push(lastValueFrom(this._returnSettingsRepository.getByParcel(this.entityId)));
                    break;
                case ReturnSettingsEntityTypeEnum.Return:
                    promises.push(lastValueFrom(this._returnSettingsRepository.getByReturn(this.entityId)));
                    break;
            }

            const results = await Promise.all(promises);
            this._lookupOptions = results[0];
            response = results[1];
        } catch (e) {
            if (e && e.status === 404) {
                // handled exception; general return settings are not set (hence the 404)
            } else {
                throw e;
            }
        }

        this.settings = response;

        if (!this.settings.parentGeneralReturnSetting) {
            this.settings.parentGeneralReturnSetting = <Compliance.GeneralReturnSettingModel>{};
        }

        if (!this.settings.currentGeneralReturnSetting) {
            this.settings.currentGeneralReturnSetting = <Compliance.GeneralReturnSettingModel>{};
        }

        const ownerContact = this.settings.currentGeneralReturnSetting.ownerContact || this.settings.parentGeneralReturnSetting.ownerContact;
        if (ownerContact) {
            this._ownerContactName = new ExtendedContactModel(ownerContact).displayName;
        }

        const accountingContact = this.settings.currentGeneralReturnSetting.accountingContact || this.settings.parentGeneralReturnSetting.accountingContact;
        if (accountingContact) {
            this._accountingContactName = new ExtendedContactModel(accountingContact).displayName;
        }

        const preparerContact = this.settings.currentGeneralReturnSetting.preparerContact || this.settings.parentGeneralReturnSetting.preparerContact;
        if (preparerContact) {
            this._preparerContactName = new ExtendedContactModel(preparerContact).displayName;
        }

        const signerContact = this.settings.currentGeneralReturnSetting.signerContact || this.settings.parentGeneralReturnSetting.signerContact;
        if (signerContact) {
            this._signerContactName = new ExtendedContactModel(signerContact).displayName;
        }
    }

    private isDisabled(): boolean{
        return this._isDisabled;
    }
    get ownerContactName(): string{
        return this._ownerContactName;
    }

    set ownerContact(value: ExtendedContactModel){
        this.settings.currentGeneralReturnSetting.ownerContactId = value.model.contactID;
        this._ownerContactName = value.displayName;
        this.hasChanges = true;
    }

    get accountingContactName(): string{
        return this._accountingContactName;
    }

    set accountingContact(value: ExtendedContactModel){
        this.settings.currentGeneralReturnSetting.accountingContactId = value.model.contactID;
        this._accountingContactName = value.displayName;
        this.hasChanges = true;
    }

    get preparerContactName(): string{
        return this._preparerContactName;
    }

    set preparerContact(value: ExtendedContactModel){
        this.settings.currentGeneralReturnSetting.preparerContactId = value.model.contactID;
        this._preparerContactName = value.displayName;
        this.hasChanges = true;
    }

    get signerContactName(): string{
        return this._signerContactName;
    }

    set signerContact(value: ExtendedContactModel){
        this.settings.currentGeneralReturnSetting.signerContactId = value.model.contactID;
        this._signerContactName = value.displayName;
        this.hasChanges = true;
    }
}
