import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ReturnService, ReturnServiceSharedState } from '../return.service';
import * as _ from 'lodash';
import { IReturnPartComponent, ReturnPartServiceBase } from '../Models/returnPartServiceBase';

interface ReturnFormRevisionListItem extends Compliance.ReturnFormRevisionModel {
    displayName?: string;
}

@Component({
    selector: 'return-form-selector',
    templateUrl: './returnFormSelector.component.html'
})
export class ReturnFormSelectorComponent implements OnInit, OnDestroy, IReturnPartComponent {
    constructor (private readonly _returnService: ReturnService) { }

    private _returnsSub: Subscription;
    private _localReturnServiceSharedState: ReturnServiceSharedState;

    @Input() returnPartService: ReturnPartServiceBase;
    @Input() allowPrimaryForReturn: boolean;

    returnFormRevisions: ReturnFormRevisionListItem[] = [];
    selectedReturnFormRevision: ReturnFormRevisionListItem = null;

    ngOnInit(): void {
        this.returnPartService.subscribeToServiceActivationCycle(this);
    }

    ngOnDestroy(): void {
        this.returnPartService.unsubscribeFromServiceActivationCycle(this);
    }

    async onReturnPartServiceActivated(): Promise<void> {
        this._returnsSub = this._returnService.returns$.subscribe((returns) => {
            if (returns) {
                this._refreshReturnFormRevisions();
            }
        });
    }

    onReturnPartServiceDeactivated(): void {
        this._returnsSub && this._returnsSub.unsubscribe();
    }

    onSelectedReturnFormRevisionChange(returnFormRevision: Compliance.ReturnFormRevisionModel): void {
        this.selectedReturnFormRevision = returnFormRevision;
        this._returnService.setFormRevisionId(this.selectedReturnFormRevision && this.selectedReturnFormRevision.formRevisionId);
    }

    getReturnFormRevisionDisplayName(returnFormRevision: Compliance.ReturnFormRevisionModel): string {
        return this._returnService.getReturnFormRevisionDisplayName(returnFormRevision);
    }

    private async _refreshReturnFormRevisions(): Promise<void> {
        const returnServiceSharedState: ReturnServiceSharedState = this._returnService.getSharedStateClone();

        // get input parameters for comparison
        const localReturnIds = (this._localReturnServiceSharedState && this._localReturnServiceSharedState.returns && this._localReturnServiceSharedState.returns.map(x => x.returnId)) || [];
        const sharedReturnIds = returnServiceSharedState.returns.map(x => x.returnId);

        const localReturnFormRevisionsUpdatedTimestamp = this._localReturnServiceSharedState && this._localReturnServiceSharedState.returnFormRevisionsUpdatedTimestamp;
        const sharedReturnFormRevisionsUpdatedTimestamp = returnServiceSharedState.returnFormRevisionsUpdatedTimestamp;

        // check to see if input parameters have changed
        if (
            !_.isEqual(localReturnIds, sharedReturnIds) ||
            !_.isEqual(localReturnFormRevisionsUpdatedTimestamp, sharedReturnFormRevisionsUpdatedTimestamp)
        ) {
            await this._loadFormRevisions();
        }

        // If there is only one form revision, select it
        if (!this._returnService.sharedState.formRevisionId && this._returnService.getUniqueAssociatedReturnFormRevisions().length === 1) {
            this._returnService.setFirstFormRevisionId(true);
        }

        // auto-select the form revision if one is selected
        this.selectedReturnFormRevision = this.returnFormRevisions.find(x => x.formRevisionId === this._returnService.sharedState.formRevisionId) || null;

        this._returnService.setFormRevisionId(
            this.selectedReturnFormRevision
            ? this.selectedReturnFormRevision.formRevisionId
            : null);
    }

    private async _loadFormRevisions(): Promise<void> {
        // persist local state for future checks
        this._localReturnServiceSharedState = this._returnService.getSharedStateClone();
        const uniqueRevisions = this._returnService.getUniqueAssociatedReturnFormRevisions() as ReturnFormRevisionListItem[];
        this.returnFormRevisions = _.sortBy(uniqueRevisions.map(x => {
            x.displayName = this._returnService.getReturnFormRevisionDisplayName(x);
            return x;
        }), (i: Compliance.ReturnFormRevisionModel) => i.formRevisionName);
    }
}
