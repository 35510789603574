import { HelpContent } from '../../../UI-Lib/Help-Tooltip';

export const FILING_BATCH_DETAILS_HELP: HelpContent[] = [
    {
        helpContentId: 'filing-batch-details.begin-preparation',
        tooltipText: 'Begin preparation.  The necessary forms and reports will be added, and asset schedule mappings and form overrides will be copied from prior returns.  The filing batch status will then be set to Started.'
    },
    {
        helpContentId: 'filing-batch-details.begin-preparation-no-filings',
        tooltipText: 'Cannot begin preparation because there are no filings.  Add filings to begin.'
    },
    {
        helpContentId: 'filing-batch-details.continue-preparation',
        tooltipText: 'Continue preparation.  If any filings did not have forms available when preparation started, the necessary forms and reports will be added, and asset schedule mappings and form overrides will be copied from prior returns.'
    },
    {
        helpContentId: 'filing-batch-details.continue-preparation',
        tooltipText: 'Continue preparation.  If any filings did not have forms available when preparation started, the necessary forms and reports will be added, and asset schedule mappings and form overrides will be copied from prior returns.'
    },
    {
        helpContentId: 'filing-batch-details.continue-preparation-bypass',
        tooltipText: 'Continue preparation using the list of assets, and forms from the last time these returns were viewed.'
    },
    {
        helpContentId: 'filing-batch-details.view-returns',
        tooltipText: 'View returns'
    },
    {
        helpContentId: 'filing-batch-details.amend-returns',
        tooltipText: 'Amend Returns'
    }
];
