import { NgModule } from '@angular/core';
import { BusyIndicatorModule } from 'src/app/Busy-Indicator';
import { WeissmanSharedModule } from 'src/app/Common/weissman-shared.module';
import { ModalWrapperModule } from 'src/app/UI-Lib/Modal-Wrapper/modalWrapper.module';
import { LoginService } from './login.service';

import { TermsAndConditionsModalComponent } from './termsAndConditionsModal.component';

@NgModule({
    imports: [
        ModalWrapperModule, 
        WeissmanSharedModule, 
        BusyIndicatorModule
    ],
    exports: [],
    declarations: [TermsAndConditionsModalComponent],
    providers: [LoginService],
})
export class LoginModule { }
