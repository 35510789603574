import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { StateIcon } from './Asset-Change-Icon/assetChangeIcon.component';
import { AssetSummary } from '../Models/assetSummary';

@Component({
    selector: 'asset-change-icon-cell',
    template: '<asset-change-icon *ngIf="!isTotalsRow" [icons]="icons"></asset-change-icon><span *ngIf="isTotalsRow">-</span>'
})
export class AssetChangeIconCellRendererComponent implements ICellRendererAngularComp {
    changeStatus: Compliance.AssetChangeStatusEnum[];
    params: ICellRendererParams;
    isTotalsRow: boolean = false;

    icons: StateIcon[] = [
        {
            selector: null,
            iconClass: 'fa-circle-thin',
            active: false,
            tooltipTitle: 'Existing'
        },
        {
            selector: Compliance.AssetChangeStatusEnum.NewAddition,
            iconClass: 'fa-plus',
            active: false,
            tooltipTitle: 'Added'
        },
        {
            selector: Compliance.AssetChangeStatusEnum.Disposed,
            iconClass: 'fa-trash',
            active: false,
            tooltipTitle: 'Disposed'
        },
        {
            selector: Compliance.AssetChangeStatusEnum.PotentiallyDisposed,
            iconClass: 'fa-trash-o',
            active: false,
            tooltipTitle: 'Potentially disposed'
        },
        {
            selector: Compliance.AssetChangeStatusEnum.Changed,
            iconClass: 'fa-pencil',
            active: false,
            tooltipTitle: 'Changed'
        }
    ];

    agInit(params: ICellRendererParams): void {
        const summary = params.data as AssetSummary;
        this.params = params;

        this.changeStatus = (summary && summary['changeStatus']) ? summary.changeStatus : null;

        if (this.changeStatus && this.changeStatus.length) {
            this.icons.forEach(i => i.active = this.changeStatus.includes(i.selector));
        } else if(!summary) {
            this.isTotalsRow = true;
        } else {
            this.icons[0].active = true;
        }
        this.isTotalsRow = summary && (summary['totalsRow'] || summary['selectedTotalsRow']);
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
