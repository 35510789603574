import { Component, OnInit, OnDestroy, Input, SimpleChanges, SimpleChange } from '@angular/core';
import { FilingControlReconciliationReport, ReturnFilingControlRow, ReturnFilingControlAssetFilter } from '../../../Models/filingControlReconciliationReport';
import { ReturnService, ReturnServiceSharedState } from '../../../return.service';
import { ReturnAssetsService, ReturnAssetsServiceSharedState } from '../../Assets/returnAssets.service';
import { FilingControlGroupBy, ReturnOverviewService, ReturnOverviewServiceSharedState } from '../returnOverview.service';
import { IReturnPartComponent } from '../../../Models/returnPartServiceBase';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { ReturnPreviewService, ReturnPreviewServiceSharedState, ReturnFormModel } from '../../Preview/returnPreview.service';

export interface FilingControlComponentChanges extends SimpleChanges {
    data: SimpleChange;
    refreshing: SimpleChange;
}

@Component({
    selector: 'filing-control-reconciliation-report',
    templateUrl: './filingControl.component.html'
})
export class FilingControlReconciliationReportComponent implements OnInit, OnDestroy, IReturnPartComponent {
    constructor(
        private readonly _returnService: ReturnService,
        private readonly _returnAssetsService: ReturnAssetsService,
        private readonly _returnOverviewService: ReturnOverviewService,
        private readonly _returnPreviewService: ReturnPreviewService) { }

    private _localReturnServiceSharedState: ReturnServiceSharedState;
    private _localReturnOverviewServiceSharedState: ReturnOverviewServiceSharedState;
    private _localReturnAssetsServiceSharedState: ReturnAssetsServiceSharedState;
    private _reconciliationReportSub: Subscription;
    private _returnsSub: Subscription;

    @Input() groupBy: FilingControlGroupBy;

    data: FilingControlReconciliationReport;
    filingControlGroupBy = FilingControlGroupBy;
    refreshing: boolean;

    get formRevisionId(): number {
        return this._localReturnServiceSharedState && this._localReturnServiceSharedState.formRevisionId;
    }

    ngOnInit(): void {
        this._returnOverviewService.subscribeToServiceActivationCycle(this);
    }

    ngOnDestroy(): void {
        this._returnOverviewService.unsubscribeFromServiceActivationCycle(this);
    }

    async onReturnPartServiceActivated(): Promise<void> {
        await this._refreshReport();
        this._reconciliationReportSub = this._returnOverviewService.reconciliationReport$.subscribe(() => this._refreshReport());
        this._returnsSub = this._returnService.returns$.subscribe(() => this._refreshReport());
    }

    onReturnPartServiceDeactivated(): void {
        this._reconciliationReportSub && this._reconciliationReportSub.unsubscribe();
        this._returnsSub && this._returnsSub.unsubscribe()
    }

    async refresh(): Promise<void> {
        await this._loadReport();
    }

    navigateToAssets(assetFilter: ReturnFilingControlAssetFilter): void {
        const sharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
        sharedState.formRevisionId = this.formRevisionId || assetFilter.primaryFormRevisionId;
        sharedState.scheduleAndFactorFilter = {
            showOnlyNotAssignedToASchedule: false,
            showOnlyAssignedToNonReportableSchedule: false,
            showOnlyAssignedToReportableSchedule: assetFilter.showOnlyAssignedToReportableSchedule,
            formRevisionScheduleIds: [],
            depreciationFactorId: null,
            scheduleAge: null
        };

        sharedState.assetStatusesFilter = [];

        this._returnAssetsService.setScheduleAndFactorFilter(sharedState.scheduleAndFactorFilter);
        this._returnOverviewService.notifyReturnAssetsSelected(sharedState);
        this._returnService.applyRemoteReturnBatchFilter(assetFilter.returnIds);
    }

    // if a form is selected, use that else use primary for return
    navigateToPreview(filingControl: ReturnFilingControlRow): void {
        if (!(this.formRevisionId || filingControl.primaryFormRevisionId)) {
            console.warn('Expected form revision or primary form revision.');
            return;
        }

        // if a form is selected, use that, otherwise use primary for return
        const formRevisionId = this.formRevisionId || filingControl.primaryFormRevisionId;
        if (!formRevisionId) {
            return;
        }

        const returnFormRevision = _.find(filingControl.returnFormRevisions, x => x.formRevisionId === formRevisionId);

        if (!returnFormRevision) {
            console.warn('Expected to find return form revision.');
            return;
        }

        const sharedState: ReturnPreviewServiceSharedState = this._returnPreviewService.getSharedStateClone();
        sharedState.returnForm = {
            mergeParcelId: filingControl.parcelId,
            returnId: filingControl.returnId,
            returnFormRevisionId: returnFormRevision.returnFormRevisionId,
            returnFormRevisionReportId: null,
            formRevisionId: returnFormRevision.formRevisionId,
            reportId: null,
            isExcel: null
        } as ReturnFormModel;

        this._returnOverviewService.notifyReturnPreviewSelected(sharedState);
    }

    private async _refreshReport(): Promise<void> {
        const returnServiceSharedState: ReturnServiceSharedState = this._returnService.getSharedStateClone();
        const returnAssetsServiceSharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
        const returnOverviewServiceSharedState: ReturnOverviewServiceSharedState = this._returnOverviewService.getSharedStateClone();

        // get input parameters for comparison
        const localFormRevisionId = this._localReturnServiceSharedState && this._localReturnServiceSharedState.formRevisionId;
        const sharedFormRevisionId = returnServiceSharedState.formRevisionId;

        const localReturnIds = (this._localReturnServiceSharedState && this._localReturnServiceSharedState.returns && this._localReturnServiceSharedState.returns.map(x => x.returnId)) || [];
        const sharedReturnIds = returnServiceSharedState.returns.map(x => x.returnId);

        const localReconciliationReport = this._localReturnOverviewServiceSharedState && this._localReturnOverviewServiceSharedState.reconciliationReport;
        const sharedReconciliationReport = returnOverviewServiceSharedState.reconciliationReport;

        const localAssetDetailsUpdatedTimestamp = this._localReturnAssetsServiceSharedState && this._localReturnAssetsServiceSharedState.assetDetailsUpdatedTimestamp;
        const sharedAssetDetailsUpdatedTimestamp = returnAssetsServiceSharedState.assetDetailsUpdatedTimestamp;

        const localAssetMappingsUpdatedTimestamp = this._localReturnAssetsServiceSharedState && this._localReturnAssetsServiceSharedState.assetMappingsUpdatedTimestamp;
        const sharedAssetMappingsUpdatedTimestamp = returnAssetsServiceSharedState.assetMappingsUpdatedTimestamp;

        const localFormRevisionsTimestamp = this._localReturnServiceSharedState && this._localReturnServiceSharedState.returnFormRevisionsUpdatedTimestamp;
        const sharedFormRevisionsTimestamp = returnServiceSharedState.returnFormRevisionsUpdatedTimestamp;

        // check to see if input parameters are available
        if (!sharedReturnIds.length || sharedReconciliationReport === null) {
            this._blankReport();
            return;
        }

        // check to see if input parameters have changed
        if (
            !_.isEqual(localFormRevisionId, sharedFormRevisionId) ||
            !_.isEqual(localReturnIds, sharedReturnIds) ||
            !_.isEqual(localReconciliationReport, sharedReconciliationReport) ||
            !_.isEqual(localAssetDetailsUpdatedTimestamp, sharedAssetDetailsUpdatedTimestamp) ||
            !_.isEqual(localAssetMappingsUpdatedTimestamp, sharedAssetMappingsUpdatedTimestamp) ||
            !_.isEqual(localFormRevisionsTimestamp, sharedFormRevisionsTimestamp)
        ) {
            await this._loadReport();
        }
    }

    private _blankReport(): void{
        this._persistState();

        this.data = new FilingControlReconciliationReport({returns: []} as Compliance.ReturnFilingControlSummaryModel,
            this._returnService.getAssociatedReturnFormRevisions(),
            this._returnService.sharedState.returns);
    }

    private _persistState(): void {
        this._localReturnServiceSharedState = this._returnService.getSharedStateClone();
        this._localReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
        this._localReturnOverviewServiceSharedState = this._returnOverviewService.getSharedStateClone();
    }

    private async _loadReport(): Promise<void> {
        this._persistState();
        this.refreshing = true;

        try {
            this.data = await this._returnOverviewService.getFilingControlReconciliationReport();
        } finally {
            this.refreshing = false;
        }
    }
}
