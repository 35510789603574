import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import AppealRecommendationCCAssignedFilterShowOptionEnum = Core.AppealRecommendationCCAssignedFilterShowOptionEnum;
import AppealRecommendationCCAssignedFilterWhereOptionEnum = Core.AppealRecommendationCCAssignedFilterWhereOptionEnum;
import AppealRecommendationCCAssignedFilterThatOptionEnum = Core.AppealRecommendationCCAssignedFilterThatOptionEnum;
import { UserSettingsService } from '../../../Account/userSettings.service';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import {
    AppealRecommendationCommandCenterAssignedDetailsModalComponent, AssignedDetailsModalParams
} from '../Appeal-Recommendation-Command-Center-Assigned-Details-Modal/appealRecommendationCommandCenterAssignedDetailsModal.component';
import { APPEAL_RECOMMENDATION_COMMAND_CENTER_HELP } from '../appealRecommendationCommandCenter.component.help';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';

@Component({
    selector: 'appeal-recommendation-command-center-assigned',
    templateUrl: './appealRecommendationCommandCenterAssigned.component.html'
})
export class AppealRecommendationCommandCenterAssignedComponent implements OnInit{

    constructor(
        private readonly _userSettingsService: UserSettingsService,
        private readonly _modalService: WeissmanModalService,
        private readonly _helpService: HelpService) {
    }

    @Output() optionChange: EventEmitter<Core.AppealRecommendationCCAssignedFilterModel> = new EventEmitter<Core.AppealRecommendationCCAssignedFilterModel>();

    defaultCustomOption: Compliance.NameValuePair<Core.AppealRecommendationCCAssignedFilterModel> = {
        name: 'Custom', value:{
            show: AppealRecommendationCCAssignedFilterShowOptionEnum.MyTasks,
            where: AppealRecommendationCCAssignedFilterWhereOptionEnum.Assigned,
            that: AppealRecommendationCCAssignedFilterThatOptionEnum.Ready,
            specificUsers: [],
            specificTeams: []
    }};

    options: Compliance.NameValuePair<Core.AppealRecommendationCCAssignedFilterModel>[] = [
        {
            name: 'My Assigned Tasks: Ready or Not Ready', value: {
                show: AppealRecommendationCCAssignedFilterShowOptionEnum.MyTasks,
                where: AppealRecommendationCCAssignedFilterWhereOptionEnum.Assigned,
                that: AppealRecommendationCCAssignedFilterThatOptionEnum.ReadyOrNotReady,
                specificUsers: [],
                specificTeams: []
            }
        },
        {
            name: `My Team's Assigned Tasks: Ready or Not Ready`, value: {
                show: AppealRecommendationCCAssignedFilterShowOptionEnum.MyTeamTasks,
                where: AppealRecommendationCCAssignedFilterWhereOptionEnum.Assigned,
                that: AppealRecommendationCCAssignedFilterThatOptionEnum.ReadyOrNotReady,
                specificUsers: [],
                specificTeams: []
            }
        },
        structuredClone(this.defaultCustomOption)
    ];

    selectedOptionName: string;

    private _savedSelectedOptionSetting: any;
    private readonly _settingName = 'Assigned-Selected-Option';

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(APPEAL_RECOMMENDATION_COMMAND_CENTER_HELP);

        const settings = this._userSettingsService.getSettingsByGroup(Core.UserSettingGroup.AppealRecommendationCommandCenter);
        this._savedSelectedOptionSetting = settings.find(x => x.name === this._settingName);

        let selectedOption: Compliance.NameValuePair<Core.AppealRecommendationCCAssignedFilterModel>;

        if (this._savedSelectedOptionSetting) {
            selectedOption = this.options.find(x => x.name === this._savedSelectedOptionSetting.value.name);

            if (selectedOption && selectedOption.name === "Custom") {
                for (const prop in this._savedSelectedOptionSetting.value.value) {
                    selectedOption.value[prop] = this._savedSelectedOptionSetting.value.value[prop];
                }
            }
        }

        if (!selectedOption) {
            selectedOption = this.options[0];
        }

        this.selectedOptionName = selectedOption.name;
        this.optionChange.emit(selectedOption.value);
    }

    async selectedOptionChanged() {
        const selectedOption = this.options
            .find(x => x.name === this.selectedOptionName);
        this.optionChange.emit(selectedOption.value);
        await this._saveSelectedOption(selectedOption);
    }

    async edit(): Promise<void> {
        const params: AssignedDetailsModalParams = {
            options: this.options,
            selectedOptionName: this.selectedOptionName,
            defaultCustomOption: this.defaultCustomOption
        };

        const result = await this._modalService.showAsync(AppealRecommendationCommandCenterAssignedDetailsModalComponent, params, 'modal-xl');

        if (!result) {
            return Promise.resolve();
        }

        this.selectedOptionName = result.name;

        if (this.selectedOptionName === 'Custom') {
            this.options[this.options.length - 1] = result;
        }

        this.optionChange.emit(result.value);

        await this._saveSelectedOption(result);
    }

    private async _saveSelectedOption(selectedOption: Compliance.NameValuePair<Core.AppealRecommendationCCAssignedFilterModel>) {
        if (!this._savedSelectedOptionSetting) {
            this._savedSelectedOptionSetting = {
                id: 0,
                name: this._settingName,
                groupId: Core.UserSettingGroup.AppealRecommendationCommandCenter,
                folderId: Core.UserSettingFolder.Default
            };
        }

        this._savedSelectedOptionSetting.value = selectedOption;
        this._savedSelectedOptionSetting = await this._userSettingsService.save(this._savedSelectedOptionSetting);
    }
}
