<ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions"></ag-grid-row-count>
<ws-ag-grid-angular [gridOptions]="gridOptions"
                    (gridReady)="onAgGridReady($event)"
                    style="display: block;"
                    [gridId]="gridId"
                    [isBulkDeleteVisible$]="isBulkDeleteVisible$"
                    [isBulkUpdateVisible$]="isBulkUpdateVisible$"
                    (bulkDelete)="bulkDelete()"
                    (bulkUpdate)="bulkUpdate()">
</ws-ag-grid-angular>
