import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { RestrictService, RestrictData, Roles, InstanceRights } from '../../../Common/Permissions/restrict.service';
import { NavigationService } from '../../../Layout/Navigation.Service.upgrade';
import { SiteService } from '../Site.Service.upgrade';
import { AttachmentModalData } from '../../../Attachment/attachment.modal.model';
import { Site, AssessorSummary } from '../Site.Model';
import { TaskService } from '../../../Task/task.service.upgrade';
import { MessageBoxService, MessageBoxResult, MessageBoxButtons } from '../../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { Constants, EntityTypeIds } from '../../../constants.new';
import { SiteClassService } from '../siteClass.service';
import { ChangeHistoryModalLaunchService } from '../../../Common/Change-History/change-history-modal-launch.service';
import { HelpContentComponentConfig, HelpService } from '../../../UI-Lib/Help-Tooltip';
import { AssessorDetailsComponent, AssessorDetailsComponentParams } from '../../Assessor-Details/assessorDetails.component';
import { StateService } from '../../../Common/States/States.Service';
import { StateSummary } from '../../../Common/States/state.model';
import { DeleteConfirmationComponentParams, DeleteConfirmationComponent } from '../../../Common/Delete-Confirmation/deleteConfirmation.component';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import * as _ from 'lodash';
import { SITE_INFO_PANEL_HELP } from './siteInfoPanel.component.help';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SiteMapService } from '../../site.map.service';
import { GoogleMapsSingleMarkerModalComponent } from '../../../Common/Address/google.maps.single.marker.modal.component';
import { InstanceRepository } from '../../Instance/instance.repository';
import { FeatureFlagsService } from '../../../Common/FeatureFlags/feature-flags-service';
import { UserInstanceService } from 'src/app/User/userInstance.service';
import { ActivityStatusService } from 'src/app/Common/Activity/activityStatus.service';

@Component({
    selector: 'site-info-panel',
    templateUrl: './siteInfoPanel.component.html',
    styleUrls: ['./siteInfoPanel.component.scss']
})
export class SiteInfoPanelComponent implements OnInit {
    constructor(
        public readonly toastr: ToastrService,
        public readonly featureFlagsService: FeatureFlagsService,
        private readonly _restrictService: RestrictService,
        private readonly _navigationService: NavigationService,
        private readonly _siteService: SiteService,
        private readonly _taskService: TaskService,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _siteClassService: SiteClassService,
        private readonly _activityStatusService: ActivityStatusService,
        private readonly _changeHistoryModalLaunchService: ChangeHistoryModalLaunchService,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _constants: Constants,
        private readonly _stateService: StateService,
        private readonly _modalService: WeissmanModalService,
        private readonly _helpService: HelpService,
        private readonly _fb: UntypedFormBuilder,
        private readonly _bsModalService: BsModalService,
        private readonly _siteMapService: SiteMapService,
        private readonly _userInstanceService: UserInstanceService,
        private readonly _instanceRepository: InstanceRepository
    ) { }

    @Input() site: Site;

    siteForm: UntypedFormGroup;

    states: StateSummary[];
    siteId: number;
    companyId: number;

    showSpinner: boolean = true;
    editMode: boolean = false;
    hasParcelInBatch: boolean;

    activityStatuses = [];
    siteClasses = [];
    imageUrls: {[img: string]: string};
    soldDatePickerIsOpen: boolean = false;
    allowUserToEditEntity: boolean = false;
    hasStateRoles: boolean = false;
    hasInstancePrivatePermission: boolean;
    hasMoveCopyPermission: boolean;
    permissionsLoaded: boolean;
    siteNumberRequired = true;

    customRenderer: HelpContentComponentConfig<AssessorDetailsComponent, AssessorDetailsComponentParams>;

    analyticsCategory: string = 'site';
    warningMessage = 'Editing is in progress. Are you sure you want to leave?';

    get fAccrualsExclude() { return (this.siteForm.get('accrualsExclude') as UntypedFormGroup); }

    get attachmentsModel(): AttachmentModalData {
        if (!this.site) {
            return null;
        }
        return {
            entityType: 'Site',
            entityName: this.site.name
        } as AttachmentModalData;
    }

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(SITE_INFO_PANEL_HELP);

        this.imageUrls = this._constants.ImageURLs;
        this.companyId = parseInt(this._routerService.getQuerystringParam('companyId'));
        this.siteId = parseInt(this._routerService.getQuerystringParam('siteId'));

        this.hasStateRoles = !this._restrictService.isNotInRoles([Roles.STATEVIEW, Roles.STATEEDIT]);

        this.entityEditPermissionCheck(this.siteId);

        this.site.transaction = this.site.transaction || {
            soldDate: null
        };
        this.siteNumberRequired = this.site.company.ppReturnPreparationAllowed;

        this.siteForm = this._fb.group({
            name: [this.site.name],
            address: this._fb.group({
                address1: [this.site.address.address1],
                address2: [this.site.address.address2],
                city: [this.site.address.city],
                stateID: [this.site.address.stateID],
                zip: [this.site.address.zip]
            }),
            property: [this.site.property],
            siteClassSecondaryID: [this.site.siteClassSecondaryID],
            activityStatusID: [this.site.activityStatusID],
            transaction: this._fb.group({
                soldDate: [this.site.transaction.soldDate]
            }),
            accrualsExclude: this._fb.group({
                excludeFromAccruals: new UntypedFormControl({ value: this.site.accrualsExclude.excludeFromAccruals, disabled: !this.editMode })
            })
        });

        const entityInstanceId = (await lastValueFrom(this._instanceRepository.getEntityInstanceId('site', this.site.siteID)));
        this.hasMoveCopyPermission = this._restrictService.hasInstanceRight(InstanceRights.ALLOWMOVECOPY, entityInstanceId);
        this.hasInstancePrivatePermission = this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSEDIT, entityInstanceId) || this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSVIEW, entityInstanceId);

        //only show assessor hover if user is in ryan instance
        this.customRenderer = this._userInstanceService.isCurrentInstanceRyan() ? {
            component: AssessorDetailsComponent,
            componentParams: {
                stateId: this.site.address.stateID,
                siteId: this.site.siteID
            },
            canHover: true
        } : null;

        this._loadActivityStatuses();
        this._loadSiteClasses();
        this._loadStates();
        this.showSpinner = false;
    }

    launchChangeHistoryModal(): void {
        this._changeHistoryModalLaunchService.openChangeHistoryModal(this.site.name, this.siteId, EntityTypeIds.SITE, null);
    }

    startEditMode(): void {
        this.editMode = true;
        if ( !this.site.accrualsExclude.parentExcludedFromAccruals )
            this.fAccrualsExclude.get('excludeFromAccruals').enable();
        else
            this.fAccrualsExclude.get('excludeFromAccruals').disable();
        this._navigationService.enableNavWarning(this.warningMessage);
    }

    cancelEditMode(): void {
        this.editMode = false;
        this.fAccrualsExclude.get('excludeFromAccruals').disable();
        this.siteForm.patchValue(this.site);
        this._navigationService.disableNavWarning();
    }

    goToMove(): void {
        this._routerService.go('moveSite', {
            companyID: this.companyId,
            siteID: this.siteId,
            isInactive: this.site.activityStatusID === Core.ActivityStatuses.Inactive
        });
    }

    goToCopy(): void {
        this._routerService.go('copySite', {
            companyID: this.companyId,
            siteID: this.siteId,
            isInactive: this.site.activityStatusID === Core.ActivityStatuses.Inactive
        });
    }

    goToAssessor(assessor: AssessorSummary): void {
        this._routerService.go('assessor', { stateId: this.site.address.stateID, id: assessor.assessorID });
    }

    getCommentsModalData() {
        return {
            entityTypeID: EntityTypeIds.SITE,
            entityID: this.site.siteID,
            description: `Site: ${  this.site.name}`,
            canEdit: this.allowUserToEditEntity
        };
    }

    async saveSiteToAPI(siteToSave): Promise<void> {
        this.showSpinner = true;

        try {
            const savedSite = await this._siteService.update(siteToSave, this.companyId);
            this._navigationService.disableNavWarning();
            this._siteService.updateParcelList();
            this.site = savedSite;
            this._siteService.site = savedSite;
            this.editMode = false;
            this.fAccrualsExclude.get('excludeFromAccruals').disable();
            this.showSpinner = false;
        } catch(err) {
            this.showSpinner = false;
            if (err.modelState != null){
                err.modelState.forEach((state) => {
                    this.toastr.error('Error!', `${state.field  }: ${  state.msg}`);
                });
            }
            if (err.error != null){
                this.toastr.error('Error!', err.error.message);
            }
        }
    }

    async saveSite(): Promise<void> {
        if (this.editMode) {

            const siteToSave = { ...this.site, ...this.siteForm.value };
            siteToSave.address = { ...this.site.address, ...this.siteForm.get('address').value };

            const isSiteNameNonAscii = siteToSave.name && !(/^[ -~\t\n\r]+$/.test(siteToSave.name));
            if (isSiteNameNonAscii) {
                this.toastr.error('Site Name contains invalid character(s)');
                return;
            }
            const isSiteNumberNonAscii = siteToSave.property && !(/^[ -~\t\n\r]+$/.test(siteToSave.property));
            if (isSiteNumberNonAscii) {
                this.toastr.error('Site Number contains invalid character(s)');
                return;
            }

            // keep the old one until user confirms the change
            this.site.activityStatusID = this.site.activityStatusID;    // TODO Umm, this is a self assignment, what is it supposed to be doing???

            if (siteToSave.activityStatusID !== this.site.activityStatusID) {

                if (siteToSave.activityStatusID === Core.ActivityStatuses.Inactive) {
                    const checkIfEntityMayBeDeactivatedResult = await this._taskService.checkIfEntityMayBeDeactivated(siteToSave.siteID, 5);

                    if (checkIfEntityMayBeDeactivatedResult.canBeDeactivated === false) {
                        await this._messageBoxService.open({
                            title: '',
                            message: 'This site\'s status cannot be changed to Inactive because at least some of its parcels have open appeals or refunds.',
                            buttons: MessageBoxButtons.OK
                        });
                        //	Change the value in the dropdown back to its previous value.
                        this.siteForm.patchValue({ activityStatusID: this.site.activityStatusID });

                        // submit button has been clicked - we need to show form again
                        this.editMode = true;

                    } else {
                        if (checkIfEntityMayBeDeactivatedResult.openPaymentBatchTaskCount > 0) {
                            await this._messageBoxService.open({
                                title: '',
                                message: 'This site\'s status cannot be changed to Inactive because at least some of its batches have open payments.',
                                buttons: MessageBoxButtons.OK
                            });
                            //	Change the value in the dropdown back to its previous value.
                            this.siteForm.patchValue({ activityStatusID: this.site.activityStatusID });
                        }
                        else {
                        // this site doesn't have any open appeals or refunds, confirm with user
                        let msg = 'Changing the site\'s status to Inactive will result in all client service responsibilities being changed to N/A on the site and all of its parcels. ';
                        msg += ` This will result in ${  checkIfEntityMayBeDeactivatedResult.openFilingTaskCount  } filing task(s) `;
                        msg += ` and ${  checkIfEntityMayBeDeactivatedResult.openAssessmentTaskCount  } assessment task(s) `;
                        msg += ` and ${  checkIfEntityMayBeDeactivatedResult.openTaxBillTaskCount  } tax bill task(s) to no longer be scheduled. `;
                        msg += ' NO FURTHER WORK OF ANY KIND WILL BE SCHEDULED FOR THIS ENTITY. ';

                        msg += ' Are you sure you want to proceed?';

                        const okDialogResult = await this._messageBoxService.open({
                            title: 'Changing site\'s status to Inactive',
                            message: msg,
                            buttons: MessageBoxButtons.YesNo
                        });
                        if (okDialogResult === MessageBoxResult.Yes) {
                            //siteToSave.statusDate = new Date();
                            return this.saveSiteToAPI(siteToSave);
                        }
                        else {
                            // user wants to cancel
                            //	Change the value in the dropdown back to its previous value.
                            this.siteForm.patchValue({ activityStatusID: this.site.activityStatusID });
                            this.editMode = true;

                        }
                    }
                }
                }
                else {
                    // activity status has been changed, but site is not deactivated

                    if (this.site.activityStatusID === Core.ActivityStatuses.Inactive
                            || ( this.site.activityStatusID === Core.ActivityStatuses.ActivePending
                            && siteToSave.activityStatusID === Core.ActivityStatuses.Active)    //  Per https://weissmandemo.atlassian.net/browse/PT-3597
                    ) {
                        return this.saveSiteToAPI(siteToSave);
                    }
                    else {
                        const okDialogResult = await this._messageBoxService.open({
                            title: 'Changing site\'s status',
                            message: 'Site status changes will cascade to all of its parcels. Proceed?',
                            buttons: MessageBoxButtons.YesNo
                        });
                        if (okDialogResult === MessageBoxResult.Yes) {
                            return this.saveSiteToAPI(siteToSave);
                        }
                        else {
                            // user wants to cancel
                            //	Change the value in the dropdown back to its previous value.
                            this.siteForm.patchValue({ activityStatusID: this.site.activityStatusID });
                            this.editMode = true;

                        }
                    }

                }
            }
            else {
                // activity status has not been changed; just saving
                return this.saveSiteToAPI(siteToSave);
            }

        }
    }

    async deleteSite(): Promise<void> {
        const params: DeleteConfirmationComponentParams = {
                item: `Site ${this.site.name}`,
                url: `/api/site/${this.site.siteID}`,
                message: 'Are you sure you want to delete this site? If you choose to proceed, all information under this site (parcels, assessments, appeals, tax bills etc) will also be removed and can not be recovered.'
        };

        const result = await this._modalService.showAsync(DeleteConfirmationComponent, params, 'modal-md');

        if (result) {
            this._navigationService.disableNavWarning();

            this._routerService.go('company',
                {
                    companyId: this.companyId
                });
        }
    }

    async launchSiteMap() {
        const params = {
            site: this.site
        };

        this._modalService.showAsync(GoogleMapsSingleMarkerModalComponent, params, 'modal-md');
    }

    getInactiveURL(): string {
        return  `/images/${(this.site.activityStatusID === Core.ActivityStatuses.Inactive) ? 'inactive' : 'active-pending'}.jpg`;
    }

    getSiteClassName(id: number): string {
        if (!id || this.siteClasses.length === 0) {
            return;
        }
        const foundSiteClass = this.siteClasses.find((site) => site.siteClassSecondaryID === id);
        return foundSiteClass && foundSiteClass.name;
    }

    async entityEditPermissionCheck(id: number): Promise<void> {
        const restrictionData: RestrictData = new RestrictData();
        restrictionData.isCompany = false;
        restrictionData.entityId = id;
        restrictionData.flag = Core.AccessRightsEnum.Write;

        try {
            const result = await this._restrictService.hasPermission(restrictionData);
            this.allowUserToEditEntity = result;
            this.permissionsLoaded = true;
        } catch (err) {
            console.log('checkEntity - error', err);
        }
    }

    onSoldDateChanged(value: Date): void {
        this.siteForm.get('transaction').get('soldDate').markAsTouched();
        this.siteForm.patchValue({
            transaction: { soldDate: (value) ? value : null}
        });
    }

    hasParcels(): boolean {
        return this._siteService.hasParcels();
    }

    getState(id: number): string {
        if (!this.states) { return; }
        const state = this.states.find((x) => x.stateID === id);
        return (state) ? state.abbr : '';
    }

    goToValuationTool() {
        window.open(this.site.valuationToolUrl as unknown as string, '_blank');
    }

    //TODO this will not work. result is a number
    private _processAttachmentDialogResult(result): void {
        if (!result) return;
        this.site.hasImages = result.hasImages;
    }

    private async _loadActivityStatuses(): Promise<void> {
        const result = await this._activityStatusService.get();
        this.activityStatuses = _.sortBy(result, 'status');
    }

    private async _loadSiteClasses(): Promise<void> {
        const result = await this._siteClassService.getSecondaries();

        this.siteClasses = _.chain(result)
            .map((siteClass: any) => {
                siteClass.name = `${siteClass.siteClassPrimary.siteClassPrimaryDesc} - ${siteClass.siteClassDesc}`;
                return siteClass;
            })
            .sortBy('name')
            .value();
    }

    private async _loadStates(): Promise<void> {
        this.states = await this._stateService.getSummary();
    }

}
