import { Component, OnInit } from '@angular/core';
import { AccountService } from './User/account.service';
import { setTheme } from 'ngx-bootstrap/utils';
import { HostListener } from '@angular/core';
import { FeatureFlagsService } from './Common/FeatureFlags/feature-flags-service';
import { AuthenticationResult } from '@azure/msal-browser';
import { StartupService } from './Hybrid/startup.service.upgrade';
import { SettingsService } from './Common/Settings/settings.service';
import { AppStateService, AppStates } from './Layout/appStateService';
import { ScriptLoaderService, ScriptLocations } from './UI-Lib/Utilities/scriptLoader.service';
import { AceAuthOidcWrapperService } from './IAM/aceAuthOidcWrapper.service';
import { filter } from 'rxjs';
import { EasyAccessService } from './Common/Services/easyAccess.service';
import { AuthStorageService, StorageTokenKeys } from './User/authStorage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    aceOidcWrapperEnabled: boolean;
    isAttachmentPopup: boolean;
    appState: AppStates;
    get AppStates() : typeof AppStates {
        return AppStates;
    }

    constructor(
        public _featureFlagsService: FeatureFlagsService,
        private _accountService: AccountService,
        private _startupService: StartupService,
        private _settingsService: SettingsService,
        private _appStateService: AppStateService,
        private _scriptLoaderService: ScriptLoaderService,
        private _aceAuthOidcWrapperService: AceAuthOidcWrapperService,
        private _easyAccessService: EasyAccessService
    ) {
        this.aceOidcWrapperEnabled = false;
        this.isAttachmentPopup = window.location.href.indexOf('#/attachmentPopup') >= 0;
        this._appStateService.appState$.subscribe(s => this.appState = s);
        setTheme('bs5');
    }

    @HostListener('window:authResponseOverride')
    handleAuthResponseOverride() {
        this._accountService.beginHomeRealmDiscovery();
    }

    @HostListener('window:authResponse', ['$event.detail'])
    handleAuthresponse(result: AuthenticationResult) {
        if (
            this._featureFlagsService.featureFlags.enableIAMLogin &&
            this.appState === AppStates.Initializing &&
            result &&
            result.idToken
        ) {
            console.log('authResponse', result);
            this._accountService.loginFromAceOidc(result.idToken);
        }
    }

    async ngOnInit() {
        const windowRef = <{
            gmapsLoaded: () => void,
            weissmanNg1Bootstrap: () => void,
            easyAccess: EasyAccessService
        }>(window as any);

        windowRef.gmapsLoaded = () => {};
        windowRef.easyAccess = this._easyAccessService;
        console.log('Debugging/troubleshooting tools are available on the window.easyAccess object');

        this._scriptLoaderService.loadScript(ScriptLocations.GoogleMaps, false).then(() => { });
        this._aceAuthOidcWrapperService.authWrapperEnabled$.pipe(filter(x => x)).subscribe(() => {
            this.aceOidcWrapperEnabled = true;
        });

        this._startupService.initializeNavService();
        await this._settingsService.Load();
        await this._accountService.checkPersistedLogin();
        windowRef.weissmanNg1Bootstrap();
    }
}
