import { Component, OnInit } from '@angular/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AgGridColumns, AgGridOptionsBuilder } from '../../../Compliance/AgGrid';
import {
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer,
    AgGridMultiSelectRendererParams, AgGridMultiSelectTracker
} from '../../../Compliance/AgGrid/MultiSelectTracker';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import {
    ParcelTypeCellRendererComponent,
    ParcelTypeCellRendererComponentParams
} from '../../../Entity/Parcel/Parcel-Type-Cell-Renderer/parcelTypeCellRenderer.component';
import { UpgradeNavigationServiceHandler } from '../../Routing/upgrade-navigation-handler.service';
import { ActivityService } from '../activity.service';
import {
    ActivityStatusCellRendererComponent
} from './Activity-Status-Cell-Renderer/activityStatusCellRenderer.component';

interface ParcelFilterModalComponentParams {
    parcels: any[];
    selectedParcels: number[];
}

@Component({
    selector: 'parcel-filter-modal',
    templateUrl: './parcelFilterModal.component.html'
})
export class ParcelFilterModalComponent implements OnInit, IWeissmanModalComponent<ParcelFilterModalComponentParams, number[]> {
    constructor(private readonly _bsModalRef: BsModalRef,
                private readonly _navigationService: UpgradeNavigationServiceHandler,
                private readonly _activityService: ActivityService) {
    }

    params: ParcelFilterModalComponentParams;
    result: number[] = [];

    excludeInactive: boolean = true;
    parcels: any[] = [];
    gridTracker: AgGridMultiSelectTracker;
    gridId: System.Guid = '9bb2d71b-40cd-437b-9941-2aa1238a5ae0';
    gridOptions: GridOptions = new AgGridOptionsBuilder({
        rowClassRules: {
            'ag-row-selected': (params) => {
                const parcel = params.data;
                return parcel && this.gridTracker.isRowSelected(parcel.parcelID);
            }
        }
    })
        .withContext(this)
        .withLoadingOverlay()
        .withMultipleColumnSort()
        .withColumnResize()
        .withTextSelection()
        .withRowId((data) => {
            const parcel = data;
            return parcel && parcel.parcelID.toString();
        })
        .withColumnPinning()
        .build();

    siteId: number;

    private _allParcels: any[] = [];
    private _gridApi: GridApi;

    ngOnInit(): void {
        this.excludeInactive = this._activityService.excludeInactive;
        this.siteId = +this._navigationService.getQuerystringParam('siteId');
        this._allParcels = this.params.parcels;
        this.parcels = this.excludeInactive ? this._allParcels.filter(x => x.activityStatus === 'Active') : this._allParcels;
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        if (this.parcels) {
            this._setColumnDefinitions();
        }

        this._setRowData();

        const selectAllRows = this.params.selectedParcels.length === this.parcels.length;
        this.gridTracker.setSelectedRowsModel({
            selectAllRows: selectAllRows,
            selectedRows: selectAllRows ? [] : [...this.params.selectedParcels]
        });
        this._gridApi.redrawRows();
    }

    selectedParcelCount(): number {
        return this.parcels.reduce((acc, p) => (acc += p.isIncluded ? 1 : 0), 0);
    }

    updateParcels(excludeInactive: boolean): void {
        this.excludeInactive = excludeInactive;
        this._activityService.excludeInactive = excludeInactive;
        this.parcels = this._allParcels.filter(x => !excludeInactive || x.activityStatus === 'Active');
        this._setRowData();
    }

    async updateParcelFilter(): Promise<void> {
        this.result = await this.gridTracker.getSelectedRowIds();
        if (this.result.length === 0) {
            this.result = this._allParcels.map(x => x.parcelID);
        }
        this._bsModalRef.hide();
    }

    private _setColumnDefinitions(): void {
        const columns: ColDef[] = [
            {
                colId: 'grid-column-select',
                field: 'parcelID',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                editable: false,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams
            },
            {
                headerName: 'Type',
                field: 'propType',
                width: 50,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                cellRendererFramework: ParcelTypeCellRendererComponent,
                cellRendererParams: {
                    getParcelType: (params: ParcelTypeCellRendererComponentParams): string => {
                        const parcel = params.data;
                        return parcel.propertyType;
                    }
                }
            },
            {
                headerName: 'Acct Num',
                field: 'accountNumber',
                lockVisible: true,
                valueFormatter: (params) => {
                    const parcel = params.data;
                    return (!parcel.isTotalRow) ? parcel.acctNum : 'TOTAL';
                }
            },
            {
                headerName: 'Status',
                field: 'activityStatus',
                lockVisible: true,
                cellRendererFramework: ActivityStatusCellRendererComponent
            }
        ];

        const defaultSortModel = [
            {
                colId: 'acctNum',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
    }

    private _setRowData(): void {
        if (!this._gridApi) {
            return;
        }

        this.gridTracker && this.gridTracker.clear();

        this._gridApi.setRowData(this.parcels);
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        const queryResultModel: Compliance.QueryResultModel<number> = {
            lastModifiedTimestamp: new Date(),
            totalRows: rows.length,
            totalValidRows: rows.length,
            data: rows.map((x) => {
                const parcel = x.data;
                return parcel && parcel.parcelID;
            })
        };

        return Promise.resolve(queryResultModel);
    }
}
