import { Component, OnInit, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { LaunchAddCompanyModalService } from '../Add/launchAddCompanyModal.service';
import { CompanyModel, CompanyService } from '../company.service';
import { ParentCompanyModel } from './companyInfoPanel.component';
import { Address } from '../../../Common/Models/common.model';
import {
    AddressDetailsModalLaunchService
} from '../../../Common/Address/Address-View-Edit/address.details.modal.launch.service';
import { NavigationService } from '../../../Layout/Navigation.Service.upgrade';
import { InstanceRights, RestrictService } from '../../../Common/Permissions/restrict.service';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { EntityAddress } from '../../../Common/Address/Address-View-Edit/address.model';
import { MessageBoxButtons, MessageBoxResult, MessageBoxService } from 'src/app/UI-Lib/Message-Box/messagebox.service.upgrade';
import { FeatureFlagsService } from '../../../Common/FeatureFlags/feature-flags-service';

@Component({
    selector: 'company-info-panel-edit',
    templateUrl: 'companyInfoPanelEdit.component.html'
})

export class CompanyInfoPanelEditComponent implements OnInit {
    constructor(
        private readonly _addressDetailsModalLaunchService: AddressDetailsModalLaunchService,
        private readonly _navigationService: NavigationService,
        private readonly _companyService: CompanyService,
        private readonly _toastr: ToastrService,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _launchAddCompanyModalService: LaunchAddCompanyModalService,
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _restrictService: RestrictService,
        private readonly _featureFlagsService: FeatureFlagsService) { }

    @Input() company: CompanyModel;
    @Input() defaultAddress: Address;
    @Input() parentCompany: ParentCompanyModel;
    @Input() infoForm: UntypedFormGroup;
    @Input() saveFunc: () => Promise<void>;
    @Input() activatedByPopover: TemplateRef<any>;
    @Output() serverActionChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    showAddSubsidiary: boolean = false;
    statuses: {id: number; value: string}[];
    hasMoveCopyPermission: boolean = false;
    isIAMEnabled: boolean = false;

    private readonly _navWarning = 'Editing is in progress. Are you sure you want to leave?';

    get isTopLevel(): boolean {
        return !this.company.parentId;
    }

    get nonDeletedEntityAddresses(): Weissman.Model.Misc.EntityAddress[] {
        return _.reject(this.company.entityAddresses, {efAction: 'delete'});
    }

    async ngOnInit() {
        this.hasMoveCopyPermission = this._restrictService.hasInstanceRight(InstanceRights.ALLOWMOVECOPY, this.company.instanceID);
        this.isIAMEnabled = this._featureFlagsService.featureFlags.enableIAM;

        if(this.isTopLevel) {
            this.showAddSubsidiary = this._restrictService.hasInstanceRight(InstanceRights.ADDNEWCOMPANY, this.company.instanceID);
        }

        this.statuses = [{
            id: Core.ActivityStatuses.Active,
            value: 'Active'
        }, {
            id: Core.ActivityStatuses.ActivePending,
            value: 'Active (Pending)'
        }, {
            id: Core.ActivityStatuses.Inactive,
            value: 'Inactive'
        }];
    }

    async launchAddressDetailsModal(): Promise<void> {
        this._navigationService.disableNavWarning();
        const entityAddresses = await this._addressDetailsModalLaunchService.openAddressDetailsModal(
            this.company.companyID,
            Core.EntityTypes.Company,
            _.cloneDeep(this.company.entityAddresses) as unknown as EntityAddress[],
            true
        );
        this.infoForm.patchValue({entityAddresses});

        this._navigationService.enableNavWarning(this._navWarning);
    }

    async addSubsidiary(): Promise<void> {
        if(this.infoForm.dirty) {
            const result = await this._messageBoxService.open({
                message: 'There is unsaved data!  Save before continuing?',
                buttons: MessageBoxButtons.YesNo
            }, true);

            if (result === MessageBoxResult.Yes) {
                await this.saveFunc();
            }
        }

        this._navigationService.disableNavWarning();
        await this._launchAddCompanyModalService.addCompany(this.company);
    }

    goToMoveCopy(isMove?: boolean): void {
        const state = isMove ? 'moveCompany' : 'copyCompany';

        this._upgradeNavigationServiceHandler.go(state, {
            companyID: this.company.companyID,
            isInactive: this.company.activityStatusID === Core.ActivityStatuses.Inactive
        });
    }

    async deleteCompany(): Promise<void> {
        const result = await this._messageBoxService.open({
            message: 'Confirm Deleting Company',
            buttons: MessageBoxButtons.OKCancel
        });

        if (result === MessageBoxResult.OK) {
            this.serverActionChange.emit(true);

            try {
                await this._companyService.deleteCompany(this.company.companyID);
                this._toastr.info('Company Deleted!');
                this._navigationService.disableNavWarning();
                this._upgradeNavigationServiceHandler.go('home', {});
            } finally {
                this.serverActionChange.emit(false);
            }
        }
    }
}
