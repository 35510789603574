import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HelpService } from '../../../../UI-Lib/Help-Tooltip';
import { RETURN_LOCK_HELP } from './returnLockModal.component.help';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';

class ReturnLockDropdownItem {
    name: string;
    label: string;
    value: number;
}

export interface ReturnLockModalParams {
    filingBatchId: number;
    companyId: number;
    returnTaskUpdate: Compliance.ReturnTaskUpdateModel;
}

@Component({
    selector: 'return-lock-modal',
    templateUrl: './returnLockModal.component.html'
})
export class ReturnLockModalComponent implements OnInit, IWeissmanModalComponent<ReturnLockModalParams, Compliance.ReturnLockRequestModel> {
    constructor(
        private _bsModalRef: BsModalRef,
        private readonly _helpService: HelpService) { }

    onClose: any;
    packageStrategies: ReturnLockDropdownItem[];
    selectedPackageStrategy: ReturnLockDropdownItem;
    returnInOwnPackageThresholdSelected: boolean = true;
    returnInOwnPackageThreshold: number;
    certifiedMailPackageThresholdSelected: boolean = true;
    certifiedMailPackageThreshold: number;
    validateReports: boolean = false;

    params: ReturnLockModalParams;
    result: Compliance.ReturnLockRequestModel;

    ngOnInit() {
        this._helpService.setContent(RETURN_LOCK_HELP);

        this.packageStrategies = [
            { name: 'Assessor', value: Compliance.ReturnLockPackageStrategyEnum.PackageByAssessor, label: 'Package by Assessor' },
            { name: 'Company', value: Compliance.ReturnLockPackageStrategyEnum.PackageByCompany, label: 'Package by Company and Assessor' },
            { name: 'Return', value: Compliance.ReturnLockPackageStrategyEnum.PackageByReturn, label: 'One Return Per Package' }
        ];

        this.selectedPackageStrategy = this.packageStrategies[0];
        this.returnInOwnPackageThreshold = 250000;
        this.certifiedMailPackageThreshold = 50000;
    }

    async save(): Promise<void> {
        this.result = {
            companyId: this.params.companyId,
            filingBatchId: this.params.filingBatchId,
            returnLockPackageStrategy: this.selectedPackageStrategy.value,
            returnTaskUpdate: this.params.returnTaskUpdate,
            validateReports: this.validateReports
        };

        if (this.certifiedMailPackageThresholdSelected) {
            this.result.certifiedMailPackageThreshold = this.certifiedMailPackageThreshold;
        }

        if (this.returnInOwnPackageThresholdSelected) {
            this.result.returnInOwnPackageThreshold = this.returnInOwnPackageThreshold;
        }

        this._bsModalRef.hide();
    }

    packageStrategyChanged(): void {
        if (this.selectedPackageStrategy == this.packageStrategies[2]){
            this.returnInOwnPackageThresholdSelected = false;
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}
