import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { HelpContentComponentConfig } from '../../../UI-Lib/Help-Tooltip';
import { AgGridHeaderLegendTooltipComponent, AgGridHeaderLegendTooltipParams } from './agGridHeaderLegendTooltip.component';
import { takeUntil } from 'rxjs/operators';
import { fromEvent, fromEventPattern, Subject } from 'rxjs';
import { AgGridPersistenceService } from '../agGridPersistence.service';

@Component({
    selector: 'ws-cell-header',
    template: `
        <div class="ag-cell-label-container"
             role="presentation"
             helpTooltip
             position="top"
             [helpDisabled]="!(groupColorClass && showGroupColor)"
             [contentComponent]="customRenderer"
             (click)="cycleSort($event)">
            <div class="ag-header-cell-label" role="presentation" unselectable="on">
                <span class="ag-header-cell-text" unselectable="on">{{params.displayName}}</span>
                <span *ngIf="params.column.isFilterActive()" class="ag-header-icon ag-header-label-icon ag-filter-icon">
                    <span class="ag-icon ag-icon-filter"></span>
                </span>
                <span *ngIf="params.enableSorting && params.column.isSorting()" class="ag-header-icon ag-header-label-icon ag-sort-order">{{sortOrderNumber}}</span>
                <span *ngIf="params.enableSorting && params.column.isSortAscending()" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon">
                    <span class="ag-icon ag-icon-asc"></span>
                </span>
                <span *ngIf="params.enableSorting && params.column.isSortDescending()" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon">
                    <span class="ag-icon ag-icon-desc"></span>
                </span>
            </div>
        </div>
    `,
    host: {'class': 'ag-cell-label-container'}
})
export class AgGridHeaderCellRendererComponent implements IHeaderAngularComp {
    constructor(private readonly _agGridPersistenceService: AgGridPersistenceService) {}

    params: IHeaderParams;
    sortOrderNumber: number;
    showGroupColor: boolean;
    groupColorClass: string;
    customRenderer: HelpContentComponentConfig<AgGridHeaderLegendTooltipComponent, AgGridHeaderLegendTooltipParams>;

    private _destroy$: Subject<void> = new Subject();

    agInit(params: IHeaderParams): void {
        this.params = params;

        // fromEvent should see that GridAPI has addEventListener, but for whatever reason it doesn't recognize the signature.
        // Use fromEventPattern to force rxjs to adapt the handler (note fromEvent worked in rxjs 6).
        fromEventPattern(handler => this.params.api.addEventListener('sortChanged', handler))
            .pipe(takeUntil(this._destroy$))
            .subscribe(x => this._sortChanged());

        if (this.params.context && this.params.context.gridId) {
            this._agGridPersistenceService.isHeaderGroupColorActive$(this.params.context.gridId)
                .pipe(takeUntil(this._destroy$))
                .subscribe(x => this.showGroupColor = x);
        } else {
            console.log('The parent component of the grid does not contain a gridId property, column header colors can not be displayed');
        }

        this._initializeHeader();
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    refresh(params: IHeaderParams): void {
        this.params = params;

        this._initializeHeader();
    }

    cycleSort(event): void {
        if(this.params.enableSorting) {
            this.params.progressSort(event.ctrlKey);
        }
    }

    private _initializeHeader(): void {
        const groupName = this.params.column.getColDef().toolPanelClass as string;
        if (groupName) {
            this.groupColorClass = this._agGridPersistenceService.getColorClassForGroupType(groupName);

            this.customRenderer = {
                component: AgGridHeaderLegendTooltipComponent,
                componentParams: {
                    groupName,
                    groupColor: this.groupColorClass,
                },
                canHover: false
            }
        }
    }

    private _sortChanged(): void {
        if (this.params.enableSorting && this.params.column.isSorting()) {
            // Converting to any is a hack to get sort order without jumping through hoops
            const cols = (this.params.api as any).sortController.getColumnsWithSortingOrdered();
            if (cols.length > 1) {
                this.sortOrderNumber = cols.findIndex(x => x.colId === this.params.column.getColId()) + 1;
            }
        }
    }
}
