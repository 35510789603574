import { Component, OnInit } from "@angular/core";
import { NavigationService } from "../../Layout/Navigation.Service.upgrade";
import { ProductAnalyticsService } from "../../Common/Amplitude/productAnalytics.service";

@Component({
    selector: 'quick-search-button-widget',
    template: `
    <div class="widget-button">
            <h2 class="text-center">Quick Search</h2>
            <div class="widget-button-content">
                <div><i class="fa fa-search fa-5x fa-fw widget-button-icon" (click)="openQuickSearch(true)"></i></div>
                <div class="widget-button-text">Navigate to a Company, Site, or Parcel. Access Quick Search by clicking the magnifying glass icon in the upper right-hand corner, or press alt-q.</div>
            </div>
    </div>
    `,
    styleUrls: ['dashboard-widget.scss']
})
export class QuickSearchButtonWidgetComponent {
    constructor(
        private readonly _navigationService: NavigationService,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) { }

    openQuickSearch(resetFilter: boolean): void {
        this._productAnalyticsService.logEvent('initiate-quick-search', { initiateQuickSearch: 'dashboard' });
        this._productAnalyticsService.logEvent('click-quick-search-widget');
        this._navigationService.openQuickSearch(resetFilter, 'dashboard');
    }
}
