import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';
import ReturnFinalizeSyncValueOptionEnum = Compliance.ReturnFinalizeSyncValueOptionEnum;

export interface ReturnFinalizeModalParams {
    filingBatchId: number;
    companyId: number;
    taskUpdateModel: Compliance.ReturnTaskUpdateModel;
    nextYearBatch: Compliance.FilingBatchCreateModel;
}

@Component({
    selector: 'return-finalize-modal',
    templateUrl: './returnFinalizeModal.component.html',
    styleUrls: ['./returnFinalizeModal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ReturnFinalizeModalComponent implements OnInit, OnDestroy, IWeissmanModalComponent<ReturnFinalizeModalParams, Compliance.ReturnFinalizeRequestModel> {
    constructor(private readonly _bsModalRef: BsModalRef) {}

    params: ReturnFinalizeModalParams;
    result: Compliance.ReturnFinalizeRequestModel;

    finalizeOptionsForm: UntypedFormGroup;
    filingBatchGroup: UntypedFormGroup;
    createNextYear: UntypedFormControl = new UntypedFormControl(false);
    nextYear: number;
    minDate: Date;

    private _attachReturns = new UntypedFormControl(true);
    private _embedExcelReportsInPdf = new UntypedFormControl(false);
    private _syncTargetValue = new UntypedFormControl(ReturnFinalizeSyncValueOptionEnum.DepreciatedValue);
    private _syncEstimatedFMV = new UntypedFormControl(ReturnFinalizeSyncValueOptionEnum.DepreciatedValue);
    private _destroy$: Subject<void> = new Subject<void>();

    syncOptions: System.Collections.Generic.KeyValuePair<Compliance.ReturnFinalizeSyncValueOptionEnum, string>[] = [
        { key: ReturnFinalizeSyncValueOptionEnum.No, value: 'No' },
        { key: ReturnFinalizeSyncValueOptionEnum.DepreciatedValue, value: 'Dep. Value' },
        { key: ReturnFinalizeSyncValueOptionEnum.EstimatedFMV, value: 'Est. FMV' }
    ];

    // validators
    nameValidation = (control: UntypedFormControl): ValidationErrors | null => (control.value && control.value.length) ? null : { nameInvalid: true };
    formValidation = (control: UntypedFormGroup): ValidationErrors | null => {
        if (this.createNextYear.value) {
            const subGroup = control.get('nextYearBatchModel');
            return (subGroup.get('description').valid
                && subGroup.get('dueDate').valid) ? null : { formInvalid: true };
        }
        return null;
    };

    ngOnInit(): void {
        this.nextYear = this.params.nextYearBatch.taxYear;
        const day = this.params.nextYearBatch.dueDate.getDate();
        const month = this.params.nextYearBatch.dueDate.getMonth();
        this.minDate = new Date(this.nextYear, month, day);

        this.filingBatchGroup = new UntypedFormGroup({
            description: new UntypedFormControl({ value: null, disabled: true }, this.nameValidation),
            dueDate: new UntypedFormControl({ value: this.minDate, disabled: true }, [Validators.required]),
            createFilingsAsNecessary: new UntypedFormControl({ value: false, disabled: true }),
            taxYear: new UntypedFormControl(this.nextYear)
        });

        this.filingBatchGroup.patchValue(this.params.nextYearBatch);
        this.filingBatchGroup.disable();

        this.finalizeOptionsForm = new UntypedFormGroup({
            attachReturns: this._attachReturns,
            embedExcelReportsInPdf: this._embedExcelReportsInPdf,
            syncTargetValue: this._syncTargetValue,
            syncEstimatedFMV: this._syncEstimatedFMV,
            nextYearBatchModel: this.filingBatchGroup
        }, this.formValidation);

        this.createNextYear.valueChanges.pipe(takeUntil(this._destroy$))
            .subscribe(checked => this._toggleNextYearGroup(checked));
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    /**
     * Submit the form
     * */
    submit(): void {
        this.result = {
            filingBatchId: this.params.filingBatchId,
            companyId: this.params.companyId,
            taskUpdateModel: this.params.taskUpdateModel,
            attachReturns: this.finalizeOptionsForm.get('attachReturns').value,
            embedExcelReportsInPdf: this.finalizeOptionsForm.get('embedExcelReportsInPdf').value,
            syncEstimatedFMV: this.finalizeOptionsForm.get('syncEstimatedFMV').value,
            syncTargetValue: this.finalizeOptionsForm.get('syncTargetValue').value,
            nextYearBatchModel: this.createNextYear.value ? this.finalizeOptionsForm.get('nextYearBatchModel').value : null,
            force: false
        };

        this._bsModalRef.hide();
    }

    /**
     * Close the modal
     * */
    cancel(): void {
        this._bsModalRef.hide();
    }

    /**
     * Set the due date value from the date picker
     * @param date
     */
    onDueDateChanged(date: Date): void {
        this.filingBatchGroup.get('dueDate').setValue(date);
    }

    private _toggleNextYearGroup(enabled: boolean): void {
        if (enabled) {
            this.filingBatchGroup.enable();
        } else {
            this.filingBatchGroup.disable();
        }
    }
}
