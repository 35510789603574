(function () {
    'use strict';

    var routes = null;

    angular
        .module('weissmanApp')
        // routes is built internally in configRoutes, but make the object availble to other services
        // by exposing a factory to return it.
        .factory('routes', function () { return routes; })
        .config(configRoutes);

    configRoutes.$inject = ['$urlRouterProvider', '$stateProvider', 'Roles', 'EntityTypeIDs', 'InstanceRights','ContactModalOrigin'];

    function configRoutes($urlRouterProvider, $stateProvider, Roles, EntityTypeIDs, InstanceRights, ContactModalOrigin) {
        var baseUrl = 'app/';

        routes = {
            'company': {
                url: '/company/:companyId',
                template: '<company-page></company-page>',
                resolve: {
                    $title: function () {
                        return 'Company';
                    }
                },
            },
            'site': {
                url: '/company/:companyId/site/:siteId',
                views: {
                    '': {
                        templateUrl: baseUrl + 'Entity/Site/_panels.html',
                        controller: 'sd.Site.Controller',
                        controllerAs: 'vm',
                    },
                    'contacts@site': {
                        templateUrl: baseUrl + 'Entity/Site/_contactsPanel.html',
                        controller: 'sd.Site.Contacts.Panel.Controller',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    $title: function () {
                        return 'Site';
                    }
                },
            },
            'parcel': {
                url: '/company/:companyId/site/:siteId/parcel/:parcelId?annualYearID?billClusterID?refundID?annualAssessmentID?appealID?filingId',
                templateUrl: baseUrl + 'Entity/Parcel/_parcel.html',
                controller: 'ParcelCtrl',
                resolve: {
                    $title: function () {
                        return 'Parcel';
                    }
                },
            },
            'siteRedirect': {
                url: '/site/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.SITE; }
                }
            },
            'parcelRedirect': {
                url: '/parcel/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.PARCEL; }
                }
            },
            'assessmentRedirect': {
                url: '/assessment/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.ASSESSMENT; }
                }
            },
            'appealRedirect': {
                url: '/appeal/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.APPEAL; }
                }
            },
            'billClusterRedirect': {
                url: '/billcluster/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.BILL_CLUSTER; }
                }
            },
            'billRedirect': {
                url: '/bill/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.TAX_BILL; }
                }
            },
            'filingRedirect': {
                url: '/filing/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.FILING; }
                }
            },
            'paymentRedirect': {
                url: '/payment/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.PAYMENT; }
                }
            },
            'refundRedirect': {
                url: '/refund/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.REFUND; }
                }
            },
            'collectorRedirect': {
                url: '/collector/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.COLLECTOR; }
                }
            },
            'assessorRedirect': {
                url: '/assessor/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.ASSESSOR; }
                }
            },
            'invoiceRedirect': {
                url: '/invoice/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.INVOICE; }
                }
            },
            'filingBatchRedirect': {
                url: '/filingbatch/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.FilingBatch; }
                }
            },
            'filingBatchRedirect2': {
                url: '/filingBatch/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.FilingBatch; }
                }
            },
            'states': {
                url: '/states',
                template: '<united-states style="height: 100%;"></united-states>',
                resolve: {
                    $title: function () {
                        return 'States';
                    }
                }
            },
            'state': {
                url: '/states/:stateId',
                template: '<us-state></us-state>',
                resolve: {
                    $title: function () {
                        return 'State';
                    }
                },
                data: {
                    //roles: [Roles.STATEVIEW, Roles.STATEEDIT]
                }
            },
            'assessors': {
                url: '/states/:stateId/assessors',
                template: '<assessor-list></assessor-list>',
                resolve: {
                    $title: function () {
                        return 'Assessors';
                    }
                }
            },
            'assessors.letter': {
                url: '?:letter'
            },
            'collectors': {
                url: '/states/:stateId/collectors',
                template: '<collector-list></collector-list>'
            },
            'collectors.letter': {
                url: '?:letter'
            },
            'companyCollectors': {
                url: '/company/:companyID/collectors',
                template: '<collector-list></collector-list>'
            },
            'companyCollectors.letter': {
                url: '?:letter'
            },
            'companyCollector': {
                url: '/company/:companyID/collector/:collectorID',
                template: '<collector></collector>'
            },
            'assessor': {
                url: '/states/:stateId/assessors/:id?:year',
                template: '<assessor></assessor>',
                resolve: {
                    $title: function () {
                        return 'Assessor';
                    }
                },
                data: {
                    //roles: [Roles.STATEVIEW, Roles.STATEEDIT]
                }
            },
            'collector': {
                url: '/states/:stateId/collectors/:stateCollectorId',
                template: '<collector></collector>'
            },
            'ssoLogin': {
                url: '/ssoLogin',
                template: '<sso-login></sso-login>',
                resolve: {
                    $title: function () {
                        return 'Login';
                    }
                }
            },
            'login': {
                url: '/login?logout',
                templateUrl: baseUrl + 'Account/Login/_login.html',
                controller: 'sd.Account.Login.Controller',
                controllerAs: 'vm',
                resolve: {
                    $title: function () {
                        return 'Login';
                    }
                }
            },
            'resetPasswordUsingTokenFromEmail': {
                url: '/login/resetPassword/:tokenHash',
                templateUrl: baseUrl + 'Account/Login/_login.html',
                controller: 'sd.Account.Login.Controller',
                controllerAs: 'vm',
                resolve: {
                    $title: function () {
                        return 'Login';
                    }
                }
            },
            'manageAPIKeys': {
                url: '/ManageAPIKeys',
                template: '<manage-api-keys></manage-api-keys>',
                resolve: {
                    $title: function () {
                        return 'Manage API Keys';
                    }
                }
            },
            'apiKeyDetail': {
                url: '/ManageAPIKeys/:integrationHmacId',
                template: '<api-key-detail></api-key-detail>',
                resolve: {
                    $title: function () {
                        return 'Manage API Keys';
                    }
                }
            },
            'externalAPIKey': {
                url: '/externalAPIKey/{requestCode:string}',
                template: '<external-api-key></external-api-key>',
                resolve: {
                    $title: function () {
                        return 'External API Key';
                    }
                }
            },
            'about': {
                url: '/about',
                template: '<about></about>',
                resolve: {
                    $title: function () {
                        return 'About';
                    }
                }
            },
            'logout': {
                onEnter: ['accountService', function (accountService) {
                    return accountService.logout().then(function () {
                        return false;
                    });
                }]
            },
            'search': {
                url: '/search/:searchId?searchType?filters',
                params: {
                    searchId: null
                },
                template: '<smart-search class="ws-page ws-page-vertical-padding" style="height: 100%;"></smart-search>',
                resolve: {
                    $title: function () {
                        return 'SMART Search';
                    }
                }
            },
            'contacts': {
                url: '/contacts/:contactId',
                params: { contactId: null },
                template: '<contact-list-page class="ws-page ws-page-vertical-padding ws-grid-page"></contact-list-page>',
                data: {
                    instanceRights: [InstanceRights.CONTACTVIEW, InstanceRights.CONTACTEDIT]
                },
                onEnter: ['sd.Contacts.Service.old', '$stateParams', function (contactModalService, $stateParams) {
                    if ($stateParams.contactId) {
                        contactModalService.openContactDialog($stateParams.contactId, ContactModalOrigin.ContactsList);
                    }
                }]
            },
            'roles': {
                url: '/roles',
                template: '<roles-list>',
                resolve: {
                    $title: function () {
                        return 'Roles';
                    }
                },
                data: {
                    roles: [Roles.CONTACTROLEVIEW]
                }
            },
            'propertyCharacteristics': {
                url: '/property-characteristics',
                template: '<property-characteristics>',
                data: {
                    roles: [Roles.PROPCHARVIEW, Roles.PROPCHAREDIT]
                }
            },
            'dashboard': {
                url: '/dashboard',
                redirectTo: 'home'
            },
            'landing': {
                url: '/landing',
                template: '<landing>',
                resolve: {
                    $title: function () {
                        return 'Home';
                    }
                },
            },
            'account': {
                url: '/account',
                templateUrl: baseUrl + 'Account/_panels.html',
                resolve: {
                    $title: function () {
                        return 'My Account';
                    }
                },
            },
            'changePassword': {
                parent: 'account',
                url: '/change-password',
                templateUrl: baseUrl + 'Account/Change-Password/_changePassword.html',
                controller: 'sd.Account.Change.Password.Controller',
                controllerAs: 'vm',
                resolve: {
                    $title: function () {
                        return 'Change Password';
                    }
                },
            },
            'unauthorizedAccess': {
                template: '<p class="text-warning text-center lead" style="margin-top: 50px;">You do not have permission to view this page. If you have any questions, please contact the site administrator.</p>',
                resolve: {
                    $title: function () {
                        return 'Unauthorized Access';
                    }
                }
            },
            'userGroupList': {
                url: '/userGroup',
                template: '<user-group-list class="ws-page ws-page-vertical-padding ws-grid-page"></user-group-list>',
                resolve: {
                    $title: function () {
                        return 'User Groups';
                    }
                },
                data: {
                    instanceRights: [InstanceRights.MANAGEUSERSETUP]
                }
            },
            'userGroupDetail': {
                url: '/userGroup/:userGroupId',
                template: '<user-group-detail class="ws-page ws-page-vertical-padding"></user-group-deta>',
                resolve: {
                    $title: function () {
                        return 'User Group';
                    }
                },
                data: {
                    instanceRights: [InstanceRights.MANAGEUSERSETUP]
                }
            },
            'teams': {
                url: '/teams',
                template: '<teams-page></teams-page>'
            },
            'actionview': {
                url: '/actionview/:actionViewId?actionViewType&displayType',
                params: { actionViewId: null },
                templateUrl: baseUrl + 'Task/ActionView/_filtersOutputs.html',
                controller: 'FiltersOutputsController',
                controllerAs: 'vm',
                resolve: {
                    $title: function () {
                        return 'Action View';
                    }
                },
                onExit: ['actionViewService', function (actionViewService) {
                    actionViewService.cancelAvSearch();
                }]
            },
            'taskviewoutputdefaults': {
                url: '/taskviewOutputDefaults',
                template: '<action-view-output-defaults class="ws-page ws-page-vertical-padding ws-grid-page"></action-view-output-defaults>',
                resolve: {
                    $title: function () {
                        return 'Action View Output Defaults';
                    }
                }
            },
            'documentintake': {
                url: '/documentintake',
                template: '<document-intake></document-intake>',
                data: {
                    instanceRights: [
                        InstanceRights.PRIVATEITEMSEDIT
                    ]
                },
                resolve: {
                    $title: function () {
                        return 'Document Intake';
                    }
                }
            },
            'documentProcessing': {
                url: '/processing/document?taskID?parcelID?intakeItemID',
                templateUrl: baseUrl + 'Processing/Documents/_documentProcessing.html',
                controller: 'DocumentProcessingController',
                controllerAs: 'vm',
                params: {
                    selectedRows: [],
                    searchTimestamp: null
                },
                resolve: {
                    $title: function () {
                        return 'Document Processing';
                    }
                }
            },
            // TODO: Consider eliminating the document preview version of this route and moving the files
            // to more logical locaitons; they're the same route except for the URL
            'attachmentPopup': {
                url: '/attachmentPopup',
                resolve: {
                    $title: function () {
                        return 'Attachment Viewer';
                    }
                },
                templateUrl: baseUrl + 'Processing/Documents/Popups/_filePreview.html',
                controller: 'FilePreviewController',
                controllerAs: 'vm'
            },
            'reportmanager': {
                url: '/reportmanager/:reportId?reportType',
                template: '<report-manager></report-manager>',
                resolve: {
                    $title: function () {
                        return 'Manage Reports';
                    }
                }
            },
            'paymentPackagesDraft': {
                url: '/processing/paymentPackages/:draftID?paymentBatchId?taskId?isPrelimApFeed',
                template: '<payment-packages></payment-packages>',
                resolve: {
                    $title: function () {
                        return 'Payment Package Drafts';
                    }
                }
            },
            'paymentPackageAttachmentSummary': {
                url: '/processing/paymentPackages/attachmentSummary/:paymentPackageID',
                template: '<payment-package-attachment-summary></payment-package-attachment-summary>',
                resolve: {
                    $title: function () {
                        return 'Payment Package';
                    }
                }
            },
            'paymentPackagesForCompany': {
                url: '/processing/paymentPackages/company/:companyID',
                template: '<payment-packages></payment-packages>',
                resolve: {
                    $title: function () {
                        return 'Company Payment Packages';
                    }
                }

            },
            'paymentPackagesForPayment': {
                url: '/processing/paymentPackages/payment/:paymentID',
                template: '<payment-packages></payment-packages>',
                resolve: {
                    $title: function () {
                        return 'Payment Packages';
                    }
                }
            },
            'paymentPackageDetail': {
                url: '/processing/paymentPackages/company/:companyID/package/:paymentPackageID?paymentBatchId',
                template: '<payment-packages></payment-packages>',
                resolve: {
                    $title: function () {
                        return 'Payment Package Details';
                    }
                }
            },
            'paymentPackageBatchDetail': {
                url: '/processing/paymentPackages/company/:companyID/package/:paymentPackageID/paymentBatch/:paymentBatchId',
                template: '<payment-packages></payment-packages>',
                resolve: {
                    $title: function () {
                        return 'Payment Package Details';
                    }
                }
            },
            'processInvoice': {
                url: '/processing/invoice/:draftId',
                template: '<invoice></invoice>',
                resolve: {
                    $title: function () {
                        return 'Process Invoice';
                    }
                }
            },
            'siteInvoice': {
                url: '/company/:companyID/site/:siteID/invoice/:invoiceID',
                template: '<invoice></invoice>',
                resolve: {
                    $title: function () {
                        return 'Site Invoice';
                    }
                }
            },
            'companyInvoice': {
                url: '/company/:companyID/invoice/:invoiceID',
                template: '<invoice></invoice>',
                resolve: {
                    $title: function () {
                        return 'Company Invoice';
                    }
                }
            },
            'companyVendorCodes': {
                url: '/company/:companyId/vendor-codes',
                template: '<vendor-code-mapping class="ws-page ws-page-vertical-padding"></vendor-code-mapping>',
                resolve: {
                    $title: function () {
                        return 'Company Vendor Codes';
                    }
                }
            },
            'importAssessments': {
                url: '/Assessment/Bulk-Import',
                template: '<bulk-import-assessments></bulk-import-assessments>',
                resolve: {
                    $title: function () {
                        return 'Import Assessments';
                    }
                }
            },
            'importSites': {
                url: '/Site/Bulk-Import',
                template: '<bulk-import-sites type="site"></bulk-import-sites>',
                resolve: {
                    $title: function () {
                        return 'Import Sites';
                    }
                }
            },
            'importParcels': {
                url: '/Parcel/Bulk-Import',
                template: '<bulk-import-sites type="parcel"></bulk-import-sites>',
                resolve: {
                    $title: function () {
                        return 'Import Parcels';
                    }
                }
            },
            'moveCompany': {
                url: '/company/:companyID/move?isInactive',
                template: '<move-copy-entity></move-copy-entity>',
                resolve: {
                    $title: function () {
                        return 'Move Company';
                    }
                }
            },
            'moveSite': {
                url: '/company/:companyID/site/:siteID/move?isInactive',
                template: '<move-copy-entity></move-copy-entity>',
                resolve: {
                    $title: function () {
                        return 'Move Site';
                    }
                }
            },
            'moveParcel': {
                url: '/company/:companyID/site/:siteID/parcel/:parcelID/move?isInactive',
                template: '<move-copy-entity></move-copy-entity>',
                resolve: {
                    $title: function () {
                        return 'Move Parcel';
                    }
                }
            },
            'copyCompany': {
                url: '/company/:companyID/copy?isInactive',
                template: '<move-copy-entity [is-copy]="true"></move-copy-entity>',
                resolve: {
                    $title: function () {
                        return 'Copy Company';
                    }
                }
            },
            'copySite': {
                url: '/company/:companyID/site/:siteID/copy?isInactive',
                template: '<move-copy-entity [is-copy]="true"></move-copy-entity>',
                resolve: {
                    $title: function () {
                        return 'Copy Site';
                    }
                }
            },
            'copyParcel': {
                url: '/company/:companyID/site/:siteID/parcel/:parcelID/copy?isInactive',
                template: '<move-copy-entity [is-copy]="true"></move-copy-entity>',
                resolve: {
                    $title: function () {
                        return 'Copy Parcel';
                    }
                }
            },
            'help': {
                url: '/help',
                template: '<help-component></help-component>',
                resolve: {
                    $title: function () {
                        return 'Help';
                    }
                }
            },
            'myDashboard': {
                url: '/my-dashboard',
                template: '<dashboard style="height: 100%"></dashboard>',
                resolve: {
                    $title: function () {
                        return 'My Dashboard';
                    }
                }
            },
            'appealApplication': {
                url: '/appeal/:appealId/appealApplication',
                template: '<appeal-application></appeal-application>',
                resolve: {
                    $title: function () {
                        return 'Appeal Application';
                    }
                }
            },
            'appealApplicationBatch': {
                url: '/appealApplicationBatch/:appealBatchId',
                template: '<appeal-application></appeal-application>',
                resolve: {
                    $title: function () {
                        return 'Appeal Application';
                    }
                }
            },
            'agents': {
                url: '/agents',
                template: '<agents-page></agents-page>',
                resolve: {
                    $title: function () {
                        return 'Agents';
                    }
                }
            },
            'processCompanyImport': {
                url: '/import/company/:companyId/:importId',
                template: '<entity-import-process class="ws-page ws-page-vertical-padding"></entity-import-process>'
            },
            'companyImports': {
                url: '/import/company/:companyId',
                template: '<entity-import-list class="ws-page ws-page-vertical-padding ws-grid-page"></entity-import-list>',
            },
            'processSystemImport': {
                url: '/import/:importId',
                template: '<entity-import-process class="ws-page ws-page-vertical-padding"></entity-import-process>'
            },
            'systemImports': {
                url: '/import',
                template: '<entity-import-list class="ws-page ws-page-vertical-padding ws-grid-page"></entity-import-list>'
            },
            'assetList': {
                url: '/asset/company/:companyId',
                template: '<asset-list class="ws-page ws-page-vertical-padding ws-grid-page"></asset-list>',
            },
            'assetListSiteFilter': {
                url: '/asset/company/:companyId/site/:siteId',
                template: '<asset-list class="ws-page ws-page-vertical-padding"></asset-list>',
            },
            'assetListParcelFilter': {
                url: '/asset/company/:companyId/parcel/:parcelId',
                template: '<asset-list class="ws-page ws-page-vertical-padding"></asset-list>',
            },

            'formPage': {
                url: '/form/:formRevisionId?taxYear',
                template: '<form-page class="ws-page ws-page-vertical-padding"></form-page>',
                data: {
                    roles: [
                        Roles.COMPLIANCESETUPSVIEW,
                        Roles.COMPLIANCESETUPSEDIT]
                }
            },
            'formList': {
                url: '/form',
                template: '<form-list class="ws-page ws-page-vertical-padding"></form-list>',
                data: {
                    roles: [
                        Roles.COMPLIANCESETUPSVIEW,
                        Roles.COMPLIANCESETUPSEDIT
                    ]
                }
            },
            'factorTableList': {
                url: '/factortable',
                template: '<factor-table-list class="ws-page ws-page-vertical-padding ws-grid-page"></factor-table-list>',
                data: {
                    roles: [
                        Roles.COMPLIANCESETUPSVIEW,
                        Roles.COMPLIANCESETUPSEDIT
                    ]

                }
            },
            'glAccountList': {
                url: '/glaccount/company/:companyId',
                template: '<gl-account-list class="ws-page ws-page-vertical-padding ws-grid-page"></gl-account-list>'
            },
            'filingBatchList': {
                url: '/filingbatch/company/:companyId',
                template: '<filing-batch-list class="ws-page ws-page-vertical-padding ws-grid-page"></filing-batch-list>'
            },
            'filingBatchDetails': {
                url: '/filingbatch/company/:companyId/batch/:filingBatchId?',
                template: '<filing-batch-details class="ws-page ws-page-vertical-padding"></filing-batch-details>'
            },
            'returnFilingBatchPage': {
                url: '/returnBatch/:filingBatchId?bypass',
                template: '<return-page class="ws-page ws-page-vertical-padding"></return-page>'
            },
            'supplementalInformationSheet': {
                url: '/supplemental/company/:companyId',
                template: '<supplemental-information-sheet class="ws-page ws-page-vertical-padding ws-grid-page"></supplemental-information-sheet>'
            },
            'longRunningProcess': {
                url: '/longrunningprocess',
                template: '<long-running-process-page class="ws-page ws-page-vertical-padding ws-grid-page"></long-running-process-page>',
                data: {
                    roles: [
                        Roles.ADMINOPERATIONSVIEW,
                        Roles.ADMINOPERATIONSEDIT
                    ]
                }
            },
            'errorLog': {
                url: '/errorlog',
                template: '<error-log-list class="ws-page ws-page-vertical-padding ws-grid-page"></error-log-page>',
                data: {
                    roles: [
                        Roles.ADMINOPERATIONSVIEW,
                        Roles.ADMINOPERATIONSEDIT
                    ]
                }
            },
            'queryInfo': {
                url: '/queryInfo',
                template: '<query-info-list class="ws-page ws-page-vertical-padding ws-grid-page"></query-info-page>',
                data: {
                    roles: [
                        Roles.ADMINOPERATIONSVIEW,
                        Roles.ADMINOPERATIONSEDIT
                    ]
                }
            },
            'testdxp': {
                url: '/testdxp',
                template: '<test-dxp-page class="ws-page ws-page-vertical-padding"></test-dxp-page>',
                data: {
                    roles: [Roles.ADMINOPERATIONSEDIT]
                }
            },
            'taxRateArea': {
                url: '/tax-rate-area/:taxRateAreaId?editMode',
                template: '<tax-rate-area class="ws-page ws-page-vertical-padding"></tax-rate-area>',
                data: {
                    roles: [Roles.ADMINOPERATIONSEDIT]
                }
            },
            'taxAuthority': {
                url: '/tax-authority/:taxAuthorityId?editMode',
                template: '<tax-authority class="ws-page ws-page-vertical-padding"></tax-authority>',
                data: {
                    roles: [Roles.ADMINOPERATIONSEDIT]
                }
            },
            'importSpecificationList': {
                url: '/importSpecifications',
                template: '<import-specification-list class="ws-page ws-page-vertical-padding ws-grid-page"></import-specification-list>',
                data: {
                    instanceRights: [
                        InstanceRights.COMPANYDATAIMPORTSVIEW
                    ]
                }
            },
            'companyImportSpecificationList': {
                url: '/importSpecifications/company/:companyId',
                template: '<import-specification-list class="ws-page ws-page-vertical-padding ws-grid-page"></import-specification-list>',
                data: {
                    instanceRights: [
                        InstanceRights.COMPANYDATAIMPORTSVIEW
                    ]
                }
            },
            'taxRateCommandCenter': {
                url: '/tax-rate-command-center',
                data: {
                    roles: [Roles.RYANPRIVATEITEMSVIEW, Roles.RYANPRIVATEITEMSEDIT]
                },
                template: '<tax-rate-command-center class="ws-page ws-page-vertical-padding ws-grid-page"></tax-rate-command-center>'
            },
            'paymentBatchCommandCenter':{
                url: '/payment-batch-command-center',
                data: {
                    roles: [Roles.RYANPRIVATEITEMSVIEW, Roles.RYANPRIVATEITEMSEDIT]
                },
                resolve: {
                    $title: function () {
                        return 'Payment Batches';
                    }
                },
                template: '<payment-batch-command-center class="ws-page ws-page-vertical-padding ws-grid-page"></payment-batch-command-center>'

            },
            'paymentBatchDetails':{
                url: '/payment-batch-command-center/batch/:paymentBatchId',
                data: {
                    roles: [Roles.RYANPRIVATEITEMSVIEW, Roles.RYANPRIVATEITEMSEDIT]
                },
                resolve: {
                    $title: function () {
                        return 'Payment Batch Details';
                    }
                },
                template: '<payment-batch-details class="ws-page ws-page-vertical-padding ws-grid-page" style="padding-bottom: 23px;"></payment-batch-details>'

            },
            'paymentBatchDetailsWithTabIndex':{
                url: '/payment-batch-command-center/batch/:paymentBatchId/:tabIndex',
                data: {
                    roles: [Roles.RYANPRIVATEITEMSVIEW, Roles.RYANPRIVATEITEMSEDIT]
                },
                resolve: {
                    $title: function () {
                        return 'Payment Batch Details';
                    }
                },
                template: '<payment-batch-details class="ws-page ws-page-vertical-padding ws-grid-page" style="padding-bottom: 23px;"></payment-batch-details>'

            },
            'forecastingBudgeting': {
                url: '/company/:companyId/forecasting-budgeting',
                data: {
                    instanceRights: [
                        InstanceRights.PRIVATEITEMSEDIT,
                        InstanceRights.PRIVATEITEMSVIEW
                    ]
                },
                template: '<forecasting-budgeting-page  class="ws-page ws-page-vertical-padding ws-grid-page"></forecasting-budgeting-page>'
            },
            'stateJurisdictionCommandCenter': {
                url: '/state-jurisdiction-command-center',
                data: {
                    roles: [Roles.RYANPRIVATEITEMSVIEW, Roles.RYANPRIVATEITEMSEDIT]
                },
                template: '<state-jurisdiction-command-center class="ws-page ws-page-vertical-padding ws-grid-page"></state-jurisdiction-command-center>'
            },
            'accruals': {
                url: '/company/:companyId/accruals',
                data: {
                    instanceRights: [
                        InstanceRights.PRIVATEITEMSEDIT,
                        InstanceRights.PRIVATEITEMSVIEW
                    ]
                },
                template: '<accruals-page class="ws-page ws-page-vertical-padding ws-grid-page"></accruals-page>'
            },
            'siteMap': {
                url: '/site-map?siteId?companyId',
                template: '<site-map class="ws-page ws-page-vertical-padding ws-grid-page"></site-map>'
            },
            'allocationDetails': {
                url: '/allocationDetails/:allocationId',
                template: '<allocation-details class="ws-page ws-page-vertical-padding ws-grid-page"></allocation-details>',
            },
            'processAllocationImport': {
                url: '/import/allocation/:allocationId/:importId',
                template: '<entity-import-process class="ws-page ws-page-vertical-padding"></entity-import-process>'
            },
            'invites': {
                url: '/invites',
                template: '<manage-invitations class="ws-page ws-page-vertical-padding"></manage-invitations>',
            },
            'consulting': {
                url: '/consulting',
                data: {
                    instanceRights: [
                        InstanceRights.MANAGECONSULTINGENGAGEMENT
                    ]
                },
                template: '<consulting-engagements class="ws-page ws-page-vertical-padding"></consulting-engagements>',
            },
            'instanceInfo': {
                url: '/instance',
                data: {
                    instanceRights: [
                        InstanceRights.MANAGECONSULTINGENGAGEMENT
                    ]
                },
                template: '<instance-info-panel class="ws-page"></instance-info-panel>',
            },
            'companyIncomeStatement': {
                url: '/incomeStatement/company/:companyId',
                template: '<income-statement-list class="ws-page ws-page-vertical-padding ws-grid-page"></income-statement-list>',
            },
            'siteIncomeStatement': {
                url: '/incomeStatement/company/:companyId/site/:siteId',
                template: '<income-statement-list class="ws-page ws-page-vertical-padding ws-grid-page"></income-statement-list>',
            },
            'csrCommandCenter': {
                url: '/csrcommandcenter/entity/:entityId/type/:entityTypeId',
                template: '<csr-command-center class="ws-page ws-page-vertical-padding"></csr-command-center>',
            },

            'companyRentRoll': {
                url: '/rentRoll/company/:companyId',
                template: '<rent-roll-list class="ws-page ws-page-vertical-padding ws-grid-page"></rent-roll-list>',
            },
            'siteRentRoll': {
                url: '/rentRoll/company/:companyId/site/:siteId',
                template: '<rent-roll-list class="ws-page ws-page-vertical-padding ws-grid-page"></rent-roll-list>',
            },
            'valuationTemplates': {
                url: '/valuationTemplate',
                data: {
                    instanceRights: [
                        InstanceRights.VALUATIONTEMPLATEEDIT
                    ]
                },
                template: '<valuation-templates class="ws-page ws-page-vertical-padding ws-grid-page"></valuation-templates>',
            },
            'valuationTemplateDetail': {
                url: '/valuationTemplate/:templateId',
                data: {
                    instanceRights: [
                        InstanceRights.VALUATIONTEMPLATEEDIT
                    ]
                },
                template: '<valuation-template-detail class="ws-page ws-page-vertical-padding"></valuation-template-detail>',
            },
            'supportInstanceList': {
                url: '/supportInstanceList',
                template: '<support-instance-list class="ws-page ws-page-vertical-padding"></support-instance-list>',
            },
            'database': {
                url: '/database',
                template: '<database-process-list class="ws-page ws-page-vertical-padding"></database-process-list>'
            },
            'documentIntakeLicensedClientList': {
                url: '/documentIntakeLicensedClientList',
                template: '<document-intake-licensed-client-list class="ws-page ws-page-vertical-padding"></document-intake-licensed-client-list>',
            },
            'electronicFiling': {
                url: '/company/:companyId/efile',
                template: '<electronic-filing class="ws-page ws-page-vertical-padding ws-grid-page"></electronic-filing>',
            },
            'appealRecommendationCommandCenter': {
                url: '/appealRecommendationCommandCenter',
                data: {
                },
                template: '<appeal-recommendation-command-center class="ws-page ws-page-vertical-padding ws-grid-page"></appeal-recommendation-command-center>',
            },
            'loginManager': {
                url: '/loginManager/:userId',
                template: '<login-manager class="ws-page ws-page-vertical-padding"></login-manager>'
            }
        };

        // Hack to produce a resolve on every state definition
        // (see https://stackoverflow.com/a/33280010; this was needed
        // for navigation warnings)
        var originalStateFunction = $stateProvider.state;
        $stateProvider.state = function (name, definition) {
            if (!definition.resolve) {
                definition.resolve = {};
            }

            return originalStateFunction.apply(this, arguments);
        };
        // End hack

        var routeKeys = Object.keys(routes);

        for (var i = 0; i < routeKeys.length; i++) {
            var routeKey = routeKeys[i];

            $stateProvider.state(routeKey, routes[routeKey]);
        }
    }
})();

