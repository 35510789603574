import { Injectable } from '@angular/core';
import { TaskSeriesType } from '../../Task/Task.Model';
import { AllColumnsData, OutputColumn, OutputColumnCategory, OutputColumnOperator } from './Column-Picker/column.model';
import { UserInstanceService } from '../../User/userInstance.service';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { AdvancedSearchPersistenceService, RevokeShareModel, SavedSearchModel } from './advancedSearchPersistence.service';
import { AdvancedSearchRepository } from '../../Core-Repositories';
import { UserSettingsService } from '../../Account/userSettings.service';
import { CacheManager, CacheManagerService } from '../../Common/Routing/cache-manager.service';
import { SearchOperatorsService } from '../search.operators.service.upgrade';

import { map, flatten, filter, uniqBy, uniq, compact, union, reject } from 'lodash/fp';
import * as _ from 'lodash';
import { Settings } from '../../User/account.service';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class SmartSearchService {
    constructor(private readonly _userInstanceService: UserInstanceService,
                private readonly _restrictService: RestrictService,
                private readonly _advancedSearchPersistenceService: AdvancedSearchPersistenceService,
                private readonly _advancedSearchRepository: AdvancedSearchRepository,
                private readonly _userSettingsService: UserSettingsService,
                private readonly _cacheManagerService: CacheManagerService,
                private readonly _searchOperators: SearchOperatorsService) {
        this._cacheManager = this._cacheManagerService.createCacheManager();
        this._cacheManager.registerKeyedStaticCache('allSearchFields', {
            cacheKey: 'searchfields',
            endpoint: '/api/search/advanced/fields/all',
            cacheDisabled: false
        });

        /* I'm sure there's a better way to do this, but as an 11th-hour fix, this is all the ways
         * I figure we sometimes call the lookups endpoint:
         *
         * SERVICE_URL + 'lookups'
         * SERVICE_URL + 'lookups?cpMode=av'
         * SERVICE_URL + 'lookups?cpMode=smart'
         * SERVICE_URL + 'lookups/all'
         * SERVICE_URL + 'lookups/all?cpMode=av'
         * SERVICE_URL + 'lookups/all?cpMode=smart'
         *
         * So we'll make a cache for each possibility */

        ['', '?cpMode=av', '?cpMode=smart', '/all', '/all?cpMode=av', '/all?cpMode=smart'].forEach((url) => {
            this._cacheManager.registerKeyedStaticCache(`searchLookups${  url}`, {
                cacheKey: 'searchfields',
                endpoint: `/api/search/advanced/lookups${url}`,
                cacheDisabled: false
            });
        });
    }

    private readonly SMART_SETTING_GROUP_ID = 18;

    private readonly COMPANY = 10;
    private readonly SITE = 20;
    private readonly PARCEL = 30;
    private readonly ASSESSMENT = 40;
    private readonly COMPANY_CONTACT = 50;
    private readonly SITE_CONTACT = 60;
    private readonly PARCEL_CONTACT = 70;
    private readonly REVISION = 80;
    private readonly COMPONENT = 90;
    private readonly APPEAL = 100;
    private readonly PAYMENT = 110;
    private readonly INTAKE_ITEM = 120;
    private readonly REFUND = 130;
    private readonly FILING = 140;
    private readonly INVOICE = 150;
    private readonly BILL = 105;

    private _cacheManager: CacheManager;
    private _canceler: (string) => void;

    allFields: Core.AdvancedSearchField[] = null;
    smartOnlyFields = null;
    columns: AllColumnsData = {
        byCategory: [],
        all: [],
        persistedDepths: []
    };

    getCacheManager(): CacheManager {
        return this._cacheManager;
    }

    async getColumns(includeTaskColumns: boolean, cpMode: 'smart'| 'av'): Promise<AllColumnsData> {
        let targetCache = null;

        if (includeTaskColumns) {
            if (cpMode === 'smart') { targetCache = 'searchLookups/all?cpMode=smart'; }
            else if (cpMode === 'av') { targetCache = 'searchLookups/all?cpMode=av'; }
            else if (!cpMode) { targetCache = 'searchLookups/all'; }
        }
        else {
            if (cpMode === 'smart') { targetCache = 'searchLookups?cpMode=smart'; }
            else if (cpMode === 'av') { targetCache = 'searchLookups?cpMode=av'; }
            else if (!cpMode) { targetCache = 'searchLookups'; }
        }

        let result;
        if (targetCache) {
            result = await this._cacheManager.keyedStaticGet(targetCache);
        }
            // I'm 99% sure that cpMode will always only be av or smart, but on the 1% chance I'm wrong,
        // go ahead and let it work otherwise, just don't cache.
        else {
            result = await lastValueFrom(this._advancedSearchRepository.getColumnsCpMode(cpMode, includeTaskColumns));
        }

        const isInRyanInstance = this._userInstanceService.isCurrentInstanceRyan();
        const hasRyanPrivate = this._restrictService.isInRole(Roles.RYANPRIVATEITEMSVIEW) || this._restrictService.isInRole(Roles.RYANPRIVATEITEMSEDIT);

        result = result.filter(category => isInRyanInstance && hasRyanPrivate || category.categoryID !== 19); // this.INVOICE

        if(!isInRyanInstance) {
            result = this.filterColumnsByRyanInstance(result);
        }
        else{
            result = this.filterColumnsByRyanPrivateAccess(result, hasRyanPrivate);
        }

        this.columns.byCategory = result;
        this.columns.all = this.getAllColumns(result);

        return this.columns;
    }

    filterColumnsByRyanInstance(categories): any[] {
        return categories.map(category => {
            category.columns = _.reject(category.columns, column => {       // This is bad and not sustainable. We need a new soluction at some point
                return _.includes(column.displayName, 'DR Team Retrieves')
                    || _.includes(column.displayName, 'Jurisdiction Specialist')
                    || _.includes(column.displayName, 'DRTT')
                    || column.isRyanInternal;            // internal ryan fields should not be shown by any other instance
            });

            if(category.subcategories.length) {
                category.subcategories = this.filterColumnsByRyanInstance(category.subcategories);
            }

            return category;
        });
    }


    filterColumnsByRyanPrivateAccess(categories: any, hasRyanPrivate: boolean): any[] {
        return categories.map(category => {
            category.columns = _.reject(category.columns, column => {
                return column.isRyanInternal && !hasRyanPrivate;     // non-ryan user should not see ryan internal SMART/AV columns
            });

            if(category.subcategories.length) {
                category.subcategories = this.filterColumnsByRyanPrivateAccess(category.subcategories, hasRyanPrivate);
            }

            return category;
        });
    }

    getAllColumns(columnCategories: any): any[] {

        console.log('stvhere smartSearch.service - columnCategories');

        const categoryColumns = _.flow([
                map(category => {
                    if (category.columns) {
                        category.columns.forEach((column) => {
                            column.categoryName = category.categoryName;
                            column.categoryID = category.categoryID;

                            column.typeAheadName = `${column.categoryName  }: ${  column.displayName}`;
                        });
                    }

                    return category;
                }),
                map(x => x.columns),
                flatten,
                compact
            ])(columnCategories);

        const subcategoryColumns = _.flow([
                map(category => {
                    category.subcategories.forEach(subcategory => {
                        subcategory.columns.forEach(column => {
                            column.categoryName = `${category.categoryName  } - ${  subcategory.categoryName}`;
                            column.categoryID = subcategory.categoryID;

                            column.typeAheadName = `${column.categoryName  }: ${  column.displayName}`;
                        });
                    });

                    return category;
                }),
                map(x => x.subcategories),
                flatten,
                map(x => x.columns),
                flatten,
                compact
            ])(columnCategories);

        return _.union(categoryColumns, subcategoryColumns);
    }

    disableColumns(checkedColumns: any[], temporary: boolean = false, taskSeriesTypeAdvancedSearchDepthValue?: number): void {
        let depthsChecked = _.flow([
                map(column => column.depthValue === undefined ? column.depth.depthValue : column.depthValue),
                union([taskSeriesTypeAdvancedSearchDepthValue]),
                uniq
            ])(checkedColumns);

        // Persisted depths are those which include the filters and output columns
        if (temporary) {
            depthsChecked = _.union(depthsChecked, this.columns.persistedDepths);
        } else {
            this.columns.persistedDepths = depthsChecked;
        }

        // disable all columns whose depth is not included into depthsChecked
        //
        this.columns.all.forEach(column => {
            column.disabled = !!this.getDepthsThatDisable(depthsChecked, column.depthValue).length;
        });
    }

    async getAllFields(smartOnly: boolean): Promise<Core.AdvancedSearchField[]> {
        // TODO: We should consider refactoring this; it's unecessarily convoluted at the moment. I attempted
        // to replace all this with a relatively straightforward call to cacheManager.keyedStaticGet and got
        // failures downsteam, so this works for now

        if (!this.allFields) {
            const cacheManager = this.getCacheManager();
            this.allFields = await cacheManager.keyedStaticGet('allSearchFields');
        }

        if (smartOnly) {
            if (!this.smartOnlyFields) {
                this.smartOnlyFields = this.allFields.filter(field => {
                    return field.usedBySmart;
                });
            }
            return this.smartOnlyFields;
        }
        else {
            return this.allFields;
        }
    }

    checkIfAllFieldsPresent() : boolean {
        if (!this.allFields) {
            return false;
        }
        return true;
    }

    getFieldById(id: number): Core.AdvancedSearchField {
        if (!this.allFields) {
            console.log('************* error - trying to access allFields while this array has not been loaded yet');
            return null;
        }

        // advancedSearchFieldID
        return this.allFields.find(x => x.advancedSearchFieldID === id);
    }

    getFieldByDisplayName(displayName: string): Core.AdvancedSearchField {
        if (!this.allFields) {
            console.log('************* error - trying to access allFields while this array has not been loaded yet');
            return null;
        }

        return this.allFields.find(x => x.displayName === displayName);
    }

    getDepthsThatDisable(depthsChecked: any[], depth: number): any[] {
        switch (depth) {
            case this.SITE:
                return _.intersection(depthsChecked, [this.COMPANY_CONTACT]);
            case this.PARCEL:
                return _.intersection(depthsChecked, [this.COMPANY_CONTACT, this.SITE_CONTACT, this.INVOICE]);
            case this.ASSESSMENT:
                return _.intersection(depthsChecked, [this.COMPANY_CONTACT, this.SITE_CONTACT, this.PARCEL_CONTACT, this.INVOICE, this.INTAKE_ITEM, this.BILL]);
            case this.COMPANY_CONTACT:
                return _.intersection(depthsChecked, [this.SITE, this.PARCEL, this.SITE_CONTACT, this.PARCEL_CONTACT, this.REVISION, this.COMPONENT, this.APPEAL, this.PAYMENT, this.BILL, this.REFUND, this.FILING, this.INVOICE, this.ASSESSMENT]);
            case this.SITE_CONTACT:
                return _.intersection(depthsChecked, [this.PARCEL, this.COMPANY_CONTACT, this.PARCEL_CONTACT, this.REVISION, this.COMPONENT, this.APPEAL, this.PAYMENT, this.BILL, this.REFUND, this.FILING, this.INVOICE, this.ASSESSMENT]);
            case this.PARCEL_CONTACT:
                return _.intersection(depthsChecked, [this.COMPANY_CONTACT, this.SITE_CONTACT, this.REVISION, this.COMPONENT, this.APPEAL, this.PAYMENT, this.BILL, this.REFUND, this.FILING, this.INVOICE, this.ASSESSMENT]);
            case this.REVISION:
                return _.intersection(depthsChecked, [this.COMPANY_CONTACT, this.SITE_CONTACT, this.PARCEL_CONTACT, this.APPEAL, this.PAYMENT, this.BILL, this.REFUND, this.FILING, this.INVOICE]);
            case this.COMPONENT:
                return _.intersection(depthsChecked, [this.COMPANY_CONTACT, this.SITE_CONTACT, this.PARCEL_CONTACT, this.APPEAL, this.PAYMENT, this.BILL, this.REFUND, this.FILING, this.INVOICE]);
            case this.APPEAL:
                return _.intersection(depthsChecked, [this.COMPANY_CONTACT, this.SITE_CONTACT, this.PARCEL_CONTACT, this.REVISION, this.COMPONENT, this.PAYMENT, this.BILL, this.REFUND, this.FILING, this.INVOICE]);
            case this.PAYMENT:
                // wk-7545 - removed this.ASSESSMENT from array below, tax fields should be blocked by this.REVISION, not this.ASSESSMENT
                return _.intersection(depthsChecked, [this.COMPANY_CONTACT, this.SITE_CONTACT, this.PARCEL_CONTACT, this.REVISION, this.COMPONENT, this.APPEAL, this.REFUND, this.FILING, this.INVOICE, this.INTAKE_ITEM]);
            case this.REFUND:
                return _.intersection(depthsChecked, [this.COMPANY_CONTACT, this.SITE_CONTACT, this.PARCEL_CONTACT, this.REVISION, this.COMPONENT, this.APPEAL, this.PAYMENT, this.BILL, this.FILING, this.INVOICE]);
            case this.FILING:
                return _.intersection(depthsChecked, [this.COMPANY_CONTACT, this.SITE_CONTACT, this.PARCEL_CONTACT, this.REVISION, this.COMPONENT, this.APPEAL, this.PAYMENT, this.BILL, this.REFUND, this.INVOICE]);
            case this.INVOICE:
                return _.intersection(depthsChecked, [this.PARCEL, this.ASSESSMENT, this.COMPANY_CONTACT, this.SITE_CONTACT, this.PARCEL_CONTACT, this.REVISION, this.COMPONENT, this.APPEAL, this.PAYMENT, this.BILL, this.REFUND, this.FILING]);
            case this.BILL:
                return _.intersection(depthsChecked, [this.COMPANY_CONTACT, this.SITE_CONTACT, this.PARCEL_CONTACT, this.REVISION, this.COMPONENT, this.APPEAL, this.REFUND, this.FILING, this.INVOICE, this.INTAKE_ITEM, this.ASSESSMENT]);
            default:
                return [];
        }
    }

    disableColumnsFromTaskTypeOnly(taskSeriesType: TaskSeriesType): void {
        this.columns.all = this.columns.all.map((column) => {
            column.disabled = !_.includes(taskSeriesType.depthValuesToEnable, column.depthValue)
                && !_.includes(taskSeriesType.fieldIdsToEnable, (column.id || column.columnId || column.advancedSearchFieldID));

            return column;
        });
    }

    getFilteredColumnCategories(columnsToExclude: OutputColumn[]): OutputColumnCategory[] {
        const initColumns = (category) => {
            return _.flow([
                    filter({ categoryID: category.categoryID }),
                    reject(column => {
                        const columnId = column.id || column.columnId || column.advancedSearchFieldID;
                        const columnIdExists = _.some(columnsToExclude, { columnId: columnId });
                        const idExists = _.some(columnsToExclude, { id: columnId });
                        const advancedSearchFieldIDExists = _.some(columnsToExclude, { advancedSearchFieldID: columnId });
                        return columnIdExists || idExists || advancedSearchFieldIDExists;
                    }),
                    map(column => {
                        column.toAdd = false;
                        return column;
                    })
                ])(this.columns.all);
        };
        return [ ...this.columns.byCategory ].map((category) => {
            category.columns = initColumns(category);
            category.subcategories = category.subcategories.map((subcategory) => {
                subcategory.columns = initColumns(subcategory);

                return subcategory;
            });

            return category;
        });
    }

    resetCategoryState(): void {
        this.columns.byCategory.forEach(category => {
            category.isOpen = false;
        });
    }

    async prepareCriteriaFromLoad(criteria): Promise<any> {
        if (typeof criteria === 'string') {
            criteria = JSON.parse(criteria);
        }
        const columns = await this.getColumns(false, 'smart');
        criteria.outputColumns = _.flow([
            map(columnId => {
                const column = columns.all.find(x => x && x.columnId === columnId);
                if(!column) {
                    this._advancedSearchPersistenceService.hiddenColumns.push(columnId);
                }

                return column;
            }),
            compact
        ])(criteria.outputColumns);

        criteria.filters = criteria.filters.map(filter => {
            const column = { ...columns.all.find(x => x && x.columnId === filter.columnId) };
            column.operators = this._searchOperators.getOperatorArray(column.operators as string[]);

            column.or = filter.or.map(or => {
                return {
                    value: or.value,
                    operator: (column.operators as OutputColumnOperator[]).find(x => x.name === or.operatorName)
                };
            });

            return column;
        });

        return criteria;
    }

    prepareCriteriaForSave(criteria: any): any {
        const outputColumns = criteria.outputColumns.map(x => x.columnId);
        const filters = criteria.filters.map(filter => {
            const or = filter.or.map(or => ({ value: or.value, operatorName: or.operator.name }));

            return {
                columnId: filter.columnId,
                or
            };
        });

        return { ...criteria, ...{ outputColumns, filters }};
    }


    getAllCustomSearches(): Promise<any> {
        const config = {
            cache: true
        };

        return lastValueFrom(this._advancedSearchRepository.getAllCustomSearches(config));
    }

    saveCustomSearch(searchItem: SavedSearchModel): Promise<any> {
        searchItem.searchCriteria = this.prepareCriteriaForSave(searchItem.searchCriteria);
        return lastValueFrom(this._advancedSearchRepository.saveCustomSearch(searchItem));
    }

    share(searchItem: Core.AdvancedSearchList): Promise<any> {
        return lastValueFrom(this._advancedSearchRepository.share(searchItem));
    }

    revokeShare(searchItem: SavedSearchModel): Promise<any> {
        const revokeModel: RevokeShareModel = {
            categoryID: searchItem.categoryId,
            categoryName: searchItem.categoryName,
            defaultDisplay: searchItem.defaultDisplay,
            instanceID: searchItem.instanceID,
            isRyanInternal: searchItem.isRyanInternal,
            isSystemSearch: searchItem.isSystemSearch,
            rowVersion: searchItem.rowVersion,
            searchCriteria: searchItem.searchCriteria,
            searchID: searchItem.searchId,
            searchName: searchItem.searchName
        };
        return lastValueFrom(this._advancedSearchRepository.revokeShare(revokeModel));
    }

    createCustomSearch(searchItem: SavedSearchModel): Promise<any> {
        searchItem.searchCriteria = this.prepareCriteriaForSave(searchItem.searchCriteria);
        return lastValueFrom(this._advancedSearchRepository.createCustomSearch(searchItem));
    }

    saveSystemSearch(searchItem: SavedSearchModel): Promise<any> {
        searchItem.searchCriteria = this.prepareCriteriaForSave(searchItem.searchCriteria);
        return lastValueFrom(this._advancedSearchRepository.saveSystemSearch(searchItem));
    }

    createSystemSearch(searchItem: SavedSearchModel): Promise<any> {
        searchItem.searchCriteria = this.prepareCriteriaForSave(searchItem.searchCriteria);
        return lastValueFrom(this._advancedSearchRepository.createSystemSearch(searchItem));
    }

    /*
            function getOneCustomSearch(searchId) {
                return sdHttp.get(SERVICE_URL_CUSTOM_SEARCH + searchId)
                    .then(returnSearch);
            }

            function getOneSystemSearch(searchId) {
                return sdHttp.get(SERVICE_URL_SYSTEM_SEARCH + searchId)
                    .then(returnSearch);
            }
    */
    async getOneSearch(searchId: number, searchType: number): Promise<any> {
        const result = await lastValueFrom(this._advancedSearchRepository.getOneSearch(searchId, searchType));
        return await this.returnSearch(result);
    }

    async returnSearch(result): Promise<any> {
        result.searchCriteria = await this.prepareCriteriaFromLoad(result.searchCriteria);
        return result;
    }

    /*

    available operators:

    public static List<Operator> AvailableOperators = new List<Operator>()
    {
        new Operator() {Name = "Nullable", Value = 1},
        new Operator() {Name = "Equal", Value = 2, Expression = " = {0}"},
        new Operator() {Name = "NotEqual", Value = 4, Expression = " != {0}"},
        new Operator() {Name = "Blank", Value = 8, Expression = " IS NULL "},
        new Operator() {Name = "Contain", Value = 16, Expression = " LIKE %{0}%"},
        new Operator() {Name = "DoesNotContain", Value = 32},
        new Operator() {Name = "BeginsWith", Value = 64},
        new Operator() {Name = "EndsWith", Value = 128},
        new Operator() {Name = "LessThan", Value = 256},
        new Operator() {Name = "LessThanEqual", Value = 512},
        new Operator() {Name = "GreaterThan", Value = 1024 },
        new Operator() {Name = "GreaterThanEqual", Value = 2048}
    };

    */

    cancelSearch(): void {
        if (this._canceler) {
            this._canceler('user cancelled');
        }
    }

    async executeSearch(payloadCriteria): Promise<any> {
        const config = {};

        return new Promise(resolve => {
            const sub = this._advancedSearchRepository.executeSearch('EXECUTE/', payloadCriteria, config)
                .subscribe(x => {
                    resolve(x);
                    this._canceler = null;
                },
                err => {
                    this._canceler('error');
                }

                );

            this._canceler = (msg: string) => {
                sub.unsubscribe();
                resolve({});
                this._canceler = null;
            };
        });
    }

    async export(payloadCriteria): Promise<number> {
        const config = {};

        return await lastValueFrom(this._advancedSearchRepository.executeSearch('export', payloadCriteria, config));
    }

    getSearchCategories(): Promise<Core.SavedSearchCategoryManagementDTO[]> {
        return lastValueFrom(this._advancedSearchRepository.getSearchCategories());
    }

    createSearchCategory(category: any): Promise<any> {
        return lastValueFrom(this._advancedSearchRepository.createSearchCategory(category));
    }

    deleteSearchCategory(category: any): Promise<any> {
        return lastValueFrom(this._advancedSearchRepository.deleteSearchCategory(category.categoryId));
    }

    patchSearch(searchItem: any, searchType: number): Promise<any> {
        return lastValueFrom(this._advancedSearchRepository.patchSearch(searchItem, searchType));
    }

    changeSearchCategory(searchItem: any, categoryId: number, searchType: number): Promise<any> {
        const categorySearchItem = {
            searchID: searchItem.searchId,
            categoryID: categoryId,
            rowVersion: searchItem.rowVersion
        };

        return lastValueFrom(this._advancedSearchRepository.changeSearchCategory(categorySearchItem, searchType));
    }

    async deleteSearch(searchItem: Core.AdvancedSearchList, searchType: Core.SearchCategoryType): Promise<any> {
        return await lastValueFrom(this._advancedSearchRepository.deleteSearch(searchItem, searchType));
    }

    async toggleSearchFavorite(searchItem: any, isFavorite: boolean): Promise<any> {
        const data = {
            systemSearchID: searchItem.searchId
        };

        if (isFavorite) {
            return await lastValueFrom(this._advancedSearchRepository.toggleSearchFavoriteOn(data));
        } else {
            return await lastValueFrom(this._advancedSearchRepository.toggleSearchFavoriteOff(searchItem.searchId));
        }
    }

    async searchHasFavorites(searchItem: any): Promise<any> {
        return await lastValueFrom(this._advancedSearchRepository.searchHasFavorites(searchItem.searchId));
    }

    async getFavoriteCount(searchItem: any): Promise<any> {
        return await lastValueFrom(this._advancedSearchRepository.getFavoriteCount(searchItem.searchId));
    }

    async persistSetting(settings): Promise<any> {
        settings =  _.pick(settings, 'excludeInactive', 'frequentlyUsed', 'groupByBatches');

        let criteriaSetting: Settings = {
            id: 0,
            name: 'Smart-Defaults',
            value: settings,
            groupId: this.SMART_SETTING_GROUP_ID,
            groupName: 'Smart-Defaults',
            folderId: 20,
            folderName: 'Smart-Defaults',
        };

        const existingSettings = this._userSettingsService.getSettingsByGroup(this.SMART_SETTING_GROUP_ID);

        if (existingSettings.length) {
            criteriaSetting = _.cloneDeep(existingSettings[0]);
            criteriaSetting.value = _.cloneDeep(settings);
        }

        return await this._userSettingsService.save(criteriaSetting);
    }

    getSavedSettings(): any {
        const existingCriteriaSettings = this._userSettingsService.getSettingsByGroup(this.SMART_SETTING_GROUP_ID);

        if (existingCriteriaSettings.length) {
            return _.cloneDeep(existingCriteriaSettings[0].value);
        } else {
            return null;
        }
    }

    getInvalidColumns(outputColumns: any[], shouldReturnColObject?: boolean): any {
        const invalidColumns = [];
        const colDepths = outputColumns.map(x => x.depthValue);

        outputColumns.forEach(colToCheck => {
            if(colToCheck.fromTaskType) {
                return;
            }

            const depthsThatDisable = this.getDepthsThatDisable(colDepths, colToCheck.depthValue);

            if (depthsThatDisable.length) {
                invalidColumns.push({
                    colName: this.getColName(colToCheck),
                    offendingColumns: _.flow([
                        filter((column) => {
                            return _.includes(depthsThatDisable, column.depthValue);
                        }),
                        uniqBy((column) => {
                            return column.typeAheadName || column.displayName;
                        }),
                        map((column) => {
                            return shouldReturnColObject ? column : this.getColName(column);
                        })
                    ])(outputColumns)
                });
            }
        });

        return invalidColumns;
    }

    getColName(column: any): string {
        return column.categoryName ? `${column.categoryName  } - ${  column.displayName}` : column.resultColumnName;
    }
}
