import { Component, OnInit, OnDestroy} from '@angular/core';
import { ReturnSummaryReconciliationReport } from '../../../Models/returnSummaryReconciliationReport';
import { ReturnService, ReturnServiceSharedState } from '../../../return.service';
import { ReturnAssetsService, ReturnAssetsServiceSharedState } from '../../Assets/returnAssets.service';
import { ReturnOverviewService, ReturnOverviewServiceSharedState } from '../returnOverview.service';
import { IReturnPartComponent } from '../../../Models/returnPartServiceBase';
import { ReturnAssetDisposalsListParams, ReturnAssetDisposalsListComponent } from '../../Assets/Asset-Disposals-List/returnAssetDisposalsList.component';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { WeissmanModalService } from '../../../../WeissmanModalService';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'return-summary-reconciliation-report',
    templateUrl: './returnSummary.component.html'
})
export class ReturnSummaryReconciliationReportComponent implements OnInit, OnDestroy, IReturnPartComponent {
    constructor(
        private readonly _returnService: ReturnService,
        private readonly _returnAssetsService: ReturnAssetsService,
        private readonly _returnOverviewService: ReturnOverviewService,
        private readonly _modalService: WeissmanModalService) {
    }

    private _localReturnServiceSharedState: ReturnServiceSharedState;
    private _localReturnOverviewServiceSharedState: ReturnOverviewServiceSharedState;
    private _localReturnAssetsServiceSharedState: ReturnAssetsServiceSharedState;
    private _showEstimatedFMV: boolean = true;
    private _destroy$: Subject<void> = new Subject();
    
    data: ReturnSummaryReconciliationReport;
    isBusy: boolean;

    ngOnInit(): void {
        this._returnOverviewService.subscribeToServiceActivationCycle(this);
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._returnOverviewService.unsubscribeFromServiceActivationCycle(this);
    }

    onReturnPartServiceActivated(): void {
        this._returnOverviewService.reconciliationReport$.pipe(takeUntil(this._destroy$))
            .subscribe(() => {
                this._refreshReport();
            });

        this._returnService.returns$.pipe(takeUntil(this._destroy$))
            .subscribe((returns) => {
                if (returns) {
                    this._refreshReport();
                }
            });
    }

    onReturnPartServiceDeactivated(): void {
        this._destroy$.next();
    }

    navigateToAssets(): void {
        const sharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
        sharedState.scheduleAndFactorFilter = {
            showOnlyNotAssignedToASchedule: false,
            showOnlyAssignedToNonReportableSchedule: false,
            showOnlyAssignedToReportableSchedule: false,
            formRevisionScheduleIds: [],
            depreciationFactorId: null,
            scheduleAge: null
        };
        sharedState.assetStatusesFilter = [];
        this._returnAssetsService.setScheduleAndFactorFilter(sharedState.scheduleAndFactorFilter);
        this._returnOverviewService.notifyReturnAssetsSelected(sharedState);
    }

    navigateToAssetsAssignedToNoSchedule(
        filterAcquisition: boolean = false,
        filterTransferIn: boolean = false,
        filterCostChange: boolean = false
    ): void {
        const sharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
        sharedState.scheduleAndFactorFilter = {
            showOnlyNotAssignedToASchedule: true,
            showOnlyAssignedToNonReportableSchedule: false,
            showOnlyAssignedToReportableSchedule: false,
            formRevisionScheduleIds: [],
            depreciationFactorId: null,
            scheduleAge: null
        };
        sharedState.assetStatusesFilter = [];

        if (filterAcquisition) sharedState.assetStatusesFilter.push(Compliance.ReturnAssetPriorReturnStatusEnum.New);
        if (filterTransferIn) sharedState.assetStatusesFilter.push(Compliance.ReturnAssetPriorReturnStatusEnum.TransferIn);
        if (filterCostChange) sharedState.assetStatusesFilter.push(Compliance.ReturnAssetPriorReturnStatusEnum.ChangedCostIncreased);

        this._returnAssetsService.setScheduleAndFactorFilter(sharedState.scheduleAndFactorFilter);
        this._returnOverviewService.notifyReturnAssetsSelected(sharedState);
    }

    navigateToAssetsAssignedToNonReportableSchedule(): void {
        const sharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
        sharedState.scheduleAndFactorFilter = {
            showOnlyNotAssignedToASchedule: false,
            showOnlyAssignedToNonReportableSchedule: true,
            showOnlyAssignedToReportableSchedule: false,
            formRevisionScheduleIds: [],
            depreciationFactorId: null,
            scheduleAge: null
        };
        sharedState.assetStatusesFilter = [];
        this._returnAssetsService.setScheduleAndFactorFilter(sharedState.scheduleAndFactorFilter);
        this._returnOverviewService.notifyReturnAssetsSelected(sharedState);
    }

    navigateToAssetsAssignedToReportableSchedule(): void {
        const sharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
        sharedState.scheduleAndFactorFilter = {
            showOnlyNotAssignedToASchedule: false,
            showOnlyAssignedToNonReportableSchedule: false,
            showOnlyAssignedToReportableSchedule: true,
            formRevisionScheduleIds: [],
            depreciationFactorId: null,
            scheduleAge: null
        };
        sharedState.assetStatusesFilter = [];
        this._returnAssetsService.setScheduleAndFactorFilter(sharedState.scheduleAndFactorFilter);
        this._returnOverviewService.notifyReturnAssetsSelected(sharedState);
    }

    navigateToAdditions(
        targetNonReportableSchedule: boolean,
        targetReportableSchedule: boolean,
        filterAcquisition: boolean,
        filterTransferIn: boolean,
        filterCostChange: boolean,
        filterReportabilityChange: boolean
    ): void {
        const sharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
        sharedState.scheduleAndFactorFilter = {
            showOnlyNotAssignedToASchedule: false,
            showOnlyAssignedToNonReportableSchedule: targetNonReportableSchedule,
            showOnlyAssignedToReportableSchedule: targetReportableSchedule,
            formRevisionScheduleIds: [],
            depreciationFactorId: null,
            scheduleAge: null
        };
        sharedState.assetStatusesFilter = [];

        if (filterAcquisition) sharedState.assetStatusesFilter.push(Compliance.ReturnAssetPriorReturnStatusEnum.New);
        if (filterTransferIn) sharedState.assetStatusesFilter.push(Compliance.ReturnAssetPriorReturnStatusEnum.TransferIn);
        if (filterCostChange) sharedState.assetStatusesFilter.push(Compliance.ReturnAssetPriorReturnStatusEnum.ChangedCostIncreased);
        if (filterReportabilityChange) sharedState.assetStatusesFilter.push(Compliance.ReturnAssetPriorReturnStatusEnum.ChangedScheduleNonReportableToReportable);

        this._returnAssetsService.setScheduleAndFactorFilter(sharedState.scheduleAndFactorFilter);
        this._returnOverviewService.notifyReturnAssetsSelected(sharedState);
    }

    async navigateToDeductions(
        title: string,
        targetReportableSchedule: boolean,
        targetNonReportableSchedule: boolean,
        filterDisposed: boolean,
        filterTransferOut: boolean,
        filterCostChange: boolean,
        filterReportabilityChange: boolean
    ): Promise<void> {

        const params: ReturnAssetDisposalsListParams = {
            title: title,
            priorReturnStatuses: [],
            formRevisionScheduleId: null,
            targetNonReportableSchedule: targetNonReportableSchedule,
            targetReportableSchedule: targetReportableSchedule,
            isDisposalOrTransferOut: filterDisposed || filterTransferOut
        };

        if (filterDisposed) params.priorReturnStatuses.push(Compliance.ReturnAssetPriorReturnStatusEnum.Retired);
        if (filterTransferOut) params.priorReturnStatuses.push(Compliance.ReturnAssetPriorReturnStatusEnum.TransferOut);
        if (filterCostChange) params.priorReturnStatuses.push(Compliance.ReturnAssetPriorReturnStatusEnum.ChangedCostDecreased);
        if (filterReportabilityChange) params.priorReturnStatuses.push(Compliance.ReturnAssetPriorReturnStatusEnum.ChangedScheduleReportableToNonReportable);

        await this._modalService.showAsync(ReturnAssetDisposalsListComponent, params, 'modal-xl');

        this._refreshReport();
    }

    get showEstimatedFMV(): boolean {
        return this._showEstimatedFMV;
    }

    private async _refreshReport(): Promise<void> {
        const returnServiceSharedState: ReturnServiceSharedState = this._returnService.getSharedStateClone();
        const returnAssetsServiceSharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
        const returnOverviewServiceSharedState: ReturnOverviewServiceSharedState = this._returnOverviewService.getSharedStateClone();

        // get input parameters for comparison
        const localFormRevisionId = this._localReturnServiceSharedState && this._localReturnServiceSharedState.formRevisionId;
        const sharedFormRevisionId = returnServiceSharedState.formRevisionId;

        const localReturnIds = (this._localReturnServiceSharedState && this._localReturnServiceSharedState.returns && this._localReturnServiceSharedState.returns.map(x => x.returnId)) || [];
        const sharedReturnIds = returnServiceSharedState.returns.map(x => x.returnId);

        const localReconciliationReport = this._localReturnOverviewServiceSharedState && this._localReturnOverviewServiceSharedState.reconciliationReport;
        const sharedReconciliationReport = returnOverviewServiceSharedState.reconciliationReport;

        const localAssetDetailsUpdatedTimestamp = this._localReturnAssetsServiceSharedState && this._localReturnAssetsServiceSharedState.assetDetailsUpdatedTimestamp;
        const sharedAssetDetailsUpdatedTimestamp = returnAssetsServiceSharedState.assetDetailsUpdatedTimestamp;

        const localAssetMappingsUpdatedTimestamp = this._localReturnAssetsServiceSharedState && this._localReturnAssetsServiceSharedState.assetMappingsUpdatedTimestamp;
        const sharedAssetMappingsUpdatedTimestamp = returnAssetsServiceSharedState.assetMappingsUpdatedTimestamp;

        // check to see if input parameters are available
        if (!sharedReturnIds.length || sharedReconciliationReport === null) {
            this._blankReport();
            return;
        }

        // check to see if input parameters have changed
        if (
            !_.isEqual(localFormRevisionId, sharedFormRevisionId) ||
            !_.isEqual(localReturnIds, sharedReturnIds) ||
            !_.isEqual(localReconciliationReport, sharedReconciliationReport) ||
            !_.isEqual(localAssetDetailsUpdatedTimestamp, sharedAssetDetailsUpdatedTimestamp) ||
            !_.isEqual(localAssetMappingsUpdatedTimestamp, sharedAssetMappingsUpdatedTimestamp)
        ) {
            await this._loadReport();
        }

        this._showEstimatedFMV = this.data && this.data.showEstimatedFMV;
    }

    private _blankReport(): void{
        this._persistState();
        this.data = null;
    }

    private _persistState(): void {
        this._localReturnServiceSharedState = this._returnService.getSharedStateClone();
        this._localReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
        this._localReturnOverviewServiceSharedState = this._returnOverviewService.getSharedStateClone();
    }

    private async _loadReport(): Promise<void> {
        this._persistState();

        this.isBusy = true;
        try {
            this.data = await this._returnOverviewService.getReturnSummaryReconciliationReport();
        } finally {
            this.isBusy = false;
        }
    }
}
