import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CommentModalData } from '../../Comments/comments.service';
import { AppealRecommendationGridRowInfoTreeItemModel } from './appealRecommendationGridRowInfoTreeItemModel';
import { AttachmentModalData } from '../../Attachment/attachment.modal.model';

export interface ICellRendererParamsForFAppealRecommendationCommandCenterGridAction extends ICellRendererParams {
}

@Component({
    selector: 'grid-action-cell',
    template:
        `
        <span class="icon-indicator" *ngIf="item && item.hasComments">
            <i class="fa fa-commenting"></i>
        </span>
        <span class="icon-indicator" *ngIf="item && !item.hasComments">
            <i class="fa fa-fw"></i>
        </span>
        <span class="icon-indicator" *ngIf="item && item.hasAttachments">
            <i class="fa fa-files-o"></i>
        </span>
        <entity-comments *ngIf="item"
                         [(hasComments)]="item && item.hasComments"
                         buttonClass="btn flat-button primary-button icon-button grid-action-button"
                         [modalData]="commentModalData"
                         helpTooltip
                         helpContentId="app.comments"
                         position="bottom">
        </entity-comments>
        <entity-attachments *ngIf="item" [(hasAttachments)]="item && item.hasAttachments"
                            buttonClass="btn flat-button primary-button icon-button grid-action-button"
                            [modalData]="attachmentModalData"
                            helpTooltip
                            helpContentId="app.attachments"
                            position="bottom">
        </entity-attachments>
`
})
export class AppealRecommendationCommandCenterGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForFAppealRecommendationCommandCenterGridAction;
    item: AppealRecommendationGridRowInfoTreeItemModel;
    commentModalData: CommentModalData;
    attachmentModalData: AttachmentModalData;

    agInit(params: ICellRendererParamsForFAppealRecommendationCommandCenterGridAction): void {
        this.params = params;
        this.item = params.data as AppealRecommendationGridRowInfoTreeItemModel;

        if (!this.item) {
            return;
        }

        this.commentModalData = {
            entityID: this.item.parcelId || this.item.siteId,
            entityTypeID: this.item.parcelId ? Core.EntityTypes.Parcel : Core.EntityTypes.Site,
            entityName: this.item.entityName,
            parcelAcctNum: this.item.parcelId ? this.item.parcelAccountNumber : null,
            canEdit: this.item.canEdit,
            description: this.item.parcelId ? `Parcel: ${this.item.parcelAccountNumber}` : `Site: ${this.item.siteName}`,
            parcelID: null,
            year: (this.item.taxYear || new Date().getFullYear()).toString(),
            commentTypeID: Core.CommentTypes.AppealRecommendation,
            disableCommentTypeSelection: !this.item.parcelId,
            disableYearSelection: true
        } as CommentModalData;

        this.attachmentModalData = {
            entityType: this.item.parcelId ? 'Parcel' : 'Site', //todo: make this a constant generated typescript value
            entityName: this.item.entityName,
            year: this.item.taxYear,
            disableYears: true,
            singleYear: true,
            entityData: {
                typeId: this.item.parcelId ? Core.EntityTypes.Parcel : Core.EntityTypes.Site,
                id: this.item.parcelId || this.item.siteId,
                name: this.item.entityName
            }
        } as AttachmentModalData;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
