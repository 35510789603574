import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { OutputColumnOperator } from './Column-Picker/column.model';
import * as _ from 'lodash';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface AdvancedSearchRouteParams {
    searchId?: number;
    searchType?: string;
    filters?: any;
}

export interface AdvancedSearchResults extends Omit<Core.AdvancedSearchResult, 'dataTable'> {
    payloadCriteria: AdvancedSearchCriteria;
    dataTable: any[];
}

export interface SearchOutputColumns {
    [key: string]: any;
}

export interface SearchOr extends Omit<Core.AdvancedSearchFilterElement, 'operator'> {
    operator: OutputColumnOperator;
}

export interface SearchFilters extends Omit<Core.AdvancedSearchFilter, 'or'>, Core.AdvancedSearchField {
    categoryID: number;
    or: SearchOr[];
}

export interface AdvancedSearchCriteria extends Omit<Core.AdvancedSearchWidgetDrilldownRequest, 'outputColumns' | 'filters'> {
    categoryId?: number;
    defaultDisplay?: number;
    filters: SearchFilters[];
    groupByBatches: boolean;
    outputColumns: SearchOutputColumns[];
    outputInt?: number;
    rowVersion?: number;
    savedSearchID?: string;
}

export interface SavedSearchModel extends Omit<Core.SavedSearch, 'rowVersion'>, Omit<Core.SavedSearchDTO, 'searchCriteria' | 'rowVersion'> {
    searchCriteria: AdvancedSearchCriteria;
    type: number;
    rowVersion: number;
}

export interface RevokeShareModel extends Omit<Core.SavedSearchInputPatchDTO, 'searchCriteria' | 'rowVersion'> {
    searchCriteria: AdvancedSearchCriteria;
    rowVersion: number;
}

@Injectable({
    providedIn: 'root'
})
export class AdvancedSearchPersistenceService {

    private _updateAdvancedSearchSubject: Subject<any> = new Subject();
    private _deleteAdvancedSearchSubject: Subject<any> = new Subject();
    private _advancedSearchSubject: Subject<SavedSearchModel> = new Subject();
    private _isSearchingSubject: Subject<boolean> = new Subject();
    private _showCriteriaSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);

    updateAdvancedSearch$: Observable<any> = this._updateAdvancedSearchSubject.asObservable();
    deleteAdvancedSearch$: Observable<any> = this._deleteAdvancedSearchSubject.asObservable();
    advancedSearch$: Observable<SavedSearchModel> = this._advancedSearchSubject.asObservable();
    isSearching$: Observable<boolean> = this._isSearchingSubject.asObservable();
    showCriteria$: Observable<boolean> = this._showCriteriaSubject.asObservable();

    routeParams: AdvancedSearchRouteParams = {};
    results: AdvancedSearchResults;
    selectedResult = {};
    sort = null;
    searchType = null;
    showPageTitle: boolean = true;
    fromWidget: boolean = false;
    hiddenColumns = [];
    reloadingSearch: boolean = false;
    columnsExpanded: boolean = false;

    private _search: SavedSearchModel;
    private _scrollLocationIndex: number;
    private _loadedSearchCriteriaCache: AdvancedSearchCriteria;

    set search(search: SavedSearchModel) {
        this._search = search;
    }

    get search(): SavedSearchModel {
        return this._search;
    }

    set scrollLocationIndex(index: number) {
        this._scrollLocationIndex = index;
    }

    get scrollLocationIndex(): number {
        return this._scrollLocationIndex;
    }

    set loadedSearchCriteriaCache(cache: AdvancedSearchCriteria) {
        this._loadedSearchCriteriaCache = cache;
    }

    updateAdvancedSearch(): void {
        this._updateAdvancedSearchSubject.next(undefined);
    }

    deleteAdvancedSearch(savedSearch: Core.AdvancedSearchList): void {
        this._deleteAdvancedSearchSubject.next(savedSearch);
    }

    setIsSearching(searching: boolean): void {
        this._isSearchingSubject.next(searching);
    }

    showCriteria(show: boolean): void {
        this._showCriteriaSubject.next(show);
    }

    clearSearchData(): void {
        this.routeParams = {};
        this.search = null;
        this.results = null;
        this.selectedResult = {};
        this.searchType = null;
        this.showPageTitle = true;
        this.fromWidget = false;
        this.hiddenColumns = [];
        this.reloadingSearch = false;
        this.columnsExpanded = false;
        this._loadedSearchCriteriaCache = null;
        this.setIsSearching(false);
        this.showCriteria(true);
    }

    setSearchCriteria(searchCriteria: AdvancedSearchCriteria) {
        if (!this.search) {
            this.search = {} as SavedSearchModel;
        }
        this.search['searchCriteria'] = searchCriteria;
        this._advancedSearchSubject.next(this.search);
    }

    resetSearchCriteria(): void {
        if (this._loadedSearchCriteriaCache) {
            this.setSearchCriteria(_.cloneDeep(this._loadedSearchCriteriaCache));
        }
    }
}
