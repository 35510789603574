import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ImportSpecificationListAgGridDataSource, ImportSpecificationListDataSourceParams } from './agGridDataSource';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { AgGridFilterParams, AgGridColumns } from '../../AgGrid';
import { AgGridOptionsBuilder } from '../../AgGrid';
import { ImportSpecificationRepository } from '../../Repositories';
import { ICellRendererParamsForImportSpecificationListGridAction, ImportSpecificationListGridActionCellRendererComponent } from './agGridActionCellRenderer.component';
import { WeissmanModalService } from '../../WeissmanModalService';
import { EntityImportSpecificationEditComponent, EntityImportSpecificationEditParams } from './Import-Specification-Edit/importSpecificationEdit.component';
import { takeUntil } from 'rxjs/operators';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { CompanyService } from '../../../Entity/Company/company.service';
import { Subject } from 'rxjs';
import { IMPORT_SPECIFICATION_LIST_HELP } from './importSpecificationList.component.help';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { BreadCrumb } from '../../../UI-Lib/Bread-Crumb/breadCrumbs.component';

@Component({
    templateUrl: './importSpecificationList.component.html',
    styleUrls: ['./importSpecificationList.component.scss'],
    selector: 'import-specification-list',
    encapsulation: ViewEncapsulation.None
})
export class ImportSpecificationListComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _importSpecificationRepository: ImportSpecificationRepository,
        private readonly _modalService: WeissmanModalService,
        private readonly _companyService: CompanyService,
        private readonly _helpService: HelpService) {}

    private _gridApi: GridApi;
    private _gridDataSource: ImportSpecificationListAgGridDataSource;
    private _companyId?: number;
    private _destroy$: Subject<void> = new Subject<void>();

    breadcrumbs: BreadCrumb[] = [];
    gridId: System.Guid = '6AA74EEF-A678-461B-B55C-CD59A9619487';
    companyName: string;
    includeDisabled: UntypedFormControl = new UntypedFormControl(false);

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        rowClassRules: {
            'row-disabled': params => params.data && (params.data as Compliance.ImportFileSpecificationModel).isDisabled
        }})
        .withColumnPinning()
        .buildDefault(this);

    get refreshing(): boolean {
        return this._gridDataSource && this._gridDataSource.isRefreshing;
    }

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(IMPORT_SPECIFICATION_LIST_HELP);

        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });

        try {
            if (!!this._routerService.getQuerystringParam('companyId')) {
                this._companyId = parseInt(this._routerService.getQuerystringParam('companyId'));
                const company = await this._companyService.load(this._companyId, false, false);

                this.companyName = company.companyName;

                this.breadcrumbs.push({
                    name: this.companyName,
                    target: 'company',
                    options: { companyId: this._companyId }
                });
            }
        } finally {
            busyRef.hide();
        }

        this.includeDisabled.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => this._setDataSource());
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Name',
                field: 'displayName',
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Type',
                field: 'importContentTypeName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Visibility',
                field: 'visibility',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Company',
                field: 'companyName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: !!this._companyId
            },
            {
                headerName: 'Owner',
                field: 'ownerName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Header Row',
                field: 'hasHeaders',
                width: AgGridColumns.textColumnExtraSmallWidth,
                lockPinned: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.booleanFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
                valueFormatter: (params) => {
                    const spec = params.data as Compliance.ImportFileSpecificationModel;
                    if (!spec) {
                        return '';
                    }
                    return spec.headerRowIndex !== 0 ? 'Yes' : 'No';
                }
            },
            {
                headerName: 'File Type',
                field: 'fileType',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },            {
                headerName: 'Delimiter',
                field: 'delimiterName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Disabled',
                field: 'isDisabled',
                width: AgGridColumns.textColumnExtraSmallWidth,
                lockPinned: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.booleanFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
                valueFormatter: (params) => {
                    const spec = params.data as Compliance.ImportFileSpecificationModel;
                    if (!spec) {
                        return '';
                    }
                    return spec.isDisabled ? 'Yes' : 'No';
                }
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(1),
                minWidth: AgGridColumns.getActionColumnWidth(1),
                maxWidth: AgGridColumns.getActionColumnWidth(1),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                sortable: false,
                cellRendererFramework: ImportSpecificationListGridActionCellRendererComponent,
                cellRendererParams: {
                    viewDetails: this._onViewDetails.bind(this)
                } as ICellRendererParamsForImportSpecificationListGridAction
            }
        ];

        const defaultSortModel = [
            {
                colId: 'displayName',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);

        this._setDataSource();
    }

    async refresh(): Promise<void> {
        this._refreshDataSource();
    }

    navigateToCompany(): void {
        this._routerService.go('company', { 'companyId': this._companyId });
    }

    async onIncludeDisabledChanged(): Promise<void> {
        this._refreshDataSource();
    }

    private async _onViewDetails(params: ICellRendererParamsForImportSpecificationListGridAction): Promise<void> {
        const importSpec = params.data as Compliance.ImportFileSpecificationModel;
        if (!importSpec) {
            return Promise.resolve();
        }

        const specParams: EntityImportSpecificationEditParams = {
            specification: importSpec
        };

        const result = await this._modalService.showAsync(EntityImportSpecificationEditComponent, specParams, 'modal-xl');

        if (!result) {
            return Promise.resolve();
        }

        await this._gridDataSource.updateRow(y => {
                const rowModel = y.data as Compliance.ImportFileSpecificationModel;
                return rowModel && rowModel.importFileSpecificationId === result.importFileSpecificationId;
            },
            () => {
                return Promise.resolve(result);
            });

        return Promise.resolve();
    }

    private _refreshDataSource(): void {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }
        this._gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }

        const dataSourceParams = (): ImportSpecificationListDataSourceParams => {
            return {
                excludeReadOnly: true,
                includeDisabled: this.includeDisabled.value,
                companyId: this._companyId
            }
        }

        this._gridDataSource = new ImportSpecificationListAgGridDataSource(this._gridApi, this._importSpecificationRepository, dataSourceParams);
        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }
}
