import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { Constants } from '../../constants.new';
import { ActivityRepository } from '../../Core-Repositories';
import { ProductAnalyticsService } from '../Amplitude/productAnalytics.service';
import * as _ from 'lodash';
import { ParcelFilterModalComponent } from './Parcel-Filter/parcelFilterModal.component';

export enum ActivityPanelModes {
    totalFMV = 'totalFMV',
    totalFMVPerSqFt = 'totalFMVPerSqFt',
    totalFMVPerUnit = 'totalFMVPerUnit',
    landFMVPerSqFt = 'landFMVPerSqFt',
    landFMVPerAcre = 'landFMVPerAcre',
    totalTaxes = 'totalTaxes',
    recentAppeals = 'recentAppeals'
}

export enum PropCharNames {
    SQFT = 'Imps. SqFt',
    UNITS = 'Units',
    ACREAGE = 'Land Acreage'
}

interface GraphConfigTitle {
    text: string;
    floating: boolean;
    useHTML?: boolean;
    zIndex?: number;
    style: { [key: string]: string | number };
}

interface GraphConfigChart {
    type?: string;
    zoomType?: string;
    spacingLeft: number;
    marginLeft: number;
    spacingRight: number;
    marginRight: number;
    spacingTop: number;
    marginTop: number;
    spacingBottom: number;
    marginBottom: number;
    width?: string;
    options3d: {
        enabled: boolean;
        alpha: number;
        beta: number;
        viewDistance: number;
        depth: number;
    },
    events?: {
        afterPrint: () => void;
    }
}

interface GraphConfigLegend {
    layout?: string;
    align?: string;
    verticalAlign?: string;
    x?: number;
    y?: number;
    floating?: boolean;
    itemStyle?: {
        width: number;
    };
    enabled?: boolean;
}

interface GraphConfigExportingMenuItem {
    textKey?: string;
    onclick?: () => void;
    separator?: boolean;
}

interface GraphConfigExporting {
    buttons: {
        contextButton: {
            menuItems: GraphConfigExportingMenuItem[]
        }
    };
}

interface GraphConfigYAxis {
    title: {
        text: string;
        x?: number,
        style?: {
            color: string;
        }
    },
    labels?: {
        format?: string;
        style?: {
            color: string;
        }
    },
    opposite?: boolean;
    allowDecimals?: boolean;
    offset?: number;
    min?: number;
}

interface GraphConfig {
    title: GraphConfigTitle;
    tableTitle: string;
    colors: string[];
    xAxis: {
        categories: any[]
    },
    series: any[];
    chartType: ActivityPanelModes | string;
    propertyCharacteristics?: any;
    chart: GraphConfigChart;
    credits: {
        enabled: boolean
    },
    yAxis: GraphConfigYAxis | GraphConfigYAxis[],
    legend: GraphConfigLegend,
    tooltip: {
        headerFormat: string;
        pointFormat: string;
        useHTML: boolean;
        shadow: boolean;
        followPointer: boolean;
        style: {
            'z-index': number;
            //'max-width': 100
            'white-space': string;
        }
    },

    plotOptions: {
        column: {
            stacking: string | boolean;
            grouping?: string | boolean;
            depth: number;
            events?: {
                legendItemClick: () => boolean;
            }
        },
        series: {
            animation: boolean;
        }
    },
    parcelFilterList?: any[];
    siteId?: number;
    loadedAllYears?: boolean;
    useLatestActualized?: boolean;
    availableYears?: number[];
    spacingBottom?: number;
    totalValues?: any[];
    exporting?: GraphConfigExporting;
}

interface UserSessionFilterCache {
    [key: number]: number[];
}

@Injectable({ providedIn: 'root' })
export class ActivityService {
    constructor(private readonly _activityRepository: ActivityRepository,
                private readonly _productAnalyticsService: ProductAnalyticsService,
                private readonly _constants: Constants,
                private readonly _modalService: WeissmanModalService
    ) {
    }

    excludeInactive: boolean = true;
    private _userSessionFilterCacheSubject: BehaviorSubject<UserSessionFilterCache> = new BehaviorSubject<UserSessionFilterCache>({});
    private _userSessionFilterCache$: Observable<UserSessionFilterCache> = this._userSessionFilterCacheSubject.asObservable();

    get userSessionFilterCache$(): Observable<UserSessionFilterCache> {
        return this._userSessionFilterCache$;
    }

    updateParcelFilterCacheForSite(siteId: number, parcelIds: number[]): void {
        const cache = this._userSessionFilterCacheSubject.getValue();
        cache[siteId] = parcelIds;
        this._userSessionFilterCacheSubject.next(cache);
    }

    clearParcelFilterCacheForSite(siteId: number): void {
        const cache = this._userSessionFilterCacheSubject.getValue();
        delete cache[siteId];
        this._userSessionFilterCacheSubject.next(cache);
    }

    getDataByYear(siteId: number, year: number, parcelFilter: any, useLatestActualized: boolean): Promise<any> {
        return lastValueFrom(this._activityRepository.getDataByYear(siteId, year, parcelFilter, useLatestActualized));
    }

    getDataForAllYears(siteId: number, parcelFilter, useLatestActualized: boolean): Promise<any> {
        return lastValueFrom(this._activityRepository.getDataForAllYears(siteId, parcelFilter, useLatestActualized));
    }

    getDataByParcel(siteId: number, year: number, parcelId: number, useLatestActualized: boolean): Promise<any> {
        return lastValueFrom(this._activityRepository.getDataByParcel(siteId, year, parcelId, useLatestActualized));
    }

    getDataByParcelAllYears(siteId: number, parcelId: number, useLatestActualized: boolean): Promise<any> {
        return lastValueFrom(this._activityRepository.getDataByParcelAllYears(siteId, parcelId, useLatestActualized));
    }

    getDeadlineData(siteId: number, parcelId: number): Promise<any> {
        return lastValueFrom(this._activityRepository.getDeadlineData(siteId, parcelId));
    }

    // TODO - this can be moved back into activity panel component once it is updated to new Angular
    async openParcelFilter(parcels: any[], selectedParcels: number[]): Promise<number[]> {
        return await this._modalService.showAsync(ParcelFilterModalComponent, { parcels, selectedParcels }, 'modal-md');
    }

    getColor(chartType: ActivityPanelModes | string, nameOrIndex: number | string, isProjected: boolean): string {
        const colors = this.getColorsArray(chartType);
        let colorIndex: number;

        if (_.isNumber(nameOrIndex)) {
            if (nameOrIndex < colors[1].length)
                colorIndex = nameOrIndex;
            else
                colorIndex = nameOrIndex % colors[1].length;
        } else { // name
            colorIndex = colors[2].indexOf(nameOrIndex);
            if (colorIndex < 0)
                throw 'unable to find color';
        }
        if (isProjected)
            return colors[1][colorIndex]; // transparent color
        else
            return colors[0][colorIndex]; // solid color
    }


    calculatePropCharAdjustedFmv(originalValueValue: number, propCharName: string, config: GraphConfig): number {
        const i = _.findIndex(config.propertyCharacteristics, (o: any) => {
            return o.label == propCharName;
        });
        if (i >= 0 && config.propertyCharacteristics[i].value != 0) {
            return new Decimal(originalValueValue).dividedBy(parseFloat(config.propertyCharacteristics[i].value)).toNumber();
        }
        return 0;
    }


    getFmvValue(originalValueValue: number, chartType: ActivityPanelModes | string, config: GraphConfig): number {
        switch (chartType) {
            case ActivityPanelModes.totalFMV:
                return originalValueValue;
            case ActivityPanelModes.totalFMVPerSqFt:
                return this.calculatePropCharAdjustedFmv(originalValueValue, PropCharNames.SQFT, config);
            case ActivityPanelModes.landFMVPerSqFt:
                // special case, instead of sqft attribute we will use acreage /43560
                //
                return new Decimal(this.calculatePropCharAdjustedFmv(originalValueValue, PropCharNames.ACREAGE, config)).dividedBy(43560).toNumber();
            case ActivityPanelModes.totalFMVPerUnit:
                return this.calculatePropCharAdjustedFmv(originalValueValue, PropCharNames.UNITS, config);
            case ActivityPanelModes.landFMVPerAcre:
                return this.calculatePropCharAdjustedFmv(originalValueValue, PropCharNames.ACREAGE, config);
        }
        return 0;
    }


    getGraphTitle(chartType: ActivityPanelModes | string): string {
        switch (chartType) {
            case ActivityPanelModes.totalFMV:
                return 'Total FMV';
            case ActivityPanelModes.totalFMVPerSqFt:
                return 'Total $/Imps. Sq Ft';
            case ActivityPanelModes.totalFMVPerUnit:
                return 'Total $/Unit';
            case ActivityPanelModes.landFMVPerSqFt:
                return 'Land $/Land Sq Ft';
            case ActivityPanelModes.landFMVPerAcre:
                return 'Land $/Acre';
            case ActivityPanelModes.totalTaxes:
            case 'totalTaxesUngrouped':
                return 'Tax Liabilities';
            case 'recentAppeals':
                return 'Recent Appeals';

        }
        return '';
    }

    getTableTitle(chartType: ActivityPanelModes | string): string {
        switch (chartType) {
            case ActivityPanelModes.totalFMV:
                return 'Total FMV';
            case ActivityPanelModes.totalFMVPerSqFt:
            case ActivityPanelModes.totalFMVPerUnit:
                return 'Total $ Per Metrics '; //+ chartType;
            case ActivityPanelModes.landFMVPerSqFt:
            case ActivityPanelModes.landFMVPerAcre:
                return 'Land $ Per Metrics '; //+ chartType;
            case ActivityPanelModes.totalTaxes:
                return 'Tax Liabilities';
            case 'recentAppeals':
                return 'Recent Appeals';

        }
        return '';
    }


    getColorsArray(chartType: ActivityPanelModes | string): string[][] {
        switch (chartType) {
            case ActivityPanelModes.totalFMV:
            case ActivityPanelModes.totalFMVPerSqFt:
            case ActivityPanelModes.totalFMVPerUnit:
                return [ // columns = values by property type
                    ['rgba(186,133,46,1)', 'rgba(104,35,45,1)', 'rgba(255,214,224, 1)', 'rgba(185,217,255, 1)'], // solid			// PP was rgba(166,153,131,1)
                    ['rgba(186,133,46,0.6)', 'rgba(104,35,45,0.5)', 'rgba(255,214,224, 0.6)', 'rgba(185,217,255, 0.6)'], // transparent
                    ['PP', 'RE', 'MN', 'CA'] // names
                ];

            case ActivityPanelModes.landFMVPerSqFt:
            case ActivityPanelModes.landFMVPerAcre:
                return [ // all columns will represent Land
                    ['rgba(113,163,96, 1)'], // solid
                    ['rgba(113,163,96, 0.5)'], // transparent
                    ['Land'] // names
                ];

            case ActivityPanelModes.totalTaxes:
            case 'totalTaxesUngrouped':
                return [ // columns = values by property type
                    ['rgba(166,206,227, 1)', 'rgba(178,223,138, 1)', 'rgba(51,160,44, 1)', 'rgba(251,154,153, 1)', 'rgba(227,26,28, 1)', 'rgba(253,191,111, 1)', 'rgba(255,127,0, 1)', 'rgba(202,178,214, 1)', 'rgba(106,61,154, 1)', 'rgba(255,255,153, 1)', 'rgba(177,89,40, 1)', 'rgba(141,211,199, 1)', 'rgba(255,255,179, 1)', 'rgba(190,186,218, 1)', 'rgba(251,128,114, 1)', 'rgba(128,177,211, 1)', 'rgba(253,180,98, 1)', 'rgba(179,222,105, 1)', 'rgba(252,205,229, 1)', 'rgba(217,217,217, 1)', 'rgba(188,128,189, 1)', 'rgba(204,235,197, 1)', 'rgba(255,237,111, 1)'], // solid
                    ['rgba(166,206,227, 0.5)', 'rgba(178,223,138, 0.5)', 'rgba(51,160,44, 0.5)', 'rgba(251,154,153, 0.5)', 'rgba(227,26,28, 0.5)', 'rgba(253,191,111, 0.5)', 'rgba(255,127,0, 0.5)', 'rgba(202,178,214, 0.5)', 'rgba(106,61,154, 0.5)', 'rgba(255,255,153, 0.5)', 'rgba(177,89,40, 0.5)', 'rgba(141,211,199, 0.5)', 'rgba(255,255,179, 0.5)', 'rgba(190,186,218, 0.5)', 'rgba(251,128,114, 0.5)', 'rgba(128,177,211, 0.5)', 'rgba(253,180,98, 0.5)', 'rgba(179,222,105, 0.5)', 'rgba(252,205,229, 0.5)', 'rgba(217,217,217, 0.5)', 'rgba(188,128,189, 0.5)', 'rgba(204,235,197, 0.5)', 'rgba(255,237,111, 0.5)'], // transparent
                    [] // names
                ];

            case 'recentAppeals':
                return [ // all columns will represent Land
                    ['rgba(100,100,100, 1)'], // solid
                    ['rgba(100,100,100, 0.5)'], // transparent
                    ['recentAppeals'] // names
                ];
        }
        return [];
    }


    getHoverPointFormat(multipleSeries: boolean, digits: number): string {
        if (multipleSeries)
            return `<span style='color:{series.color}'>\u25CF</span> {series.name}: {point.y:,.${digits}f} / {point.stackTotal:,.${digits}f}`;
        else
            return `<span style='color:{series.color}'>\u25CF</span> {series.name}: {point.y:,.${digits}f}`;
    }

    getTotalTaxHoverPointFormat(multipleSeries: boolean, digits: number): string {
        if (multipleSeries)
            return `<div style='width: 300px; white-space:normal;'><span style='color:{series.color}'>\u25CF</span> {series.name} <br/> {point.y:,.${digits}f} / {point.stackTotal:,.${digits}f}</div>`;
        else
            return `<div style='width: 300px; white-space:normal;'><span style='color:{series.color}'>\u25CF</span> <div style='max-width: 150px; word-wrap:break-word;'>{series.name} </div> <br/> {point.y:,.${digits}f}</div>`;
    }


    createGraphConfig(config: any, chartType: ActivityPanelModes | string): GraphConfig {
        let graphConfig: GraphConfig = {
            title: {
                text: this.getGraphTitle(chartType),
                floating: false,
                //useHTML: true,
                //zIndex: 1,
                style: {
                    color: 'black',
                    fontWeight: 'bold',
                    'z-index': 0,
                    'font-size': '12pt',
                    'background-color': 'white',
                    //'margin': '30px;',
                    //border: '1px solid black',
                    '-ms-transform': 'rotate(3deg)',
                    /* IE 9 */
                    '-webkit-transform': 'rotate(3deg)',
                    /* Chrome, Safari, Opera */
                    'transform': 'rotate(3deg)'
                }
            },
            tableTitle: this.getTableTitle(chartType),

            // this will return 3 arrays; first one (solid colors) will be used for legend
            colors: this.getColorsArray(chartType)[0],
            xAxis: {
                categories: []
            },
            series: [],
            chartType: chartType,
            propertyCharacteristics: config.propertyCharacteristics,

            chart: {
                type: 'column',
                spacingLeft: 10,
                marginLeft: 40,

                spacingRight: 10,
                marginRight: 30,

                spacingTop: 0,
                marginTop: 20,

                spacingBottom: 10,
                marginBottom: 50,

                //width: '400',
                options3d: {
                    enabled: true,
                    alpha: 15,
                    beta: 15,
                    viewDistance: 25,
                    depth: 70
                }
            },

            credits: {
                enabled: false
            },

            yAxis: {
                allowDecimals: false,
                offset: -30,
                min: 0,
                title: {
                    text: ''
                }
            },

            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: '',
                x: 10,
                y: 60,
                floating: true
            },

            tooltip: {
                headerFormat: '<b style="z-index: 100">{point.key}</b><br>',
                pointFormat: '', // will be defined later
                useHTML: true,
                shadow: false,
                followPointer: false,
                style: {
                    'z-index': 100,
                    //'max-width': 100
                    'white-space': 'normal !important'
                }
            },

            plotOptions: {
                column: {
                    stacking: 'normal',
                    depth: 50,
                    events: {
                        legendItemClick: null
                    }
                },
                series: {
                    animation: false
                }
            },
            parcelFilterList: null,
            siteId: null,
            loadedAllYears: null,
            useLatestActualized: null,
            availableYears: null
        };

        switch (chartType) {
            case ActivityPanelModes.totalFMV:
                graphConfig = this.createPropertyTypeAndPropCharConfig(graphConfig, config, chartType);
                graphConfig.tooltip.pointFormat = this.getHoverPointFormat((graphConfig.series.length > 1), 0);
                break;

            case ActivityPanelModes.totalFMVPerSqFt:
            case ActivityPanelModes.totalFMVPerUnit:
                graphConfig = this.createPropertyTypeAndPropCharConfig(graphConfig, config, chartType);
                graphConfig.tooltip.pointFormat = this.getHoverPointFormat((graphConfig.series.length > 1), 2);
                break;
            case ActivityPanelModes.landFMVPerSqFt:
                graphConfig = this.createLandConfig(graphConfig, config, chartType);
                graphConfig.tooltip.pointFormat = this.getHoverPointFormat((graphConfig.series.length > 1), 2);
                break;
            case ActivityPanelModes.landFMVPerAcre:
                graphConfig = this.createLandConfig(graphConfig, config, chartType);
                graphConfig.tooltip.pointFormat = this.getHoverPointFormat((graphConfig.series.length > 1), 2);
                break;
            case ActivityPanelModes.totalTaxes:
                graphConfig = this.createTotalTaxesConfig(graphConfig, config, chartType);
                graphConfig.tooltip.pointFormat = this.getTotalTaxHoverPointFormat((graphConfig.series.length > 1), 2);
                break;
            case 'recentAppeals':
                graphConfig = this.createTaxSavingsConfig(graphConfig, config, chartType);
                graphConfig.tooltip.pointFormat = this.getHoverPointFormat(false, 0);
                break;
            case 'totalTaxesUngrouped':
                graphConfig = this.createTotalTaxesUngroupedConfig(graphConfig, config);
        }
        //console.log('graphConfig', JSON.stringify(graphConfig));

        graphConfig.parcelFilterList = config.parcelFilterList; // we will need it if we want to load data for all years from within the table
        graphConfig.siteId = config.siteId;
        graphConfig.loadedAllYears = config.loadedAllYears;
        graphConfig.useLatestActualized = config.useLatestActualized;
        graphConfig.availableYears = config.availableYears;

        if (graphConfig.series.length < 2) {
            // there is no reason to show/hide part of the column in this case
            graphConfig.plotOptions.column.events = {
                legendItemClick: () => {
                    return false;
                }
            };
        }

        return graphConfig;

    }


    createPropertyTypeAndPropCharConfig(graphConfig: GraphConfig, config: any, chartType: ActivityPanelModes | string): GraphConfig {
        // create categories
        _.forEach(config.annualData, (annualYear) => {
            graphConfig.xAxis.categories.push(annualYear.year);
            _.forEach(annualYear.fmvByPropertyType, (FMVByPropertyTypeItem) => {
                // if our series array does not have this property type - add it
                const propertyTypeIndex = _.findIndex(graphConfig.series, o => {
                    return o.name == FMVByPropertyTypeItem.name;
                });
                if (propertyTypeIndex < 0) {
                    const newSeriesItem = {
                        name: FMVByPropertyTypeItem.name,
                        propertyTypeSequence: this._constants.PropertyTypes[FMVByPropertyTypeItem.name].sequence,
                        data: []
                    };
                    graphConfig.series.push(newSeriesItem);
                    //propertyTypeIndex = graphConfig.series.length - 1;
                }
            });
        });

        // sorting years
        graphConfig.xAxis.categories.sort();


        // sorting series (property types - the one with the smallest sequence must go to the end)
        //
        graphConfig.series.sort(
            (item1, item2) => {
                return item2.propertyTypeSequence - item1.propertyTypeSequence;
            }
        );

        _.forEach(graphConfig.series, (seriesItem) => {
            seriesItem._colorIndex = graphConfig.colors.indexOf(this.getColor(chartType, seriesItem.name, false));
            // set NULL for each property type (if you don't do that, HighChart will assign Zero)
            for (let i = 0; i < graphConfig.xAxis.categories.length; i++) {
                seriesItem.data.push({
                    y: null
                });
            }
        });

        // by now our graphConfig should have all years, property types and series initialized with 0 for every year
        //
        _.forEach(config.annualData, (annualYear) => {
            const annualYearIndex = graphConfig.xAxis.categories.indexOf(annualYear.year);

            _.forEach(annualYear.fmvByPropertyType, (FMVByPropertyTypeItem) => {
                // if our series does not have this property type - add it
                const propertyTypeIndex = _.findIndex(graphConfig.series, (o) => {
                    return o.name == FMVByPropertyTypeItem.name;
                });

                // now we can insert data for this year and property type
                //
                graphConfig.series[propertyTypeIndex].data[annualYearIndex].y = this.getFmvValue(FMVByPropertyTypeItem.fmv, chartType, config);
                graphConfig.series[propertyTypeIndex].data[annualYearIndex].originalValue = FMVByPropertyTypeItem.fmv;

                // now mark it as projected
                graphConfig.series[propertyTypeIndex].data[annualYearIndex].color = this.getColor(chartType, FMVByPropertyTypeItem.name, FMVByPropertyTypeItem.isProjected);

                graphConfig.series[propertyTypeIndex].data[annualYearIndex].isProjected = FMVByPropertyTypeItem.isProjected;
            });
        });

        return graphConfig;

    }

    createLandConfig(graphConfig: GraphConfig, config: any, chartType: ActivityPanelModes | string): GraphConfig {
        graphConfig.series = [{
            name: 'Land',
            data: []
        }];

        // create categories (years)
        _.forEach(config.annualData, (annualYear) => {
            graphConfig.xAxis.categories.push(annualYear.year);
        });

        // sorting years
        graphConfig.xAxis.categories.sort();

        const seriesItem = graphConfig.series[0]; // that's the only series item we'll have here
        seriesItem._colorIndex = graphConfig.colors.indexOf(this.getColor(chartType, 'Land', false));
        for (let i = 0; i < graphConfig.xAxis.categories.length; i++) {
            seriesItem.data.push({
                y: 0
            });
        }

        // by now our graphConfig should have all years  - and series data initialized with 0 for every year
        //
        _.forEach(config.annualData, (annualYear) => {
            const annualYearIndex = graphConfig.xAxis.categories.indexOf(annualYear.year);

            graphConfig.series[0].data[annualYearIndex].y = this.getFmvValue(annualYear.fmvForLandTypeComponents.fmv, chartType, config);
            graphConfig.series[0].data[annualYearIndex].originalValue = annualYear.fmvForLandTypeComponents.fmv;

            // now mark it as projected
            graphConfig.series[0].data[annualYearIndex].color = this.getColor(chartType, 'Land', annualYear.fmvForLandTypeComponents.isProjected);
            graphConfig.series[0].data[annualYearIndex].isProjected = annualYear.fmvForLandTypeComponents.isProjected;
        });

        return graphConfig;

    }


    createTotalTaxesConfig(graphConfig: GraphConfig, config: any, chartType: ActivityPanelModes | string): GraphConfig {
        // create categories
        _.forEach(config.annualData, (annualYear) => {
            graphConfig.xAxis.categories.push(annualYear.year);
            _.forEach(annualYear.collectorTaxes, (collectorTaxesItem) => {
                // if our series array does not have this property type - add it
                const existingSeriesIndex = _.findIndex(graphConfig.series, (o) => {
                    return o.name == collectorTaxesItem.collectorName;
                });
                if (existingSeriesIndex < 0) {
                    const newSeriesItem = {
                        name: collectorTaxesItem.collectorName,
                        data: []
                    };
                    graphConfig.series.push(newSeriesItem);
                    //propertyTypeIndex = graphConfig.series.length - 1;
                }
            });
        });

        // sorting years
        graphConfig.xAxis.categories.sort();

        let sdColorIndex = 0;
        _.forEach(graphConfig.series, (seriesItem) => {
            seriesItem._colorIndex = graphConfig.colors.indexOf(this.getColor(chartType, sdColorIndex, false));
            seriesItem.sdColorIndex = sdColorIndex;
            for (let i = 0; i < graphConfig.xAxis.categories.length; i++) {
                seriesItem.data.push({
                    y: 0
                });
            }
            sdColorIndex++;
        });

        // by now our graphConfig should have all years and series initialized with 0 for every year
        //
        _.forEach(config.annualData, (annualYear) => {
            const annualYearIndex = graphConfig.xAxis.categories.indexOf(annualYear.year);

            _.forEach(annualYear.collectorTaxes, (collectorTaxesItem) => {
                // if our series does not have this property type - add it
                const existingSeriesIndex = _.findIndex(graphConfig.series, (o) => {
                    return o.name == collectorTaxesItem.collectorName;
                });

                if (collectorTaxesItem.tax) {
                    // now we can insert data for this year and property type
                    //
                    graphConfig.series[existingSeriesIndex].data[annualYearIndex].y = collectorTaxesItem.tax;
                    graphConfig.series[existingSeriesIndex].data[annualYearIndex].originalValue = collectorTaxesItem.tax;
                    //graphConfig.series[existingSeriesIndex].data[annualYearIndex].originalTax = collectorTaxesItem.tax;

                    // now mark it as projected
                    graphConfig.series[existingSeriesIndex].data[annualYearIndex].color = this.getColor(chartType, graphConfig.series[existingSeriesIndex].sdColorIndex, collectorTaxesItem.isProjected);

                    graphConfig.series[existingSeriesIndex].data[annualYearIndex].isProjected = collectorTaxesItem.isProjected;
                }
            });
        });

        //console.log('graphConfig.series.length', graphConfig.series.length);

        if (graphConfig.series.length <= 4) {

            graphConfig.chart.marginBottom = (graphConfig.series.length * 21) + 25;
            graphConfig.spacingBottom = 10;
            graphConfig.chart.marginRight = 0;

            graphConfig.legend = {
                layout: 'horizontal',
                align: 'left',
                //itemWidth: LegendWidth,
                verticalAlign: 'bottom',
                floating: false,
                //y: 10,
                itemStyle: { width: 400 }
            };

        } else {
            graphConfig.legend = {
                enabled: false
            };
        }

        return graphConfig;

    }

    createTaxSavingsConfig(graphConfig: GraphConfig, config: any, chartType: ActivityPanelModes | string): GraphConfig {
        // create categories
        _.forEach(config.annualData, (annualYear) => {
            graphConfig.xAxis.categories.push(annualYear.year);
        });

        // sorting years
        graphConfig.xAxis.categories.sort();

        graphConfig.colors = ['rgba(200, 200, 200, 1)']; // this color will be used to indicate Savings legend

        graphConfig.chart = {
            zoomType: 'xy',
            options3d: {
                enabled: false,
                alpha: 15,
                beta: 15,
                viewDistance: 25,
                depth: 70
            },
            spacingLeft: 5,
            marginLeft: 60,

            spacingRight: 10,
            marginRight: 60,

            spacingTop: 0,
            marginTop: 30,

            spacingBottom: 0,
            marginBottom: 80
        };


        graphConfig.title = {
            text: this.getGraphTitle(chartType),
            floating: false,
            useHTML: true,
            style: {
                color: 'black',
                fontWeight: 'bold',
                'font-size': '12pt',
                'background-color': 'white',
                'font-family': 'Arial, Helvetica, sans-serif'
                //'margin': '30px;',
                //border: '1px solid black',
            }
        };


        graphConfig.plotOptions = {
            column: {
                depth: 40,
                stacking: false,
                grouping: false
                //groupZPadding: 10
            },
            series: {
                animation: false
            }
        };

        graphConfig.yAxis = [{ // Primary yAxis - savings oer year
            labels: {
                style: {
                    color: 'rgba(100, 100, 100, 1)'
                }
            },
            title: {
                text: 'Savings',
                x: 5,
                style: {
                    color: 'rgba(100, 100, 100, 1)'
                }
            }


        }, { // Secondary yAxis - FMV per year
            //gridLineWidth: 0,
            title: {
                text: 'FMV',
                style: {
                    color: 'red'
                }
            },
            labels: {
                //format: '{value} mm',
                style: {
                    color: 'red'
                }
            },
            offset: -30,
            opposite: true // draw this one on the right side
        }];


        graphConfig.series = [{
            'name': 'Savings',
            type: 'column',
            yAxis: 0,
            zIndex: 1,
            'data': []

        }, {
            type: 'line',
            name: 'Original FMV',
            yAxis: 1,
            zIndex: 2,
            data: [],
            lineColor: 'red',
            marker: {
                lineWidth: 2,
                lineColor: 'red',
                fillColor: 'yellow'
            }
        }, {
            type: 'line',
            name: 'Current FMV',
            yAxis: 1,
            zIndex: 3,
            data: [],
            lineColor: 'red',
            marker: {
                lineWidth: 2,
                lineColor: 'red',
                fillColor: 'green'
            }
        }];


        // now we will have several series

        //const sdColorIndex = 0;
        _.forEach(graphConfig.series, (seriesItem) => {
            //seriesItem._colorIndex = graphConfig.colors.indexOf(getColor(chartType, sdColorIndex, false))
            //seriesItem.sdColorIndex = sdColorIndex;
            for (let i = 0; i < graphConfig.xAxis.categories.length; i++) {
                seriesItem.data.push({
                    y: 0
                });
            }
            //sdColorIndex++;
        });

        // by now our graphConfig should have all years and series initialized with 0 for every year
        //
        _.forEach(config.annualData, (annualYear) => {
            const annualYearIndex = graphConfig.xAxis.categories.indexOf(annualYear.year);

            // now we can insert data for this year
            //
            graphConfig.series[0].data[annualYearIndex].color = 'rgba(166,153,131,1)';
            if (annualYear.taxSavings) {
                // savings bar
                graphConfig.series[0].data[annualYearIndex].y = this.getNumericValue(annualYear.taxSavings.savings); // should we have a here?

                // original fmv line
                graphConfig.series[1].data[annualYearIndex].y = annualYear.taxSavings.originalFMV;
                graphConfig.series[1].data[annualYearIndex].originalFMV = annualYear.taxSavings.originalFMV;
                graphConfig.series[1].data[annualYearIndex].appealStatus = annualYear.taxSavings.status;
                graphConfig.series[1].data[annualYearIndex].savings = annualYear.taxSavings.savings;
            } else { // taxSavings record doesn't exist
                // savings bar
                graphConfig.series[0].data[annualYearIndex].y = 0;
                // original fmv line
                graphConfig.series[1].data[annualYearIndex].y = 0;
            }
            // current fmv line
            graphConfig.series[2].data[annualYearIndex].y = annualYear.totalFMV;
            graphConfig.series[1].data[annualYearIndex].currentFMV = annualYear.totalFMV;

            // now mark it as projected
            //graphConfig.series[existingSeriesIndex].data[annualYearIndex].color = getColor(chartType, graphConfig.series[existingSeriesIndex].sdColorIndex, collectorTaxesItem.isProjected);
            //graphConfig.series[existingSeriesIndex].data[annualYearIndex].isProjected = collectorTaxesItem.isProjected;
        });

        graphConfig.legend = {
            layout: 'horizontal',
            align: 'left',
            verticalAlign: 'bottom',
            floating: true
        };
        return graphConfig;

    }


    getNumericValue(val: string): number {
        let result = parseFloat(val);
        if (isNaN(result))
            result = 0;
        return result;
    }


    // BEGIN Dashboard-specific methods
    //
    getValueForPropType(propTypeData, chartType: ActivityPanelModes | string): number {
        switch (chartType) {
            case ActivityPanelModes.totalFMV:
                return propTypeData.fmv;
            case ActivityPanelModes.totalFMVPerSqFt:
                return propTypeData.fmvPerSqFt;
            case ActivityPanelModes.totalFMVPerUnit:
                return propTypeData.fmvPerUnit;
        }
        return 0;
    }

    getLandValueForYear(yearData, chartType: ActivityPanelModes | string): number {
        switch (chartType) {
            case ActivityPanelModes.totalFMV:
                return yearData.fmvForLandTypeComponents.fmv;
            case ActivityPanelModes.landFMVPerSqFt:
                return yearData.fmvForLandTypeComponents.landFMVPerSqFt;
            case ActivityPanelModes.landFMVPerAcre:
                return yearData.fmvForLandTypeComponents.landFMVPerAcre;
        }
        return 0;
    }


    createGraphConfigForDashboard(config: any, chartType: ActivityPanelModes | string, afterPrintCb: () => void): GraphConfig {
        const productAnalyticsService = this._productAnalyticsService;
        let graphConfig: GraphConfig = {
            title: {
                text: this.getGraphTitle(chartType),
                floating: false,
                //useHTML: true,
                //zIndex: 1,
                style: {
                    color: 'black',
                    fontWeight: 'bold',
                    'z-index': 0,
                    'font-size': '12pt',
                    'background-color': 'white',
                    //'margin': '30px;',
                    //border: '1px solid black',
                    '-ms-transform': 'rotate(3deg)',
                    /* IE 9 */
                    '-webkit-transform': 'rotate(3deg)',
                    /* Chrome, Safari, Opera */
                    'transform': 'rotate(3deg)'
                }
            },
            tableTitle: this.getTableTitle(chartType),

            // this will return 3 arrays; first one (solid colors) will be used for legend
            colors: this.getColorsArray(chartType)[0],
            xAxis: {
                categories: []
            },
            series: [],
            chartType: chartType,
            totalValues: [],

            chart: {
                type: 'column',
                spacingLeft: 10,
                marginLeft: 40,

                spacingRight: 10,
                marginRight: 30,

                spacingTop: 0,
                marginTop: 20,

                spacingBottom: 10,
                marginBottom: 50,

                //width: '400',
                options3d: {
                    enabled: true,
                    alpha: 15,
                    beta: 15,
                    viewDistance: 25,
                    depth: 70
                },

                events: {
                    afterPrint: afterPrintCb
                }
            },

            credits: {
                enabled: false
            },

            yAxis: {
                allowDecimals: false,
                offset: -30,
                min: 0,
                title: {
                    text: ''
                }
            },

            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: '',
                x: 20,
                floating: false,
                y: 60
            },

            tooltip: {
                headerFormat: '<b style="z-index: 100">{point.key}</b><br>',
                pointFormat: '', // will be defined later
                useHTML: true,
                shadow: false,
                followPointer: false,
                style: {
                    'z-index': 100,
                    //'max-width': 100
                    'white-space': 'normal !important'
                }
            },

            plotOptions: {
                column: {
                    stacking: 'normal',
                    depth: 50
                },
                series: {
                    animation: false
                }
            },

            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: [
                            {
                                textKey: 'printChart',
                                onclick: function() {
                                    this.print();
                                    productAnalyticsService.logEvent('click-portfolio-widget', {
                                        printChart: 'print'
                                    });
                                }
                            },
                            { separator: true },
                            {
                                textKey: 'downloadPNG',
                                onclick: function() {
                                    this.exportChart();
                                    productAnalyticsService.logEvent('click-portfolio-widget', {
                                        printChart: 'png'
                                    });
                                }
                            },
                            {
                                textKey: 'downloadJPEG',
                                onclick: function() {
                                    this.exportChart({
                                        type: 'image/jpeg'
                                    });
                                    productAnalyticsService.logEvent('click-portfolio-widget', {
                                        printChart: 'jpeg'
                                    });
                                }
                            },
                            {
                                textKey: 'downloadPDF',
                                onclick: function() {
                                    this.exportChart({
                                        type: 'application/pdf'
                                    });
                                    productAnalyticsService.logEvent('click-portfolio-widget', {
                                        printChart: 'pdf'
                                    });
                                }
                            },
                            {
                                textKey: 'downloadSVG',
                                onclick: function() {
                                    this.exportChart({
                                        type: 'image/svg+xml'
                                    });
                                    productAnalyticsService.logEvent('click-portfolio-widget', {
                                        printChart: 'svg'
                                    });
                                }
                            }
                        ]
                    }
                }
            }
        };

        // create categories (years) and totalValues by years.
        _.forEach(config.annualData, (annualYear) => {
            graphConfig.xAxis.categories.push(annualYear.year);

            const newYearTotals = {
                year: annualYear.year,
                totalFMV: annualYear.totalFMV,
                fmvPerSqFt: annualYear.totalFMVPerSqFt,
                fmvPerUnit: annualYear.totalFMVPerUnit,
                landFMV: annualYear.fmvForLandTypeComponents.fmv,
                landFMVPerSqFt: annualYear.fmvForLandTypeComponents.landFMVPerSqFt,
                landFMVPerAcre: annualYear.fmvForLandTypeComponents.landFMVPerAcre,
                totalTax: annualYear.totalTax
            };
            graphConfig.totalValues.push(newYearTotals);
        });

        // sort categories and totalValues in ascending years so they are parallel
        graphConfig.xAxis.categories.sort();
        graphConfig.totalValues.sort(
            (item1, item2) => {
                return item1.year - item2.year;
            }
        );

        switch (chartType) {
            case ActivityPanelModes.totalFMV:
                graphConfig = this.createPropertyTypeConfig(graphConfig, config, chartType);
                graphConfig.tooltip.pointFormat = this.getHoverPointFormat((graphConfig.series.length > 1), 0);
                break;

            case ActivityPanelModes.totalFMVPerSqFt:
            case ActivityPanelModes.totalFMVPerUnit:
                graphConfig = this.createPropertyTypeConfig(graphConfig, config, chartType);
                graphConfig.tooltip.pointFormat = this.getHoverPointFormat((graphConfig.series.length > 1), 2);
                break;
            case ActivityPanelModes.landFMVPerSqFt:
            case ActivityPanelModes.landFMVPerAcre:
                graphConfig = this.createLandConfigForDashboard(graphConfig, config, chartType);
                graphConfig.tooltip.pointFormat = this.getHoverPointFormat((graphConfig.series.length > 1), 2);
                break;
            /* TODO for Site
            case ActivityPanelModes.totalTaxes:
                graphConfig = createTotalTaxesConfig(graphConfig, config, chartType);
                graphConfig.tooltip.pointFormat = getTotalTaxHoverPointFormat((graphConfig.series.length > 1), 2);
                break;
            case "recentAppeals":
                graphConfig = createTaxSavingsConfig(graphConfig, config, chartType);
                graphConfig.tooltip.pointFormat = getHoverPointFormat(false, 0);
                break;
            */
            case 'totalTaxesUngrouped':
                graphConfig = this.createTotalTaxesUngroupedConfig(graphConfig, config);
                graphConfig.tooltip.pointFormat = this.getTotalTaxHoverPointFormat(false, 2);
                break;
        }
        //console.log('graphConfig', JSON.stringify(graphConfig));

        graphConfig.parcelFilterList = config.parcelFilterList; // we will need it if we want to load data for all years from within the table
        graphConfig.siteId = config.siteId;
        graphConfig.loadedAllYears = config.loadedAllYears;
        graphConfig.useLatestActualized = config.useLatestActualized;
        graphConfig.availableYears = config.availableYears;

        if (graphConfig.series.length < 2) {
            // there is no reason to show/hide part of the column in this case
            graphConfig.plotOptions.column.events = {
                legendItemClick: () => {
                    return false;
                }
            };
        }

        return graphConfig;

    }


    createPropertyTypeConfig(graphConfig: GraphConfig, config: any, chartType: ActivityPanelModes | string): GraphConfig {
        // create series
        _.forEach(config.annualData, (annualYear) => {
            _.forEach(annualYear.fmvByPropertyType, (FMVByPropertyTypeItem) => {
                // if our series array does not have this property type - add it
                const propertyTypeIndex = _.findIndex(graphConfig.series, (o) => {
                    return o.name == FMVByPropertyTypeItem.name;
                });
                if (propertyTypeIndex < 0) {
                    const newSeriesItem = {
                        name: FMVByPropertyTypeItem.name,
                        propertyTypeSequence: this._constants.PropertyTypes[FMVByPropertyTypeItem.name].sequence,
                        data: []
                    };
                    graphConfig.series.push(newSeriesItem);
                    //propertyTypeIndex = graphConfig.series.length - 1;
                }
            });
        });


        // sorting series (property types - the one with the smallest sequence must go to the end)
        //
        graphConfig.series.sort(
            (item1, item2) => {
                return item2.propertyTypeSequence - item1.propertyTypeSequence;
            }
        );

        _.forEach(graphConfig.series, (seriesItem) => {
            seriesItem._colorIndex = graphConfig.colors.indexOf(this.getColor(chartType, seriesItem.name, false));
            // set NULL for each property type (if you don't do that, HighChart will assign Zero)
            for (let i = 0; i < graphConfig.xAxis.categories.length; i++)
                seriesItem.data.push({
                    y: null
                });
        });

        // by now our graphConfig should have all years, property types and series initialized with 0 for every year
        //
        _.forEach(config.annualData, (annualYear) => {
            const annualYearIndex = graphConfig.xAxis.categories.indexOf(annualYear.year);

            _.forEach(annualYear.fmvByPropertyType, (FMVByPropertyTypeItem) => {
                // if our series does not have this property type - add it
                const propertyTypeIndex = _.findIndex(graphConfig.series, (o) => {
                    return o.name == FMVByPropertyTypeItem.name;
                });

                // now we can insert data for this year and property type
                //
                graphConfig.series[propertyTypeIndex].data[annualYearIndex].y = this.getValueForPropType(FMVByPropertyTypeItem, chartType);
                graphConfig.series[propertyTypeIndex].data[annualYearIndex].originalValue = this.getValueForPropType(FMVByPropertyTypeItem, chartType);

                // now mark it as projected
                graphConfig.series[propertyTypeIndex].data[annualYearIndex].color = this.getColor(chartType, FMVByPropertyTypeItem.name, FMVByPropertyTypeItem.isProjected);
                graphConfig.series[propertyTypeIndex].data[annualYearIndex].isProjected = false;
                /* TODO for SITE
                graphConfig.series[propertyTypeIndex].data[annualYearIndex].isProjected = FMVByPropertyTypeItem.isProjected;
                */
            });
        });

        return graphConfig;

    }

    createLandConfigForDashboard(graphConfig: GraphConfig, config: any, chartType: ActivityPanelModes | string): GraphConfig {

        graphConfig.series = [{
            name: 'Land',
            data: []
        }];

        const seriesItem = graphConfig.series[0]; // that's the only series item we'll have here
        seriesItem._colorIndex = graphConfig.colors.indexOf(this.getColor(chartType, 'Land', false));
        for (let i = 0; i < graphConfig.xAxis.categories.length; i++)
            seriesItem.data.push({
                y: 0
            });

        // by now our graphConfig should have all years  - and series data initialized with 0 for every year
        //
        _.forEach(config.annualData, (annualYear) => {
            const annualYearIndex = graphConfig.xAxis.categories.indexOf(annualYear.year);

            graphConfig.series[0].data[annualYearIndex].y = this.getLandValueForYear(annualYear, chartType);
            graphConfig.series[0].data[annualYearIndex].originalValue = this.getLandValueForYear(annualYear, chartType);

            // now mark it as projected
            graphConfig.series[0].data[annualYearIndex].color = this.getColor(chartType, 'Land', annualYear.fmvForLandTypeComponents.isProjected);
            graphConfig.series[0].data[annualYearIndex].isProjected = false;
            /* TODO for SITE
            graphConfig.series[0].data[annualYearIndex].isProjected = annualYear.fmvForLandTypeComponents.isProjected;
            */
        });

        return graphConfig;

    }

    createTotalTaxesUngroupedConfig(graphConfig, config) {
        graphConfig.series = [{
            name: 'Tax Liabilities',
            data: []
        }];

        const seriesItem = graphConfig.series[0]; // that's the only series item we'll have here
        seriesItem._colorIndex = graphConfig.colors.indexOf('rgba(166,206,227, 1)');
        for (let i = 0; i < graphConfig.xAxis.categories.length; i++) {
            seriesItem.data.push({
                y: 0
            });
        }

        // by now our graphConfig should have all years  - and series data initialized with 0 for every year
        //
        _.forEach(config.annualData, (annualYear) => {
            const annualYearIndex = graphConfig.xAxis.categories.indexOf(annualYear.year);

            graphConfig.series[0].data[annualYearIndex].y = annualYear.totalTax;
            graphConfig.series[0].data[annualYearIndex].originalValue = annualYear.totalTax;

            // now mark it as projected
            graphConfig.series[0].data[annualYearIndex].color = 'rgba(166,206,227, 1)';
            graphConfig.series[0].data[annualYearIndex].isProjected = false;
        });

        return graphConfig;
    }

    //
    // END Dashboard-specific methods
}
