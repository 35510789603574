<loading-spinner [show]="isLoading" [hasOverlay]="true" [size]="5"></loading-spinner>
<ws-modal-wrapper [headerLabel]="('CSR Bulk Update')"
                  subHeaderLabel="Please select the new responsibility for the selected CSRs"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!isFormValid || isSaving">
                  <h1>Reassign CSRs</h1>
                  <div>You have selected {{params.csrs.length}} responsibilites to change. Select the responsible person below</div>
                  <hr/>

    <!-- account handler option-->
    <div class="radio">
        <label>
            <input type="radio" (change)="determineShowDatepicker()" [(ngModel)]="updateWorkflowType" [value]="WorkflowUserTypes['ACCOUNTHANDLER'].id"> Account Handler
        </label>
    </div>

	<!--jurisdiction specialist (only available inside ryan instance)-->
    <div class="radio" *ngIf="isRyanInstance">
        <label>
            <input type="radio" (change)="determineShowDatepicker()" [(ngModel)]="updateWorkflowType" [value]="WorkflowUserTypes['JURISDICTIONSPECIALIST'].id" > Jurisdiction Specialist
        </label>
    </div>

    <!-- consultant option (only for for consulting clients) -->
	<div class="radio" *ngIf="isConsultingClient() && consultingEngagements && consultingEngagements.length">
		<label>
			<input type="radio" (change)="determineShowDatepicker()" [(ngModel)]="updateWorkflowType" [value]="CONSULTING_ENGAGEMENT"> Consultant
            <!-- only one consultant available - just show their name -->
			<span *ngIf="consultingEngagements.length === 1"> - {{consultingEngagements[0].name}}</span>
		</label>
        <!--more than one consultant available - show dropdown for user to select-->
		<div *ngIf="consultingEngagements.length > 1" class="consultant-selector">
			<select name="consultingEngagement"
					class="form-select form-select-sm"
					style="width: auto;"
                    [disabled]="updateWorkflowType >= 0 || !updateWorkflowType"
					(change)="changeConsultingEngagement($event.target)"
					popover-trigger="mouseenter">
                    <option value="null" selected></option>
                    <option *ngFor="let ce of consultingEngagements" [value]="ce.consultingEngagementId">{{ ce.name }}</option>
			</select>
		</div>
	</div>

    <!-- specific individual -->
    <div class="radio" (click)="updateWorkflowType = WorkflowUserTypes['USER'].id">
        <label>
            <input type="radio" (change)="determineShowDatepicker()" [(ngModel)]="updateWorkflowType" [value]="WorkflowUserTypes['USER'].id"> Specific Individual
            <div>
                <user-team-picker
                    [user]="updateUser"
                    (userChange)="setUser($event)"
                    (clickFunction)="assignUserValue()"
                    [entityTypeScope]="'Instance'"
                    [entityIdScope] = "instanceId">
                </user-team-picker>
            </div>
        </label>
    </div>

    <!--N/A option-->
    <div class="radio">
        <label>
            <input type="radio" (change)="determineShowDatepicker()" [(ngModel)]="updateWorkflowType" [value]="WorkflowUserTypes['NA'].id"> N/A
        </label>
    </div>

    <div *ngIf="showDatePicker" class="from-date-picker" >
        <label style="float:left;font-weight: bold;">From Date: </label>
        <div style="width: 120px; padding-left: 6px;float:left">
            <weissman-datetime [(inputDate)]="setDateFrom" (change)="determineShowDatepicker()" [dateOnly]="true" [readOnly]="false" [direction]="'up'">
            </weissman-datetime>
        </div>
        <i class="fa fa-info-circle" style="padding-left:7px;margin-top:8px;" title="This date is used to reassign tasks after a certain date. &#013;This will only affect responsibilities that were previously not set to N/A"></i>
    </div>
</ws-modal-wrapper>
