import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CostCenterTeam } from '../../../Team/teamPicker.component';
import TeamModel = Core.TeamModel;

export interface AssignedDetailsModalParams {
    options: Compliance.NameValuePair<Core.AppealRecommendationCCAssignedFilterModel>[],
    selectedOptionName: string,
    defaultCustomOption: Compliance.NameValuePair<Core.AppealRecommendationCCAssignedFilterModel>;
}

export enum AppealRecommendationCCAssignedFilterShowOptionEnum {
    MyTasks = Core.AppealRecommendationCCAssignedFilterShowOptionEnum.MyTasks,
    MyTeamTasks = Core.AppealRecommendationCCAssignedFilterShowOptionEnum.MyTeamTasks,
    SpecificIndividualTasks = Core.AppealRecommendationCCAssignedFilterShowOptionEnum.SpecificIndividualTasks,
    SpecificTeamTasks = Core.AppealRecommendationCCAssignedFilterShowOptionEnum.SpecificTeamTasks
}

export enum AppealRecommendationCCAssignedFilterWhereOptionEnum {
    Assigned = Core.AppealRecommendationCCAssignedFilterWhereOptionEnum.Assigned,
    UltimatelyResponsible = Core.AppealRecommendationCCAssignedFilterWhereOptionEnum.UltimatelyResponsible,
    AssignedOrUltimatelyResponsible = Core.AppealRecommendationCCAssignedFilterWhereOptionEnum.AssignedOrUltimatelyResponsible,
    AccountHandler = Core.AppealRecommendationCCAssignedFilterWhereOptionEnum.AccountHandler
}

export enum AppealRecommendationCCAssignedFilterThatOptionEnum {
    Ready = Core.AppealRecommendationCCAssignedFilterThatOptionEnum.Ready,
    NotReady = Core.AppealRecommendationCCAssignedFilterThatOptionEnum.NotReady,
    ReadyOrNotReady = Core.AppealRecommendationCCAssignedFilterThatOptionEnum.ReadyOrNotReady,
    Completed = Core.AppealRecommendationCCAssignedFilterThatOptionEnum.Completed
}

@Component({
    selector: 'appeal-recommendation-command-center-assigned-details-modal',
    templateUrl: './appealRecommendationCommandCenterAssignedDetailsModal.component.html',
    styleUrls: ['./appealRecommendationCommandCenterAssignedDetailsModal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppealRecommendationCommandCenterAssignedDetailsModalComponent implements OnInit, IWeissmanModalComponent<AssignedDetailsModalParams, Compliance.NameValuePair<Core.AppealRecommendationCCAssignedFilterModel>>{
    constructor(
        private readonly _bsModalRef: BsModalRef
    ){ }

    params: AssignedDetailsModalParams;
    result: Compliance.NameValuePair<Core.AppealRecommendationCCAssignedFilterModel>;
    appealRecommendationCCAssignedFilterShowOptionEnum = AppealRecommendationCCAssignedFilterShowOptionEnum;
    appealRecommendationCCAssignedFilterWhereOptionEnum = AppealRecommendationCCAssignedFilterWhereOptionEnum;
    appealRecommendationCCAssignedFilterThatOptionEnum = AppealRecommendationCCAssignedFilterThatOptionEnum;
    selectedOption: Compliance.NameValuePair<Core.AppealRecommendationCCAssignedFilterModel>;
    _originalCustomOption: Compliance.NameValuePair<Core.AppealRecommendationCCAssignedFilterModel>;
    specificTeams: CostCenterTeam[] = [];

    ngOnInit(): void {
        this.selectedOption = this.params.options.find(x => x.name === this.params.selectedOptionName);
        this._originalCustomOption = structuredClone(this.params.options[this.params.options.length - 1]);

        if (this.selectedOptionName === 'Custom' && this.selectedOption.value.specificUsers.length === 0) {
            this.addNewUser();
        }

        if (this.selectedOptionName === 'Custom' && this.selectedOption.value.specificTeams.length === 0) {
            this.addNewTeam();
        }

        if (this.selectedOption.name === 'Custom') {
            this._populateSpecificTeams(this.selectedOption.value.specificTeams);
        }
    }


    save() {
        if (this.selectedOption.name === 'Custom') {
            this.selectedOption.value.specificTeams = this.specificTeams.map(x => {
                return {teamID: x.teamID, name: x.name} as TeamModel;
            });
        }
        this.result = this.selectedOption;
        this._bsModalRef.hide();
    }

    cancel() {
        if (this.selectedOption.name === 'Custom') {
            Object.assign(this.selectedOption, this._originalCustomOption);
        }
        this._bsModalRef.hide();
    }

    removeUser(index: number) {
        this.selectedOption.value.specificUsers.splice(index, 1);
    }

    setUser(user: Core.UserTeamModel, index: number) {
        this.selectedOption.value.specificUsers[index] = user;
    }

    addNewUser() {
        this.selectedOption.value.specificUsers.push({} as Core.UserTeamModel);
    }

    selectionChanged(newValue: any, propertyName: string) {
        const modifiedSelectedOption = structuredClone(this.selectedOption);
        modifiedSelectedOption.value[propertyName] = newValue;

        const predefinedOption = this.params.options
            .find(x => x.value.show === modifiedSelectedOption.value.show &&
                x.value.where === modifiedSelectedOption.value.where &&
                x.value.that === modifiedSelectedOption.value.that);

        if (predefinedOption) {
            this.selectedOptionChanged(predefinedOption.name, false);
        } else {
            if (this.selectedOptionName !== 'Custom') {
                this.selectedOption = structuredClone(this.params.defaultCustomOption);
            }

            this.selectedOption.value[propertyName] = newValue;
            if (propertyName === 'show') {
                if (newValue === AppealRecommendationCCAssignedFilterShowOptionEnum.MyTasks ||
                    newValue === AppealRecommendationCCAssignedFilterShowOptionEnum.MyTeamTasks) {
                    this.selectedOption.value.specificUsers = [];
                    this.selectedOption.value.specificTeams = [];
                    this.specificTeams = [];
                } else if (newValue === AppealRecommendationCCAssignedFilterShowOptionEnum.SpecificIndividualTasks) {
                    this.selectedOption.value.specificUsers = [{} as Core.UserTeamModel];
                    this.selectedOption.value.specificTeams = [];
                    this.specificTeams = [];
                } else if (newValue === AppealRecommendationCCAssignedFilterShowOptionEnum.SpecificTeamTasks) {
                    this.selectedOption.value.specificUsers = [];
                    this.specificTeams = [{} as CostCenterTeam];
                }
            }
        }
    }

    selectedOptionChanged(optionName: string, reloadCustom: boolean) {
        this.selectedOption = this.params.options
            .find(x => x.name === optionName);

        if (optionName === 'Custom' && reloadCustom) {
            Object.assign(this.selectedOption, this._originalCustomOption);
        }
    }

    addNewTeam() {
        this.specificTeams.push({} as CostCenterTeam);
    }

    removeTeam(index: number) {
        this.specificTeams.splice(index, 1);
    }
    teamChanged(team: CostCenterTeam, index: number) {
        this.specificTeams[index] = team;
    }

    get selectedOptionName(): string {
        return this.selectedOption && this.selectedOption.name;
    }

    get disableSubmit(): boolean {
        const selectedOptionValue = this.selectedOption.value;
        const specificIndividualTasks = selectedOptionValue.show === Core.AppealRecommendationCCAssignedFilterShowOptionEnum.SpecificIndividualTasks
            && selectedOptionValue.specificUsers.some(x => x && !x.userID);
        const specificTeamTasks = selectedOptionValue.show === Core.AppealRecommendationCCAssignedFilterShowOptionEnum.SpecificTeamTasks
            && this.specificTeams.some(x => x && !x.teamID);
        return specificIndividualTasks || specificTeamTasks;
    }

    private _populateSpecificTeams(teams: Core.TeamModel[]) {
        this.specificTeams = teams.map(x => {
            return {teamID: x.teamID, name: x.name} as CostCenterTeam;
        });

        if (this.specificTeams.length === 0) {
            this.addNewTeam();
        }
    }
}
