import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PermissionService } from '../permission.service';
import * as _ from 'lodash';
import { UserInstanceService } from '../../User/userInstance.service';
import { BusyIndicatorService } from '../../Busy-Indicator';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { AgGridFilterParams, AgGridOptionsBuilder } from '../../Compliance/AgGrid';
import { ApplicationPermissionGridActionCellRendererComponent, ICellRendererParamsForApplicationPermissionGridAction } from './agGridActionCellRenderer.component';
import { NavigationService } from '../../Layout/Navigation.Service.upgrade';
import { UserGroupsService } from '../../User-Group/userGroup.service';
import { UserGroupPanel } from 'src/app/User-Group/User-Group-Detail/userGroupDetail.component';

export interface PermissionWithOptions {
    right: Core.RoleModel;
    rightOptions: Core.RoleModel[];
    isDeleted: boolean;
}

@Component({
    templateUrl: './applicationPermissionPanel.component.html',
    selector: 'application-permission-panel'
})
export class ApplicationPermissionPanelComponent implements OnInit {
    @Input() userGroup: Core.GroupModel;
    @Input() canEdit: boolean;
    @Input() userSetup: boolean;
    @Output() expandClicked: EventEmitter<UserGroupPanel> = new EventEmitter<UserGroupPanel>();

    canEnterEditMode: boolean = true;
    editMode: boolean = false;
    isExpanded: boolean = false;
    gridIsDirty: boolean = false;

    allPermissions: PermissionWithOptions[];
    assignedPermissions: PermissionWithOptions[];
    availablePermissions: PermissionWithOptions[];

    private _originalAllPermissions: PermissionWithOptions[];

    private _gridApi: GridApi;
    private _gridDataSource: any;

    constructor(
        private readonly _permissionService: PermissionService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _navigationService: NavigationService,
        private readonly _userGroupsService: UserGroupsService,
        public readonly userInstanceService: UserInstanceService
    ) { }

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        suppressHorizontalScroll: true        
    })
        .withContext(this)
        .withLoadingOverlay()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();
        
    async ngOnInit(): Promise<void> {
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Permission',
                field: 'right.screenType',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Access Level',
                field: 'right.rights',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: ApplicationPermissionGridActionCellRendererComponent,
                cellRendererParams: {
                    canEdit: () => {
                        return this.editMode && this.canEdit;
                    },
                    permissionChanged: () => {
                        this.gridIsDirty = true;
                    }
                } as ICellRendererParamsForApplicationPermissionGridAction                
            }            
        ];

        const defaultSortModel = [
            {
                colId: 'right.screenType',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._gridApi.sizeColumnsToFit();
        this.setDataSource();
    }

    setDataSource(): void {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }

        try {
            this._gridApi && this._gridApi.showLoadingOverlay();

            this.loadPermissions().then(r=>{
                this._gridApi.setRowData(this.allPermissions);
                this._gridApi && this._gridApi.hideOverlay();
            });
        }
        finally {
            this._gridApi && this._gridApi.hideOverlay();
        }

    }

    private async loadPermissions() {
        const assignedPermissions = await this._permissionService.getGroupApplicationRights(this.userGroup.groupID);
        await this._initPermissions(assignedPermissions);
    }

    private async _initPermissions(assignedPermissions: Core.RoleModel[]) {

        const availablePermissions = await this._permissionService.getAvailableGroupApplicationRights(this.userGroup.groupID);

        this.assignedPermissions = _.map(assignedPermissions, permission => {
            const otherOptions = _.filter(availablePermissions, x => x.screenTypeID === permission.screenTypeID)
            const noneOption: Core.RoleModel = { roleID: -1, rightsID: -1, name: permission.name,  rights:'None', screenType: permission.screenType, screenTypeID:-1, sequence:-1 }
            return {
                right: permission,
                rightOptions: _.sortBy([...otherOptions, permission, noneOption], 'rightsID'),
                isDeleted: false
            }
        });

        this.availablePermissions = _.chain(availablePermissions)
            .reject(x => _.some(assignedPermissions, { screenTypeID: x.screenTypeID }))
            .groupBy('screenTypeID')
            .toArray()
            .map(rightOptions => {
                const viewRight = _.find(rightOptions, { rightsID: Core.RightEnum.View }) || rightOptions[0];
                const noneOption: Core.RoleModel = { roleID: -1, rightsID: -1, name: rightOptions[0].name,  rights:'None', screenType: rightOptions[0].screenType, screenTypeID:-1, sequence:-1 }

                return {
                    right: noneOption,
                    rightOptions: _.sortBy([...rightOptions,noneOption], 'rightsID'),
                    isDeleted: false
                }
            })
            .value();

            this.allPermissions =  _.sortBy(this.assignedPermissions.concat(this.availablePermissions),'right.screenType');

    }

    canBeEdited(permission: PermissionWithOptions) {
        return true;
    }

    addPermission(permission: PermissionWithOptions) {
        this.assignedPermissions.push(permission);
        _.remove(this.availablePermissions, permission);
    }

    edit() {
        this._originalAllPermissions = _.cloneDeep(this.allPermissions);
        this.editMode = true;
        this._userGroupsService.setPanelEdit(this.editMode);
        this._navigationService.enableNavWarning('Editing is in progress. Are you sure you want to leave?');
    }

    cancel() {
        this.allPermissions = this._originalAllPermissions;
        this.setDataSource();
        this._exitEditMode();
    }

    async save() {
        const roleIds = this.allPermissions
            .filter(a=>a.right.rightsID > -1)
            .map(x => x.right.roleID);

        const busyRef = this._busyIndicatorService.show({ message: 'Updating Application Rights' });
        try {
            const assignedPermissions = await this._permissionService.updateGroupApplicationRights(this.userGroup.groupID, roleIds);
            await this._initPermissions(assignedPermissions);

            this._exitEditMode();
        } catch (e2) {
            if (e2 && e2.status !== 422) {
                return Promise.reject(e2);
            }
        }
        finally {
            await busyRef.hide();
        }
    }

    expandCollapse(expand: boolean) {
        this.expandClicked.emit('applicationPermission');
        setTimeout(() => {
            this._gridApi.sizeColumnsToFit();
        })
        this.isExpanded = expand;
    }

    private _exitEditMode(): void {
        this.editMode = false;
        this._userGroupsService.setPanelEdit(this.editMode);
        this._navigationService.disableNavWarning();
        this.gridIsDirty = false;
    }
}
