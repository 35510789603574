import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { BusyIndicatorMessageManager} from '../../../Busy-Indicator';
import { RestrictService, Roles } from '../../../Common/Permissions/restrict.service';
import { YearPickerMixin } from '../../../UI-Lib/Mixins';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ComplianceInfoRepository } from '../../Repositories/complianceInfo.repository';
import {
    ComplianceLienDateConfigurationExceptionComponent,
    ComplianceLienDateConfigurationExceptionModalParams
} from '../Compliance-Lien-Date-Configuation-Exception/complianceLienDateConfigurationException.component';
import { WeissmanModalService } from '../../WeissmanModalService';
import { ComplianceLienDateConfigurationService } from '../complianceLienDateConfiguration.service';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';

@Component({
    selector: 'compliance-lien-date-configuration-panel',
    templateUrl: './compliance-lien-date-configuration-panel.component.html',
    styleUrls: ['./compliance-lien-date-configuration-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ComplianceLienDateConfigurationPanelComponent extends YearPickerMixin() implements OnInit {
    constructor(private readonly _restrictService: RestrictService,
                private readonly _fb: UntypedFormBuilder,
                private readonly _complianceInfoRepository: ComplianceInfoRepository,
                private readonly _modalService: WeissmanModalService,
                private readonly _complianceLienDateConfigurationService: ComplianceLienDateConfigurationService,
                private readonly _routerService: UpgradeNavigationServiceHandler) {
        super();
    }

    @Input() stateId: number;
    @Input() assessorId: number;

    busyMessageManager = new BusyIndicatorMessageManager<string>();
    configurationForm: UntypedFormGroup;
    canView: boolean = false;
    isInitialized: boolean = false;
    stateLienDateInfo : Compliance.ComplianceLienDateInfoStateModel;

    private _isExpanded: boolean = false;
    private _lienDateInfo: Compliance.ComplianceLienDateInfoModel;
    private _selectedTaxYear: number;

    async ngOnInit(): Promise<void> {
        this.canView = this._restrictService.isInRole(Roles.COMPLIANCESETUPSVIEW);
        this._selectedTaxYear = new Date().getFullYear();
    }

    async expandOrCollapse(): Promise<void>{
        this._isExpanded = !this._isExpanded;

        if (this._isExpanded && !this.isInitialized){
            const busyMessageId = 'loading';
            this.busyMessageManager.add('Loading', busyMessageId);

            try {
                if (this.stateId){
                    this.stateLienDateInfo = await lastValueFrom(this._complianceInfoRepository.getStateLienDates(this.stateId));
                    this._lienDateInfo = this.stateLienDateInfo;
                }

                if (this.assessorId) {
                    this._lienDateInfo = await lastValueFrom(this._complianceInfoRepository.getAssessorLienDates(this.assessorId));
                }

                this.configurationForm = this._fb.group({
                    taxYear: [this._selectedTaxYear]
                });

                this.isInitialized = true;
            }
            finally {
                this.busyMessageManager.remove(busyMessageId);
            }
        }
    }

    get showBody(): boolean {
        return this._isExpanded;
    }

    onTaxYearChange(value: any) : void {
        this._selectedTaxYear = value;
    }

    get lienDate(): string {
        const localLienDateInfo = {... this._lienDateInfo};
        if (this.assessorId){
            localLienDateInfo.ppLienDateDisplayMonthDay = this._lienDateInfo.ppLienDateDisplayMonthDay || this.stateLienDateInfo.ppLienDateDisplayMonthDay;
        }

        return this._complianceLienDateConfigurationService.getLienDate(this._selectedTaxYear, localLienDateInfo);
    }

    get assetCutOffDate(): string {
        const localLienDateInfo = {... this._lienDateInfo};
        if (this.assessorId){
            localLienDateInfo.ppLienDateMonthDay = this._lienDateInfo.ppLienDateMonthDay || this.stateLienDateInfo.ppLienDateMonthDay;
            localLienDateInfo.ppLienDateYearOffset = this._lienDateInfo.ppLienDateYearOffset || this.stateLienDateInfo.ppLienDateYearOffset;
        }

        return this._complianceLienDateConfigurationService.getAssetCutOffDate(this._selectedTaxYear, localLienDateInfo);
    }

    get age0Range(): string {
        const localLienDateInfo = {... this._lienDateInfo};
        if (this.assessorId){
            localLienDateInfo.ppLienDateAgeBeginMonthDay = this._lienDateInfo.ppLienDateAgeBeginMonthDay || this.stateLienDateInfo.ppLienDateAgeBeginMonthDay;
            localLienDateInfo.ppLienDateAgeBeginYearOffset = this._lienDateInfo.ppLienDateAgeBeginYearOffset || this.stateLienDateInfo.ppLienDateAgeBeginYearOffset;
        }

        return this._complianceLienDateConfigurationService.getAge0Range(this._selectedTaxYear, localLienDateInfo);
    }

    get stateLienDateHasException(): boolean {
        return !this.assessorId && this.stateLienDateInfo.ppLienDateDisplayExceptions && this.stateLienDateInfo.ppLienDateDisplayExceptions.length !== 0;
    }

    get stateAssetCutOffDateHasException(): boolean {
        return !this.assessorId && this.stateLienDateInfo.ppLienDateExceptions && this.stateLienDateInfo.ppLienDateExceptions.length !== 0;
    }

    get stateAge0RangeHasException(): boolean {
        return !this.assessorId && this.stateLienDateInfo.ppLienDateAgeBeginExceptions && this.stateLienDateInfo.ppLienDateAgeBeginExceptions.length !== 0;
    }

    get assessorLienDateHasException(): boolean {
        return !!this.assessorId && !!this._lienDateInfo.ppLienDateDisplayMonthDay;
    }

    get assessorAssetCutOffDateHasException(): boolean {
        return !!this.assessorId && (!!this._lienDateInfo.ppLienDateMonthDay || !!this._lienDateInfo.ppLienDateYearOffset);
    }

    get assessorAge0RangeHasException(): boolean {
        return !!this.assessorId && (!!this._lienDateInfo.ppLienDateAgeBeginMonthDay || !!this._lienDateInfo.ppLienDateAgeBeginYearOffset);
    }

    async showExceptions(dateName: string, exceptions: Compliance.ComplianceLienDateInfoAssessorExceptionModel[],
                         dateInfo: (taxYear: number, configuration: Compliance.ComplianceLienDateInfoModel) => string): Promise<void>{
        const params: ComplianceLienDateConfigurationExceptionModalParams = {
            stateId: this.stateId,
            taxYear: this._selectedTaxYear,
            dateName: dateName,
            exceptions: exceptions,
            dateInfo: dateInfo
        };

        await this._modalService.showAsync(ComplianceLienDateConfigurationExceptionComponent, params, 'modal-md');
    }

    exceptionGetLienDate = (taxYear: number, configuration: Compliance.ComplianceLienDateInfoModel) => {
        return this._complianceLienDateConfigurationService.getLienDate(taxYear, configuration);
    }

    exceptionGetAssetCutOffDate = (taxYear: number, configuration: Compliance.ComplianceLienDateInfoModel) => {
        return this._complianceLienDateConfigurationService.getAssetCutOffDate(taxYear, configuration);
    }

    exceptionGetAge0Range = (taxYear: number, configuration: Compliance.ComplianceLienDateInfoModel) => {
        return this._complianceLienDateConfigurationService.getAge0Range(taxYear, configuration);
    }

    navigateToState(): void {
        this._routerService.go('state', {stateId: this.stateId});
    }
}
