import { Component, Input } from '@angular/core';

@Component({
    selector: 'loading-spinner',
    template: `
        <div *ngIf="show" class="text-center" [class.spinnerbg]="hasOverlay" [ngStyle]="{ 'margin-top': !hasOverlay && top ,'margin-bottom':  !hasOverlay && bottom }">
            <i class="fa fa-spinner fa-spin-pulse" [ngClass]="faSize"></i>
        </div>
    `,
    styles: [`.spinnerbg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0,0,0,0.5);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999999999;
        margin: 0;
    }`]
})
export class LoadingSpinnerComponent {
    @Input() show: boolean = true;
    @Input() hasOverlay: boolean = false; // Parent container must be display relative

    @Input()
    set marginTop(marginTop: number) {
        this.top = `${marginTop}px`;
    };

    @Input()
    set marginBottom(marginBottom: number) {
        this.bottom = `${marginBottom}px`;
    };

    @Input()
    set size(size: number) {
        this.faSize = `fa-${size}x`;
    };

    top: string = '50px';
    bottom: string = '1px';
    faSize: string;
}
