import { Component, OnInit, TemplateRef } from '@angular/core';
import { RestrictService, InstanceRights } from '../Common/Permissions/restrict.service';
import { Team } from './team.model';
import * as _ from 'lodash';
import { MessageBoxButtons, MessageBoxResult, MessageBoxService } from '../UI-Lib/Message-Box/messagebox.service.upgrade';
import { ToastrService } from 'ngx-toastr';
import { NavigationService } from '../Layout/Navigation.Service.upgrade';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserTeamMember } from '../Common/Models/common.model';
import { TeamService } from './team.service';

export class TeamUI extends Team {
    isEditing: boolean;
    isDeleting: boolean;
}

@Component({
    selector: 'teams-page',
    templateUrl: './teams.page.component.html',
    styleUrls: ['./teams.page.component.scss']
})
export class TeamsPageComponent implements OnInit {
    constructor(private teamsService: TeamService,
        private restrictService: RestrictService,
        private messageBoxService: MessageBoxService,
        private toastr: ToastrService,
        private navigationService: NavigationService,
        private modalService: BsModalService) { }

    teams: TeamUI[];
    dataLoading: boolean = false;
    hasTeamEditPermissions: boolean;
    editMode: boolean;
    originalTeam: TeamUI;
    showUniqueTeamNameError: boolean;
    readonly warningMessage: string = 'Editing is in progress. Are you sure you want to leave?';
    teamMembers: UserTeamMember[];
    unableToDeleteRef: BsModalRef;
    teamMembersLoading: boolean = false;


    async ngOnInit() {
        this.dataLoading = true;
        const teams = await this.teamsService.getAllTeams(false) as TeamUI[];
        this.teams = _.sortBy(teams, 'name');
        this.dataLoading = false;

        this.hasTeamEditPermissions = this.restrictService.hasInstanceRight(InstanceRights.TEAMSETUPSEDIT);
    }

    addTeam() {
        this.editMode = true;
        this.showUniqueTeamNameError = false;

        this.navigationService.enableNavWarning(this.warningMessage);
        const newTeam = new TeamUI();
        newTeam.isEditing = true;
        this.teams.push(newTeam);
    }

    enableEdit(team: TeamUI) {
        this.originalTeam = _.cloneDeep(team);
        this.editMode = true;
        team.isEditing = true;
        this.showUniqueTeamNameError = false;

        this.navigationService.enableNavWarning(this.warningMessage);
    }

    cancelEdit(team: TeamUI) {
        if (!_.isUndefined(team.teamID)) {
            _.assign(team, this.originalTeam);
            this.showUniqueTeamNameError = false;
            team.isEditing = false;
        } else {
            _.remove(this.teams, team);
        }

        this.editMode = false;
        this.navigationService.disableNavWarning();
    }

    async saveTeam(teamToSave: TeamUI) {
        let savedTeam: TeamUI;

        //Check for team name uniqueness
        if (_.some(this.teams, team => {
            return team.name === teamToSave.name && team.teamID !== teamToSave.teamID;
        })) {
            this.showUniqueTeamNameError = true;
            return;
        }

        if (!_.isUndefined(teamToSave.teamID)) {
            savedTeam = await this.teamsService.editTeam(teamToSave) as TeamUI;
        } else {
            savedTeam = await this.teamsService.createTeam(teamToSave) as TeamUI;
        }

        _.assign(teamToSave, savedTeam);
        teamToSave.isEditing = false;
        this.editMode = false;
        this.showUniqueTeamNameError = false;
        this.navigationService.disableNavWarning();
    }

    async deleteTeam(team: TeamUI, unableToDeleteTemplate: TemplateRef<any>) {
        if (team.teamMembers.length > 0) {
            this.teamMembers = [];
            this.unableToDeleteRef = this.modalService.show(unableToDeleteTemplate);
            this.teamMembersLoading = true;
            this.teamMembers = await this.teamsService.getMembersForTeam(team.teamID);
            this.teamMembersLoading = false;

        } else {
            const result = await this.messageBoxService.open({
                message: `Are you sure you want to delete ${team.name}?`,
                buttons: MessageBoxButtons.YesNo
            });

            if (result == MessageBoxResult.Yes) {
                team.isDeleting = true;
                try {
                    await this.teamsService.deleteTeam(team);

                    _.remove(this.teams, _.omit(team, 'isDeleting'));
                    this.toastr.info('Team Deleted');
                } catch {
                    team.isDeleting = false;
                }
            }
        }
    }
}
