import { Component, OnInit, Input } from '@angular/core';
import { RestrictService, Roles } from '../../../Common/Permissions/restrict.service';
import { AssessorCollectorService } from '../../assessor.collector.service';
import { Assessor } from '../../assessor.model';
import * as _ from 'lodash';
import { StateSummary } from 'src/app/Common/States/state.model';
import { StateService } from 'src/app/Common/States/States.Service';
import { NavigationService } from 'src/app/Layout/Navigation.Service.upgrade';
import { reject, sortBy } from 'lodash/fp';

export interface CollectorAssociation {
    assessor?: Assessor;
    assessorID: number;
    collector: Weissman.Model.Collectors.Collector;
    collectorID: number;
    efAction: string;
    hasCompanyCollectors?: boolean;
    isDefault: boolean;
    stateAbbr: string;
}

@Component({
    selector: 'assessor-available-collectors',
    templateUrl: './assessor.available.collectors.component.html'
})
export class AssessorAvailableCollectorsComponent implements OnInit {
    constructor(
        private readonly _restrictService: RestrictService,
        private readonly _assessorCollectorService: AssessorCollectorService,
        private readonly _stateService: StateService,
        private readonly _navigationService: NavigationService
    ) { }

    @Input() assessor: Assessor;
    @Input() state: Weissman.Model.Misc.State;

    collectorAssociations: CollectorAssociation[] = [];
    serverAction: boolean = false;
    loadingAvailableCollectors: boolean = false;
    filteredStateCollectors: Weissman.Model.Collectors.Collector[];
    newCollector: Weissman.Model.Collectors.Collector;
    editMode: boolean = false;
    hasWritePermission: boolean = false;

    private _states: StateSummary[];
    private _originalCollectorAssociations: CollectorAssociation[];
    private _stateCollectors: Weissman.Model.Collectors.Collector[];


    async ngOnInit(): Promise<void> {
        this.hasWritePermission = this._restrictService.isInRole(Roles.ASSESSOREDIT);
        let collectorAssociations: CollectorAssociation[];

        this.serverAction = true;

        try {
            [this._states, collectorAssociations] = await Promise.all([
                this._stateService.getSummary(),
                this._assessorCollectorService.getCollectorAssociations(this.assessor.assessorID)
            ]);


            this.collectorAssociations = _.map(collectorAssociations, x => {
                const collectorState = _.find(this._states, { stateID: x.collector.stateID });
                x.stateAbbr =  collectorState ? collectorState.abbr : '';

                return x;
            });
        } finally {
            this.serverAction = false;
        }
    }

    async save(): Promise<void> {
        this.serverAction = true;

        try {
            await this._assessorCollectorService.saveAssociations(this.assessor.assessorID, this.collectorAssociations);

            this.editMode = false;
            this._navigationService.disableNavWarning();
        } finally {
            this.serverAction = false;
        }
    }

    async edit(): Promise<void> {
        this._originalCollectorAssociations = _.cloneDeep(this.collectorAssociations);
        this.editMode = true;
        this._navigationService.enableNavWarning('Editing is in progress.  Are you sure you wish to leave?');

        this.loadAvailableCollectors(this.state.stateID);
    }

    cancel(): void {
        this.collectorAssociations = this._originalCollectorAssociations;
        this.editMode = false;
        this._navigationService.disableNavWarning();
    }

    removeAssociation(collectorassociation: CollectorAssociation) {
        this.filteredStateCollectors.push(collectorassociation.collector);
        this.filteredStateCollectors = _.sortBy(this.filteredStateCollectors, 'abbr');

        _.remove(this.collectorAssociations, collectorassociation);
    }

    addAssociation(): void {
        const stateAbbr = _.find(this._states, { stateID: this.newCollector.stateID })?.abbr;

        const collectorAssociationToAdd: CollectorAssociation = {
            assessorID: this.assessor.assessorID,
            collectorID: this.newCollector.collectorID,
            isDefault: false,
            collector: this.newCollector,
            efAction: null,
            stateAbbr
        };

        this.collectorAssociations.push(collectorAssociationToAdd);

        _.remove(this.filteredStateCollectors, this.newCollector);

        setTimeout(() => {
            this.newCollector = undefined;
        });
    }

    async loadAvailableCollectors(stateId: number): Promise<void> {
        this.loadingAvailableCollectors = true;

        try {
            this._stateCollectors = await this._assessorCollectorService.getAssessorCollectorList(stateId, this.assessor.assessorID);
            this.filteredStateCollectors = _.flow([
                reject(x => _.some(this.collectorAssociations, { collectorID: x.collectorID })),
                sortBy('abbr')
            ])(this._stateCollectors);

        } finally {
            this.loadingAvailableCollectors = false;
        }
    }
}
