import {Component, OnInit, OnDestroy, Input, ViewEncapsulation} from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { GridApi, GridReadyEvent, ColDef, ICellRendererParams, GridOptions } from 'ag-grid-community';
import { EntityImportProcessService } from '../../importProcess.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { AgGridOptionsBuilder, AgGridColumns } from '../../../../AgGrid';

@Component({
    selector: 'entity-import-validation-summary',
    templateUrl: './validationSummary.component.html',
    styleUrls: ['./validationSummary.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EntityImportValidationSummaryComponent implements OnInit, OnDestroy {
    constructor(
        private _entityImportProcessService: EntityImportProcessService,
        private _decimalPipe: DecimalPipe) {
    }

    @Input() importFileModel: Compliance.ImportFileModel;

    private _validationSummarySub: Subscription;
    private _gridApi: GridApi;
    private _validationErrors: Compliance.ImportFileValidationErrorModel[];
    private _selectedIds: string[] = [];
    private _transferCompletedSub: Subscription;
    private _showValidRowsOnlyChangedSub: Subscription;
    private _clearErrorsWarningsSelectionSub: Subscription;
    private _notValidatedUpdatesSub: Subscription;
    notValidatedUpdatesCount: number = 0;

    gridOptions: GridOptions = new AgGridOptionsBuilder(
        {
            getRowNodeId: this._getRowId
        })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withMultipleRowSelect()
        .withTextSelection()
        .build();

    ngOnInit() {
        this._validationSummarySub = this._entityImportProcessService.validationSummaryChanged$.subscribe(
            (validationErrors: Compliance.ImportFileValidationErrorModel[]) => {
                this._loadValidationErrors(validationErrors);
            });

        this._transferCompletedSub = this._entityImportProcessService.transferCompletedEventEmitter.subscribe(() => {
            this._entityImportProcessService.getValidationResults(this.importFileModel.importFileId).then(validationErrors => this._loadValidationErrors(validationErrors));
        });

        this._showValidRowsOnlyChangedSub =
            this._entityImportProcessService.showValidRowsOnlyChanged$.subscribe(
                (showValidRowsOnly) => this._showValidRowsOnlyChanged(showValidRowsOnly));

        this._clearErrorsWarningsSelectionSub = this._entityImportProcessService.clearErrorsWarningsSelectionChanged$.subscribe(() => this._clearSelection());

        this._notValidatedUpdatesSub = this._entityImportProcessService.notValidatedUpdates$.subscribe((x) => {
            this.notValidatedUpdatesCount = x;
        });
    }

    ngOnDestroy(): void {
        this._validationSummarySub.unsubscribe();
        this._transferCompletedSub.unsubscribe();
        this._showValidRowsOnlyChangedSub.unsubscribe();
        this._clearErrorsWarningsSelectionSub.unsubscribe();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerCheckboxSelection: true,
                checkboxSelection: true,
                suppressSizeToFit: true,
                resizable: false,
                width: AgGridColumns.selectionColumnWidth
            },
            {
                headerName: 'Type',
                field: 'isWarning',
                cellRenderer: (params: ICellRendererParams): string => {
                    if (params.data.isWarning) {
                        return '<span title="Warning">Warning</span>';
                    } else {
                        return '<span title="Error">Error</span>';
                    }
                },
                cellClass: (params: ICellRendererParams): string => {
                    const validationError = params.data as Compliance.ImportFileValidationErrorModel;
                    if (!validationError) {
                        return '';
                    }

                    return validationError.isWarning ? 'cell-validation-warning' : 'cell-validation-error';
                },
                suppressSizeToFit: true,
                resizable: false,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Column',
                field: 'fieldName',
                width: AgGridColumns.textColumnWidth,
                suppressSizeToFit: true,
                valueGetter: (params: ICellRendererParams): string => {
                    const validationError = params.data as Compliance.ImportFileValidationErrorModel;
                    if (!validationError) {
                        return '';
                    }

                    let result = validationError.fieldName;

                    if (!result) {
                        result = validationError.errorSource === 'Transfer'
                            ? 'N/A - Transfer Error'
                            : 'N/A - Column name is not available';
                    }

                    return result;
                }
            },
            {
                headerName: 'Rows Affected',
                type: 'numericColumn',
                valueFormatter: (params) => {
                    return this._decimalPipe.transform(params.value, '1.0-0');
                },
                field: 'count',
                width: AgGridColumns.numericColumnWidth,
                suppressSizeToFit: true
            },
            {
                headerName: 'Issue',
                field: 'issue'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setRowData(this._validationErrors);
        this._gridApi.sizeColumnsToFit();
    }

    onAgGridSelectionChanged(): void {
        const selectedData = this._gridApi.getSelectedNodes().map(node => node.data as Compliance.ImportFileValidationErrorModel);
        const ids = selectedData.map(data => this._getRowId(data)).sort();

        // if the same error is in the list but only the count is changing, return
        if (_.isEqual(ids, this._selectedIds)) {
            return;
        }

        this._selectedIds = ids;
        this._entityImportProcessService.notifyErrorFilterChanged(selectedData || []);
    }

    rowsSelected(): boolean {
        return this._gridApi && this._gridApi.getSelectedNodes().length === 0;
    }

    errorCount(): number {
        return _.filter(this._validationErrors,
            (x) => {
                return !x.isWarning;
            }).length;
    }

    warningCount(): number {
        return _.filter(this._validationErrors,
            (x) => {
                return x.isWarning;
            }).length;
    }

    private _getRowId(data: Compliance.ImportFileValidationErrorModel): string {
        return data.fieldName + data.issue + data.isWarning;
    }

    private _loadValidationErrors(validationErrors: Compliance.ImportFileValidationErrorModel[]): void{
        this._validationErrors = _.orderBy(validationErrors, ['isWarning'], ['asc']);

        if (!this._gridApi) {
            return;
        }

        // get the ids of the selected rows
        const selectedIds = this._gridApi.getSelectedNodes().map(node => {
            const data = node.data as Compliance.ImportFileValidationErrorModel;
            return this._getRowId(data);
        });

        this._gridApi.setRowData(this._validationErrors);

        // re-select rows
        _.each(selectedIds, (id) => {
            const node = this._gridApi.getRowNode(id);
            if (node) {
                node.setSelected(true);
            }
        });

        const selectedData = this._gridApi.getSelectedNodes().map(node => node.data as Compliance.ImportFileValidationErrorModel);
        this._entityImportProcessService.notifyErrorFilterChanged(selectedData || []);
    }

    private _showValidRowsOnlyChanged(showValidRowsOnly: boolean) {
        if (showValidRowsOnly){
            _.each(this._gridApi.getSelectedNodes(), node => {
                const data = node.data as Compliance.ImportFileValidationErrorModel;
                if (!data.isWarning){
                    node.setSelected(false);
                }
            })

            this.onAgGridSelectionChanged();
        }
    }

    private _clearSelection() {
        this._gridApi.deselectAll();
    }
}
