import { NgModule } from '@angular/core';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { AppealRecommendationTemplateComponent } from './Appeal-Recommendation-Template/appealRecommendationTemplate.component';
import { AppealRecommendationTemplateModalService } from './Appeal-Recommendation-Template/appealRecommendationTemplateModal.service';
import { BusyIndicatorModule } from '../Busy-Indicator';
import { QuillEditorComponent } from 'ngx-quill';
import { AppealRecommendationRepository } from './appealRecommendation.repository';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {
    AppealRecommendationCommandCenterComponent
} from './Appeal-Recommendation-Command-Center/appealRecommendationCommandCenter.component';
import { CompanyModule } from '../Entity/Company/company.module';
import {
    AppealRecommendationCommandCenterGridActionCellRendererComponent
} from './Appeal-Recommendation-Command-Center/agGridActionCellRenderer.component';
import {
    AppealRecommendationCommandCenterAssignedComponent
} from './Appeal-Recommendation-Command-Center/Appeal-Recommendation-Command-Center-Assigned/appealRecommendationCommandCenterAssigned.component';
import {
    AppealRecommendationCommandCenterAssignedDetailsModalComponent
} from './Appeal-Recommendation-Command-Center/Appeal-Recommendation-Command-Center-Assigned-Details-Modal/appealRecommendationCommandCenterAssignedDetailsModal.component';
import { TeamModule } from '../Team/team.module';
import {
    AppealRecommendationTemplateLastSentHelpComponent
} from './Appeal-Recommendation-Template/appealRecommendationTemplateLastSentHelp.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    AppealRecommendationTemplateDetailsComponent
} from './Appeal-Recommendation-Template/Appeal-Recommendation-Template-Details/appealRecommendationTemplateDetails.component';

@NgModule({
    imports: [
        WeissmanSharedModule,
        BusyIndicatorModule,
        QuillEditorComponent,
        BsDropdownModule.forRoot(),
        CompanyModule,
        TeamModule,
        ReactiveFormsModule,
        FormsModule
    ],
    declarations: [
        AppealRecommendationTemplateComponent,
        AppealRecommendationCommandCenterComponent,
        AppealRecommendationCommandCenterGridActionCellRendererComponent,
        AppealRecommendationCommandCenterAssignedComponent,
        AppealRecommendationCommandCenterAssignedDetailsModalComponent,
        AppealRecommendationTemplateLastSentHelpComponent,
        AppealRecommendationTemplateDetailsComponent
    ],
    providers: [
        AppealRecommendationTemplateModalService,
        AppealRecommendationRepository
    ],
    exports: [
        AppealRecommendationTemplateComponent,
        AppealRecommendationCommandCenterComponent,
        AppealRecommendationCommandCenterGridActionCellRendererComponent,
        AppealRecommendationCommandCenterAssignedComponent,
        AppealRecommendationCommandCenterAssignedDetailsModalComponent,
        AppealRecommendationTemplateLastSentHelpComponent
    ]

})
export class AppealRecommendationModule {
    static setupModule() {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Provider('appealRecommendationModalService', AppealRecommendationTemplateModalService);
        hybridAdapterUtility.downgradeNg2Component('appealRecommendationCommandCenter', AppealRecommendationCommandCenterComponent);
    }
}
