import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../WeissmanModalService';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import * as _ from 'lodash';

export interface ComplianceLienDateConfigurationExceptionModalParams {
    stateId: number,
    taxYear: number,
    dateName: string,
    exceptions: Compliance.ComplianceLienDateInfoAssessorExceptionModel[],
    dateInfo: (taxYear: number, configuration: Compliance.ComplianceLienDateInfoModel) => string;
}

@Component({
    selector: 'compliance-lien-gate-configuration-exception',
    templateUrl: './complianceLienDateConfigurationException.component.html'
})
export class ComplianceLienDateConfigurationExceptionComponent implements OnInit, IWeissmanModalComponent<ComplianceLienDateConfigurationExceptionModalParams, void> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _routerService: UpgradeNavigationServiceHandler) {
    }

    params: ComplianceLienDateConfigurationExceptionModalParams;
    result: void;

    private _orderedExceptions: Compliance.ComplianceLienDateInfoAssessorExceptionModel[] = null;

    async ngOnInit(): Promise<void> {
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    get exceptions(): Compliance.ComplianceLienDateInfoAssessorExceptionModel[]{
        if (!this._orderedExceptions){
            this._orderedExceptions = _.orderBy(this.params.exceptions, i => i.assessorAbbr + i.municipalityName);
        }

        return this._orderedExceptions;
    }

    showAssessor(assessorId: number): void{
        this._routerService.go('assessor', {stateId: this.params.stateId, id: assessorId, year: this.params.taxYear});
        this._bsModalRef.hide();
    }
}
