import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ProductAnalyticsService } from '../Common/Amplitude/productAnalytics.service';
import { DashboardDataService } from "./dashboard.data.service";
import { Dashboard, Widget, UserWidget } from "./dashboard.model";
import { GridsterConfig, GridsterItem } from "angular-gridster2";
import { ToastrService } from 'ngx-toastr';
import { UpgradeNavigationServiceHandler } from '../Common/Routing/upgrade-navigation-handler.service';
import { MessageBoxService, MessageBoxButtons, MessageBoxResult } from '../UI-Lib/Message-Box/messagebox.service.upgrade';
import { Subscription } from "rxjs";
import { FeatureFlagsService } from "../Common/FeatureFlags/feature-flags-service";
import * as _ from "lodash";

@Component({
    selector: 'dashboard',
    template: `
        <div *ngIf="dashboard" style="height: 100%; display: flex; flex-flow: column;">
            <div *ngIf="dashboard.firstName" style="flex: 0 1 auto; padding: 10px; display: flex; justify-content: space-between;">
                <div>
                    <h1 style="display: inline-block; margin: 0">Welcome {{dashboard.firstName}}!</h1>
                    <div [hidden]="!designMode" class="btn-group" dropdown style="margin-left: 30px; vertical-align: top;" #addWidgetDropdown="bs-dropdown">
                        <button dropdownToggle type="button"  [disabled]="loading" class="flat-button primary-button dropdown-toggle">
                            Add Widget <span class="caret"></span>
                        </button>
                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                            <li *ngFor="let widget of dashboard.availableWidgets" role="menuitem">
                                <a class="dropdown-item" (click)="addWidget(widget)" style="cursor: pointer;">{{widget.title}}</a>
                            </li>
                        </ul>
                        <button type="button" class="flat-button primary-button" (click)="resetToDefault()" [disabled]="loading">
                            Reset To Default
                        </button>
                    </div>
                </div>

                <div>
                    <label style="margin-right: 50px;">
                        Show dashboard on login
                        <ws-switch style="margin-left: 10px;" [(ngModel)]="isShowDashboard" (selectedOptionChanged)="toggleShowDashboard()"></ws-switch>
                    </label>
                    <label>
                        Design Mode
                        <ws-switch style="margin-left: 10px;" [(ngModel)]="designMode" (selectedOptionChanged)="toggleDesignMode()"></ws-switch>
                    </label>
                </div>
            </div>
            <i class="fa fa-spinner fa-pulse fa-5x" *ngIf="loading" style="position: absolute; top: 35%; left: 43%; z-index: 2;"></i>
            <gridster [options]="options" style="background-color: #ddd;" [style.opacity]="loading ? 0.5 : 1">
                <gridster-item [item]="userWidget" *ngFor="let userWidget of dashboard.userWidgets">
                    <user-widget [userWidget]="userWidget" (onWidgetRemove)="removeWidget($event)" [designMode]="designMode"></user-widget>
                </gridster-item>
            </gridster>
        </div>
    `
})
export class DashboardComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _dashboardDataService: DashboardDataService,
        private readonly _toastr: ToastrService,
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _featureFlagsService: FeatureFlagsService,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) {}

    dashboard: Dashboard = new Dashboard();
    options: GridsterConfig;
    designMode: boolean = false;
    loading: boolean = false;
    isShowDashboard: boolean = false;
    IAMactive: boolean = false;
    resizeGridSub: Subscription;
    @ViewChild('addWidgetDropdown') addWidgetDropdown;

    async ngOnInit(): Promise<void> {
        this.options = {
            draggable: {},
            resizable: {},

            margin: 5,
            displayGrid: this.designMode ? 'always' : 'none',
            enableEmptyCellClick: this.designMode,

            maxCols: 4,
            minCols: 4,
            minRows: 3,
            maxRows: 3,
            // pushItems: true,
            scrollToNewItems: true,
            emptyCellClickCallback: ()=> {
                // this.addWidgetDropdown.show()
            },
            itemResizeCallback: (item: UserWidget) => {
                this._dashboardDataService.userWidgetResize$.next(item.userWidgetId);
            },
            itemChangeCallback: async (userWidget: UserWidget)=> {
                this.loading = true;

                try {
                    await this._dashboardDataService.updateUserWidget(userWidget);
                } finally {
                    this.loading = false;
                }
            }
        };

        this.loading = true;

        try {
            this.setDashboard(await this._dashboardDataService.getDashboard());
        } finally {
            this.loading = false;
        }

        this.isShowDashboard = this._dashboardDataService.getShowDashBoardUserSetting();
        this.resizeGridSub = this._dashboardDataService.resizeGrid$.subscribe(() => this.options.api.resize())
    }

    ngOnDestroy(): void {
        this.resizeGridSub.unsubscribe();
    }

    private setDashboard(dashboard: Dashboard): void {
        dashboard.userWidgets = _.map(dashboard.userWidgets, (userWidget: UserWidget) => {
            userWidget.rows = userWidget.rows || 1;
            userWidget.cols = userWidget.cols || 1;

            userWidget.minItemCols = userWidget.widget.width;
            userWidget.minItemRows = userWidget.widget.height;

            return userWidget;
        });

        this.dashboard = dashboard;
    }

    async addWidget(widget: Widget): Promise<void> {
        let possibleGridItem: GridsterItem = { rows: widget.height, cols: widget.width, x: 0, y: 0 };

        if(!this.options.api.getNextPossiblePosition(possibleGridItem)) {
            this._toastr.error('Not enough room to fit widget!');

            return;
        }

        const firstPossiblePosition: GridsterItem = this.options.api.getFirstPossiblePosition(possibleGridItem);

        this.loading = true;

        try {
            const dashboard: Dashboard = await this._dashboardDataService.addToDashboard(widget.widgetId, firstPossiblePosition);

            this.dashboard.availableWidgets = dashboard.availableWidgets;
            const newUserWidgetIdArr: number[] = _.chain(dashboard.userWidgets)
                .map('userWidgetId')
                .difference(_.map(this.dashboard.userWidgets, 'userWidgetId'))
                .value();

            let newUserWidget: UserWidget = _.find(dashboard.userWidgets, {userWidgetId: newUserWidgetIdArr[0]});
            newUserWidget.minItemCols = newUserWidget.widget.width;
            newUserWidget.minItemRows = newUserWidget.widget.height;

            this.dashboard.userWidgets.push(newUserWidget);
        } finally {
            this.loading = false;
        }

    }

    async removeWidget(userWidgetId: number): Promise<void> {
        this.loading = true;

        try {
            const dashboard: Dashboard = await this._dashboardDataService.removeFromDashboard(userWidgetId);

            this.dashboard.availableWidgets = dashboard.availableWidgets;

            let removedIdx: number = _.findIndex(this.dashboard.userWidgets, {userWidgetId: userWidgetId})
            this.dashboard.userWidgets.splice(removedIdx, 1);
        } finally {
            this.loading = false;
        }

    }

    toggleDesignMode(): void {
        if(!this.designMode && !this.dashboard.userWidgets.length) {
            this._upgradeNavigationServiceHandler.transitionTo(this._upgradeNavigationServiceHandler.getCurrentState(),
                {}, {
                    reload: true
                })
        }
        this.options.draggable.enabled = this.designMode;
        this.options.resizable.enabled = this.designMode;
        this.options.displayGrid = this.designMode ? 'always' : 'none';
        this.options.enableEmptyCellClick = this.designMode;

        this._productAnalyticsService.logEvent('click-design-mode', {
            'designMode': this.designMode ? 'on' : 'off'
        });

        this.options.api.optionsChanged();
    }

    async resetToDefault(): Promise<void> {
        if ((await this._messageBoxService.open({
            buttons: MessageBoxButtons.YesNo,
            message: 'This will delete all of your dashboard settings; are you sure?',
            title: 'Confirm resetting dashboard to defaults'
        })) === MessageBoxResult.Yes) {
            this.loading = true;

            try {
                this.setDashboard(await this._dashboardDataService.resetDashboard());
                this.designMode = false;
            } finally {
                this.loading = false;
            }
        }
    }

    async toggleShowDashboard(): Promise<void> {
        await this._dashboardDataService.updateShowDashboardUserSetting(this.isShowDashboard);
    }
}
