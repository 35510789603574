import { distinctUntilChanged } from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable,  BehaviorSubject,  Subject,  Subscription, lastValueFrom } from 'rxjs';
import { EntityImportRepository } from '../../Repositories';
import * as _ from 'lodash';
import { WebsocketListenerService } from '../../websocketListener.service';
import { WeissmanModalService } from '../../WeissmanModalService';
import { TransferConfirmComponent, TransferConfirmParams } from './Transfer-Confirm/transferConfirm.component';
import { AssetRepository } from '../../Repositories';
import { EntityImportService } from '../entityImport.service';

export interface IEntityImportValidationSummaryChangedCallback {
    (validationErrors: Compliance.ImportFileValidationErrorModel[]): void;
}

export class ISelectedRowsEvent {
    selectedRowsModel: Compliance.ImportBulkOperationModel;
    selectedRowsCount: number;
}

@Injectable()
export class EntityImportProcessService {
    constructor(
        private readonly _entityImportRepository: EntityImportRepository,
        private readonly _websocketListenerService: WebsocketListenerService,
        private readonly _modalService: WeissmanModalService,
        private readonly _assetRepository: AssetRepository,
        private readonly _entityImportService: EntityImportService) {
    }

    private _validationSummaryBehaviorSubject: BehaviorSubject<Compliance.ImportFileValidationErrorModel[]> = new BehaviorSubject<Compliance.ImportFileValidationErrorModel[]>([]);
    private _validatedEventEmitter: EventEmitter<Promise<void>> = new EventEmitter<Promise<void>>();
    private _evaluatingEventEmitter: EventEmitter<Promise<Compliance.ImportEvaluateResultModel>> = new EventEmitter<Promise<Compliance.ImportEvaluateResultModel>>();
    private _transferredEventEmitter: EventEmitter<void> = new EventEmitter<void>();
    private _cellUpdatedEventEmitter: EventEmitter<number> = new EventEmitter<number>();
    private _selectedRowsEventEmitter: EventEmitter<ISelectedRowsEvent> = new EventEmitter<ISelectedRowsEvent>();
    private _errorFilterChangedBehaviorSubject: BehaviorSubject<Compliance.ImportFileValidationErrorModel[]> = new BehaviorSubject<Compliance.ImportFileValidationErrorModel[]>([]);
    private _importStatusSubject: Subject<Compliance.ImportFileModel> = new Subject();
    private _importProgressStatusSubject: Subject<Compliance.LongRunningProcessProgressChangeModel> = new Subject();
    private _importFile: Compliance.ImportFileModel;
    private _importProcessStatusError: string;
    private _importStatusChangeSub: Subscription;
    private _importProgressChangeSub: Subscription;
    private _lastEvaluationResult: Compliance.ImportEvaluateResultModel;
    private _showValidRowsOnlyEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
    private _longRunningProcessId: number;
    private _assetEffectiveDate?: Date = null;
    private _assetOwnershipTypeId?: number = null;
    private _assetOwnershipTypeName: string = null;
    private _selectedRowsInfo: Compliance.ImportSelectedRowsInfoModel = null;
    private _forceValidateSubject: Subject<void> = new Subject<void>();
    private _sitesUpdatesOnlyMatchBy: string;
    private _accrualAdjustmentAmountValueType: string = null;
    private _taxPaymentsMatchOverall: string = null;
    private _isTaxPaymentsImportMatchByPaymentSystemId: boolean = false;
    private _taxPaymentsMatchParcel: string = null;
    private _taxPaymentsMatchCollector: string = null;
    private _incomeStatementsEndingDate: Date = null;
    private _rentRollEndingDate: Date = null;
    private _glAccountsIntendedUse: string = null;
    private _clearErrorsWarningsSelectionEventEmitter: EventEmitter<void> = new EventEmitter<void>();

    transferCompletedEventEmitter: EventEmitter<Promise<void>> = new EventEmitter<Promise<void>>();
    get longRunningProcessId(): number { return this._longRunningProcessId; }
    set longRunningProcessId(id: number) { this._longRunningProcessId = id; }
    get importProcessStatusError(): string {
        return this._importProcessStatusError;
    }

    get importStatus$(): Observable<Compliance.ImportFileModel> { return this._importStatusSubject.asObservable(); }
    get importProgress$(): Observable<Compliance.LongRunningProcessProgressChangeModel> { return this._importProgressStatusSubject.asObservable(); }

    async initialize(importFile: Compliance.ImportFileModel):Promise<void> {
        this._importFile = importFile;

        this._assetEffectiveDate = null;
        this._assetOwnershipTypeId = null;
        this._assetOwnershipTypeName = null;
        this._accrualAdjustmentAmountValueType = null;
        this._taxPaymentsMatchOverall = null;
        this._isTaxPaymentsImportMatchByPaymentSystemId = false;
        this._taxPaymentsMatchParcel = null;
        this._taxPaymentsMatchCollector = null;
        let assetEffectiveDate: string = null;

        switch (this._importFile.importContentTypeId) {
            case Compliance.ImportContentTypeIdEnum.Assets:
                assetEffectiveDate = this._importFile.fileFields.find(i => +i.importFieldId === Compliance.ImportFieldIdEnum.AssetEffectiveDate).value;
                this._assetOwnershipTypeId = +this._importFile.fileFields.find(i => +i.importFieldId === Compliance.ImportFieldIdEnum.AssetAssetOwnershipType).value;
                break;
            case Compliance.ImportContentTypeIdEnum.AssetUpdatesOnly:
                assetEffectiveDate = this._importFile.fileFields.find(i => +i.importFieldId === Compliance.ImportFieldIdEnum.AssetUpdatesOnlyEffectiveDate).value;
                this._assetOwnershipTypeId = +this._importFile.fileFields.find(i => +i.importFieldId === Compliance.ImportFieldIdEnum.AssetUpdatesOnlyAssetOwnershipType).value;
                break;
            case Compliance.ImportContentTypeIdEnum.SitesUpdatesOnly:
                this._sitesUpdatesOnlyMatchBy = this._importFile.fileFields.find(i => +i.importFieldId === Compliance.ImportFieldIdEnum.SiteUpdatesOnlyMatchBy).value;
                this._sitesUpdatesOnlyMatchBy = this._sitesUpdatesOnlyMatchBy[0].toUpperCase() + this._sitesUpdatesOnlyMatchBy.slice(1);
                break;
            case Compliance.ImportContentTypeIdEnum.AccrualExpenseAdjustments:
                this._accrualAdjustmentAmountValueType = this._importFile.fileFields.find(i => +i.importFieldId === Compliance.ImportFieldIdEnum.AmountValueType).value;
                break;
            case Compliance.ImportContentTypeIdEnum.TaxPayments:
                const matchOverall = this._importFile.fileFields.find(i => +i.importFieldId === Compliance.ImportFieldIdEnum.TaxPaymentsMatchOverall).value;
                this._taxPaymentsMatchOverall = this._entityImportService.taxPaymentsMatchOverallOptions.find(x => x.value === matchOverall).name;
                this._isTaxPaymentsImportMatchByPaymentSystemId = matchOverall === 'payment_system_id';

                if (!this._isTaxPaymentsImportMatchByPaymentSystemId) {
                    const matchParcel = this._importFile.fileFields.find(i => +i.importFieldId === Compliance.ImportFieldIdEnum.TaxPaymentsMatchParcel).value;
                    this._taxPaymentsMatchParcel = this._entityImportService.taxPaymentsMatchParcelOptions.find(x => x.value === matchParcel).name;

                    const matchCollector = this._importFile.fileFields.find(i => +i.importFieldId === Compliance.ImportFieldIdEnum.TaxPaymentsMatchCollector).value;
                    this._taxPaymentsMatchCollector = this._entityImportService.taxPaymentsMatchCollectorOptions.find(x => x.value === matchCollector).name;
                }
                break;
            case Compliance.ImportContentTypeIdEnum.AssetsCreateSplits:
                assetEffectiveDate = this._importFile.fileFields.find(i => +i.importFieldId === Compliance.ImportFieldIdEnum.AssetsCreateSplitsEffectiveDate).value;
                break;
            case Compliance.ImportContentTypeIdEnum.AssetsUpdateClassification:
                assetEffectiveDate = this._importFile.fileFields.find(i => +i.importFieldId === Compliance.ImportFieldIdEnum.AssetsUpdateClassificationEffectiveDate).value;
                break;
            case Compliance.ImportContentTypeIdEnum.AssetsUpdateAssetNumber:
                assetEffectiveDate = this._importFile.fileFields.find(i => +i.importFieldId === Compliance.ImportFieldIdEnum.AssetsUpdateAssetNumberEffectiveDate).value;
                break;
            case Compliance.ImportContentTypeIdEnum.IncomeStatements:
                this._incomeStatementsEndingDate = new Date(this._importFile.fileFields.find(x => +x.importFieldId === Compliance.ImportFieldIdEnum.IncomeStatementEndingDate).value);
                break;
            case Compliance.ImportContentTypeIdEnum.RentRoll:
                this._rentRollEndingDate = new Date(this._importFile.fileFields.find(x => +x.importFieldId === Compliance.ImportFieldIdEnum.RentRollEndDate).value);
                break;
            case Compliance.ImportContentTypeIdEnum.GLAccount:
                const intendedUse = this._importFile.fileFields.find(i => +i.importFieldId === Compliance.ImportFieldIdEnum.GLAccountIntendedUse).value;
                this._glAccountsIntendedUse = this._entityImportService.glAccountIntendedUseOptions.find(x => x.value === intendedUse).name;
                break;
        }

        if (assetEffectiveDate) {
            this._assetEffectiveDate = new Date(assetEffectiveDate);
        }

        if (this._assetOwnershipTypeId || this._assetOwnershipTypeId === 0) {
            const assetOwnershipTypes = await lastValueFrom(this._assetRepository.getOwnershipTypes());
            const assetOwnershipType = assetOwnershipTypes.find(i => i.assetOwnershipTypeId === this._assetOwnershipTypeId);

            if (assetOwnershipType) {
                this._assetOwnershipTypeName = assetOwnershipType.description;
            }
        }

        this._importStatusChangeSub = this._websocketListenerService.longRunningProcessStatusChange$.subscribe(x => this._wsHandleStatusChange(x));
        this._importProgressChangeSub = this._websocketListenerService.longRunningProcessProgressChange$.subscribe(x => this._wsHandleProgressChange(x));
    }

    destroy() : void{
        this._importStatusChangeSub && this._importStatusChangeSub.unsubscribe();
        this._importProgressChangeSub && this._importProgressChangeSub.unsubscribe();
    }

    async validate(importFileId: number, model?: Compliance.ImportValidateModel): Promise<void> {
        const result = await lastValueFrom(this._entityImportRepository.validate(importFileId, model));
        this._longRunningProcessId = result.longRunningProcessId;
        this._importFile.processStatus = Compliance.ImportFileProcessStatusEnum.Validating;
    }

    getValidationSummary(importFileId: number): Promise<void> {
        const promise = lastValueFrom(this._entityImportRepository.getValidationSummary(importFileId))
            .then((data) => {
                this._importFile.processStatus = Compliance.ImportFileProcessStatusEnum.Validated;
                this.notifyValidationSummaryChanged(data);
                this.notifyErrorFilterChanged([]);
            });

        this.notifyValidated(promise);

        return promise;
    }

    evaluate(importFileId: number, selectedRowsModel: Compliance.SelectedRowsModel): Promise<Compliance.ImportEvaluateResultModel> {
        const promise = lastValueFrom(this._entityImportRepository.evaluate(importFileId, selectedRowsModel));
        this.notifyEvaluating(promise);

        promise.then(result => {
            this._lastEvaluationResult = result;
            if (this._selectedRowsInfo){
                this._selectedRowsInfo.evaluatedInsertCount = result.insertCount;
                this._selectedRowsInfo.evaluatedUpdateCount = result.updateCount;
                this._selectedRowsInfo.evaluatedDeleteCount = result.deleteCount;
                this._selectedRowsInfo.evaluatedNoChangeCount = result.noChangeCount;
            }
        });

        return promise;
    }

    async transfer(): Promise<boolean> {
        const params: TransferConfirmParams = {
            evaluatedInsertCount: this._selectedRowsInfo.evaluatedInsertCount,
            evaluatedUpdateCount: this._selectedRowsInfo.evaluatedUpdateCount,
            evaluatedDeleteCount: this._selectedRowsInfo.evaluatedDeleteCount,
            evaluatedNoChangeCount: this._selectedRowsInfo.evaluatedNoChangeCount,
            effectiveDate: this._assetEffectiveDate,
            assetOwnershipType: this._assetOwnershipTypeName,
            accrualAdjustmentAmountValueType: this._accrualAdjustmentAmountValueType,
            importContentType: this._importFile.importContentTypeId
        };

        return await this._modalService.showAsync(TransferConfirmComponent, params, 'modal-md');
    }

    startTransfer(importFileId: number, selectedRowsModel: Compliance.SelectedRowsModel): Promise<Compliance.LongRunningProcessModel> {
        return lastValueFrom(this._entityImportRepository.transfer(importFileId, selectedRowsModel));
    }

    updateCell(importId: number, rowIndex: number, importFileSpecificationImportFieldId: number, value: string): Promise<number> {
        const promise = lastValueFrom(this._entityImportRepository.updateImportFieldValue(importId, rowIndex, importFileSpecificationImportFieldId, value));
        promise.then(x => this.notifyCellUpdated(x));
        return promise;
    }

    get notValidatedUpdates$(): Observable<number> {
        return this._cellUpdatedEventEmitter.asObservable();
    }

    notifyCellUpdated(value: number) {
        this._cellUpdatedEventEmitter.emit(value);
    }

    get validationSummaryChanged$(): Observable<Compliance.ImportFileValidationErrorModel[]> {
        return this._validationSummaryBehaviorSubject
            .asObservable().pipe(
            distinctUntilChanged((
                x: Compliance.ImportFileValidationErrorModel[],
                y: Compliance.ImportFileValidationErrorModel[]) => _.isEqual(x, y)));
    }

    notifyValidationSummaryChanged(validationErrors: Compliance.ImportFileValidationErrorModel[]): void {
        this._validationSummaryBehaviorSubject.next(validationErrors);
    }

    get validated$(): Observable<Promise<void>> {
        return this._validatedEventEmitter.asObservable();
    }

    notifyValidated(promise: Promise<any>) {
        this._validatedEventEmitter.next(promise);
    }

    get evaluating$(): Observable<Promise<Compliance.ImportEvaluateResultModel>> {
        return this._evaluatingEventEmitter.asObservable();
    }

    notifyEvaluating(promise: Promise<Compliance.ImportEvaluateResultModel>) {
        this._evaluatingEventEmitter.emit(promise);
    }

    get transferred$(): Observable<void> {
        return this._transferredEventEmitter.asObservable();
    }

    notifyTransferred() {
        this._transferredEventEmitter.emit();
    }

    get selectedRowsChanged$(): Observable<ISelectedRowsEvent> {
        return this._selectedRowsEventEmitter.asObservable();
    }

    notifySelectedRowsChanged(selectedRowsModel: Compliance.ImportBulkOperationModel, selectedRowsCount: number) {
        this._selectedRowsEventEmitter.emit({ selectedRowsModel: selectedRowsModel, selectedRowsCount: selectedRowsCount });
    }

    get errorFilterChanged$(): Observable<Compliance.ImportFileValidationErrorModel[]> {
        return this._errorFilterChangedBehaviorSubject
            .asObservable().pipe(
            distinctUntilChanged((
                x: Compliance.ImportFileValidationErrorModel[],
                y: Compliance.ImportFileValidationErrorModel[]) => _.isEqual(x, y)));
    }

    notifyErrorFilterChanged(errors: Compliance.ImportFileValidationErrorModel[]) {
        this._errorFilterChangedBehaviorSubject.next(errors);
    }

    getValidationResults(importFileId: number): Promise<Compliance.ImportFileValidationErrorModel[]>{
        return lastValueFrom(this._entityImportRepository.getValidationSummary(importFileId));
    }

    async deleteStagingRows(importFileId: number, selectedRowsModel: Compliance.ImportBulkOperationModel): Promise<Compliance.ImportFileModel>{
        this._importFile = await lastValueFrom(this._entityImportRepository.deleteStagingRows(importFileId, selectedRowsModel));

        if (this._importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Completed ||
            this._importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Transferred) {
            this._importFile.processStatus = Compliance.ImportFileProcessStatusEnum.ProcessingCompleted;
        }

        this._importStatusSubject.next(this._importFile);
        return this._importFile;
    }

    get showValidRowsOnlyChanged$(): Observable<boolean> {
        return this._showValidRowsOnlyEventEmitter.asObservable();
    }

    notifyShowValidRowsOnlyChanged(showValidRowsOnly: boolean) {
        this._showValidRowsOnlyEventEmitter.emit(showValidRowsOnly);
    }

    get assetEffectiveDate(): Date {
        return this._assetEffectiveDate;
    }

    get assetOwnershipTypeId(): number {
        return this._assetOwnershipTypeId;
    }

    get assetOwnershipTypeName(): string {
        return this._assetOwnershipTypeName;
    }

    get accrualAdjustmentAmountValueType(): string {
        return this._accrualAdjustmentAmountValueType;
    }

    async getSelectedRowsInfo(importFileId: number, selectedRowsModel: Compliance.ImportBulkOperationModel): Promise<Compliance.ImportSelectedRowsInfoModel> {
        this._selectedRowsInfo = await lastValueFrom(this._entityImportRepository.getSelectedRowsInfo(importFileId, selectedRowsModel));
        return this._selectedRowsInfo;
    }

    canDelete(importFile: Compliance.ImportFileModel): boolean {
        return (importFile.processStatus === Compliance.ImportFileProcessStatusEnum.ShowPreview ||
            importFile.processStatus === Compliance.ImportFileProcessStatusEnum.ProcessingCompleted ||
            importFile.processStatus === Compliance.ImportFileProcessStatusEnum.ProcessingError ||
            importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Validated ||
            importFile.processStatus === Compliance.ImportFileProcessStatusEnum.TransferError && importFile.transferredRows === 0);
    }

    get forceValidate$(): Observable<void> {
        return this._forceValidateSubject.asObservable();
    }

    forceValidate() {
        this._forceValidateSubject.next();
    }

    get importContentTypeId(): number {
        return this._importFile.importContentTypeId;
    }

    get sitesUpdatesOnlyMatchBy(): string {
        return this._sitesUpdatesOnlyMatchBy;
    }

    get taxPaymentsMatchOverall(): string {
        return this._taxPaymentsMatchOverall;
    }

    get isTaxPaymentsImportMatchByPaymentSystemId(): boolean {
        return this._isTaxPaymentsImportMatchByPaymentSystemId;
    }

    get taxPaymentsMatchParcel(): string {
        return this._taxPaymentsMatchParcel;
    }

    get taxPaymentsMatchCollector(): string {
        return this._taxPaymentsMatchCollector;
    }

    get incomeStatementsEndingDate(): Date {
        return this._incomeStatementsEndingDate;
    }

    get rentRollEndingDate(): Date {
        return this._rentRollEndingDate;
    }

    get glAccountsIntendedUse(): string {
        return this._glAccountsIntendedUse;
    }

    get clearErrorsWarningsSelectionChanged$(): Observable<void> {
        return this._clearErrorsWarningsSelectionEventEmitter.asObservable();
    }

    notifyClearErrorsWarningsSelection() {
        this._clearErrorsWarningsSelectionEventEmitter.emit();
    }

    private async _wsHandleStatusChange(statusChange: Compliance.LongRunningProcessStatusChangeModel): Promise<void> {
        if (!(this._importFile && this._importFile.importFileId === statusChange.entityId && statusChange.isCompleted)) {
            return;
        }

        // reload
        this._importFile = await lastValueFrom(this._entityImportRepository.getImport(this._importFile.importFileId));

        if (this._importFile.longRunningProcessId === statusChange.longRunningProcessId) {
            //hack
            if (statusChange.operationName === 'Validate' && this._importFile.processStatus !== Compliance.ImportFileProcessStatusEnum.Validated) {
                this._importFile.processStatus = Compliance.ImportFileProcessStatusEnum.Validating;
            } else if (statusChange.operationName === 'Transfer') {
                this._importFile.processStatus = Compliance.ImportFileProcessStatusEnum.TransferCompleted;
            } else if (statusChange.operationName === 'Bulk Update') {
                this._importFile.processStatus = Compliance.ImportFileProcessStatusEnum.BulkUpdated;
            }

            this._importProcessStatusError = statusChange.errorMessage;
            this._importStatusSubject.next(this._importFile);
        }
    }

    private async _wsHandleProgressChange(progressChange: Compliance.LongRunningProcessProgressChangeModel): Promise<void> {
        if (this._importFile && this._importFile.importFileId === progressChange.entityId) {
            this._importProgressStatusSubject.next(progressChange);
        }
    }
}
