import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { Observable } from 'rxjs'

@Injectable()
export class FilingBatchRepository extends BaseRepository {
    constructor(httpClient: HttpClient) { super(httpClient) }

    get(filingBatchId: number): Observable<Compliance.FilingBatchModel> {
        return this.httpGet(`/api/filingBatch/${filingBatchId}`);
    }

    getList(companyId: number, searchParams: Compliance.FilingBatchSearchModel): Observable<Compliance.QueryResultModel<Compliance.FilingBatchModel>> {
        return this.httpPost(`/api/filingBatch/company/${companyId}`, searchParams);
    }

    getListByBatch(filingBatchId: number, model: Compliance.FilingSearchModel): Observable<Compliance.QueryResultModel<Compliance.FilingModel>> {
        return this.httpPost(`/api/filing/filingbatch/${filingBatchId}`, model);
    }

    getListByBatchAvailable(filingBatchId: number, model: Compliance.FilingSearchModel): Observable<Compliance.QueryResultModel<Compliance.FilingModel>> {
        return this.httpPost(`/api/filing/filingbatch/${filingBatchId}/available`, model);
    }

    getCountByBatchAvailable(filingBatchId: number, filingIdsToExclude: number[]): Observable<number> {
        return this.httpPost(`/api/filing/filingbatch/${filingBatchId}/available/count`, filingIdsToExclude);
    }

    getRowIdsByBatchAvailable(filingBatchId: number, model: Compliance.FilingSearchModel): Observable<Compliance.QueryResultModel<number>> {
        return this.httpPost(`/api/filing/filingbatch/${filingBatchId}/available/rowids`, model);
    }

    getListByPriorReturnAvailable(filingId: number): Observable<Compliance.FilingModel[]> {
        return this.httpGet(`/api/filing/${filingId}/priorreturn`);
    }

    getListByFilingIds(filingIds: number[]): Observable<Compliance.FilingModel[]> {
        return this.httpPost(`/api/filing/list`, filingIds);
    }

    create(model: Compliance.FilingBatchModel): Observable<Compliance.FilingBatchModel> {
        return this.httpPost(`/api/filingBatch`, model);
    }

    update(model: Compliance.FilingBatchModel): Observable<Compliance.FilingBatchModel> {
        return this.httpPut(`/api/filingBatch/${model.filingBatchId}`, model);
    }

    updateFilings(filingBatchId:number, model: Compliance.FilingBatchFilingsUpdateModel):Observable<void> {
        return this.httpPost(`/api/filingBatch/${filingBatchId}/filing`, model);
    }

    delete(filingBatchId: number, force: boolean): Observable<boolean> {
        return this.httpDelete(`/api/filingBatch/${filingBatchId}?force=${force}`);
    }

    getTasks(filingBatchId: number): Observable<Core.TasksByEntityModel> {
        return this.httpGet(`/api/tasks/entity?entityID=${filingBatchId}&entityTypeID=${Core.EntityTypes.FilingBatch}`);
    }

    getNextYearInfo(filingBatchId: number): Observable<Compliance.FilingBatchCreateModel> {
        return this.httpGet(`/api/filingBatch/${filingBatchId}/nextYearInfo`);
    }

    getListByAvailableToMoveFilingsToAsync(filingBatchId: number, filingIds: number[]): Observable<Compliance.FilingBatchModel[]> {
        return this.httpPost(`/api/filingBatch/${filingBatchId}/moveFilings/available`, filingIds);
    }

    moveFilings(filingBatchId: number, model: Compliance.FilingBatchFilingsMoveModel): Observable<Compliance.FilingBatchModel> {
        return this.httpPost(`/api/filingBatch/${filingBatchId}/moveFilings`, model);
    }

    amendFilings(filingBatchId: number, model: Compliance.FilingBatchFilingsAmendModel): Observable<Compliance.FilingBatchModel> {
        return this.httpPost(`/api/filingBatch/${filingBatchId}/amendFilings`, model);
    }

    getRowIds(companyId: number, searchParams: Core.SearchModel<Compliance.FilingBatchPropertyEnum>): Observable<Compliance.QueryResultModel<{ filingBatchId: number, isLockedOrCompleted: boolean; }>> {
        return this.httpPost(`/api/filingBatch/company/${companyId}/rowIds`, searchParams);
    }
}
