(function () {
    'use strict';

    angular
        .module('weissmanApp')
        .controller('ProcessAppealWarrantedModalController', ProcessAppealWarrantedModalController);

    ProcessAppealWarrantedModalController.$inject = [
        'taskIDs',
        '$uibModalInstance',
        'processingService',
        'searchTimestamp',
        'optionalArgs',
        'messageBoxService'
    ];

    function ProcessAppealWarrantedModalController() {
        weissNg1WrapFunc(ng1ControllerInit, arguments, this);
    }

    function ng1ControllerInit(taskIDs, $uibModalInstance, processingService, searchTimestamp, optionalArgs, messageBoxService) {
        var vm = this;

        vm.payload = {
            taskIDs: taskIDs,
            appealLevelID: null,
            comment: null,
            searchTimestamp: searchTimestamp
        };

        activate();

        ////////////////

        async function activate() {
            vm.loading = true;

            try {
                const result = await processingService.getAppealLevels(taskIDs);
                vm.validationMessage = result.validationMessage;
                vm.appealLevels = result.appealLevels;

                if (result.warningMessage) {
                    await messageBoxService.warning(result.warningMessage);
                }

                if (vm.appealLevels) {
                    vm.payload.appealLevelID = _.head(vm.appealLevels).appealLevelID;
                }
            } finally {
                vm.loading = false;
            }
        }

        vm.cancel = function () {
            $uibModalInstance.dismiss();
        };

        vm.process = function () {
            if (optionalArgs.parentCallsService) {
                $uibModalInstance.close(function (taskIDs) {
                    var payload = _.cloneDeep(vm.payload);
                    payload.taskIDs = taskIDs;
                    return processingService.processAppealWarranted(payload);
                });
            } else {
                vm.loading = true;

                processingService
                    .processAppealWarranted(vm.payload)
                    .then(function (result) {
                        $uibModalInstance.close(result);
                    })
                    .catch(function (error) {
                        $uibModalInstance.dismiss();
                    });
            }
        };
    }
})();
