<ul #dropdown
    [@dropdownVisible]="open ? 'show' : 'hide'"
    class="select-dropdown"
    [class.active]="open && animateComplete"
    [style.top]="dropdownPosition?.top"
    [style.left]="dropdownPosition?.left"
    [style.width]="dropdownPosition?.width"
    clickOutside
    (onClickOutside)="closeDropdown()">
    <ng-container *ngIf="!renderedTop">
        <li *ngIf="selectConfig && (selectConfig.options.length < selectConfig.includeSearchAfterN || !selectConfig.canSearch)" class="selected-option">
            <span class="dropdown-value" *ngIf="!selectConfig.selectedOption">{{selectConfig.placeholder}}</span>
            <span class="dropdown-value" *ngIf="selectConfig.selectedOption">{{(selectConfig.isPrimitive) ? selectConfig.selectedOption : selectConfig.selectedOption[selectConfig.labelProperty]}}</span>
        </li>
        <li *ngIf="selectConfig && selectConfig.canSearch && selectConfig.options.length >= selectConfig.includeSearchAfterN" class="search-option">
            <span class="dropdown-value">
                <label class="select-search-label">
                    <input class="exclude-modal-styling" #searchInput type="text" [(ngModel)]="searchValue" (input)="search()"/>
                    <i class="fa fa-search"></i>
                </label>
            </span>
        </li>
    </ng-container>
    <li *ngIf="selectConfig && selectConfig.canDeselect"
        class="dropdown-option"
        (click)="optionSelected(selectConfig.deselectValue)">
        <span class="dropdown-value">
            {{selectConfig.deselectLabel}}
        </span>
    </li>
    <li *ngFor="let option of selectConfig?.searchableOptions"
        class="dropdown-option"
        [class.selected]="option === selectConfig.selectedOption"
        [class.disabled]="option[selectConfig.disabledProperty]"
        (click)="optionSelected(option)">
        <span class="dropdown-value"
              [ngClass]="(selectConfig.optionStyleClass && selectConfig.optionStyleClass(option[selectConfig.valueProperty]))"
              [ngStyle]="{ 'text-align': selectConfig.alignOptionText }"
              [title]="((selectConfig.isPrimitive) ? option : option[selectConfig.labelProperty])">
            {{((selectConfig.isPrimitive) ? option : option[selectConfig.labelProperty])}}
        </span>
    </li>
    <li *ngIf="!searchValue && (selectConfig?.isShowMoreFromParent || (selectConfig?.maxListSize && selectConfig?.searchableOptions.length < searchResultCount))"
        class="dropdown-option"
        (click)="showMore($event)">
        <span class="dropdown-value show-more"><i class="fa fa-caret-down"></i> Show More</span>
    </li>
    <ng-container *ngIf="renderedTop">
        <li *ngIf="selectConfig && (selectConfig.options.length < selectConfig.includeSearchAfterN || !selectConfig.canSearch)" class="selected-option bottom">
            <span class="dropdown-value" *ngIf="!selectConfig.selectedOption">{{selectConfig.placeholder}}</span>
            <span class="dropdown-value" *ngIf="selectConfig.selectedOption">{{(selectConfig.isPrimitive) ? selectConfig.selectedOption : selectConfig.selectedOption[selectConfig.labelProperty]}}</span>
        </li>
        <li *ngIf="selectConfig && selectConfig.canSearch && selectConfig.options.length >= selectConfig.includeSearchAfterN" class="search-option bottom">
            <span class="dropdown-value">
                <label class="select-search-label">
                    <input class="exclude-modal-styling" #searchInput type="text" [(ngModel)]="searchValue" (input)="search()"/>
                    <i class="fa fa-search"></i>
                </label>
            </span>
        </li>
    </ng-container>
</ul>
