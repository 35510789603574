import { GridApi, IGetRowsParams } from 'ag-grid-community';
import { lastValueFrom } from 'rxjs';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../../../AgGrid';
import { ReturnAssetsService } from '../returnAssets.service';
import { ReturnService } from '../../../return.service';
import { ReturnAssetRepository } from '../../../../Repositories';
import ReturnAssetPropertyEnum = Compliance.ReturnAssetPropertyEnum;

export class ReturnAssetListAgGridDataSource extends AgGridDataSourceBase<void> {
    constructor(
        gridApi: GridApi,
        private readonly _returnService: ReturnService,
        private readonly _returnAssetsService: ReturnAssetsService,
        private readonly _returnAssetRepository: ReturnAssetRepository,
        private readonly _totalsUpdateCallback: (totals: Compliance.ReturnAssetSearchTotalsModel, selectedTotals?: boolean, isLoading?: boolean) => void
    ) {
        super(gridApi);
    }

    private readonly _propertyMap: Compliance.NameValuePair<Compliance.ReturnAssetPropertyEnum>[] = [
        { name: 'assetNumber', value: Compliance.ReturnAssetPropertyEnum.AssetNumber },
        { name: 'description', value: Compliance.ReturnAssetPropertyEnum.Description },
        { name: 'glAccount', value: Compliance.ReturnAssetPropertyEnum.GLAccount },
        { name: 'glAccountNumber', value: Compliance.ReturnAssetPropertyEnum.GLAccountNumber },
        { name: 'classificationName', value: Compliance.ReturnAssetPropertyEnum.ClassificationName },
        { name: 'parcelAccountNumber', value: Compliance.ReturnAssetPropertyEnum.ParcelAccountNumber },
        { name: 'parcelDescription', value: Compliance.ReturnAssetPropertyEnum.ParcelDescription },
        { name: 'assessorAbbr', value: Compliance.ReturnAssetPropertyEnum.AssessorAbbr },
        { name: 'acqDate', value: Compliance.ReturnAssetPropertyEnum.AcqDate },
        { name: 'age', value: Compliance.ReturnAssetPropertyEnum.Age },
        { name: 'priorAcqDate', value: Compliance.ReturnAssetPropertyEnum.PriorAcqDate },
        { name: 'cost', value: Compliance.ReturnAssetPropertyEnum.Cost },
        { name: 'costChange', value: Compliance.ReturnAssetPropertyEnum.CostChange },
        { name: 'priorCost', value: Compliance.ReturnAssetPropertyEnum.PriorCost },
        { name: 'scheduleName', value: Compliance.ReturnAssetPropertyEnum.Schedule },
        { name: 'site', value: Compliance.ReturnAssetPropertyEnum.Site },
        { name: 'siteName', value: Compliance.ReturnAssetPropertyEnum.SiteName },
        { name: 'siteProperty', value: Compliance.ReturnAssetPropertyEnum.SiteProperty },
        { name: 'parcelSiteProperty', value: Compliance.ReturnAssetPropertyEnum.ParcelSiteProperty },
        { name: 'parcelSiteName', value: Compliance.ReturnAssetPropertyEnum.ParcelSiteName },
        { name: 'priorScheduleName', value: Compliance.ReturnAssetPropertyEnum.PriorSchedule },
        { name: 'depreciationFactorTableName', value: Compliance.ReturnAssetPropertyEnum.DepreciationFactorTableName },
        { name: 'priorDepreciationFactorTableName', value: Compliance.ReturnAssetPropertyEnum.PriorDepreciationFactorTableName},
        { name: 'factor', value: Compliance.ReturnAssetPropertyEnum.Factor },
        { name: 'reportedValue', value: Compliance.ReturnAssetPropertyEnum.DepreciationCost },
        { name: 'acqYear', value: Compliance.ReturnAssetPropertyEnum.AcqYear },
        { name: 'linkedParcelAccountNumber', value: Compliance.ReturnAssetPropertyEnum.LinkedParcelAccountNumber },
        { name: 'linkedParcelDescription', value: Compliance.ReturnAssetPropertyEnum.LinkedParcelDescription },
        { name: 'linkedParcelAssessorAbbr', value: Compliance.ReturnAssetPropertyEnum.LinkedParcelAssessorAbbr },
        { name: 'acqYear', value: Compliance.ReturnAssetPropertyEnum.AcqYear },
        { name: 'acqYear', value: Compliance.ReturnAssetPropertyEnum.AcqYear },
        { name: 't1', value: Compliance.ReturnAssetPropertyEnum.T1 },
        { name: 't2', value: Compliance.ReturnAssetPropertyEnum.T2 },
        { name: 't3', value: Compliance.ReturnAssetPropertyEnum.T3 },
        { name: 't4', value: Compliance.ReturnAssetPropertyEnum.T4 },
        { name: 't5', value: Compliance.ReturnAssetPropertyEnum.T5 },
        { name: 't6', value: Compliance.ReturnAssetPropertyEnum.T6 },
        { name: 't7', value: Compliance.ReturnAssetPropertyEnum.T7 },
        { name: 't8', value: Compliance.ReturnAssetPropertyEnum.T8 },
        { name: 't9', value: Compliance.ReturnAssetPropertyEnum.T9 },
        { name: 't10', value: Compliance.ReturnAssetPropertyEnum.T10 },
        { name: 't11', value: Compliance.ReturnAssetPropertyEnum.T11 },
        { name: 't12', value: Compliance.ReturnAssetPropertyEnum.T12 },
        { name: 't13', value: Compliance.ReturnAssetPropertyEnum.T13 },
        { name: 't14', value: Compliance.ReturnAssetPropertyEnum.T14 },
        { name: 't15', value: Compliance.ReturnAssetPropertyEnum.T15 },
        { name: 't16', value: Compliance.ReturnAssetPropertyEnum.T16 },
        { name: 't17', value: Compliance.ReturnAssetPropertyEnum.T17 },
        { name: 't18', value: Compliance.ReturnAssetPropertyEnum.T18 },
        { name: 't19', value: Compliance.ReturnAssetPropertyEnum.T19 },
        { name: 't20', value: Compliance.ReturnAssetPropertyEnum.T20 },
        { name: 't20', value: Compliance.ReturnAssetPropertyEnum.T20 },
        { name: 't21', value: Compliance.ReturnAssetPropertyEnum.T21 },
        { name: 't22', value: Compliance.ReturnAssetPropertyEnum.T22 },
        { name: 't23', value: Compliance.ReturnAssetPropertyEnum.T23 },
        { name: 't24', value: Compliance.ReturnAssetPropertyEnum.T24 },
        { name: 't25', value: Compliance.ReturnAssetPropertyEnum.T25 },
        { name: 't26', value: Compliance.ReturnAssetPropertyEnum.T26 },
        { name: 't27', value: Compliance.ReturnAssetPropertyEnum.T27 },
        { name: 't28', value: Compliance.ReturnAssetPropertyEnum.T28 },
        { name: 't29', value: Compliance.ReturnAssetPropertyEnum.T29 },
        { name: 't30', value: Compliance.ReturnAssetPropertyEnum.T30 },
        { name: 'n1', value: Compliance.ReturnAssetPropertyEnum.N1 },
        { name: 'n2', value: Compliance.ReturnAssetPropertyEnum.N2 },
        { name: 'n3', value: Compliance.ReturnAssetPropertyEnum.N3 },
        { name: 'n4', value: Compliance.ReturnAssetPropertyEnum.N4 },
        { name: 'n5', value: Compliance.ReturnAssetPropertyEnum.N5 },
        { name: 'n6', value: Compliance.ReturnAssetPropertyEnum.N6 },
        { name: 'n7', value: Compliance.ReturnAssetPropertyEnum.N7 },
        { name: 'n8', value: Compliance.ReturnAssetPropertyEnum.N8 },
        { name: 'n9', value: Compliance.ReturnAssetPropertyEnum.N9 },
        { name: 'n10', value: Compliance.ReturnAssetPropertyEnum.N10 },
        { name: 'd1', value: Compliance.ReturnAssetPropertyEnum.D1 },
        { name: 'd2', value: Compliance.ReturnAssetPropertyEnum.D2 },
        { name: 'd3', value: Compliance.ReturnAssetPropertyEnum.D3 },
        { name: 'd4', value: Compliance.ReturnAssetPropertyEnum.D4 },
        { name: 'd5', value: Compliance.ReturnAssetPropertyEnum.D5 },
        { name: 'd6', value: Compliance.ReturnAssetPropertyEnum.D6 },
        { name: 'd7', value: Compliance.ReturnAssetPropertyEnum.D7 },
        { name: 'd8', value: Compliance.ReturnAssetPropertyEnum.D8 },
        { name: 'd9', value: Compliance.ReturnAssetPropertyEnum.D9 },
        { name: 'd10', value: Compliance.ReturnAssetPropertyEnum.D10 },
        { name: 'inventoryJan', value: Compliance.ReturnAssetPropertyEnum.InventoryJan },
        { name: 'inventoryFeb', value: Compliance.ReturnAssetPropertyEnum.InventoryFeb },
        { name: 'inventoryMar', value: Compliance.ReturnAssetPropertyEnum.InventoryMar },
        { name: 'inventoryApr', value: Compliance.ReturnAssetPropertyEnum.InventoryApr },
        { name: 'inventoryMay', value: Compliance.ReturnAssetPropertyEnum.InventoryMay },
        { name: 'inventoryJun', value: Compliance.ReturnAssetPropertyEnum.InventoryJun },
        { name: 'inventoryJul', value: Compliance.ReturnAssetPropertyEnum.InventoryJul },
        { name: 'inventoryAug', value: Compliance.ReturnAssetPropertyEnum.InventoryAug },
        { name: 'inventorySep', value: Compliance.ReturnAssetPropertyEnum.InventorySep },
        { name: 'inventoryOct', value: Compliance.ReturnAssetPropertyEnum.InventoryOct },
        { name: 'inventoryNov', value: Compliance.ReturnAssetPropertyEnum.InventoryNov },
        { name: 'inventoryDec', value: Compliance.ReturnAssetPropertyEnum.InventoryDec },
        { name: 'inventoryAvrCalculated', value: Compliance.ReturnAssetPropertyEnum.InventoryAvr },
        { name: 'splitAllocationPercentage', value: Compliance.ReturnAssetPropertyEnum.SplitAllocationPercentage },
        { name: 'additionalDepreciation', value: Compliance.ReturnAssetPropertyEnum.AdditionalDepreciation },
        { name: 'estimatedFMV', value: Compliance.ReturnAssetPropertyEnum.EstimatedFMV },
        { name: 'isReportable', value: Compliance.ReturnAssetPropertyEnum.IsReportable },
        { name: 'isTaxable', value: Compliance.ReturnAssetPropertyEnum.IsTaxable },
        { name: 'parcelAddressComplete', value: Compliance.ReturnAssetPropertyEnum.ParcelAddressComplete },
        { name: 'companyCode', value: Compliance.ReturnAssetPropertyEnum.CompanyCode },
        { name: 'netBookValue', value: Compliance.ReturnAssetPropertyEnum.NetBookValue },
        { name: 'indexFactorTableName', value: Compliance.ReturnAssetPropertyEnum.IndexFactorTableName },
        { name: 'form', value: Compliance.ReturnAssetPropertyEnum.Form },
        { name: 'assetOwnershipType', value: Compliance.ReturnAssetPropertyEnum.AssetOwnershipType },
        { name: 'alternativeCostDescriptor', value: Compliance.ReturnAssetPropertyEnum.AlternativeCostDescriptor },
        { name: 'alternativeAcquisitionDateDescriptor', value: Compliance.ReturnAssetPropertyEnum.AlternativeAcquisitionDateDescriptor },
        { name: 'doNotPotentiallyDispose', value: Compliance.ReturnAssetPropertyEnum.DoNotPotentiallyDispose },
        { name: 'isAnchored', value: Compliance.ReturnAssetPropertyEnum.IsAnchored },
        { name: 'isAssetLocked', value: Compliance.ReturnAssetPropertyEnum.IsLocked },
        { name: 'leaseStartDate', value: Compliance.ReturnAssetPropertyEnum.LeaseStartDate },
        { name: 'leaseEndDate', value: Compliance.ReturnAssetPropertyEnum.LeaseEndDate },
        { name: 'leaseClientId', value: Compliance.ReturnAssetPropertyEnum.LeaseClientId },
        { name: 'leaseTermInMonths', value: Compliance.ReturnAssetPropertyEnum.LeaseTermInMonths },
        { name: 'leaseMonthlyPayment', value: Compliance.ReturnAssetPropertyEnum.LeaseMonthlyPayment },
        { name: 'leaseTypeId', value: Compliance.ReturnAssetPropertyEnum.LeaseTypeId },
        { name: 'leaseAssetId', value: Compliance.ReturnAssetPropertyEnum.LeaseAssetId },
        { name: 'leaseNumber', value: Compliance.ReturnAssetPropertyEnum.LeaseNumber },
        { name: 'siteAddress', value: Compliance.ReturnAssetPropertyEnum.SiteAddress }
    ];

    private _lastFilterUsedInTotals: string;
    lastFetchedTotals: Compliance.ReturnAssetSearchTotalsModel;

    protected canGetRows(): boolean {
        const searchParams = this.getSearchParamsWithoutPagination();
        return !!(searchParams && searchParams.parcelIds.length);
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        this.gridApi.setPinnedBottomRowData([]);
        const searchParams = this.getSearchParamsWithoutPagination();
        const searchParamsString = JSON.stringify(searchParams);
        const fetchTotals: boolean = (!this.lastFetchedTotals) || (this._lastFilterUsedInTotals !== searchParamsString);

        this._lastFilterUsedInTotals = searchParamsString;

        searchParams.pagination = {
            skip: params.startRow,
            take: params.endRow - params.startRow
        };

        const result: Compliance.QueryResultWithTotalsModel<Compliance.ReturnAssetModel, Compliance.ReturnAssetSearchTotalsModel> =
            await lastValueFrom(this._returnAssetRepository.getListByFilingBatch(this._returnService.filingBatchId, searchParams));

        this.lastFetchedTotals = result.totals;
        this._totalsUpdateCallback(this.lastFetchedTotals);

        const dataSourceResult = {
            rows: result.data,
            totalRows: result.totalRows,
            lastModifiedTimestamp: result.lastModifiedTimestamp
        };

        if (fetchTotals) {
            this._getAllRowTotals(params);
        }

        if (!result.data.length && this._returnAssetsService.hasResetNoDataFilterApplied) {
            this._returnAssetsService.setScheduleAndFactorFilter({
                showOnlyNotAssignedToASchedule: false,
                showOnlyAssignedToNonReportableSchedule: false,
                showOnlyAssignedToReportableSchedule: false,
                depreciationFactorId: null,
                scheduleAge: null,
                formRevisionScheduleIds: []
            });
        }

        return dataSourceResult;
    }

    public async getSelectedRowTotals(selectedRowsModel: Compliance.SelectedRowsModel): Promise<Compliance.ReturnAssetSearchTotalsModel> {
        const searchParams = this.getSearchParamsWithoutPagination();
        searchParams.selectedRows = selectedRowsModel;
        searchParams.includeTotals = true;
        searchParams.includeOnlyTotals = true;
        searchParams.pagination = {
            skip: 0,
            take: 100
        };

        const result = await lastValueFrom(this._returnAssetRepository
            .getListByFilingBatch(this._returnService.filingBatchId, searchParams));

        return result.totals;
    }

    clearTotals(): void {
        this._lastFilterUsedInTotals = '';
    }

    getSearchParamsWithoutPagination(): Compliance.ReturnAssetSearchModel {
        let searchModel: Compliance.ReturnAssetSearchModel = {
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            formRevisionIds: this._returnService.sharedState.formRevisionId ? [this._returnService.sharedState.formRevisionId] : [],
            parcelIds: this._returnService.sharedState.returns.map(x => x.parcelId),
            assessorIds: this._returnAssetsService.sharedState.assessorFactorsFilter.assessorIds || [],
            formRevisionScheduleIds: this._returnAssetsService.sharedState.scheduleAndFactorFilter.formRevisionScheduleIds,
            formFactorTableId: this._returnAssetsService.sharedState.scheduleAndFactorFilter.depreciationFactorId,
            age: this._returnAssetsService.sharedState.scheduleAndFactorFilter.scheduleAge,
            priorReturnStatuses: this._returnAssetsService.sharedState.assetStatusesFilter,
            scheduleGroupType: Compliance.ScheduleGroupTypeEnum.Unrestricted,
            priorScheduleGroupType: Compliance.ScheduleGroupTypeEnum.Unrestricted,
            selectedRows: null,
            includeTotals: false,
            includeOnlyTotals: false,
            includeMergedParcels: this._returnService.isConsolidatedReturn,
            includeUnassigned: false,
            includeDisposed: false
        };

        if (this._returnAssetsService.sharedState.scheduleAndFactorFilter.showOnlyAssignedToNonReportableSchedule) {
            searchModel.scheduleGroupType = Compliance.ScheduleGroupTypeEnum.NonReportable;
        }

        if (this._returnAssetsService.sharedState.scheduleAndFactorFilter.showOnlyAssignedToReportableSchedule) {
            searchModel.scheduleGroupType = Compliance.ScheduleGroupTypeEnum.ReportableTaxableNotTaxable;
        }

        if (this._returnAssetsService.sharedState.scheduleAndFactorFilter.showOnlyNotAssignedToASchedule) {
            searchModel.scheduleGroupType = Compliance.ScheduleGroupTypeEnum.OnlyNotAssignedToASchedule;
        }

        return searchModel;
    }

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<number>> {
        const searchParams = this.getSearchParamsWithoutPagination();
        searchParams.pagination = {
            skip: startIndex,
            take: endIndex - startIndex + 1
        };

        return await lastValueFrom(this._returnAssetRepository.getRowIdsByFilingBatch(this._returnService.filingBatchId, searchParams));
    }

    private async _getAllRowTotals(params: IGetRowsParams): Promise<Compliance.ReturnAssetSearchTotalsModel> {

        this._totalsUpdateCallback(this.lastFetchedTotals, false, true);
        const searchParams = this.getSearchParamsWithoutPagination();

        searchParams.includeTotals = true;
        searchParams.includeOnlyTotals = true;
        searchParams.pagination = {
            skip: params.startRow,
            take: params.endRow - params.startRow
        };

        let result: Compliance.QueryResultWithTotalsModel<Compliance.ReturnAssetModel, Compliance.ReturnAssetSearchTotalsModel>;

        if (searchParams.assessorIds.length || searchParams.parcelIds.length || !searchParams.formRevisionIds.length) {
            result = await lastValueFrom(this._returnAssetRepository
                .getListByFilingBatch(this._returnService.filingBatchId, searchParams));
        } else {
            result = {
                totals: {} as Compliance.ReturnAssetSearchTotalsModel,
                data: [],
                totalRows: 0,
                totalValidRows: 0,
                lastModifiedTimestamp: new Date()
            };
        }

        this.lastFetchedTotals = result.totals;
        this._totalsUpdateCallback(this.lastFetchedTotals);

        return result.totals;
    }
}
