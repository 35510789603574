import { Injectable } from '@angular/core';
import { FilingBatchRepository } from '../Repositories/filingBatch.repository';
import { Subject ,  Observable ,  Subscription, lastValueFrom } from 'rxjs';
import { WebsocketListenerService } from '../websocketListener.service';
import { TaskService } from '../../Task/task.service.upgrade';
import { IExpandableComponentContainer,  } from '../../UI-Lib/Expandable-Component/expandableComponentContainer.model';
import { IExpandableComponent } from '../../UI-Lib/Expandable-Component/expandableComponent.model';

@Injectable()
export class FilingBatchService implements IExpandableComponentContainer {
    constructor(
        private readonly _filingBatchRepository: FilingBatchRepository,
        private readonly _websocketListenerService: WebsocketListenerService,
        private readonly _taskService: TaskService
    ) { }

    private _filingBatchStatusChangeSub: Subscription;
    private _filingBatchSubject = new Subject<Compliance.FilingBatchModel>();
    private _filingBatchId: number;
    private _expandedComponent: IExpandableComponent = null;

    get editGroup(): string {
        return 'filing-batch';
    }

    get filingBatchChange$(): Observable<Compliance.FilingBatchModel> {
        return this._filingBatchSubject.asObservable();
    }

    async start(filingBatchId: number): Promise<void> {
        this._filingBatchId = filingBatchId;
        this._filingBatchStatusChangeSub = this._websocketListenerService.longRunningProcessStatusChange$.subscribe(
            x => {
                if (x.processType === Compliance.LongRunningProcessTypeEnum.Return && this._filingBatchId === x.entityId) {
                    this.notifyFilingBatchStatusChange(x);
                }
            });
    }

    stop(): void {
        this._filingBatchStatusChangeSub && this._filingBatchStatusChangeSub.unsubscribe();
    }

    isNotStarted(processStatus: Compliance.FilingBatchProcessStatusEnum): boolean { return processStatus === Compliance.FilingBatchProcessStatusEnum.NotStarted; }
    isStarted(processStatus: Compliance.FilingBatchProcessStatusEnum): boolean { return processStatus === Compliance.FilingBatchProcessStatusEnum.Started; }
    isLocked(processStatus: Compliance.FilingBatchProcessStatusEnum): boolean { return processStatus === Compliance.FilingBatchProcessStatusEnum.Locked; }
    isLocking(processStatus: Compliance.FilingBatchProcessStatusEnum): boolean { return processStatus === Compliance.FilingBatchProcessStatusEnum.Locking; }
    isUnlocking(processStatus: Compliance.FilingBatchProcessStatusEnum): boolean { return processStatus === Compliance.FilingBatchProcessStatusEnum.Unlocking; }
    isFinalizing(processStatus: Compliance.FilingBatchProcessStatusEnum): boolean { return processStatus === Compliance.FilingBatchProcessStatusEnum.Finalizing; }
    isFinalized(processStatus: Compliance.FilingBatchProcessStatusEnum): boolean { return processStatus === Compliance.FilingBatchProcessStatusEnum.Finalized; }
    isSigned(processStatus: Compliance.FilingBatchProcessStatusEnum): boolean { return processStatus === Compliance.FilingBatchProcessStatusEnum.Signed; }
    isSigning(processStatus: Compliance.FilingBatchProcessStatusEnum): boolean { return processStatus === Compliance.FilingBatchProcessStatusEnum.Signing; }
    isUnsigning(processStatus: Compliance.FilingBatchProcessStatusEnum): boolean { return processStatus === Compliance.FilingBatchProcessStatusEnum.Unsigning; }
    isReadOnly(processStatus: Compliance.FilingBatchProcessStatusEnum): boolean { return processStatus !== Compliance.FilingBatchProcessStatusEnum.NotStarted && processStatus !== Compliance.FilingBatchProcessStatusEnum.Started; }
    isLongRunningProcessActive(processStatus: Compliance.FilingBatchProcessStatusEnum): boolean {
        return processStatus === Compliance.FilingBatchProcessStatusEnum.Locking ||
            processStatus === Compliance.FilingBatchProcessStatusEnum.Unlocking ||
            processStatus === Compliance.FilingBatchProcessStatusEnum.Signing ||
            processStatus === Compliance.FilingBatchProcessStatusEnum.Unsigning ||
            processStatus === Compliance.FilingBatchProcessStatusEnum.Finalizing;
    }

    hasLockError(filingBatch: Compliance.FilingBatchModel) {
        return filingBatch && filingBatch.longRunningProcessError && filingBatch.processStatus === Compliance.FilingBatchProcessStatusEnum.Started;
    }

    hasFinalizeError(filingBatch: Compliance.FilingBatchModel) {
        return filingBatch && filingBatch.longRunningProcessError && filingBatch.processStatus === Compliance.FilingBatchProcessStatusEnum.Locked;
    }

    getProgressStatusDisplay(processStatus: Compliance.FilingBatchProcessStatusEnum): string {
        switch (processStatus) {
            case Compliance.FilingBatchProcessStatusEnum.NotStarted: return 'Not started';
            case Compliance.FilingBatchProcessStatusEnum.Started: return 'Started';
            case Compliance.FilingBatchProcessStatusEnum.Finalizing: return 'Finalizing';
            case Compliance.FilingBatchProcessStatusEnum.Finalized: return 'Finalized';
            case Compliance.FilingBatchProcessStatusEnum.Locking: return 'Locking';
            case Compliance.FilingBatchProcessStatusEnum.Locked: return 'Locked';
            case Compliance.FilingBatchProcessStatusEnum.Unlocking: return 'Unlocking';
            case Compliance.FilingBatchProcessStatusEnum.Signing: return 'Signing';
            case Compliance.FilingBatchProcessStatusEnum.Unsigning: return 'Unsigning';
            case Compliance.FilingBatchProcessStatusEnum.Signed: return 'Signed';
            default: return '';
        }
    }

    async notifyFilingBatchFilingsChange(filingBatchId: number): Promise<void> {
        const filingBatch = await lastValueFrom<Compliance.FilingBatchModel>(this._filingBatchRepository.get(filingBatchId));
        this._filingBatchSubject.next(filingBatch);
    }

    /**
     * Notifies all subscribers listening for filingBatch changes that the filing batch has changed.
     * Fetches and provides the most up-to-date data for the filing batch.
     * @param filingBatchId The ID of the filing batch.
     */
    async notifyFilingBatchStatusChange(statusChange: Compliance.LongRunningProcessStatusChangeModel): Promise<void> {
        const filingBatch = await lastValueFrom<Compliance.FilingBatchModel>(this._filingBatchRepository.get(statusChange.entityId));
        this._filingBatchSubject.next(filingBatch);
    }

    async viewTasks(filingBatch: Compliance.FilingBatchModel, editMode: boolean = false): Promise<void> {
        const entityId = filingBatch.filingBatchId;

        const entityType = Core.EntityTypes.FilingBatch;

        const updated = await this._taskService.launchTaskModal(entityId, entityType, (!editMode) || this.isReadOnly(filingBatch.processStatus));
        if (!updated) {
            return;
        }

        const filingBatchUpdate = await lastValueFrom(this._filingBatchRepository.get(filingBatch.filingBatchId));

        this._filingBatchSubject.next(filingBatchUpdate);
    }

    async getListByAvailableToMoveFilings(filingIds: number[]): Promise<Compliance.FilingBatchModel[]> {
        return await lastValueFrom(this._filingBatchRepository.getListByAvailableToMoveFilingsToAsync(this._filingBatchId, filingIds));
    }

    async nextYearInfo(): Promise<Compliance.FilingBatchCreateModel> {
        return await lastValueFrom(this._filingBatchRepository.getNextYearInfo(this._filingBatchId));
    }

    async moveFilings(moveModel: Compliance.FilingBatchFilingsMoveModel): Promise<Compliance.FilingBatchModel> {
        return await lastValueFrom(this._filingBatchRepository.moveFilings(this._filingBatchId, moveModel));
    }

    async amendFilings(amendModel: Compliance.FilingBatchFilingsAmendModel): Promise<Compliance.FilingBatchModel> {
        return await lastValueFrom(this._filingBatchRepository.amendFilings(this._filingBatchId, amendModel));
    }

    setExpandedComponent(component: IExpandableComponent): void {
        this._expandedComponent = component;
    }

    isComponentExpanded(component: IExpandableComponent): boolean {
        return this._expandedComponent === component;
    }

    isExpanded(componentName: string): boolean {
        return this._expandedComponent && (this._expandedComponent.getName() === componentName);
    }

    isOtherComponentExpanded(componentName: string): boolean {
        return this._expandedComponent && (this._expandedComponent.getName() !== componentName);
    }
}
