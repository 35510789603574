import { forwardRef, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { AnnualDetailsModule } from '../Annual-Details/annual-details.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { TeamModule } from '../Team/team.module';
import { EntityEditComponent } from './Documents/entity-edit.component';
import { ChangeDueDateModal } from './change-due-date.modal.component';
import { RetrievalStatusModalService } from './RetrievalStatus/retrieval-status-modal.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { Constants } from '../constants.new';
import { SharedPipesModule } from "../UI-Lib/Pipes/shared-pipes.module";
import { ProcessInvoiceComponent } from "./Invoice/invoice.component";
import { InvoiceDetailsComponent } from "./Invoice/invoice-details.component";
import { InvoiceService } from "./Invoice/invoice.service";
import { InvoiceRecipientLookupComponent } from '../Common/Contracts-Invoices/invoice-recipient-lookup.component';
import { SetDocumentRetrievalStatusModalComponent } from './RetrievalStatus/set-document-retrieval-status.modal.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { RetrievalStatusService } from './RetrievalStatus/retrieval-status.service';
import { FeeAllocationComponent } from './Invoice/Fee-Allocation/fee-allocation.component';
import { AgGridExtensionsModule } from '../Compliance/AgGrid/agGridExtensions.module';
import { AgGridModule } from 'ag-grid-angular';
import { RevenueShareGridActionCellRendererComponent } from './Invoice/Fee-Allocation/agGridActionCellRendererComponent';
import { AgGridCostCenterCellRenderer } from './Invoice/Fee-Allocation/agGridCostCenterCellRenderer';
import { AgGridEngagementNumberCellRenderer } from './Invoice/Fee-Allocation/agGridEngagementNumberCellRenderer';
import { AgGridExpenseAmountCellRenderer } from './Invoice/Fee-Allocation/agGridExpenseAmountCellRenderer';
import { AgGridPercentAllocationCellRenderer } from './Invoice/Fee-Allocation/agGridPercentAllocationCellRenderer';
import { TransmittalModule } from './Transmittal/transmittal.module';
import { InvoicePanelComponent } from './Invoice/invoice-panel.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        WeissmanSharedModule,
        TransmittalModule,
        SharedPipesModule,
        ModalModule,
        AnnualDetailsModule,
        PopoverModule,
        TooltipModule,
        TypeaheadModule.forRoot(),
        CurrencyMaskModule,
        AgGridExtensionsModule,
        TeamModule,
        AgGridModule.withComponents([
            RevenueShareGridActionCellRendererComponent,
            AgGridCostCenterCellRenderer,
            AgGridEngagementNumberCellRenderer,
            AgGridExpenseAmountCellRenderer,
            AgGridPercentAllocationCellRenderer
        ]),
    ],
    declarations: [
        EntityEditComponent,
        ChangeDueDateModal,
        ProcessInvoiceComponent,
        FeeAllocationComponent,
        InvoiceDetailsComponent,
        InvoiceRecipientLookupComponent,
        SetDocumentRetrievalStatusModalComponent,
        RevenueShareGridActionCellRendererComponent,
        AgGridCostCenterCellRenderer,
        AgGridEngagementNumberCellRenderer,
        AgGridExpenseAmountCellRenderer,
        AgGridPercentAllocationCellRenderer,
        InvoicePanelComponent
    ],
    providers: [
        RetrievalStatusModalService,
        RetrievalStatusService,
        Constants,
        InvoiceService,
        DecimalPipe,
        CurrencyPipe
    ],
    exports: [
        InvoicePanelComponent
    ]
})
export class ProcessingModule {
    static setupModule(): void {
        TransmittalModule.setupModule();

        let hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Provider('retrievalStatusModalService', RetrievalStatusModalService);
        // Angular 2+ components we're making available for AngularJS
        hybridAdapterUtility.downgradeNg2Component('processingEntityEdit', EntityEditComponent);
        hybridAdapterUtility.downgradeNg2Component('changeDueDateModal', ChangeDueDateModal);
        hybridAdapterUtility.downgradeNg2Component('invoice', ProcessInvoiceComponent);
        hybridAdapterUtility.downgradeNg2Component('invoicePanel', InvoicePanelComponent);

    }
}
