import { Component, OnInit, OnDestroy} from '@angular/core';
import { Subject } from 'rxjs';
import { ReturnService, ReturnServiceSharedState } from '../../return.service';
import { FilingControlGroupBy, ReturnOverviewService } from './returnOverview.service';
import { IReturnPartComponent, ReturnPartServiceBase } from '../../Models/returnPartServiceBase';
import { ReconciliationReport } from '../../Models/reconciliationReport';
import { RETURN_OVERVIEW_COMPONENT_HELP } from './returnOverview.component.help';
import { HelpService } from '../../../../UI-Lib/Help-Tooltip';
import { UntypedFormControl } from '@angular/forms';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ReturnAssetsService, ReturnAssetsServiceSharedState } from '../Assets/returnAssets.service';
import * as _ from 'lodash';

@Component({
    selector: 'return-overview',
    templateUrl: './returnOverview.component.html'
})
export class ReturnOverviewComponent implements OnInit, OnDestroy, IReturnPartComponent {
    constructor(
        private readonly _returnService: ReturnService,
        private readonly _returnOverviewService: ReturnOverviewService,
        private readonly _helpService: HelpService,
        private readonly _returnAssetsService: ReturnAssetsService
    ) {}

    selectedReconciliationReport: ReconciliationReport = null;

    hideEmptySchedules: boolean = true;
    groupByAssessor: boolean = false;
    groupByConsolidated: boolean = false;
    isReturnConsolidated: boolean = false;

    groupBy: UntypedFormControl = new UntypedFormControl(FilingControlGroupBy.None);
    groupOptions: Compliance.NameValuePair<FilingControlGroupBy>[] = [
        { name: 'None', value: FilingControlGroupBy.None },
        { name: 'Assessor', value: FilingControlGroupBy.Assessor }
    ];

    readonly returnSummaryReconciliationReport: ReconciliationReport = {
        name: 'Return Summary',
        value: 0,
        allowPrimaryForReturn: true
    };

    readonly scheduleAdditionsDisposalsReconciliationReport: ReconciliationReport = {
        name: 'Schedule Additions and Disposals',
        value: 1,
        allowPrimaryForReturn: false
    };

    readonly filingControlReconciliationReport: ReconciliationReport = {
        name: 'Filing Control',
        value: 2,
        allowPrimaryForReturn: true
    };

    private _reconciliationReports: ReconciliationReport[] = [
        this.returnSummaryReconciliationReport,
        this.scheduleAdditionsDisposalsReconciliationReport,
        this.filingControlReconciliationReport
    ];

    private _localReturnServiceSharedState: ReturnServiceSharedState;
    private _localReturnAssetsServiceSharedState: ReturnAssetsServiceSharedState;
    private _hasForms: boolean = false;
    private _consolidatedOption: Compliance.NameValuePair<FilingControlGroupBy> = { name: 'Consolidated Returns', value: FilingControlGroupBy.Consolidated };
    private _destroy$: Subject<void> = new Subject();

    get returnPartServiceForInterPartComponent(): ReturnPartServiceBase { return this._returnOverviewService; }

    get reconciliationReports(): ReconciliationReport[] {
        return _.filter(this._reconciliationReports, x => x.allowPrimaryForReturn || this._hasForms);
    }

    ngOnInit(): void {
        this._helpService.setContent(RETURN_OVERVIEW_COMPONENT_HELP);

        this._returnOverviewService.subscribeToServiceActivationCycle(this);
    }

    ngOnDestroy(): void {
        this._returnOverviewService.unsubscribeFromServiceActivationCycle(this);
    }

    async onReturnPartServiceActivated(): Promise<void> {
        this._returnService.formRevisionId$
            .pipe(takeUntil(this._destroy$), distinctUntilChanged())
            .subscribe(() => this._refreshReconciliationReports());

        this._returnService.returns$
            .pipe(takeUntil(this._destroy$))
            .subscribe((returns) => {
            if (returns) {
                if (this._returnService.isConsolidatedReturn && !this.groupOptions.includes(this._consolidatedOption)) {
                    this.groupOptions.push(this._consolidatedOption);
                }
                this._refreshReconciliationReports();
            }
        });
    }

    onReturnPartServiceDeactivated(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    onSelectedReconciliationReportChange(report: ReconciliationReport): void {
        this.selectedReconciliationReport = report;
        this._returnOverviewService.setReconciliationReport(this.selectedReconciliationReport);
        this._refreshReconciliationReports();
    }

    private async _refreshReconciliationReports(): Promise<void> {
        const returnServiceSharedState: ReturnServiceSharedState = this._returnService.getSharedStateClone();
        const returnAssetsServiceSharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();

        // get input parameters for comparison
        const localReturnIds = (this._localReturnServiceSharedState && this._localReturnServiceSharedState.returns && this._localReturnServiceSharedState.returns.map(x => x.returnId)) || [];
        const sharedReturnIds = returnServiceSharedState.returns.map(x => x.returnId);

        const localFormRevisionId = this._localReturnServiceSharedState && this._localReturnServiceSharedState.formRevisionId;
        const sharedFormRevisionId = returnServiceSharedState.formRevisionId;

        const localReturnFormRevisionsUpdatedTimestamp = this._localReturnServiceSharedState && this._localReturnServiceSharedState.returnFormRevisionsUpdatedTimestamp;
        const sharedReturnFormRevisionsUpdatedTimestamp = returnServiceSharedState.returnFormRevisionsUpdatedTimestamp;

        const localAssetsUpdatedTimestamp = this._localReturnAssetsServiceSharedState && this._localReturnAssetsServiceSharedState.assetDetailsUpdatedTimestamp;
        const sharedAssetsUpdatedTimestamp = returnAssetsServiceSharedState && returnAssetsServiceSharedState.assetDetailsUpdatedTimestamp;

        const localAssetMappingsUpdatedTimestamp = this._localReturnAssetsServiceSharedState && this._localReturnAssetsServiceSharedState.assetMappingsUpdatedTimestamp;
        const sharedAssetMappingsUpdatedTimestamp = returnAssetsServiceSharedState && returnAssetsServiceSharedState.assetMappingsUpdatedTimestamp;

        // check to see if input parameters have changed
        if (!_.isEqual(localReturnIds, sharedReturnIds) ||
            !_.isEqual(localFormRevisionId, sharedFormRevisionId) ||
            !_.isEqual(localReturnFormRevisionsUpdatedTimestamp, sharedReturnFormRevisionsUpdatedTimestamp) ||
            !_.isEqual(this.selectedReconciliationReport, this._returnOverviewService.sharedState.reconciliationReport) ||
            !_.isEqual(localAssetsUpdatedTimestamp, sharedAssetsUpdatedTimestamp) ||
            !_.isEqual(localAssetMappingsUpdatedTimestamp, sharedAssetMappingsUpdatedTimestamp)) {
            this._persistLocalState();

            this.selectedReconciliationReport = this.reconciliationReports.find(x => x === this._returnOverviewService.sharedState.reconciliationReport) || this.reconciliationReports[0];
            this._hasForms = !!this._returnService.getUniqueAssociatedReturnFormRevisions().length;

            this._returnOverviewService.setReconciliationReport(this.selectedReconciliationReport);
        }
    }

    private _persistLocalState(): void {
        this._localReturnServiceSharedState = this._returnService.getSharedStateClone();
        this._localReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
    }
}
