import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Ng2StateDeclaration, UIRouter } from '@uirouter/angular';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ProductAnalyticsService } from '../Common/Amplitude/productAnalytics.service';
import { FeatureFlagsService } from '../Common/FeatureFlags/feature-flags-service';
import { InstanceRights, RestrictService, Roles } from '../Common/Permissions/restrict.service';
import { WeissmanModalService } from '../Compliance/WeissmanModalService';
import { LaunchAddCompanyModalService } from '../Entity/Company/Add/launchAddCompanyModal.service';
import { AddInstanceModalComponent } from '../Entity/Instance/Add/addInstanceModal.component';
import { InstanceRepository } from '../Entity/Instance/instance.repository';
import { TopNavRoles, TopNavService } from '../Navigation/Top-Nav/topNav.service';
import { UserInstanceService } from '../User/userInstance.service';

interface AdminOption {
    name: string;
    route: string;
    isShown: () => boolean;
    isDisabled?: () => boolean;
    amplitudeProperty?: { event: string, properties?: any };
}

interface AdminGroup {
    group: string;
    items: AdminOption[];
}

@Component({
    selector: 'admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _featureFlagsService: FeatureFlagsService,
        private readonly _router: UIRouter,
        private readonly _modalService: WeissmanModalService,
        private readonly _launchAddCompanyModalService: LaunchAddCompanyModalService,
        private readonly _restrictService: RestrictService,
        private readonly _userInstanceService: UserInstanceService,
        private readonly _topNavService: TopNavService,
        private readonly _instanceRepository: InstanceRepository,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) {
    }

    adminControl: FormControl<AdminOption> = new FormControl();
    adminOptions: AdminGroup[] = [];

    roles: TopNavRoles;

    hasAddNewCompanyRole: boolean;
    hasAddNewInstanceRole: boolean;
    isSingleInstanceSelected: boolean;
    showManageInstancesMenuItem: boolean;

    private _enableLicensedDocumentIntakeAndProcessing: boolean;

    private _allManageOptions: AdminGroup[] = [
        {
            group: 'General',
            items: [
                {
                    name: 'Contacts',
                    route: 'manage.contacts',
                    isShown: () => this.roles.hasContactRoles,
                    amplitudeProperty: { event: 'click-manage-admin-contacts' }
                },
                {
                    name: 'Forms',
                    route: 'manage.forms',
                    isShown: () => this.roles.hasComplianceSetupsView || this.roles.hasComplianceSetupsEdit,
                    amplitudeProperty: { event: 'click-manage-admin-forms' }
                },
                {
                    name: 'Factor Tables',
                    route: 'manage.factorTables',
                    isShown: () => this.roles.hasComplianceSetupsView || this.roles.hasComplianceSetupsEdit,
                    amplitudeProperty: { event: 'click-manage-admin-factor-tables' }
                },
                {
                    name: 'Action View Output Fields',
                    route: 'manage.actionViewOutputFields',
                    isShown: () => true,
                    amplitudeProperty: { event: 'click-manage-AV-output' }
                },
                {
                    name: 'About',
                    route: 'manage.about',
                    isShown: () => this.roles.hasAdminOperationsView || this.roles.hasAdminOperationsEdit
                }
            ]
        },
        {
            group: 'Imports',
            items: [
                {
                    name: 'Import Assessments',
                    route: 'manage.importAssessments',
                    isShown: () => this.roles.hasAllowDataImports,
                    amplitudeProperty: { event: 'click-manage-admin-import', properties: { manageAdminImport: 'assessments' } }
                },
                {
                    name: 'Import Sites',
                    route: 'manage.importSites',
                    isShown: () => this.roles.hasAllowDataImports,
                    amplitudeProperty: { event: 'click-manage-admin-import', properties: { manageAdminImport: 'sites' } }
                },
                {
                    name: 'Import Parcels',
                    route: 'manage.importParcels',
                    isShown: () => this.roles.hasAllowDataImports,
                    amplitudeProperty: { event: 'click-manage-admin-import', properties: { manageAdminImport: 'parcels' } }
                },
                {
                    name: 'Data Imports',
                    route: 'manage.dataImports',
                    isShown: () => this.roles.hasAllowDataImports,
                    amplitudeProperty: { event: 'click-manage-admin-import', properties: { manageAdminImport: 'data' } }
                }
            ]
        },
        {
            group: 'Info & Management',
            items: [
                {
                    name: 'Property Characteristics',
                    route: 'manage.propertyCharacteristics',
                    isShown: () => this.roles.hasPropCharsRoles,
                    amplitudeProperty: { event: 'click-manage-admin-property-characteristics' }
                },
                {
                    name: 'User Groups',
                    route: 'manage.userGroups',
                    isShown: () => this.roles.hasUserGroupViewOrEdit,
                    isDisabled: () => !this.roles.hasUserGroupEdit,
                    amplitudeProperty: { event: 'click-manage-admin-user-groups' }
                },
                {
                    name: 'Roles',
                    route: 'manage.roles',
                    isShown: () => this.roles.hasContactRoleRoles,
                    amplitudeProperty: { event: 'click-manage-admin-roles' }
                },
                {
                    name: 'Agents',
                    route: 'manage.agents',
                    isShown: () => this.roles.hasAgentInfo,
                    amplitudeProperty: { event: 'click-manage-admin-agents' }
                },
                {
                    name: 'Teams',
                    route: 'manage.teams',
                    isShown: () => this.roles.hasTeamViewOrEdit,
                    amplitudeProperty: { event: 'click-manage-admin-teams' }
                },
                {
                    name: 'Manage API Keys',
                    route: 'manage.manageApiKeys',
                    isShown: () => this.roles.hasManageAPIKeys
                },
                {
                    name: 'Manage Consulting Engagements',
                    route: 'manage.manageConsultingEngagements',
                    isShown: () => this.isSingleInstanceSelected
                        && this._userInstanceService.getSelectedInstance().offersConsultingServices
                        && this._restrictService.hasInstanceRight(InstanceRights.MANAGECONSULTINGENGAGEMENT, this._userInstanceService.getSelectedInstance().instanceId)
                },
                {
                    name: 'Manage Licensed Client Documents',
                    route: 'manage.manageLicensedClientDocuments',
                    isShown: () => this.roles.hasLicensedDocumentServices && this._enableLicensedDocumentIntakeAndProcessing,
                },
                {
                    name: 'Manage Instance Settings',
                    route: 'manage.manageInstanceSettings',
                    isShown: () => this.roles.hasManageConsultingEngagements,
                    amplitudeProperty: { event: 'click-manage-admin-instance-settings' }
                },
                {
                    name: 'Manage My Instances',
                    route: 'manage.manageInstances',
                    isShown: () => this.showManageInstancesMenuItem,
                    amplitudeProperty: { event: 'click-manage-my-instances' }
                },
                {
                    name: 'Manage Support Instances',
                    route: 'manage.manageSupportInstances',
                    isShown: () => this.roles.hasSupportRole
                },
                {
                    name: 'Valuation Templates',
                    route: 'manage.valuationTemplates',
                    isShown: () => this.roles.hasValuationTemplateEdit,
                    amplitudeProperty: { event: 'click-manage-admin-valuation-templates' }
                }
            ]
        },
        {
            group: 'Command Centers',
            items: [
                {
                    name: 'State & Jurisdiction Command Center',
                    route: 'manage.stateJurisdictionCommandCenter',
                    isShown: () => this.roles.hasViewRyanPrivatePermissions || this.roles.hasEditRyanPrivatePermissions,
                    amplitudeProperty: { event: 'click-manage-admin-state-juris-CC' }
                },
                {
                    name: 'Tax Rate Command Center',
                    route: 'manage.taxRateCommandCenter',
                    isShown: () => this.roles.hasViewRyanPrivatePermissions || this.roles.hasEditRyanPrivatePermissions,
                    amplitudeProperty: { event: 'click-manage-admin-tax-rate-CC' }
                },
                {
                    name: 'Payment Batch Command Center',
                    route: 'manage.paymentBatchCommandCenter',
                    isShown: () => this._featureFlagsService.featureFlags.enablePaymentBatch && (this.roles.hasViewRyanPrivatePermissions || this.roles.hasEditRyanPrivatePermissions),
                    amplitudeProperty: { event: 'click-manage-payment-batch-CC' }
                },
                {
                    name: 'Appeal Recommendation Command Center',
                    route: 'manage.appealRecommendationCommandCenter',
                    isShown: () => this.roles.hasViewRyanPrivatePermissions || this.roles.hasEditRyanPrivatePermissions
                    // amplitudeProperty: { event: 'click-manage-admin-valuation-templates' }
                },
            ]
        }
    ];

    private _destroy$: Subject<void> = new Subject<void>();
    private _destroyTransitionHook: Function;

    async ngOnInit(): Promise<void> {
        this.roles = this._topNavService.roles();
        this._enableLicensedDocumentIntakeAndProcessing = this._featureFlagsService.featureFlags.enableLicensedDocumentIntakeAndProcessing;

        this.hasAddNewCompanyRole = this._restrictService.hasInstanceRight(InstanceRights.ADDNEWCOMPANY);
        this.hasAddNewInstanceRole = this._restrictService.isInRole(Roles.INSTANCEADMIN);

        this.isSingleInstanceSelected = this._userInstanceService.isSingleInstanceSelected();

        if (!this.roles.hasAdminRole && !this.roles.hasAddNewCompanyRole && !this.roles.hasAddNewInstanceRole) {
            this._router.stateService.go('home', {});
        }
        this.showManageInstancesMenuItem = (await this._instanceRepository.getCurrentUsersContactInviteStatuses()).length > 1;

        this.adminOptions = this._allManageOptions.map(x => {
            return {
                group: x.group,
                items: x.items.filter(y => y.isShown())
            };
        });

        this._setAdminControlValue(this._router.globals.current);

        this._destroyTransitionHook = this._router.transitionService.onSuccess({}, (transition) => {
            this._setAdminControlValue(this._router.globals.current);
        });

        this.adminControl.valueChanges
            .pipe(takeUntil(this._destroy$), distinctUntilChanged())
            .subscribe((value) => {
                if (value.amplitudeProperty) {
                    this._productAnalyticsService.logEvent(value.amplitudeProperty.event, value.amplitudeProperty.properties);
                }
                this._router.stateService.transitionTo(value.route, {});
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._destroyTransitionHook();
    }

    async newInstance(): Promise<void> {
        const result = await this._modalService.showAsync(AddInstanceModalComponent, {}, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }
    }

    async newCompany(): Promise<void> {
        await this._launchAddCompanyModalService.addCompany();
    }

    private _setAdminControlValue(routerState: Ng2StateDeclaration): void {
        if (routerState.name === 'manage') {
            return;
        }
        const option = this.adminOptions
            .reduce((acc, x) => acc = [...acc, ...x.items], [])
            .find(y => y.route === routerState.name);
        if (option) {
            this.adminControl.setValue(option);
        } else {
            console.warn('No route found for', routerState.name);
            this._router.stateService.go('home', {});
        }
    }
}
