import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { UserGroupsService } from '../../userGroup.service';
import { NavigationService } from '../../../Layout/Navigation.Service.upgrade';
import { FeatureFlagsService } from '../../../Common/FeatureFlags/feature-flags-service';
import { RyanInstanceId } from '../../../constants.new';
import { UserInstanceService } from '../../../User/userInstance.service';
import { UserGroupPanel } from '../userGroupDetail.component';

enum UserGroupPermissions {
    View = 'View',
    Edit = 'Edit',
    None = 'None'
}
@Component({
    selector: 'user-group-info',
    templateUrl: './userGroupInfo.component.html'
})
export class UserGroupInfoComponent implements OnInit {
    constructor(
        private readonly _fb: UntypedFormBuilder,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _userGroupsService: UserGroupsService,
        private readonly _navigationService: NavigationService,
        private readonly _featureFlagService: FeatureFlagsService,
        private readonly _userInstanceService: UserInstanceService
    ) { }

    @Input() userGroup: Core.GroupModel;
    @Input() canEdit: boolean;
    @Output() expandClicked: EventEmitter<UserGroupPanel> = new EventEmitter<UserGroupPanel>();

    editMode: boolean = false;
    canEnterEditMode: boolean = true;
    infoForm: UntypedFormGroup;
    isExpanded: boolean = false;
    permissionOptions: string[] = Object.values(UserGroupPermissions);
    canUncheckIsConsultantGroup: boolean;
    showSupportGroupCheckbox: boolean;
    showDocumentsGroupCheckbox: boolean;

    private _initialValues: AbstractControl;

    async ngOnInit(): Promise<void> {
        this._initForm();
    }

    edit() {
        this.infoForm.enable();
        if (!this.canUncheckIsConsultantGroup){
            this.infoForm.controls['isConsultantGroup'].disable();
        }
        this._userGroupsService.setPanelEdit(true);
        this._navigationService.enableNavWarning('Editing is in progress. Are you sure you want to leave?');
    }

    cancel() {
        this.infoForm.reset(this._initialValues);
        this._exitEditMode();
    }

    async onSubmit() {
        _.assign(this.userGroup, _.omit(this.infoForm.value, 'defaultPermission'));
        this.userGroup.grantReadToNewCompanies = this.infoForm.value.defaultPermission !== UserGroupPermissions.None;
        this.userGroup.grantWriteToNewCompanies = this.infoForm.value.defaultPermission === UserGroupPermissions.Edit;

        const busyRef = this._busyIndicatorService.show({ message: 'Updating Group Info' });
        try {
            await this._userGroupsService.update(this.userGroup as Core.GroupModel);
            this._initialValues = this.infoForm.value;
            this._exitEditMode();
        } catch (e2) {
            if (e2 && e2.status !== 422) {
                return Promise.reject(e2);
            }
        }
        finally {
            await busyRef.hide();
        }
    }

    expandComponent() {
        this.expandClicked.emit('userGroupInfo');
        this.isExpanded = true;
    }

    collapseComponent() {
        this.expandClicked.emit('userGroupInfo');
        this.isExpanded = false;
    }

    private _initForm() {
        this.canUncheckIsConsultantGroup = !this.userGroup.isConsultantGroup || !this.userGroup.consultingEngagementsCount;
        this.showDocumentsGroupCheckbox = this._featureFlagService.featureFlags.enableLicensedDocumentIntakeAndProcessing &&
            this._userInstanceService.RyanInstanceId === this._userInstanceService.getSelectedInstance().instanceId;

        //support group checkbox settings
        this.showSupportGroupCheckbox = this.userGroup.instanceID !== RyanInstanceId;

        this.infoForm = this._fb.group({
            name: [this.userGroup.name, Validators.required],
            description: [this.userGroup.description],
            grantReadToNewCompanies: [this.userGroup.grantReadToNewCompanies],
            grantWriteToNewCompanies: [this.userGroup.grantWriteToNewCompanies],
            defaultPermission: [this._getDefaultPermission()],
            isConsultantGroup: [this.userGroup.isConsultantGroup],
            isSupportGroup: [this.userGroup.isSupportGroup],
            isDocumentsGroup: [this.userGroup.isDocumentsGroup]
        });

        this._initialValues = this.infoForm.value;
        this.infoForm.disable();
    }


    get isConsultantGroupTitle(): string {
        return this.infoForm.enabled && !this.canUncheckIsConsultantGroup
            ? 'User group assigned to one or more consulting engagements.'
            : '';
    }

    private _getDefaultPermission() {
        if(this.userGroup.grantWriteToNewCompanies) {
            return UserGroupPermissions.Edit;
        } else if (this.userGroup.grantReadToNewCompanies) {
            return UserGroupPermissions.View;
        } else {
            return UserGroupPermissions.None;
        }
    }

    private _exitEditMode(): void {
        this.infoForm.disable();
        this._userGroupsService.setPanelEdit(false);
        this._navigationService.disableNavWarning();
    }
}
