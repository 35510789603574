import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductAnalyticsService } from '../../../../Common/Amplitude/productAnalytics.service';
import { RestrictService, Roles } from '../../../../Common/Permissions/restrict.service';
import { SmartSearchService } from '../../smartSearch.service';
import { SearchOperatorsService } from '../../../search.operators.service.upgrade';
import { AdvancedSearchCriteria, AdvancedSearchPersistenceService, AdvancedSearchRouteParams } from '../../advancedSearchPersistence.service';
import { AdvancedSearchRepository } from '../../../../Core-Repositories';

import * as _ from 'lodash';

@Component({
    selector: 'advanced-filter-list',
    templateUrl: './filterList.component.html',
    styleUrls: ['../../../../../styles/utilities/helpers.scss', './filterList.component.scss']
})
export class FilterListComponent implements OnInit {
    constructor(
        private readonly _restrictService: RestrictService,
        private readonly _smartSearchService: SmartSearchService,
        private readonly _searchOperators: SearchOperatorsService,
        private readonly _advancedSearchPersistenceService: AdvancedSearchPersistenceService,
        private readonly _advancedSearchRepository: AdvancedSearchRepository,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) {}

    @Input() routeParams: AdvancedSearchRouteParams;

    @Input()
    set searchCriteria(searchCriteria: AdvancedSearchCriteria) {
        this._searchCriteria = { ...searchCriteria };

        this.outputColumns = this._searchCriteria.outputColumns;
        this.searchFilters = this._searchCriteria.filters;
        this.excludeInactive = this._searchCriteria.excludeInactive;
        this.showOrs = this._searchCriteria.showOrs;
        this.frequentlyUsed = this._searchCriteria.frequentlyUsed;
    }

    @Output() filtersChanged: EventEmitter<void> = new EventEmitter();

    outputColumns: any[] = [];
    searchFilters: any[] = [];
    excludeInactive: boolean;
    showOrs: boolean;
    frequentlyUsed: boolean;

    loadSaveSettings: boolean;
    hasRyanPrivatePermission: boolean;

    private _searchCriteria: AdvancedSearchCriteria;

    get areNoOrs(): boolean {
        return this.searchFilters
            .map(x => x.or)
            .every(orArr => orArr.length < 2);
    }

    ngOnInit(): void {
        this.hasRyanPrivatePermission = this._restrictService.isInRole(Roles.RYANPRIVATEITEMSEDIT) ||
            this._restrictService.isInRole(Roles.RYANPRIVATEITEMSVIEW);

        // Dima: this call is to ensure that the user has permissions to execute the smart search.
        // See issue [WK-3479 In SMART, 'No permission to view this page' warning isn't displayed in the 1st go] for additional details
        if (this.routeParams.searchId !== 0) {
            this._advancedSearchRepository.getOneSearch(Number(this.routeParams.searchId), Number(this.routeParams.searchType));
        }
    }

    addFilters(columns: any): void {
        columns.forEach(column => {
            const filter = { ...column };

            filter.operators = this._searchOperators.getOperatorArray(filter.operators);

            //Temporary workaround. If the Company or Company Name fields are used, we should default to Equals instead
            //This is different than every other column. It's so we can use the company picker
            if (column.columnId === 101 || column.columnId === 109) {
                filter.or = [this._searchOperators.getCompanyOr(filter.operators)];
            } else {
                filter.or = filter.operators.length ? [this._searchOperators.getOr(filter.operators)] : [];
            }

            this.searchFilters.push(filter);

            const notAlreadyAdded = !this.outputColumns.some(x => x.columnId === column.columnId);

            if (notAlreadyAdded) {
                this.outputColumns.push({ ...column });
            }
        });

        this.disableColumns();
        this.updateCriteria();
    }

    disableColumns(): void {
        this._smartSearchService.disableColumns(_.union(this.searchFilters, this.outputColumns));
    }

    updateColumnFilters(filters: any[]): void {
        this.searchFilters = filters;
        this.updateCriteria();
    }

    toggleOrs(): void {
        this.updateCriteria();
        this._productAnalyticsService.logEvent('click-or-toggle', { smartOrToggle: this.showOrs ? 'on' : 'off' });
    }

    updateCriteria(): void {
        this._searchCriteria.outputColumns = this.outputColumns;
        this._searchCriteria.filters = this.searchFilters;
        this._searchCriteria.excludeInactive = this.excludeInactive;
        this._searchCriteria.showOrs = this.showOrs;
        this._searchCriteria.frequentlyUsed = this.frequentlyUsed;

        this._advancedSearchPersistenceService.setSearchCriteria(this._searchCriteria);
        this.filtersChanged.emit();
    }

    async persistSetting(analyticsEvent?: string): Promise<void> {
        this.updateCriteria();
        this.loadSaveSettings = true;
        await this._smartSearchService.persistSetting(this);
        this.loadSaveSettings = false;
        if (analyticsEvent) {
            this._productAnalyticsService.logEvent(analyticsEvent);
        }
    }
}
