<ws-modal-wrapper headerLabel="Filter by Parcel"
                  [hideCancel]="true"
                  [hideCancelX]="true"
                  (save)="updateParcelFilter()">
    <div>
        <label class="pull-right">
            <input type="checkbox" [ngModel]="excludeInactive" (ngModelChange)="updateParcels($event)"> Exclude Inactive
        </label>
    </div>
    <div>
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    </div>
</ws-modal-wrapper>
