import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';
import { SelectConfig, SelectSearch, DropdownPosition } from '../select.interface';
import { TimerService } from '../../Utilities';

const noop = (values: any[], searchValue: string) => [];

interface WindowBuffer {
    leftBuffer: number;
    topBuffer: number;
}

@Component({
    selector: 'ws-select-dropdown',
    templateUrl: './selectDropdown.component.html',
    styleUrls: ['./selectDropdown.component.scss'],
    animations: [
        trigger('dropdownVisible', [
            state('show', style({
                opacity: '1'
            })),
            state('hide', style({
                opacity: '0'
            })),
            transition('show => hide', animate('150ms ease')),
            transition('hide => show', animate('150ms ease'))
        ])
    ]
})
export class SelectDropdownComponent {
    constructor(private readonly _changDetectorRef: ChangeDetectorRef, private readonly _timer: TimerService) { }

    @Input()
    set dropdownConfig(selectConfig: SelectConfig) {
        this.selectConfig = selectConfig;
        if (this.selectConfig.customSearch) {
            this._search.custom = this.selectConfig.customSearch;
        }
        this.search();
    }
    @Input()
    set targetPosition(target: DOMRect) {
        this._targetPosition = target;
        this.updateDropdownPosition();
    }
    @Input()
    set isOpen(open: boolean) {
        this.open = open;
        this._timer.setTimeout(() => {
            if (open && this.selectConfig.canSearch && this.selectConfig.options.length >= this.selectConfig.includeSearchAfterN) {
                this.searchInput.nativeElement.focus();
            }
            const selectedItem = this.dropdown.nativeElement.querySelector('.selected');
            if (selectedItem) {
                this.dropdown.nativeElement.scrollTop = selectedItem.offsetTop - (this.renderedTop ? 0 : 34);
            }
            this.animateComplete = open;
            this._changDetectorRef.detectChanges();
        }, 150);
    }

    @Output() closed = new EventEmitter<SelectConfig>();

    @ViewChild('dropdown', { static: true }) dropdown: ElementRef;
    @ViewChild('searchInput') searchInput: ElementRef;

    selectConfig: SelectConfig;
    open: boolean;
    renderedTop: boolean;
    animateComplete: boolean;
    searchValue: string;
    searchResultCount: number;
    dropdownPosition: DropdownPosition;

    private _showMoreClicked: number = 0;
    private _targetPosition: DOMRect;
    private _edgeBuffer = 10;
    private _search: SelectSearch = {
        exact: (values: any[], searchValue: string) => values.filter(o => {
            const value = (`${(this.selectConfig.isPrimitive) ? o : o[this.selectConfig.labelProperty]}`).toLowerCase();
            return (value) ? value.substring(0, searchValue.length) === searchValue.toLowerCase() : false;
        }),
        fuzzy: (values: any[], searchValue: string) => {
            const pattern = searchValue.split('').reduce((a, b) => (`${a  }[^${  b  }]*${  b}`));
            const regex = new RegExp(pattern);
            return values.filter(o => {
                const value = (this.selectConfig.isPrimitive) ? o : o[this.selectConfig.labelProperty];
                return (value) ? regex.test((`${value}`).toLowerCase()) : false;
            }).sort((a, b) => {
                // Sort by most exact match first
                const valueA = (`${(this.selectConfig.isPrimitive) ? a : a[this.selectConfig.labelProperty]}`).toLowerCase();
                const valueB = (`${(this.selectConfig.isPrimitive) ? b : b[this.selectConfig.labelProperty]}`).toLowerCase();
                const aIncludes = valueA.includes(searchValue.toLowerCase());
                const bIncludes = valueB.includes(searchValue.toLowerCase());
                if (!aIncludes && bIncludes) {
                    return 1;
                } else if (aIncludes && !bIncludes) {
                    return -1;
                }
                return valueA.localeCompare(valueB);
            });
        },
        substring: (values: any[], searchValue: string) => values.filter(o => {
            const value = (`${(this.selectConfig.isPrimitive) ? o : o[this.selectConfig.labelProperty]}`).toLowerCase();
            return (value) ? value.includes(searchValue.toLowerCase()) : false;
        }),
        state: (values: { value: Core.StateModel }[], searchValue: string) => {
            const pattern = searchValue.split('').reduce((a, b) => (`${a  }[^${  b  }]*${  b}`));
            const regex = new RegExp(pattern);
            return values.filter(o => {
                return (o.value.fullName && regex.test(o.value.fullName.toLowerCase())) || (o.value.abbr && regex.test(o.value.abbr.toLowerCase()));
            }).sort((a, b) => {
                // Sort by state abbreviation exact match first
                const aMatch = a.value.abbr.toLowerCase() === searchValue.toLowerCase();
                const bMatch = b.value.abbr.toLowerCase() === searchValue.toLowerCase();
                if (!aMatch && bMatch) {
                    return 1;
                } else if (aMatch && !bMatch) {
                    return -1;
                }
                return a.value.fullName.localeCompare(b.value.fullName);
            });
        },
        custom: noop
    };

    private readonly MAX_ROWS_BEFORE_SCROLLBAR = 7;

    /**
     * Update the position of the dropdown
     */
    updateDropdownPosition(): void {
        // Pre-render to calculate right edge detection accurately
        this._prerender();

        // Calculate actual position
        const boundingBox = this.dropdown.nativeElement.getBoundingClientRect();

        const top = this._targetPosition.y + window.scrollY;
        const left = this._targetPosition.x;

        const buffer = this._getBuffer(boundingBox, this._targetPosition.y, left);

        if (buffer.topBuffer < 0) {
            this._renderToTop();
            return;
        }

        const width = Math.ceil(this._targetPosition.width);
        this.dropdownPosition = {
            left: `${left + buffer.leftBuffer}px`,
            top: `${top}px`,
            width: this._scrollBarWidth(width)
        };
        this.renderedTop = false;
        this._changDetectorRef.detectChanges();
    }

    /**
     * Search the data
     */
    search(): boolean {
        const searchValue = (this.searchValue && this.searchValue.length) ? this.searchValue.trim().toLowerCase() : null;

        const options =  [...this.selectConfig.options];

        let listSize: number;
        if (this.selectConfig.maxListSize) {
            listSize = this.selectConfig.maxListSize + (10 * this._showMoreClicked);
            listSize = listSize > this.selectConfig.options.length ? this.selectConfig.options.length : listSize;
        }

        if (searchValue) {
            const searchResult = (searchValue.length === 1)
                ? this._search.exact(options, searchValue)
                : this._search[this.selectConfig.searchType](options, searchValue);
            this.searchResultCount = searchResult.length;
            this.selectConfig.searchableOptions = (this.selectConfig.maxListSize) ? searchResult.slice(0, listSize) : searchResult;
        } else {
            this.searchResultCount = options.length;
            this.selectConfig.searchableOptions = (this.selectConfig.maxListSize) ? options.slice(0, listSize) : options;
        }

        this._changDetectorRef.detectChanges();

        // prevent event propagation
        return false;
    }

    /**
     * Emit selected option
     * @param option
     */
    optionSelected(option: any): boolean {
        this.selectConfig.selectedOptionChanged(option);

        this.closeDropdown();
        // prevent event propagation
        return false;
    }

    /**
     * Close the dropdown
     */
    closeDropdown(): void {
        this.animateComplete = false;
        this.closed.emit(this.selectConfig);
        this._timer.setTimeout(() => {
            this._resetSearch();
        }, 150);
    }

    /**
     * Show more if the list has a max limit to start
     */
    showMore(event: MouseEvent): boolean {
        event.stopPropagation();
        const currentShownOptions = this.selectConfig.searchableOptions.length;
        this._showMoreClicked++;

        if (this.selectConfig.isShowMoreFromParent) {
            this.selectConfig.showMoreClicked(this._showMoreClicked);
        } else {
            this.search();
        }

        // Create the space for the scrollbar if it is going to show up
        if (currentShownOptions < this.MAX_ROWS_BEFORE_SCROLLBAR && this.selectConfig.searchableOptions.length > this.MAX_ROWS_BEFORE_SCROLLBAR) {
            this.updateDropdownPosition();
        }
        // prevent event propagation
        return false;
    }

    /**
     * Reset the search
     */
    private _resetSearch(): void {
        this.searchValue = '';
        this.search();
    }

    /**
     * Flip the dropdown to the top
     */
    private _renderToTop(): void {
        this._prerender();

        const boundingBox = this.dropdown.nativeElement.getBoundingClientRect();

        const top = this._targetPosition.y + window.scrollY - boundingBox.height;
        const left = this._targetPosition.x - Math.floor((boundingBox.width - this._targetPosition.width) / 2);

        const buffer = this._getBuffer(boundingBox, top, left);

        this.dropdownPosition = {
            left: `${left + buffer.leftBuffer}px`,
            top: `${top + this._targetPosition.height}px`,
            width: this._scrollBarWidth(this._targetPosition.width)
        };

        this.renderedTop = true;
        this._changDetectorRef.detectChanges();
    }

    /**
     * Pre-render to calculate right edge detection accurately
     */
    private _prerender(): void {
        this.dropdownPosition = {
            left: `${window.innerWidth / 2 - 130}px`,
            top: '10px',
            width: this._scrollBarWidth(this._targetPosition.width)
        };
        this._changDetectorRef.detectChanges();
    }

    /**
     * Check distance to edge of screen
     * @param element
     * @param idealTop
     * @param idealLeft
     */
    private _getBuffer(element: DOMRect, idealTop: number, idealLeft: number): WindowBuffer {
        const wY = window.innerHeight;
        const wX = window.innerWidth;

        const closestXEdgeDistance = (idealLeft < (wX - (idealLeft + element.width))) ? idealLeft : (wX - (idealLeft + element.width));
        const closestYEdgeDistance = (idealTop < (wY - (idealTop + element.height))) ? idealTop : (wY - (idealTop + element.height));
        const isXAdditive = idealLeft < (wX - (idealLeft + element.width));
        const isYAdditive = idealTop < (wY - (idealTop + element.height));

        let xBuffer = 0;
        if (closestXEdgeDistance < this._edgeBuffer) {
            xBuffer = this._edgeBuffer - closestXEdgeDistance;
            xBuffer = (isXAdditive) ? xBuffer : -xBuffer;
        }
        let yBuffer = 0;
        if (closestYEdgeDistance < this._edgeBuffer) {
            yBuffer = this._edgeBuffer - closestYEdgeDistance;
            yBuffer = (isYAdditive) ? yBuffer : -yBuffer;
        }

        return {
            leftBuffer: xBuffer,
            topBuffer: yBuffer
        };
    }
    private _scrollBarWidth(width: number): string {
        if(width === 0) {
            return `${width}px`;
        }

        width += 3;

        // Add 15 for scrollbar
        return (this.selectConfig.searchableOptions.length > this.MAX_ROWS_BEFORE_SCROLLBAR) ? `${width + 15}px` : `${width}px`;
    }

}
