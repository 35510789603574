import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ScheduleAdditionsDisposalsReconciliationReport, ScheduleAdditionsDisposalReconciliationReportRow } from '../../../Models/scheduleAdditionsDisposalsReconciliationReport';
import { ReturnServiceSharedState, ReturnService } from '../../../return.service';
import { ReturnAssetsService, ReturnAssetsServiceSharedState } from '../../Assets/returnAssets.service';
import { ReturnOverviewService, ReturnOverviewServiceSharedState } from '../returnOverview.service';
import { IReturnPartComponent } from '../../../Models/returnPartServiceBase';
import { Subject } from 'rxjs';
import { WeissmanModalService } from '../../../../WeissmanModalService';
import { ReturnAssetDisposalsListParams, ReturnAssetDisposalsListComponent } from '../../Assets/Asset-Disposals-List/returnAssetDisposalsList.component';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
    selector: 'schedule-additions-disposals-reconciliation-report',
    templateUrl: './scheduleAdditionsDisposals.component.html'
})
export class ScheduleAdditionsDisposalsReconciliationReportComponent implements OnInit, OnDestroy, IReturnPartComponent {
    constructor(
        private readonly _returnService: ReturnService,
        private readonly _returnAssetsService: ReturnAssetsService,
        private readonly _returnOverviewService: ReturnOverviewService,
        private readonly _modalService: WeissmanModalService) { }

    @Input() hideEmptySchedules: boolean;

    isBusy: boolean;
    data: ScheduleAdditionsDisposalsReconciliationReport;
    canNavigate: boolean = false;

    private _localReturnServiceSharedState: ReturnServiceSharedState;
    private _localReturnOverviewServiceSharedState: ReturnOverviewServiceSharedState;
    private _localReturnAssetsServiceSharedState: ReturnAssetsServiceSharedState;

    private _destroy$: Subject<void> = new Subject();

    get reportableSchedules(): ScheduleAdditionsDisposalReconciliationReportRow[] {
        return this.data &&
            _.filter(this.data.reportableSchedules,
            x => {
                if (this.hideEmptySchedules) {
                    return (x.cost || x.priorCost || x.additions || x.disposals) ? true : false;
                } else {
                    return true;
                }
            });
    }

    ngOnInit(): void {
        this._returnOverviewService.subscribeToServiceActivationCycle(this);
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._returnOverviewService.unsubscribeFromServiceActivationCycle(this);
    }

    async onReturnPartServiceActivated(): Promise<void> {
        await this._refreshReport();

        this._returnOverviewService.reconciliationReport$.pipe(takeUntil(this._destroy$))
            .subscribe(() => this._refreshReport());

        this._returnService.formRevisionId$
            .pipe(takeUntil(this._destroy$), distinctUntilChanged())
            .subscribe((id) => this._loadReport());
    }

    onReturnPartServiceDeactivated(): void {
        this._destroy$.next();
    }

    navigateToReturnTotals(
        row: ScheduleAdditionsDisposalReconciliationReportRow,
        isForAdditions: boolean,
        isForNonReportableAssets: boolean,
        isForReportableAssets: boolean,
        isForUnassignedAssets: boolean
    ): void {
        const sharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();

        if (isForAdditions) {
            sharedState.assetStatusesFilter = [
                Compliance.ReturnAssetPriorReturnStatusEnum.New,
                Compliance.ReturnAssetPriorReturnStatusEnum.TransferIn,
                Compliance.ReturnAssetPriorReturnStatusEnum.ChangedCostIncreased,
                Compliance.ReturnAssetPriorReturnStatusEnum.ChangedScheduleNonReportableToReportable
            ];
        } else {
            sharedState.assetStatusesFilter = [];
        }

        if (isForNonReportableAssets) {
            sharedState.scheduleAndFactorFilter = {
                showOnlyNotAssignedToASchedule: false,
                showOnlyAssignedToNonReportableSchedule: true,
                showOnlyAssignedToReportableSchedule: false,
                formRevisionScheduleIds: [],
                depreciationFactorId: null,
                scheduleAge: null
            };
        } else if (isForReportableAssets) {
            sharedState.scheduleAndFactorFilter = {
                showOnlyNotAssignedToASchedule: false,
                showOnlyAssignedToNonReportableSchedule: false,
                showOnlyAssignedToReportableSchedule: true,
                formRevisionScheduleIds: [],
                depreciationFactorId: null,
                scheduleAge: null
            };
        }
        else if (isForUnassignedAssets) {
            sharedState.scheduleAndFactorFilter = {
                showOnlyNotAssignedToASchedule: true,
                showOnlyAssignedToNonReportableSchedule: false,
                showOnlyAssignedToReportableSchedule: false,
                formRevisionScheduleIds: [],
                depreciationFactorId: null,
                scheduleAge: null
            };
        } else if(row) {
            sharedState.scheduleAndFactorFilter = {
                showOnlyNotAssignedToASchedule: false,
                showOnlyAssignedToNonReportableSchedule: false,
                showOnlyAssignedToReportableSchedule: false,
                formRevisionScheduleIds: row.formRevisionScheduleId ? [row.formRevisionScheduleId] : [],
                depreciationFactorId: null,
                scheduleAge: null
            };
            sharedState.separateFactorTablesFilter = false;
            if (row.assetsCount === 0) sharedState.hideEmptySchedulesFilter = false;
        } else {
            sharedState.scheduleAndFactorFilter = {
                showOnlyNotAssignedToASchedule: false,
                showOnlyAssignedToNonReportableSchedule: false,
                showOnlyAssignedToReportableSchedule: false,
                formRevisionScheduleIds: [],
                depreciationFactorId: null,
                scheduleAge: null
            };
        }

        this._returnAssetsService.setScheduleAndFactorFilter(sharedState.scheduleAndFactorFilter);
        this._returnOverviewService.notifyReturnAssetsSelected(sharedState);
    }

    async navigateToReturnDisposals(row: ScheduleAdditionsDisposalReconciliationReportRow, targetReportableSchedule: boolean, targetNonReportableSchedule: boolean): Promise<void> {
        const params: ReturnAssetDisposalsListParams = {
            priorReturnStatuses: [
                Compliance.ReturnAssetPriorReturnStatusEnum.Retired,
                Compliance.ReturnAssetPriorReturnStatusEnum.TransferOut,
                Compliance.ReturnAssetPriorReturnStatusEnum.ChangedCostDecreased
            ],
            formRevisionScheduleId: null,
            targetNonReportableSchedule: targetNonReportableSchedule,
            targetReportableSchedule: targetReportableSchedule,
            isDisposalOrTransferOut: false
        };

        if (row) {
            params.formRevisionScheduleId = row.formRevisionScheduleId;
        }

        const result = await this._modalService.showAsync(ReturnAssetDisposalsListComponent, params, 'modal-xl');

        if (result) {
            this._loadReport();
        }
    }

    private _refreshCanNavigate(): void {
        this.canNavigate = this._localReturnServiceSharedState && this._localReturnServiceSharedState.formRevisionId ? true : false;
    }

    private async _refreshReport(): Promise<void> {
        const returnServiceSharedState: ReturnServiceSharedState = this._returnService.getSharedStateClone();
        const returnAssetsServiceSharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
        const returnOverviewServiceSharedState: ReturnOverviewServiceSharedState = this._returnOverviewService.getSharedStateClone();

        // get input parameters for comparison
        const localReturnIds = (this._localReturnServiceSharedState && this._localReturnServiceSharedState.returns && this._localReturnServiceSharedState.returns.map(x => x.returnId)) || [];
        const sharedReturnIds = returnServiceSharedState.returns.map(x => x.returnId);

        const localReconciliationReport = this._localReturnOverviewServiceSharedState && this._localReturnOverviewServiceSharedState.reconciliationReport;
        const sharedReconciliationReport = returnOverviewServiceSharedState.reconciliationReport;

        const localAssetDetailsUpdatedTimestamp = this._localReturnAssetsServiceSharedState && this._localReturnAssetsServiceSharedState.assetDetailsUpdatedTimestamp;
        const sharedAssetDetailsUpdatedTimestamp = returnAssetsServiceSharedState.assetDetailsUpdatedTimestamp;

        const localAssetMappingsUpdatedTimestamp = this._localReturnAssetsServiceSharedState && this._localReturnAssetsServiceSharedState.assetMappingsUpdatedTimestamp;
        const sharedAssetMappingsUpdatedTimestamp = returnAssetsServiceSharedState.assetMappingsUpdatedTimestamp;

        // check to see if input parameters are available
        if (!sharedReturnIds.length || sharedReconciliationReport === null || !returnServiceSharedState.formRevisionId) {
            this._blankReport();
            return;
        }

        // check to see if input parameters have changed
        if (
            !_.isEqual(localReturnIds, sharedReturnIds) ||
            !_.isEqual(localReconciliationReport, sharedReconciliationReport) ||
            !_.isEqual(localAssetDetailsUpdatedTimestamp, sharedAssetDetailsUpdatedTimestamp) ||
            !_.isEqual(localAssetMappingsUpdatedTimestamp, sharedAssetMappingsUpdatedTimestamp)
        ) {
            await this._loadReport();
        }
    }

    private _blankReport(): void{
        this._persistState();
        this.data = null;
        this._refreshCanNavigate();
    }

    private _persistState(): void {
        this._localReturnServiceSharedState = this._returnService.getSharedStateClone();
        this._localReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
        this._localReturnOverviewServiceSharedState = this._returnOverviewService.getSharedStateClone();
    }

    private async _loadReport(): Promise<void> {
        this._persistState();

        this.isBusy = true;
        try {
            this.data = await this._returnOverviewService.getScheduleAdditionsDisposalReconciliationReport();
            this._refreshCanNavigate();
        } finally {
            this.isBusy = false;
        }
    }
}
