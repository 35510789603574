import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { UserInstanceService } from '../../User/userInstance.service';
import { UpgradeNavigationServiceHandler } from '../Routing/upgrade-navigation-handler.service';

/**
 * Instance Filter Single-selection dropdown
 * loads the list of a user's instance memberships from UserInstanceService
 * works in conjunction with the InstanceHeaderInterceptor.
 * NOTE: When the instance is changed, the user is redirected to the homepage
 *  to ensure any navigation/views will respect the change
 * Example usage:
 * <ws-instance-filter></ws-instance-filter>
 *
 */
@Component({
    selector: 'ws-instance-filter',
    templateUrl: './instanceFilter.component.html',
    styleUrls: ['./instanceFilter.component.scss']
})
export class InstanceFilterComponent implements OnInit {
    constructor(
        @Inject('$rootScope') private _rootScope: any,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private userInstanceService : UserInstanceService,
    ) {
        _rootScope.$on('global.edit.mode.activated', () => {
            this.isDisabled = true;
        });
        _rootScope.$on('global.edit.mode.deactivated', () => {
            this.isDisabled = false;
        });
    }

    instances: Array<Core.InstanceViewModel> = [];
    selectedInstance: Core.InstanceViewModel;
    isDisabled: boolean = false;

    ngOnInit(): void {
        // get all the instances the current user has access to
        this.instances = [this.userInstanceService.allInstancesItem].concat(this.userInstanceService.getUserInstanceMembership(false));

        // set initial value
        this.selectedInstance = this.userInstanceService.getSelectedInstance();
    }

    instanceChanged(instance: Core.InstanceViewModel): void {
        this.selectedInstance = instance;
        this.userInstanceService.saveSelectedInstance(instance);

        //go home and reload page so that all the permissions are checked - especially on the top menus which do not reload on navigation
        window.location.replace(`${window.location.origin}/#/home`);
        window.location.reload();
    }

}
