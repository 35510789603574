import { Injectable } from '@angular/core';
import { TimerService } from './timer.service';

import * as moment from 'moment';
import * as _ from 'lodash';
let angular;

@Injectable(
    { providedIn: 'root' }
)
export class UtilitiesService {
    constructor(private readonly _timerService: TimerService) {}

    /*TODO: We almost certainly should not be using isNumeric. During the migration from
    rxjs 6 to 7 many instances were discovered of importing isNumeric from
    rxjs/internal-compatibility. There are several problems with this.

    1. In every instance it was used to check something with type "number", so either our
        types are wrong or this makes no sense (likely we meant to check if something
        was null or undefined but for some reason we didn't just check that)
    2. We shouldn't EVER pull in third-party libraries for simple utility checks (like
        "is this thing a number").
    3. The word "internal" is literally in the name of the import. That should have been
        a very obvious red flag that they did not intend for you to import this.

    Because these uses were buried in code that's difficult to verify (no comments
    indicate why we're not simply saying thing !== undefined && thing !== null), I copied
    these utility functions from rxjs 6 basically as-is to ensure nothing breaks. Later
    we should specifically investigate uses of this function to eliminate the need.  */
    static isNumeric(val:any): boolean {
        // parseFloat NaNs numeric-cast false positives (null|true|false|"")
        // ...but misinterprets leading-number strings, particularly hex literals ("0x...")
        // subtraction forces infinities to NaN
        // adding 1 corrects loss of precision from parseFloat (#15100)
        return !(val && typeof val.length === "number") && (val - parseFloat(val) + 1) >= 0;
    }

    focusOnElement(elem, delay: number) {
        delay = delay || 100;

        this._timerService.setTimeout(() => {
            const element = angular.element(elem)
            element.focus();
        }, delay);
    }

    nonDeletedFilter(item): boolean {
        return item.efAction !== 'delete';
    }

    findPropertyName(object, property_value, strict?: boolean) {
        let result = 'unknown property name';

        if (typeof strict === 'undefined') {
            strict = false;
        }

        Object.getOwnPropertyNames(object).forEach(propertyName => {
            if ((strict && object[propertyName] === property_value) ||
                (!strict && object[propertyName] === property_value)) {
                result = propertyName;
            }
        });

        return result.toLowerCase();
    }

    isJson(str): boolean {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    nonDeleted(list): any[] {
        return _.reject(list, {
            efAction: 'delete'
        });
    }

    updateAction(thing): void {
        thing.efAction = thing.efAction === null ? 'update' : thing.efAction;
    }

    // TODO find all usages of the below date methods and replace with moment
    // All of the below date methods should not be used, Moment should be used instead
    convertDateStringsToDates(input) {
        // Ignore things that aren't objects.
        if (typeof input !== "object") return input;

        for (const key in input) {
            if (!input.hasOwnProperty(key)) { continue; }
            const value = input[key];
            const match = this.dateStringMatch(value);
            if (match) {
                const newValue = this.getDateValue(match);
                if (newValue) {
                    input[key] = newValue;
                }
            } else if (typeof value === 'object') {
                // Recurse into object
                this.convertDateStringsToDates(value);
            }
        }
    }

    dateStringMatch(value) {
        const regexIso = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d(?:(?::[0-5]\d)?|(?::[0-5]\d\.\d+))?(Z|(?:[+-][0-2]\d:[0-5]\d))?$/;

        // Check for string properties which look like dates.
        if (typeof value === 'string') {
            const match = value.match(regexIso);
            if (match && !this.isJson(value)) {
                return match;
            }
        }

        return undefined;
    }

    getDateValue(match) {
        // The first capture group is the timezone specifier; if not present, assume UTC
        const momentVal = match[1] ? moment(match[0]) : moment.utc(match[0]);
        if (momentVal.isValid()) {
            return momentVal.toDate();
        }

        return undefined;
    }
}
