(function () {
    'use strict';

    angular
        .module('weissmanApp')
        .controller('ProcessNoAppealModalController', ProcessNoAppealModalController);

    ProcessNoAppealModalController.$inject = ['taskIDs', 'RevisionChangeTypes', 'utils', '$uibModalInstance', 'processingService', 'actionViewPersistenceService', 'searchTimestamp', 'optionalArgs'];
    function ProcessNoAppealModalController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit(taskIDs, RevisionChangeTypes, utils, $uibModalInstance, processingService, actionViewPersistenceService, searchTimestamp, optionalArgs) {
        var vm = this;

        vm.revisionChangeTypes = RevisionChangeTypes;

        activate();

        ////////////////

        function activate() {
            vm.loading = false;

            vm.payload = {
                taskIDs: taskIDs,
                revisionChangeTypeID: actionViewPersistenceService.revisionChangeTypeID || 1,
                newRevisionDescription: '',
                comment: null,
                searchTimestamp: searchTimestamp
            }
        }

        vm.focus = function () {
            utils.focusOnElement('input[name=other]');
        }

        vm.cancel = function() {
            $uibModalInstance.dismiss();
        }

        vm.process = function () {
            actionViewPersistenceService.revisionChangeTypeID = vm.payload.revisionChangeTypeID;

            if (optionalArgs.parentCallsService) {
                $uibModalInstance.close(function () {
                    return processingService.processNoAppealWarranted(vm.payload);
                });
            }
            else {
                vm.loading = true;

                processingService.processNoAppealWarranted(vm.payload).then(function (result) {
                    $uibModalInstance.close(result);
                }).catch(function (error) {
                    $uibModalInstance.dismiss();
                });
            }
        }
    }
})();
