(function () {
	'use strict';

	var SERVICE_URL = '/api/Tasks';

	angular
		.module('weissmanApp')
		.factory('taskService', taskService);

	taskService.$inject = ['sd.Http.Service', '$uibModal', 'TaskActionTypes', '$q', '$rootScope', 'toastr', 'messageBoxService'];

	function taskService(sdHttp, $uibModal, TaskActionTypes, $q, $rootScope, toastr, messageBoxService) {
		return {
			getByEntity: getByEntity,
			getTaskSummaryByEntity: getTaskSummaryByEntity,
			checkIfEntityMayBeDeactivated : checkIfEntityMayBeDeactivated,
			launchTaskModal: launchTaskModal,
			rescindTask: rescindTask,
			insertTask: insertTask,
			updateTask: updateTask,
			deleteTask: deleteTask,
			completeTask: completeTask,
			skipTask: skipTask,
			splitIntoGroups: splitIntoGroups,
			getTaskPermissions: getTaskPermissions,
			getInsertOptions: getInsertOptions,
			getMaxTasksWide: getMaxTasksWide,
            changeContacts: changeContacts,
			reassignMany: reassignMany,
			skipMany: skipMany,
			completeMany: completeMany,
            showErrorNotificationModal: showErrorNotificationModal,
			validateAssessorStateReqs: validateAssessorStateReqs
		};

		function rescindTask(task) {
			var taskProcessRequestDTO = {
				entityID: task.entityId,
				entityTypeID: task.entityTypeID,
				taskID: task.taskID,
				rowVersion: task.rowVersion,
				actionID: TaskActionTypes.RESCINDTASK
			};
			var url = SERVICE_URL + '/performAction';

			return sdHttp.put(url, taskProcessRequestDTO);
		}

		function insertTask(task, newTaskTypeID, actionID) {
			var taskProcessRequestDTO = {
				entityID: task.entityId,
				entityTypeID: task.entityTypeID,
				taskID: task.taskID,
				rowVersion: task.rowVersion,
				actionID: actionID,
				newTaskTypeID: newTaskTypeID
			};

			var url = SERVICE_URL + '/performAction';

			return sdHttp.put(url, taskProcessRequestDTO);
		}

		function updateTask(task) {
			var taskProcessRequestDTO = {
				entityID: task.entityId,
				entityTypeID: task.entityTypeID,
				taskID: task.taskID,
				rowVersion: task.rowVersion,
				actionID: TaskActionTypes.REASSIGNTASK,
				assignedUserID: task.assignedUserID,
				assignedTeamID: task.assignedTeamID
			};
			var url = SERVICE_URL + '/performAction';

			return sdHttp.put(url, taskProcessRequestDTO);
		}

		function deleteTask(task) {
			var taskProcessRequestDTO = {
				entityID: task.entityId,
				entityTypeID: task.entityTypeID,
				taskID: task.taskID,
				rowVersion: task.rowVersion,
				actionID: TaskActionTypes.DELETETASK
			};
			var url = SERVICE_URL + '/performAction';

			return sdHttp.put(url, taskProcessRequestDTO);
		}

		function skipTask(task) {
			var taskProcessRequestDTO = {
				entityID: task.entityId,
				entityTypeID: task.entityTypeID,
				taskID: task.taskID,
				rowVersion: task.rowVersion,
				actionID: TaskActionTypes.SKIPTASK
			};
			var url = SERVICE_URL + '/performAction';

			return sdHttp.put(url, taskProcessRequestDTO);
		}

		async function completeTask(task) {
			const warning = await validateAssessorStateReqs([task.taskID]);
			if (warning) {
				await messageBoxService.warning(warning);
			}

			const taskProcessRequestDTO = {
				entityID: task.entityId,
				entityTypeID: task.entityTypeID,
				taskID: task.taskID,
				rowVersion: task.rowVersion,
				actionID: TaskActionTypes.COMPLETETASK
			};
			const url = SERVICE_URL + '/performAction';

			return await sdHttp.put(url, taskProcessRequestDTO);
		}

		function checkIfEntityMayBeDeactivated(entityID, entityTypeID) {
			var params = {
				entityID: entityID,
				entityTypeID: entityTypeID
			};

			var url = SERVICE_URL + '/entity/CheckIfMayBeDeactivated';

			return sdHttp.get(url, { params: params });
		}


		function getByEntity(entityID, entityTypeID) {
			var params = {
				entityID: entityID,
				entityTypeID: entityTypeID
			};

			var url = SERVICE_URL + '/entity';

			return sdHttp.get(url, { params: params });
		}

		function getTaskSummaryByEntity(entityID, entityTypeID) {
			var params = {
				entityID: entityID,
				entityTypeID: entityTypeID
			};

			var url = SERVICE_URL + '/entity/summary';

			return sdHttp.get(url, { params: params });
		}


		function getTaskPermissions(entityID, entityTypeID, taskID) {
			//var params = {
			//	entityID: entityID,
			//	entityTypeID: entityTypeID,
			//	taskID: taskID
			//};

			//var url = SERVICE_URL + '/permissions';

			//return sdHttp.get(url, { params: params })
			//	.then(function (response) {
			//		return response;
			//	});
		}

		function getInsertOptions(entityID, entityTypeID, taskID) {
			var params = {
				entityId: entityID,
				entityTypeID: entityTypeID,
				taskID: taskID
			};

			var url = SERVICE_URL + '/InsertOptions';

			return sdHttp.get(url, { params: params })
				.then(function (response) {
					return response;
				});
		}

		function launchTaskModal(entityID, entityTypeID, readonly, toastMessage, editonly) {
			if(toastMessage) {
				toastr.info(toastMessage);
			}

		    return $q(function (resolve) {
                var loadingModal = $uibModal.open({
                    template: '<sd-loading-spinner margin-bottom="50"></sd-loading-spinner>',
                    size: 'sm',
					windowClass: 'show',
					backdropClass: 'show',
                    animation: false
                })

                getByEntity(entityID, entityTypeID)
                    .then(function (result) {
                        // HACK: The task modal doesn't have the concept of "readonly" built-in,
                        // but it does read permissions to check if it should allow changes; we'll
                        // overwrite the permission data here to put the modal in read-only mode.
                        if (readonly) {
                            var targetTasks = [];
                            if (result && result.tasks) {
                                targetTasks = result.tasks
                            }

                            _.forEach(targetTasks, function (task) {
                                task.taskAuthorizationInfo = {
                                    completeTask: false,
                                    deleteTask: false,
                                    insertTaskAfter: false,
                                    insertTaskBefore: false,
                                    reassignTask: false,
                                    rescindTask: false,
                                    skipTask: false
                                };
                            });
                        }

                        if(editonly) {
                            var targetTasks = [];
                            if (result && result.tasks) {
                                targetTasks = result.tasks
                            }
                            _.forEach(targetTasks, function (task) {
                                if(task.taskAuthorizationInfo) {
                                    task.taskAuthorizationInfo.completeTask = false;
                                }
                            });
                        }
                        var taskGroupsBySequence = splitIntoGroups(result.tasks);
                        var tasksWide = getMaxTasksWide(taskGroupsBySequence);
                        var modalSize = tasksWide > 1 ? 'lg' : 'sm';
                        var windowClass = '';

                        if (tasksWide > 1) {
                            windowClass = tasksWide < 4 ? 'task-modal-' + tasksWide : 'task-modal-xl';
                        }

                        var modalInstance = $uibModal.open({
                            templateUrl: 'app/Task/_tasksModal.html',
                            controller: 'TasksModalController',
                            controllerAs: 'vm',
                            size: modalSize,
                            windowClass: `${windowClass} show`,
							backdropClass: 'show',
                            resolve: {
                                tasks: function () {
                                    return taskGroupsBySequence;
                                }
                            }
                        });

                        var reloadRequired = false; 

                        // HACK: The AngularJS version of this used events to notify that entity task summaries
                        // need to be reloaded; for Angular 2+ it's easier for this function to return a promise
                        // whose resolution is a bool indicating if the tasks changed.
                        // Watch for events, then return "true" if the entity task was updated while the modal
                        // was open.
                        var taskUpdateListener = $rootScope.$on('entityTaskUpdated', function () {
                            reloadRequired = true;
                        });

                        modalInstance.rendered
                            .then(function () {
                                loadingModal.dismiss();
                            })

                        modalInstance.closed.then(function () {
                            // Remove the listener
                            taskUpdateListener();
                            resolve(reloadRequired);
                        });

                        modalInstance.result
                            .then(function () { });
                    })
		    });
		}

		function changeContacts(request) {
		    return sdHttp.put(SERVICE_URL + '/changecontacts', request);
		}

		function reassignMany(reassignObj) {
		    return sdHttp.put(SERVICE_URL + '/reassignUser', reassignObj);
		}

		function skipMany(taskIds) {
		    return sdHttp.put(SERVICE_URL + '/skiptasks', taskIds);
		}

		function completeMany(taskIds) {
		    return sdHttp.put(SERVICE_URL + '/completetasks', taskIds);
		}

	    // Errors and warnings are string arrays, error message will be shown over error messages
	    // and warning message will be shown over warning messages (warningMessage is optional,
        // even if the warning array has entries)
		function showErrorNotificationModal(errors, warnings, errorMessage, warningMessage) {
            $uibModal.open({
                templateUrl: 'app/Task/error-notification.modal.html',
                controller: 'ErrorNotificationModalController',
                controllerAs: 'vm',
				windowClass: 'show',
				backdropClass: 'show',
                resolve: {
                    errorMessage: function () { return errorMessage; },
                    errors: function () { return errors; },
                    warningMessage: function () { return warningMessage },
                    warnings: function () { return warnings; }
                }
            });
		}

		function getMaxTasksWide(taskGroupsBySequence) {
			return _.chain(taskGroupsBySequence)
				.map(function (taskGroupCollection) {
					return taskGroupCollection.taskGroups.length;
				})
				.max()
				.value();
		}

		function splitIntoGroups(tasks) {
			var idSequencer = 0;
			var taskGroupArr = [];
			var firstGroup = {
				tasks: [],
				sequence: 1,
				id: idSequencer++
			};
			var firstTask = _.find(tasks, { sequence: 1 });

			compileThing(firstTask, firstGroup);

			return _.chain(taskGroupArr)
				.groupBy('sequence')
				.map(function (taskGroups, sequence) {
					return {
						taskGroups: taskGroups,
						sequence: Number(sequence)
					};
				})
				.value();

			function compileThing(task, group) {
				group.tasks.push(task);

				if (task.pointsTo.length > 1) {
					_.forEach(task.pointsTo, function (taskID) {
						var firstTask = _.find(tasks, { taskID: taskID });
						var newGroup = {
							tasks: [],
							sequence: group.sequence + 1,
							id: idSequencer++
						}
						compileThing(firstTask, newGroup)
					});

					taskGroupArr.unshift(group);
				} else if (task.pointsTo.length === 1) {
					var nextTask = _.find(tasks, { taskID: task.pointsTo[0] });

					var otherTasksThatPointToIt = _.filter(tasks, function (t) {
						return _.includes(t.pointsTo, task.pointsTo[0]) && t.taskID !== task.taskID;
					})

					var taskIds = _.chain(taskGroupArr)
						.map('tasks')
						.flatten()
						.map('taskID')
						.value();

					if (otherTasksThatPointToIt.length) {
						taskGroupArr.unshift(group);
						if (!_.includes(taskIds, nextTask.taskID)) {
							var newGroup = {
								tasks: [],
								sequence: group.sequence + 1,
								id: idSequencer++
							}
							compileThing(nextTask, newGroup);
						}
					} else {
						compileThing(nextTask, group);
					}
				} else {
					taskGroupArr.unshift(group)
				}
			}
		}

		async function validateAssessorStateReqs(taskSeriesIds) {
			return await sdHttp.post(SERVICE_URL + '/ValidateAssessorStateReqs', taskSeriesIds);
		}
	}
})();
