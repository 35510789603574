import { Injectable } from '@angular/core';
import { WeissmanDateFormatPipe } from '../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';

@Injectable()
export class ComplianceLienDateConfigurationService {
    constructor(private readonly _datePipe: WeissmanDateFormatPipe ) {
    }

    getLienDate(taxYear: number, configuration: Compliance.ComplianceLienDateInfoModel): string{
        return `${configuration.ppLienDateDisplayMonthDay}/${taxYear}`;
    }

    getAssetCutOffDate(taxYear: number, configuration: Compliance.ComplianceLienDateInfoModel): string{
        return `${configuration.ppLienDateMonthDay}/${taxYear + configuration.ppLienDateYearOffset}`;
    }

    getAge0Range(taxYear: number, configuration: Compliance.ComplianceLienDateInfoModel): string{
        const age0Begins = this._getAge0Begins(taxYear, configuration);
        return `${this._datePipe.transform(age0Begins, true)} - ${this._datePipe.transform(this._getAge0Ends(age0Begins), true)}`;
    }

    private _getAge0Begins(taxYear: number, configuration: Compliance.ComplianceLienDateInfoModel): Date{
        return new Date(`${configuration.ppLienDateAgeBeginMonthDay}/${taxYear + configuration.ppLienDateAgeBeginYearOffset}Z`);
    }

    private _getAge0Ends(age0Begins: Date): Date{
        let result = new Date(age0Begins);

        result.setFullYear(result.getFullYear() + 1);
        result.setDate(result.getDate() - 1);

        return result;
    }
}
