import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { lastValueFrom, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { TaskService } from '../../Task/task.service.upgrade';
import { BreadCrumb } from '../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { YearPickerMixin } from '../../UI-Lib/Mixins';
import { WeissmanDateFormat } from '../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../AgGrid';
import {
    AgGridLinkCellRenderer,
    AgGridLinkCellRendererParams
} from '../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { AgGridYesNoFloatingFilterComponent } from '../AgGrid/FloatingFilters/agGridYesNoFloatingFilter.component';
import {
    AgGridMultiSelectCellRendererParams,
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer,
    AgGridMultiSelectTracker
} from '../AgGrid/MultiSelectTracker';
import {
    ICellRendererParamsForFilingBatchListGridAction
} from '../Filing-Batch/Filing-Batch-List/agGridActionCellRenderer.component';
import { ElectronicFilingRepository } from '../Repositories';
import { ElectronicFilingAgGridDataSource, ElectronicFilingDataSourceParams } from './agGridDataSource';
import { AgGridExportOptions, AgGridExportStartLRP } from '../AgGrid/ToolPanel/models';
import { BusyIndicatorService, SnackBarService } from '../../Busy-Indicator';
import { HelpService } from '../../UI-Lib/Help-Tooltip';
import { ELECTRONIC_FILING_HELP } from './electronicFiling.component.help';
import { FeatureFlagsService } from '../../Common/FeatureFlags/feature-flags-service';

interface ViewControlFormModel {
    electronicFilingType: FormControl<Compliance.ElectronicFilingTypeModel | null>;
    taxYear: FormControl<number>;
}

interface DownloadControlFormModel {
    bundleAssessors: FormControl<boolean>;
}

@Component({
    selector: 'electronic-filing',
    templateUrl: './electronicFiling.component.html'
})
export class ElectronicFilingComponent extends YearPickerMixin() implements OnInit, OnDestroy {
    constructor(private readonly _electronicFilingRepository: ElectronicFilingRepository,
                private readonly _fb: FormBuilder,
                private readonly _routerService: UpgradeNavigationServiceHandler,
                private readonly _taskService: TaskService,
                private readonly _decimalPipe: DecimalPipe,
                private readonly _busyIndicatorService: BusyIndicatorService,
                private readonly _snackBarService: SnackBarService,
                private readonly _helpService: HelpService,
                private readonly _featureFlagsService: FeatureFlagsService) {
        super();
    }

    breadcrumbs: BreadCrumb[] = [];
    isInitialized: boolean = false;
    hasRowsSelected: boolean = false;
    companyId: number;
    companyName: string;
    viewControlForm: FormGroup<ViewControlFormModel>;
    downloadControlForm: FormGroup<DownloadControlFormModel>;
    sdrTestUrl: string;
    sdrUrl: string;
    electronicFilingTypes: Compliance.ElectronicFilingTypeModel[];
    placeholderElectronicFilingType: Compliance.ElectronicFilingTypeModel = {
        name: '',
        electronicFilingTypeId: null,
        stateAbbr: null,
        displayName: 'Nothing selected',
        stateId: null
    };
    isCASdr: boolean = false;

    gridId: System.Guid = 'a070ef4b-6057-410f-8a54-7cbc8a59fdca';
    gridTracker: AgGridMultiSelectTracker;
    gridOptions: GridOptions = new AgGridOptionsBuilder({
        onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
        onSortChanged: () => this.gridTracker.onGridSortChanged(),
        rowClassRules: {
            'ag-row-selected': (params) => {
                const data = params.data as Compliance.ElectronicFilingModel;
                if (!data) {
                    return false;
                }
                const isDisabled = !data.ppeFilingAvailability || data.ppeFilingAvailability === this.NOT_AVAILABLE || (!(this._featureFlagsService.featureFlags.allowElectronicFilingUnapprovedDownload || (data.hasApprovalTask && data.isApproved) || (!data.hasApprovalTask && data.isReviewed)));
                return !isDisabled && this.gridTracker.isRowSelected(data.returnId);
            }
        }
    })
    .withColumnPinning()
    .buildDefault(this);

    exportOptions: AgGridExportOptions = {
        start: async (columnsToReturn: Compliance.NameValuePair<string>[], fileFormat: Compliance.ExportFileFormatEnum): Promise<AgGridExportStartLRP> => {
            const searchParams = this._gridDataSource.getSearchModel();
            searchParams.pagination = null;

            searchParams.selectedRows = this.gridTracker.getSelectedRowsModel();
            if (!searchParams.selectedRows.selectAllRows && searchParams.selectedRows.selectedRows.length === 0) {
                searchParams.selectedRows.selectAllRows = true;
            }

            const exportModel: Compliance.ElectronicFilingExportModel = {
                searchModel: searchParams,
                columnsToReturn: columnsToReturn
            };

            const lrp$ = this._electronicFilingRepository.startExport(this.companyId, exportModel);
            const longRunningProcessId = await lastValueFrom(lrp$);
            return { longRunningProcessId, longRunningProcessTypeId: Compliance.LongRunningProcessTypeEnum.ExportElectronicFiling };
        },
        canCancel: true,
        showFileFormatSelection: false,
        getDisabled: () => !this._gridDataSource || this._gridDataSource.isRefreshing
    };

    private readonly NOT_AVAILABLE: string = 'Not Available';
    private _gridApi: GridApi;
    private _gridDataSource: ElectronicFilingAgGridDataSource;
    private _destroy$: Subject<void> = new Subject();

    get canRefresh(): boolean {
        const { electronicFilingType, taxYear } = this.viewControlForm.getRawValue();
        return !this._gridDataSource?.isRefreshing && !!electronicFilingType && !!taxYear;
    }

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(ELECTRONIC_FILING_HELP);

        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });

        try {
            this.companyId = parseInt(this._routerService.getQuerystringParam('companyId'));
            const info = await lastValueFrom(this._electronicFilingRepository.getElectronicFilingInfo(this.companyId));

            this.companyName = info.companyName;
            this.electronicFilingTypes = info.electronicFilingTypes;
            this.electronicFilingTypes.unshift(this.placeholderElectronicFilingType);

            this.sdrTestUrl = info.caSdrTestUrl;
            this.sdrUrl = info.caSdrUrl;

            this.breadcrumbs = [{
                name: this.companyName,
                target: 'company',
                options: { companyId: this.companyId }
            }];

            this.viewControlForm = this._fb.group<ViewControlFormModel>({
                electronicFilingType: this._fb.control<Compliance.ElectronicFilingTypeModel | null>(this.placeholderElectronicFilingType),
                taxYear: this._fb.control<number>(new Date().getFullYear())
            });

            this.downloadControlForm = this._fb.group<DownloadControlFormModel>({
                bundleAssessors: this._fb.control<boolean>(false)
            });

            this.viewControlForm.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
                const { electronicFilingType, taxYear } = this.viewControlForm.getRawValue();
                if (electronicFilingType && taxYear) {
                    this._refreshDataSource();
                }
            });

            this.isInitialized = true;

        } finally {
            busyRef.hide();
        }
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    refresh(): void {
        this._refreshDataSource();
    }

    onElectronicFilingTypeChanged(electronicFilingType: Compliance.ElectronicFilingTypeModel): void {
        this.isCASdr = electronicFilingType && electronicFilingType.electronicFilingTypeId === Compliance.ElectronicFilingTypeEnum.Ca571LSdr;
    }

    async downloadElectronicFilings(): Promise<void> {
        this.disableDownload = true;
        const busyRef = this._busyIndicatorService.show({ message: 'Working on it...', delay: 0 });

        try {
            const { electronicFilingType, taxYear } = this.viewControlForm.getRawValue();

            const { bundleAssessors } = this.downloadControlForm.getRawValue();

            const searchParams = this._gridDataSource.getSearchModel();
            searchParams.pagination = null;
            searchParams.selectedRows = this.gridTracker.getSelectedRowsModel();

            // not that this is assuming there is only one electronic filing type per state
            // in future we may want to pass in the electronic filing type id as well to limit the list by state and type
            const outputModel: Compliance.ElectronicFilingOutputModel = {
                topLevelCompanyId: this.companyId,
                taxYear: taxYear,
                bundleByAssessor: bundleAssessors,
                electronicFilingTypeId: electronicFilingType.electronicFilingTypeId,
                searchModel: searchParams
            };

            const lrpId = await lastValueFrom(this._electronicFilingRepository.generateElectronicFilingOutput(outputModel));
            this._snackBarService.addById(lrpId, Compliance.LongRunningProcessTypeEnum.GenerateElectronicFilingOutput);
            this.disableDownload = false;
        } finally {
            busyRef.hide();
        }
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this.gridTracker.selectedRows$.pipe(takeUntil(this._destroy$)).subscribe(() => {
            this.hasRowsSelected = this.gridTracker.hasSelectedRows() && !this.gridTracker.isAllRowsDisabled();
        });

        const columns: ColDef[] = [
            {
                colId: 'grid-column-multiselect',
                headerName: '',
                field: 'returnId',
                width: AgGridColumns.selectionColumnWidth,
                maxWidth: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                suppressColumnsToolPanel: true,
                editable: false,
                pinned: 'left',
                lockPinned: true,
                lockVisible: true,
                lockPosition: true,
                suppressMovable: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker,
                    isCellRendererDisabledFn: (rowId: number, data: Compliance.ElectronicFilingModel) => {
                        return this.gridTracker.isAllRowsDisabled();
                    }
                } as AgGridMultiSelectCellRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker,
                    tooltipText: (params) => {
                        const data = params.data as Compliance.ElectronicFilingModel;
                        let message: string = '';
                        if(!data) {
                            return message;
                        }
                        if (!data.ppeFilingAvailability) {
                            message = 'This assessor does not accept electronic filings (availability not set)';
                        }
                        if (data.ppeFilingAvailability === this.NOT_AVAILABLE) {
                            message = 'This assessor does not accept electronic filings';
                        }
                        if (data.hasApprovalTask && !data.isApproved) {
                            if (message) {
                                message += ' ';
                            }
                            message += `${message ? ' and this' : 'This'} return has not been approved`;
                        }
                        if (!data.hasApprovalTask && !data.isReviewed) {
                            if (message) {
                                message += ' ';
                            }
                            message += `${message ? ' and this' : 'This'} return has not been reviewed`;
                        }
                        return message ? `${message}.` : 'Toggle selection';
                    },
                    isCellRendererDisabledFn: (rowId: number, data: Compliance.ElectronicFilingModel) => {
                        return !data.ppeFilingAvailability || data.ppeFilingAvailability === this.NOT_AVAILABLE || (!(this._featureFlagsService.featureFlags.allowElectronicFilingUnapprovedDownload || (data.hasApprovalTask && data.isApproved) || (!data.hasApprovalTask && data.isReviewed)));
                    }
                } as AgGridMultiSelectCellRendererParams
            },
            {
                headerName: 'Parcel Acct Num',
                field: 'parcelAccountNumber',
                width: AgGridColumns.textColumnWidth,
                editable: false,
                pinned: 'left',
                lockPinned: true,
                lockVisible: true,
                lockPosition: true,
                suppressMovable: true,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-parcel',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const model = params.data as Compliance.ElectronicFilingModel;
                        if (!model) {
                            return '';
                        }
                        return `#/parcel/${model.parcelId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Assessor',
                field: 'assessorAbbr',
                width: AgGridColumns.textColumnWidth,
                editable: false,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-assessor',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const model = params.data as Compliance.ElectronicFilingModel;
                        if (!model) {
                            return '';
                        }
                        return `#/assessor/${model.assessorId}?year=${this.viewControlForm.value.taxYear}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Batch Number',
                field: 'filingBatchNumber',
                width: AgGridColumns.textColumnWidth,
                editable: false,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-filing-batch',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const model = params.data as Compliance.ElectronicFilingModel;
                        if (!model) {
                            return '';
                        }
                        return `#/returnBatch/${model.filingBatchId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Return Due Date',
                field: 'returnDueDate',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: (params) => {
                    const model = params.data as Compliance.ElectronicFilingModel;
                    if (!(model && model.returnDueDate)) {
                        return '';
                    }
                    return WeissmanDateFormat(model.returnDueDate, true, 'stored');
                }
            },
            {
                headerName: 'Status',
                field: 'status',
                width: AgGridColumns.textColumnLargeWidth,
                sortable: false,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-task-series',
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        return null;
                    },
                    onClick: this._viewTasks.bind(this)
                } as AgGridLinkCellRendererParams,
            },
            {
                headerName: 'Site Name',
                field: 'siteName',
                width: AgGridColumns.textColumnWidth,
                editable: false,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const model = params.data as Compliance.ElectronicFilingModel;
                        if (!model) {
                            return '';
                        }
                        return `#/site/${model.siteId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'PP E-Filing Availability',
                field: 'ppeFilingAvailability',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Cost',
                field: 'cost',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => {
                    const rowData = x.data as Compliance.ElectronicFilingModel;
                    return !rowData
                        ? null
                        : x.value || x.value === 0 || rowData.cost
                            ? this._decimalPipe.transform(x.value, '1.2-2')
                            : 'N/A';
                },
                cellClass: 'ag-numeric-cell'
            },
            {
                headerName: 'Rep. Cost',
                field: 'reportedCost',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => {
                    const rowData = x.data as Compliance.ElectronicFilingModel;
                    return !rowData
                        ? null
                        : x.value || x.value === 0 || rowData.reportedCost
                            ? this._decimalPipe.transform(x.value, '1.2-2')
                            : 'N/A';
                },
                cellClass: 'ag-numeric-cell'
            },
            {
                headerName: 'Dep. Value',
                field: 'reportedValue',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => {
                    const rowData = x.data as Compliance.ElectronicFilingModel;
                    return !rowData
                        ? null
                        : x.value || x.value === 0 || rowData.reportedValue
                            ? this._decimalPipe.transform(x.value, '1.2-2')
                            : 'N/A';
                },
                cellClass: 'ag-numeric-cell'
            },
            {
                headerName: 'Est. Tax',
                field: 'taxLiability',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => {
                    const rowData = x.data as Compliance.ElectronicFilingModel;
                    return !rowData
                        ? null
                        : x.value || x.value === 0 || rowData.taxLiability
                            ? this._decimalPipe.transform(x.value, '1.2-2')
                            : 'N/A';
                },
                cellClass: 'ag-numeric-cell'
            },
            {
                headerName: 'Est. FMV',
                field: 'estimatedFMV',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => {
                    const rowData = x.data as Compliance.ElectronicFilingModel;
                    return !rowData
                        ? null
                        : x.value || x.value === 0 || rowData.estimatedFMV
                            ? this._decimalPipe.transform(x.value, '1.2-2')
                            : 'N/A';
                },
                cellClass: 'ag-numeric-cell'
            },
            {
                headerName: 'Batch Name',
                field: 'filingBatchName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'City',
                field: 'assessorCity',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Company',
                field: 'companyName',
                width: AgGridColumns.textColumnWidth,
                editable: false,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-company',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const model = params.data as Compliance.ElectronicFilingModel;
                        if (!model) {
                            return '';
                        }
                        return `#/company/${model.companyId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Downloaded Date',
                field: 'downloadedDate',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: (params) => {
                    const model = params.data as Compliance.ElectronicFilingModel;
                    if (!(model && model.downloadedDate)) {
                        return '';
                    }
                    return WeissmanDateFormat(model.downloadedDate, true, 'stored');
                },
                hide: true
            },
            {
                headerName: 'Is Amended',
                field: 'isAmended',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agYesNoColumnFilter',
                filterParams: AgGridFilterParams.yesNoFilterParams,
                floatingFilterComponentFramework: AgGridYesNoFloatingFilterComponent,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueGetter: params => {
                    const model = params.data as Compliance.ElectronicFilingModel;
                    return model?.isAmended ? 'Yes' : 'No';
                },
                hide: true
            },
            {
                headerName: 'Is Consolidated',
                field: 'isConsolidatedReturn',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agYesNoColumnFilter',
                filterParams: AgGridFilterParams.yesNoFilterParams,
                floatingFilterComponentFramework: AgGridYesNoFloatingFilterComponent,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueGetter: params => {
                    const model = params.data as Compliance.ElectronicFilingModel;
                    return model?.isConsolidatedReturn ? 'Yes' : 'No';
                },
                hide: true
            },
            {
                headerName: 'Not Rep. Asset Count',
                field: 'notReportedAssetCount',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => {
                    const rowData = x.data as Compliance.ElectronicFilingModel;
                    if (!rowData) {
                        return '0';
                    }
                    return x.value || rowData.notReportedAssetCount ? rowData.notReportedAssetCount.toString() : '0';
                },
                hide: true
            },
            {
                headerName: 'PP E-Filing Remarks',
                field: 'ppeFilingRemarks',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'PP E-Filing URL',
                field: 'ppeFilingUrl',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Prior Return',
                field: 'priorReturnName',
                width: AgGridColumns.textColumnWidth,
                editable: false,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-return',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const model = params.data as Compliance.ElectronicFilingModel;
                        if (!model) {
                            return '';
                        }
                        return `#/returnBatch/${model.priorReturnId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Rep. Asset Count',
                field: 'reportedAssetCount',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => {
                    const rowData = x.data as Compliance.ElectronicFilingModel;
                    if (!rowData) {
                        return '0';
                    }
                    return x.value || rowData.reportedAssetCount ? rowData.reportedAssetCount.toString() : '0';
                },
                hide: true
            },
            {
                headerName: 'Site Number',
                field: 'siteNumber',
                width: AgGridColumns.textColumnWidth,
                editable: false,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const model = params.data as Compliance.ElectronicFilingModel;
                        if (!model) {
                            return '';
                        }
                        return `#/site/${model.siteId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            }
        ];

        const defaultSortModel = [
            {
                colId: 'parcelAccountNumber',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setDataSource();
    }

    private async _viewTasks(params: ICellRendererParamsForFilingBatchListGridAction): Promise<void> {
        const efiling = params.data as Compliance.ElectronicFilingModel;
        if (!efiling) {
            return;
        }

        const entityId = efiling.filingBatchId;

        const entityType = Core.EntityTypes.FilingBatch;

        await this._taskService.launchTaskModal(entityId, entityType, true);
    }

    private _refreshDataSource(): void {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }

        this.gridTracker.clear();

        this._gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return false;
        }

        this.gridTracker.clear();

        const dataSourceParams = (): ElectronicFilingDataSourceParams => {
            const { electronicFilingType, taxYear } = this.viewControlForm.getRawValue();
            return {
                companyId: this.companyId,
                stateId: electronicFilingType && electronicFilingType.stateId,
                electronicFilingTypeId: electronicFilingType && electronicFilingType.electronicFilingTypeId,
                taxYear: taxYear
            };
        };

        this._gridDataSource = new ElectronicFilingAgGridDataSource(
            this._gridApi,
            this._electronicFilingRepository,
            dataSourceParams);

        this._gridDataSource.onFirstDataRendered$.pipe(take(1)).subscribe(() => {
            this._gridApi.sizeColumnsToFit();
        });

        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }

    private _getGridRowIds(skip: number, take: number): Promise<Compliance.QueryResultModel<number>> {
        return this._gridDataSource.getRowIdsInternal(skip, take);
    }
}
