<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Data Import Specification"
                  acceptLabel="Save"
                  [suppressKeyboardEvents]="true"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!canEdit && !editForm.valid">
    <form [formGroup]="editForm">
        <div class="mb-3 row">
            <label class="col-md-2 control-label" for="type">Type: </label>
            <div class="col-md-9">
                <p class="form-control-static ws-truncate" [title]="specification.importContentTypeName">{{ specification.importContentTypeName }}</p>
            </div>
        </div>

        <div class="mb-3 row" [ngClass]="{ 'has-error': editForm.controls.displayName.touched && editForm.controls.displayName.errors }">
            <label class="col-md-2 control-label" for="displayName">Name: </label>
            <div class="col-md-9">
                <input type="text" class="form-control" id="displayName" placeholder="Name" maxlength="50" formControlName="displayName">
                <div *ngIf="editForm.controls.displayName.touched && editForm.controls.displayName.errors" class="error-feedback">
                    <div *ngIf="editForm.controls.displayName.errors?.required" class="text-danger small">Please provide a name.</div>
                </div>
            </div>
        </div>

        <div class="mb-3 row">
            <label class="col-md-2 control-label" for="visibility">Visibility: </label>
            <div class="col-md-9">
                <ws-select [isPrimitive]="true"
                           [returnEntireOption]="true"
                           [options]="visibilityOptions"
                           formControlName="visibility"></ws-select>
            </div>
        </div>

        <div class="mb-3 row">
            <label class="col-md-2 control-label" for="fileType">File type: </label>
            <div class="col-md-9">
                <p class="form-control-static ws-truncate" [title]="specification.fileType">{{ specification.fileType }}</p>
            </div>
        </div>

        <div class="mb-3 row" [ngClass]="{ 'has-error': editForm.controls.companyName.touched && editForm.controls.companyName.errors }" *ngIf="editForm.controls.companyName.enabled" >
            <label class="col-md-2 control-label" for="companyId">Company: </label>
            <div class="col-md-9">
                <div class="input-group">
                    <input type="text"
                           class="form-control"
                           autocomplete="off"
                           placeholder="Filter Companies..."
                           [typeaheadWaitMs]="750"
                           formControlName="companyName"
                           [(ngModel)]="companyFilter"
                           [typeahead]="companiesDataSource$"
                           typeaheadOptionField="companyName"
                           [typeaheadOptionsLimit]="50"
                           [typeaheadMinLength]="1"
                           [typeaheadScrollable]="true"
                           (typeaheadOnSelect)="onCompanySelected($event)"
                           (typeaheadLoading)="onCompanyLoadingChange($event)"
                           (blur)="onCompanyBlur()" />
                    <span class="input-group-text" [style.visibility]="companiesLoading ? 'visible' : 'hidden'">
                        <i class="fa fa-spin fa-spinner"></i>
                    </span>
                    <div *ngIf="editForm.controls.companyName.touched && editForm.controls.companyName.errors" class="error-feedback">
                        <span class="text-danger small">Please select a company</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-3 row">
            <label class="col-md-2 control-label" style="padding-top: 0;">Disabled:</label>
            <div class="col-md-9">
                <input type="checkbox" formControlName="isDisabled">
            </div>
        </div>
    </form>
    <ws-ag-grid-angular [gridOptions]="gridOptions"
                        (gridReady)="onAgGridReady($event)">
    </ws-ag-grid-angular>
</ws-modal-wrapper>
