import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DescriptorPickerComponent } from './descriptor.picker.component';
import { DescriptorService } from './descriptor.service';
import { DescriptorPicklistService } from './descriptor.picklist.service';
import { DescriptorHelperService } from './descriptor.helper.service';
import { PropertyCharacteristicsPickerComponent } from './Picker/property.characteristics.picker.component';
import { PropertyCharacteristicsComponent } from './property.characteristics.component';
import { DescriptorValidationComponent } from './descriptor.validation.component';
import { DescriptorOverridesService } from './descriptor.overrides.service';
import { PropertyCharacteristicsOverridesComponent } from './property.characteristics.overrides.component';
import { EntityPropertyCharacteristicsComponent } from './Entity-Property-Characteristics/entity.property.characteristics.component';
import { EntityPropertyCharacteristicsInputComponent } from './Entity-Property-Characteristics/Characteristic-Input/entity.property.characteristics.input.component';
import { EntityPropertyCharacteristicsService } from './Entity-Property-Characteristics/entity.property.characteristics.service';
import { EntityPropertyCharacteristicsListComponent } from './Entity-Property-Characteristics/entity.property.characteristics.list.component';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';

@NgModule({
    imports: [
        CommonModule,
        WeissmanSharedModule
    ],
    declarations: [
        DescriptorPickerComponent,
        PropertyCharacteristicsComponent,
        DescriptorValidationComponent,
        PropertyCharacteristicsPickerComponent,
        PropertyCharacteristicsOverridesComponent,
        EntityPropertyCharacteristicsComponent,
        EntityPropertyCharacteristicsInputComponent,
        EntityPropertyCharacteristicsListComponent
    ],
    providers: [
        DescriptorPicklistService,
        DescriptorHelperService,
        DescriptorOverridesService,
        EntityPropertyCharacteristicsService
    ],
    exports: [
        DescriptorPickerComponent,
        PropertyCharacteristicsComponent,
        PropertyCharacteristicsOverridesComponent,
        EntityPropertyCharacteristicsComponent
    ]
})
export class PropertyCharacteristicsModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('descriptorPicker', DescriptorPickerComponent);
        hybridAdapterUtility.downgradeNg2Component('propertyCharacteristics', PropertyCharacteristicsComponent);
        hybridAdapterUtility.downgradeNg2Component('propertyCharacteristicsOverrides', PropertyCharacteristicsOverridesComponent);
        hybridAdapterUtility.downgradeNg2Component('entityPropertyCharacteristics', EntityPropertyCharacteristicsComponent);

        hybridAdapterUtility.downgradeNg2Provider('DescriptorService', DescriptorService);
        hybridAdapterUtility.downgradeNg2Provider('descriptorHelperService', DescriptorHelperService);
        hybridAdapterUtility.downgradeNg2Provider('DescriptorPicklistService', DescriptorPicklistService);
    }
}
