import { Component, OnInit } from '@angular/core';
import { GridApi, GridOptions, GridReadyEvent, ColDef, CellValueChangedEvent } from 'ag-grid-community';
import { BreadCrumb } from 'src/app/UI-Lib/Bread-Crumb/breadCrumbs.component';
import { UpgradeNavigationServiceHandler } from 'src/app/Common/Routing/upgrade-navigation-handler.service';
import { AgGridOptionsBuilder, AgGridFilterParams, AgGridColumns } from 'src/app/Compliance/AgGrid';
import { CompanyService } from '../company.service';
import { VendorCodeService } from './vendorCode.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';

export interface CollectorVendorCode {
    state: string;
    collectorName: string;
    collectorID: number;
    collectorAddressID: number;
    collectorAddress: Core.AddressModel;
    vendorCodeID: number;
    vendorCode: string;
    companyID: number;
    stateID: number;
    rowVersion: string;
}

@Component({
    selector: 'vendor-code-mapping',
    templateUrl: './vendorCodeMapping.component.html',
    styles: [`
        :host {
            display: flex;
            flex-flow: column;
            height: 100%;
            width: 100%;
        }
    `]
})

export class VendorCodeMappingComponent implements OnInit {
    constructor(
        private readonly _vendorCodeService: VendorCodeService,
        private readonly _companyService: CompanyService,
        private readonly _toastr: ToastrService,
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler) { }

    private _gridApi: GridApi;
    private _vendorCodes: CollectorVendorCode[];

    companyId: number;
    showAllCollectors: boolean = false;
    breadcrumbs: BreadCrumb[];

    gridId: System.Guid = '7D885CAC-39F4-4CAE-AE56-B8948DE60508'; // need new guid
    isInitialized: boolean = false;
    gridOptions: GridOptions = new AgGridOptionsBuilder({
        suppressScrollOnNewData: true
    })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    refreshing: boolean;

    async ngOnInit(): Promise<void> {
        this.companyId = +this._upgradeNavigationServiceHandler.getQuerystringParam('companyId');
        const companyName = await this._companyService.getCompanyName(this.companyId);
        this.breadcrumbs = [
            {
                name: companyName,
                target: 'company',
                options: { companyId: this.companyId }
            }
        ];

        this.isInitialized = true;
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'State',
                field: 'state',
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Collector Name',
                field: 'collectorName',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Vendor Code',
                field: 'vendorCode',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                editable: true,
                onCellValueChanged: this._cellValueChanged.bind(this)
            },
            {
                headerName: 'Address1',
                field: 'collectorAddress.address1',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Address2',
                field: 'collectorAddress.address2',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'City',
                field: 'collectorAddress.city',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'State Abbr',
                field: 'collectorAddress.abbr',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.stateAbbreviationColumnWidth
            },
            {
                headerName: 'Zip',
                field: 'collectorAddress.zip',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            }
        ];

        const defaultSortModel = [
            {
                colId: 'state',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._gridApi.sizeColumnsToFit();

        this._refresh();
    }

    async refresh(): Promise<void> {
        await this._refresh();
    }

    private async _cellValueChanged(params: CellValueChangedEvent) {
        if (params.newValue === params.oldValue) {
            return;
        }

        let newValue = params.newValue;
        const collector = params.data as CollectorVendorCode;
        newValue = _.trim(newValue);

        if (newValue.length > 50) {
            this._toastr.error('Vendor Code must be 50 characters or less');
            params.node.setDataValue('vendorCode', params.oldValue);

            return;
        }

        if (!collector.vendorCode) {
            if (!collector.vendorCodeID) {
                return;
            }

            await this._vendorCodeService.deleteCollectorVendorCode(collector.vendorCodeID);
            this._toastr.info('Vendor Code Deleted!');
        } else {
            const payload = {
                companyID: collector.companyID || this.companyId,
                collectorID: collector.collectorID,
                vendorCodeValue: collector.vendorCode,
                vendorCodeID: collector.vendorCodeID || 0,
                rowVersion: collector.rowVersion
            } as unknown as CollectorVendorCode;

            if(payload.vendorCodeID) {
                await this._vendorCodeService.updateCollectorVendorCode(payload)
            } else {
                await this._vendorCodeService.createCollectorVendorCode(payload);
            }

            this._toastr.info('Vendor Code Saved!');
            this._refresh();
        }

    }

    private async _refresh(): Promise<void> {
        this.refreshing = true;

        this._vendorCodes = [];

        try {
            this._gridApi && this._gridApi.showLoadingOverlay();
            const vendorCodes = await this._vendorCodeService.getCompanyCollectorVendorCodes(this.companyId, this.showAllCollectors);
            this._vendorCodes = this.showAllCollectors ? vendorCodes : _.filter(vendorCodes, 'vendorCodeID');

        } finally {
            this._gridApi && this._gridApi.hideOverlay();
        }

        this._setRowData();
        this.refreshing = false;
    }

    private _setRowData() {
        if (!(this._gridApi && this._vendorCodes)) {
            return;
        }

        this._gridApi.setRowData(this._vendorCodes);
    }
}
