(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.factory('processingService', processingService);

	processingService.$inject = [
		'sd.Quick.Filters.Service',
		'searchService',
		'sd.Http.Service',
		'sd.Account.User.Service',
		'$uibModal',
		'sd.Account.User.Settings.Service',
	];

	var QUICK_SEARCH_SERVICE_URL = '/api/search';
	var DUPLICATE_SERVICE_URL = '/api/documentintake/duplicate';
	var DUPLICATE_BULK_SERVICE_URL = DUPLICATE_SERVICE_URL + '/bulk';

	function processingService(quickFilters, searchService, sdHttp, userService, $uibModal, userSettingsService) {
		var service = {
			getCriteria: getCriteria,
			getDefaultCriteria: getDefaultCriteria,
			getResultTypes: getResultTypes,
			saveCriteria: saveCriteria,
			getSavedCriteria: getSavedCriteria,
			search: search,
			completeItem: completeItem,
			processDocumentException: processDocumentException,
            processDocumentBulkException: processDocumentBulkException,
			saveItemEntity: saveItemEntity,
			getEntityTree: getEntityTree,
			getResultTypesByDocumentType: getResultTypesByDocumentType,
            launchDuplicateIntakeItemModal: launchDuplicateIntakeItemModal,
            // launchDuplicateIntakeItemBulkModal: launchDuplicateIntakeItemBulkModal,
            createDuplicateIntakeItem: createDuplicateIntakeItem,
			createDuplicateIntakeItemBulk: createDuplicateIntakeItemBulk,
			processNoAppealWarranted: processNoAppealWarranted,
			processAppealWarranted: processAppealWarranted,
			completeFileAppeal: completeFileAppeal,
			getAppealLevels: getAppealLevels,
			changeDueDate: changeDueDate,
            processPaymentReviewed: processPaymentReviewed,
            getBulkDocumentExceptionData: getBulkDocumentExceptionData
		};
		return service;

		var entityToMap = {};

		////////////////

		function getCriteria() {
			return getSavedCriteria() || getDefaultCriteria();
		}

		function getDefaultCriteria() {
			return {
				activeFilters: quickFilters.getDocumentActive(),
				inactiveFilters: quickFilters.getDocumentInactive(),
				activeOnly: true,
				primaryAcctNumOnly: true
			};
		}

		function getResultTypes() {
			var documentResultTypes = [{
				name: 'Assessments',
				type: 'assessment',
				id: 7
			}, {
				name: 'Appeals',
				type: 'appeal',
				id: 8
			}, {
				name: 'Tax Bills',
				type: 'taxBill',
				id: 9
			}, {
				name: 'Refunds',
				type: 'refund',
				id: 14
			}, {
				name: 'Comp Filing',
				type: 'filing',
				id: 10
			}, {
				name: 'Invoices',    // Added for WK-9242  -- Band aid fix. I imagine more result types are missing, but I'm not sure if we need to include all of the entity types
				type: 'invoice',
				id: 21
			}];

			return _.union(searchService.getResultTypes(), documentResultTypes)
		}

		function saveCriteria(criteria) {
			var criteriaSetting = {
				id: 0,
				name: 'Document-Processing-Criteria',
				value: criteria,
				groupId: 13,
				groupName: 'Document-Processing-Criteria',
				folderId: 20,
				folderName: 'Document-Processing-Criteria',
			};

			var existingCriteriaSettings = userSettingsService.getSettingsByGroup(13);

			if (existingCriteriaSettings.length) {
				criteriaSetting = angular.copy(existingCriteriaSettings[0]);
				criteriaSetting.value = angular.copy(criteria);
			}

			userSettingsService.save(criteriaSetting)

			return userSettingsService.save(criteriaSetting, userService.getUser().id)
				.then(function (setting) {
					console.log(setting)
				})
		}

		function getSavedCriteria() {
			var existingCriteriaSettings = userSettingsService.getSettingsByGroup(13);

			if (existingCriteriaSettings.length) {
			    var savedCriteria = angular.copy(existingCriteriaSettings[0].value);
			    savedCriteria.executeSearch = false;
				return savedCriteria;
			} else {
				return null;
			}
		}

		function search(pageNumber, numberToGet, criteria, resultTypes) {
			var resultTypeObj = _.find(resultTypes, {
				type: criteria.resultType
			});

			var queryParams = {
				resultsAs: resultTypeObj.id,
				activeOnly: criteria.activeOnly,
				primaryAcctNumOnly: criteria.primaryAcctNumOnly,
				pageNumber: pageNumber,
				pageSize: numberToGet,
				anticipatedItemsOnly: criteria.anticipated,
				currentIntakeItemID: criteria.currentIntakeItemID,
                includeIntakeItemData: criteria.includeIntakeItemData,
                anticipatedAttachmentTypeID: criteria.anticipated ? criteria.anticipatedAttachmentTypeID : null
			};

			var query = _.chain(criteria.activeFilters)
				.filter('text')
				.reduce(function (result, filter) {
					result[filter.field] = filter.text;

					return result;
				}, {})
				.assign(queryParams)
				.value();

			return sdHttp.post(QUICK_SEARCH_SERVICE_URL + '/quick', query)
				.then(function (response) {
					return {
						//results: JSON.parse(response)
						results: response
					};
				});
		}

		function processDocumentException(documentID, taskID, entityDetails, actionID, newAssignment, commentBody, newDocumentTypeID, data, intakeItemRowVersion, parcelChanges) {
			var documentTaskExceptionEndpoint = '/api/Processing/Document/' + documentID + '/Task/' + taskID + '/Exception';
			var processedEntityDetails = entityDetails.entityID !== null ? entityDetails : null;

			var exceptionDataObj = {
				documentExceptionAction: actionID,
				associatedEntity: processedEntityDetails,
				newAssignment: newAssignment,
				commentBody: commentBody,
                newDocumentTypeID: newDocumentTypeID,
				entryJSON: data,
				intakeItemRowVersion: intakeItemRowVersion,
				parcelChanges: parcelChanges
			}
			return sdHttp.put(documentTaskExceptionEndpoint, exceptionDataObj);
		}

		function processDocumentBulkException(payload) {
		    return sdHttp.post('/api/Processing/Document/BulkException', payload);
		}

		function completeItem(documentID, taskID, entityDetails, newDocumentTypeID, data, intakeItemRowVersion, parcelChanges) {
			var documentTaskProcessingEndpoint = '/api/Processing/Document/' + documentID + '/Task/' + taskID + '/Complete';
			return sdHttp.put(documentTaskProcessingEndpoint, {
                associatedEntity: entityDetails,
                entryJSON: data,
                newDocumentTypeID: newDocumentTypeID,
				intakeItemRowVersion: intakeItemRowVersion,
				parcelChanges: parcelChanges
            });
		}

		function saveItemEntity(documentID, taskID, entityDetails, newDocumentTypeID, data, intakeItemRowVersion, parcelChanges) {
			var documentTaskProcessingEndpoint = '/api/Processing/Document/' + documentID + '/Task/' + taskID;
			return sdHttp.put(documentTaskProcessingEndpoint, {
                associatedEntity: entityDetails,
                entryJSON: data,
                newDocumentTypeID: newDocumentTypeID,
				intakeItemRowVersion: intakeItemRowVersion,
				parcelChanges: parcelChanges
            });
		}

		function getEntityTree(entityTypeId, entityId, extended) {
		    var entityTreeEndpoint;
		    if (extended) {
		        entityTreeEndpoint = '/api/entityTree/extended/entityType/';

		    }
		    else {
		        entityTreeEndpoint = '/api/entityTree/entityType/';
		    }

            entityTreeEndpoint = entityTreeEndpoint + entityTypeId + '/entity/' + entityId;
			return sdHttp.get(entityTreeEndpoint);
		}

		function getResultTypesByDocumentType(documentType) {
			var resultTypes = _.filter(getResultTypes(), function (resultType) {
				var entity = _.capitalize(resultType.type);

				switch (entity) {
					case 'Taxbill':
						return documentType['allowAtTaxBill'] || documentType.allowAtTaxPayment;
					case 'Filing':
						return documentType['allowAt' + entity] || documentType.allowAtPPReturn;
					default:
						return documentType['allowAt' + entity];
				}
			});

			return resultTypes;
		}

		//TODO
        //Launch duplication modal with list of items
/*
        function launchDuplicateIntakeItemBulkModal(duplicateItemInfo) {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/Processing/Documents/Modals/_duplicateIntakeItemModal.html',
                controller: 'DuplicateIntakeItemModalController',
                controllerAs: 'vm',
                resolve: {
                    isBulk: true,
                    duplicateItem: duplicateItemInfo
                }
            });

            return modalInstance.result
                .then(function (response) {
                    if (result)
                    {
                        toastr.success(response.length + ' items were created.', 'Duplication Complete');
                    }
                });
        }
*/

        //Launch duplication modal with single item
        function launchDuplicateIntakeItemModal(duplicateItemInfo) {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/Processing/Documents/Modals/_duplicateIntakeItemModal.html',
                controller: 'DuplicateIntakeItemModalController',
                controllerAs: 'vm',
				windowClass: 'show',
				backdropClass: 'show',
                resolve: {
                    isBulk: false,
                    duplicateItem: duplicateItemInfo
                }
            });

            return modalInstance.result;
        }

        //call API with list
        function createDuplicateIntakeItem(duplicateItem) {
            return sdHttp.post(DUPLICATE_SERVICE_URL, duplicateItem);
        }

        function createDuplicateIntakeItemBulk(duplicateItem) {
            return sdHttp.post(DUPLICATE_BULK_SERVICE_URL, duplicateItem);
        }

		//call API with single


		function processNoAppealWarranted(payload) {
			return sdHttp.post('/api/processing/assessment/noappeal', payload);
		}

		function processAppealWarranted(payload) {
			return sdHttp.post('/api/processing/assessment/appealwarranted', payload);
		}

		function completeFileAppeal(payload) {
		    return sdHttp.put('/api/Tasks/completefileappeals', payload);
		}

		function getAppealLevels(taskIDs) {
			return sdHttp.post('/api/processing/assessment/appeallevels', taskIDs);
		}

		function changeDueDate(taskIDs, dueDate, searchTimestamp) {
		    return sdHttp.put('/api/tasks/changeduedates', {
		        taskIDs: taskIDs,
		        dueDate: dueDate,
                searchTimestamp: searchTimestamp
		    });
		}

		function processPaymentReviewed(payload) {
		    return sdHttp.put('/api/tasks/processpaymentreviewed', payload);
		}

		function getBulkDocumentExceptionData(taskIds) {
			return sdHttp.post('/api/processing/Document/BulkException/TaskData', taskIds);
		}
	}
})();
