(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.directive('sdQuickCriteria', sdQuickCriteria);

	sdQuickCriteria.$inject = [];

	function sdQuickCriteria() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			bindToController: true,
			controller: Controller,
			controllerAs: 'vm',
			restrict: 'E',
			templateUrl: 'app/Common/Quick-Search/quickSearchCriteria.html',
			scope: {
				criteria: '=',
				searchFn: '&',
				searchFnFromEnter: '&',
				isDocumentProcessing: '=',
				resultTypes: '=',
				currentDocument: '='
			}
		};

		return directive;
	}

	Controller.$inject = ['searchService', '$scope', 'StatesService', 'sd.Property.Type.Service', 'processingService', 'appStateService', 'productAnalyticsService']

	function Controller() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
	function ng1ControllerInit(searchService, $scope, StatesService, propertyTypeService, processingService, appStateService, productAnalyticsService) {
		var vm = this;

		activate();

		function activate() {
            appStateService.appState$.subscribe(function (state) {
                if (state === appStateService.appStates.LoggedIn) {
                    StatesService.getSummary().then(function (result) {
                        result.unshift({
                            label: ''
                        });

                        vm.states = result;
                    });
                }
            });

			propertyTypeService.get()
				.then(function (result) {
					result.unshift({
						propertyTypeID: '',
						propTypeAbbr: ''
					});

					vm.propertyTypes = result;
				});
		}

		$scope.$watch('vm.currentDocument', function (newVal, oldVal) {
			if (!newVal || (oldVal && newVal.attachmentID === oldVal.attachmentID)) {
				return;
			}

			vm.resultTypes = processingService.getResultTypesByDocumentType(vm.currentDocument.attachmentType);

			if (vm.resultTypes.length) {
				var foundResultType = _.find(vm.resultTypes, {id: vm.currentDocument.attachmentType.defaultResultsEntityTypeID});

				vm.criteria.resultType = foundResultType ? foundResultType.type : vm.resultTypes[0].type;
			}

			vm.criteria.activeFilters = _.map(vm.criteria.activeFilters, function (filter) {
				filter.text = vm.currentDocument[filter.field] || vm.currentDocument[filter.resultField] || filter.text;

				return filter
			})

			angular.forEach(vm.criteria.inactiveFilters, function (filter) {
				filter.text = vm.currentDocument[filter.field] || vm.currentDocument[filter.resultField] || filter.text;

				if (filter.text) {
					vm.criteria.activeFilters.push(filter);
					_.remove(vm.criteria.inactiveFilters, filter);
				}
			})
		})

		vm.addFilter = function (filter) {
			filter.text = '';

			_.remove(vm.criteria.inactiveFilters, filter);
			vm.criteria.activeFilters.push(filter);

			var sortedFilters = _.sortBy(vm.criteria.activeFilters, 'sequenceNum');
			var sortedIndex = _.findIndex(sortedFilters, {
				field: filter.field
			});

            productAnalyticsService.logSequenceEvent('add-new-criteria', 'clickFilter');
			searchService.focusOnFilter(sortedIndex)
		}

		vm.removeFilter = function (filter, index) {
			_.remove(vm.criteria.activeFilters, filter)
			vm.criteria.inactiveFilters.push(filter)

			searchService.focusOnFilter(index - 1)
		}

		vm.detectEnter = function (e) {
			if (e.which === 13) {
				vm.searchFnFromEnter();
			}
		}

		vm.changeResultType = function (filter) {
			var entityTypes = ['parcel', 'site', 'company'];
			var subEntityTypes = ['filing', 'taxBill', 'assessment', 'appeal'];

			var filterTypes = _.chain(vm.criteria.activeFilters)
				.filter('text')
				.map('associatedWith')
				.value();

			var foundType = _.find(_.union(entityTypes, subEntityTypes), function (type) {
				return _.includes(filterTypes, type) && _.some(vm.resultTypes, { type: type });
			});

			if(!foundType || (foundType === 'parcel' && _.includes(subEntityTypes, vm.criteria.resultType))) {
				return;
			}

			vm.criteria.resultType = foundType;
		}

		vm.showAnticipated = function () {
			var anticipatedResultTypes = ['assessment', 'appeal', 'taxBill', 'refund', 'filing'];

			return vm.isDocumentProcessing && _.includes(anticipatedResultTypes, vm.criteria.resultType);
		}

        vm.addFilterToggled = function (isOpen) {
            if (isOpen) {
                productAnalyticsService.initiateSequenceEvent({
                    event: 'add-new-criteria',
                    properties: {
                        addCriteria: 'no'
                    }
                }, ['clickAdd', 'clickAway'], true);
                productAnalyticsService.initiateSequenceEvent({
                    event: 'add-new-criteria',
                    properties: {
                        addCriteria: 'yes'
                    }
                }, ['clickAdd', 'clickFilter'], true);

            } else {
                addFilterClosed();
            }
        }

        function addFilterClosed() {
            productAnalyticsService.logSequenceEvent('add-new-criteria', 'clickAway');
        }

	}
})();
