import { ErrorHandler, NgModule } from '@angular/core';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { WeissmanSharedModule } from "../../Common/weissman-shared.module";
import { SharedPipesModule } from "../../UI-Lib/Pipes/shared-pipes.module";
import { BusyIndicatorModule } from "../../Busy-Indicator";
import { ComplianceLienDateConfigurationPanelComponent } from './Compliance-Lien-Date-Configuration-Panel/compliance-lien-date-configuration-panel.component';
import { GlobalErrorHandler } from '../../Common/ErrorHandler/globalErrorHandler';
import { httpInterceptorProviders } from '../../Common/Data/HttpInterceptors';
import { ComplianceInfoRepository } from '../Repositories/complianceInfo.repository';
import { ComplianceLienDateConfigurationExceptionComponent } from './Compliance-Lien-Date-Configuation-Exception/complianceLienDateConfigurationException.component';
import { ComplianceLienDateConfigurationService } from './complianceLienDateConfiguration.service';

@NgModule({
    imports: [
        ReactiveFormsModule,
        CommonModule,
        WeissmanSharedModule,
        SharedPipesModule,
        BusyIndicatorModule
    ],
    declarations: [
        ComplianceLienDateConfigurationPanelComponent,
        ComplianceLienDateConfigurationExceptionComponent
    ],
    providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        httpInterceptorProviders,
        ComplianceInfoRepository,
        ComplianceLienDateConfigurationService
    ]
})

export class ComplianceLienDateConfigurationModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('complianceLienDateConfigurationPanel', ComplianceLienDateConfigurationPanelComponent);
    }
}
