import { Injectable } from '@angular/core';
import { ReturnPartServiceBase } from '../../Models/returnPartServiceBase';
import { ReturnService } from '../../return.service';
import { AssessorFactorsEnum, ReturnAssetsServiceSharedState } from '../Assets/returnAssets.service';
import { ReturnAssetRepository, ReturnRepository } from '../../../Repositories';
import { ScheduleAdditionsDisposalsReconciliationReport } from '../../Models/scheduleAdditionsDisposalsReconciliationReport';
import { ReturnSummaryReconciliationReport } from '../../Models/returnSummaryReconciliationReport';
import { Subject, Observable, lastValueFrom } from 'rxjs';
import { FilingControlReconciliationReport } from '../../Models/filingControlReconciliationReport';
import { ReconciliationReport } from '../../Models/reconciliationReport';
import { ReturnPreviewServiceSharedState } from '../Preview/returnPreview.service';

export interface ReturnOverviewServiceSharedState {
    reconciliationReport: ReconciliationReport;
    firstLoad: boolean;
}

export enum FilingControlGroupBy {
    None,
    Assessor,
    Consolidated
}

@Injectable()
export class ReturnOverviewService extends ReturnPartServiceBase {
    constructor(
        private readonly _returnRepository: ReturnRepository,
        private readonly _returnAssetRepository: ReturnAssetRepository,
        private readonly _returnService: ReturnService
    ) { super(); }

    private _sharedState: ReturnOverviewServiceSharedState = {
        reconciliationReport: null
    } as ReturnOverviewServiceSharedState;

    private _reconciliationReportSubject: Subject<ReconciliationReport> = new Subject();
    private _returnAssetsSelectedSubject: Subject<ReturnAssetsServiceSharedState> = new Subject();
    private _returnPreviewSelectedSubject: Subject<ReturnPreviewServiceSharedState> = new Subject();

    get sharedState(): ReturnOverviewServiceSharedState { return this._sharedState; }

    get reconciliationReport$(): Observable<ReconciliationReport> { return this._reconciliationReportSubject.asObservable(); }
    get returnAssetsSelected$(): Observable<ReturnAssetsServiceSharedState> { return this._returnAssetsSelectedSubject.asObservable(); }
    get returnPreviewSelected$(): Observable<ReturnPreviewServiceSharedState> { return this._returnPreviewSelectedSubject.asObservable(); }

    initialize(): void {
        this._sharedState = {
            reconciliationReport: null,
            firstLoad: true // on first load, do not load the report because it is part of the filing batch start payload
        } as ReturnOverviewServiceSharedState;
    }

    setReconciliationReport(reconciliationReport: ReconciliationReport): void {
        this._sharedState.reconciliationReport = reconciliationReport;

        // if the report doesn't support primary for return and primary for return is selected, select a form
        if ((!reconciliationReport.allowPrimaryForReturn) && (!this._returnService.sharedState.formRevisionId)) {
            this._returnService.setFirstFormRevisionId();
        }

        this._reconciliationReportSubject.next(this._sharedState.reconciliationReport);
    }

    notifyReturnAssetsSelected(value: ReturnAssetsServiceSharedState): void {
        this._returnAssetsSelectedSubject.next(value);
    }

    notifyReturnPreviewSelected(value: ReturnPreviewServiceSharedState): void {
        this._returnPreviewSelectedSubject.next(value);
    }

    async getScheduleAdditionsDisposalReconciliationReport(): Promise<ScheduleAdditionsDisposalsReconciliationReport> {
        // the schedule summary returns information about assets not assigned to a schedule
        // using this API call to put together a list of all the schedules that needs to be passed to schedule details
        const searchModel = {
            assessorFilter: AssessorFactorsEnum.AnyAssessor,
            formRevisionIds: [this._returnService.sharedState.formRevisionId],
            parcelIds: this._returnService.sharedState.returns.map(x => x.parcelId),
            priorReturnStatuses: [],
            aggregationLevel: Compliance.ScheduleDetailsAggregationLevelEnum.Schedule
        } as Compliance.ReturnAssetScheduleDetailsSearchModel;

        const responses = await Promise.all([
            lastValueFrom(this._returnAssetRepository.getScheduleDetailsByFilingBatch(this._returnService.filingBatchId, searchModel)),
            lastValueFrom(this._returnAssetRepository.getUnassignedScheduleDetailsByFilingBatch(this._returnService.filingBatchId, searchModel))
        ]);

        const assignedScheduleDetails = responses[0] as Compliance.ReturnAssetScheduleDetailsAssignedItemModel[];
        const unassignedScheduleDetails: Compliance.ReturnAssetScheduleDetailsUnassignedItemModel[] = responses[1];

        return new ScheduleAdditionsDisposalsReconciliationReport(assignedScheduleDetails, unassignedScheduleDetails);
    }

    async getReturnSummaryReconciliationReport(): Promise<ReturnSummaryReconciliationReport> {
        let returnSummaryResult: Compliance.ReturnSummaryModel;

        /* Initial state is null, so since first load, we'll assume the first one is ok.*/
        if (this._sharedState && this._sharedState.firstLoad && this._returnService.returnSummary) {
            returnSummaryResult = this._returnService.returnSummary;
            this._sharedState.firstLoad = false;
        }
        else {
            const inUseReturnFormRevisions = this._returnService.getAssociatedReturnFormRevisions();
            const formRevisions = inUseReturnFormRevisions.filter(x => x.formRevisionId === this._returnService.sharedState.formRevisionId);
            let parcelIds: number[];

            //Check if a specific form is selected
            if (formRevisions.length !== 0) {
                parcelIds = this._returnService.sharedState.returns
                    .filter(x => formRevisions.map(x => x.returnId).includes(x.returnId))
                    .map(x => x.parcelId);
            } else { //Primary for return form is selected
                parcelIds = this._returnService.sharedState.returns.map(x => x.parcelId);
            }

            returnSummaryResult = await lastValueFrom(this._returnRepository.getReturnSummary(
                this._returnService.filingBatchId,
                this._returnService.sharedState.formRevisionId,
                parcelIds
            ));
        }
        return new ReturnSummaryReconciliationReport(returnSummaryResult);
    }

    async getFilingControlReconciliationReport(): Promise<FilingControlReconciliationReport> {
        const filingControlSummaryResult: Compliance.ReturnFilingControlSummaryModel = await lastValueFrom(this._returnRepository.getReturnFilingControlSummary(
            this._returnService.filingBatchId,
            this._returnService.sharedState.formRevisionId,
            this._returnService.sharedState.returns.map(x => x.parcelId)
        ));

        return new FilingControlReconciliationReport(filingControlSummaryResult, this._returnService.getAssociatedReturnFormRevisions(),
            this._returnService.sharedState.returns);
    }
}
