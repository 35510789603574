import { NgModule, ErrorHandler } from '@angular/core';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { ElectronicFilingModule } from './Electronic-Filing/electronicFiling.module';
import { EntityImportModule } from './Entity-Import/entityImport.module';
import { AssetModule } from './Asset/asset.module';
import { FormModule } from './Form/form.module';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridLoadingOverlayComponent } from '../Common/AgGrid/agGridLoadingOverlay.component';
import { BusyIndicatorModule } from '../Busy-Indicator';
import { FactorTableModule } from './Factor-Table/factorTable.module';
import { GLAccountModule } from './GL-Account/glAccount.module';
import { FilingBatchModule } from './Filing-Batch/filingBatch.module';
import { ReturnModule } from './Return/return.module';
import { SupplementalInformationModule } from './Supplemental-Information/supplementalInformation.module';
import { LongRunningProcessModule } from './Long-Running-Process/longRunningProcess.module';
import {
    EntityImportRepository,
    AssetRepository,
    CostAdjustmentRepository,
    AssetClassificationRepository,
    FactorTableRepository,
    FormRepository,
    GLAccountRepository,
    FilingBatchRepository,
    ReturnRepository,
    ReturnAssetRepository,
    ReturnFormRepository,
    ReturnFormRevisionRepository,
    SupplementalInformationRepository,
    ReturnFormRevisionReportRepository,
    ReturnReportRepository,
    ReturnSettingsRepository,
    GridLayoutSpecificationRepository,
    CompanyAssetDescriptorRepository,
    LongRunningProcessRepository,
    ReportingParcelRepository,
    ErrorLogRepository,
    QueryInfoRepository,
    ImportSpecificationRepository,
    CompanyRepository,
    IncomeStatementCategoryRepository,
    AssessorCommandCenterRepository
} from './Repositories';
import { httpInterceptorProviders } from '../Common/Data/HttpInterceptors';
import { GlobalErrorHandler } from '../Common/ErrorHandler/globalErrorHandler';
import { LoggingService } from '../Common/Logging/logging.service';
import { AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectedCellRenderer } from './AgGrid/MultiSelectTracker';
import { WeissmanModalService} from './WeissmanModalService';
import { EntityPickerService } from './Entity-Picker/entityPicker.service';
import { EntityPickerComponent } from './Entity-Picker/entityPicker.component';
import { WeissmanMutexService } from './WeissmanMutexService';
import { AgGridExtensionsModule } from './AgGrid/agGridExtensions.module';
import { ComplianceParcelModule } from './Parcel/parcel.module';
import { AgGridDateFilterComponent } from './AgGrid';
import { ComplianceCompanyModule } from './Company/company.module';
import { ComplianceInfoRepository } from './Repositories/complianceInfo.repository';
import { ComplianceSiteModule } from './Site/site.module';
import { ErrorLogModule } from '../Diagnostics/Error-Log/errorLog.module';
import { QueryInfoModule } from '../Diagnostics/Query-Info/queryInfo.module';
import { FormSetupRepository } from './Repositories';
import { StateJurisdictionModule } from './State-And-Jurisdiction/stateJurisdiction.module';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { AllocationModule } from './Allocation/allocation.module';
import { ComplianceLienDateConfigurationModule } from './Compliance-Lien-Date-Configuration/complianceLienDateConfiguration.module';
import {FormLibraryRepository} from './Repositories/formLibraryRepository';

@NgModule({
    imports: [
        WeissmanSharedModule,
        EntityImportModule,
        AssetModule,
        FactorTableModule,
        FormModule,
        GLAccountModule,
        FilingBatchModule,
        ReturnModule,
        ComplianceCompanyModule,
        ComplianceSiteModule,
        ComplianceParcelModule,
        StateJurisdictionModule,
        SupplementalInformationModule,
        LongRunningProcessModule,
        ErrorLogModule,
        QueryInfoModule,
        BusyIndicatorModule.forRoot(),
        AgGridModule.withComponents([
            // shared
            AgGridLoadingOverlayComponent,
            AgGridMultiSelectedHeaderRenderer,
            AgGridMultiSelectedCellRenderer,
            AgGridDateFilterComponent
        ]),
        AllocationModule,
        ComplianceLienDateConfigurationModule,
        ElectronicFilingModule
    ],
    declarations: [
        EntityPickerComponent
    ],
    providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        httpInterceptorProviders,
        LoggingService,
        EntityImportRepository,
        ImportSpecificationRepository,
        AssetRepository,
        AssetClassificationRepository,
        CostAdjustmentRepository,
        FactorTableRepository,
        FormRepository,
        GLAccountRepository,
        FilingBatchRepository,
        ReturnRepository,
        ReturnAssetRepository,
        ReturnFormRepository,
        ReturnFormRevisionRepository,
        ReturnFormRevisionReportRepository,
        ReturnReportRepository,
        SupplementalInformationRepository,
        ReturnSettingsRepository,
        GridLayoutSpecificationRepository,
        WeissmanModalService,
        WeissmanMutexService,
        EntityPickerService,
        CompanyAssetDescriptorRepository,
        LongRunningProcessRepository,
        ErrorLogRepository,
        QueryInfoRepository,
        ReportingParcelRepository,
        ComplianceInfoRepository,
        FormSetupRepository,
        CompanyRepository,
        AssessorCommandCenterRepository,
        IncomeStatementCategoryRepository,
        FormLibraryRepository
    ],
    exports: []
})
export class ComplianceModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        EntityImportModule.setupModule();
        AssetModule.setupModule();
        FactorTableModule.setupModule();
        FormModule.setupModule();
        GLAccountModule.setupModule();
        FilingBatchModule.setupModule();
        ReturnModule.setupModule();
        SupplementalInformationModule.setupModule();
        LongRunningProcessModule.setupModule();
        ErrorLogModule.setupModule();
        QueryInfoModule.setupModule();
        ComplianceParcelModule.setupModule();
        ComplianceCompanyModule.setupModule();
        ComplianceSiteModule.setupModule();
        StateJurisdictionModule.setupModule();
        hybridAdapterUtility.downgradeNg2Provider('longRunningProcessRepository', LongRunningProcessRepository);
        AllocationModule.setupModule();
        ComplianceLienDateConfigurationModule.setupModule();
        ElectronicFilingModule.setupModule();
    }
}
