import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ReturnService } from '../return.service';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import { WeissmanModalService } from '../../WeissmanModalService';
import { ReturnLockModalComponent, ReturnLockModalParams } from './Return-Lock-Modal/returnLockModal.component';
import { IMutexServiceHandler, WeissmanMutexService } from '../../WeissmanMutexService';
import { Subscription } from 'rxjs';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { TaskTypes, ReturnSignActions } from '../Models/enums';
import { TaskError } from '../Models/taskError';
import { ReturnSignModalComponent } from './Return-Sign-Modal/returnSignModal.component';
import { ReturnFinalizeModalComponent, ReturnFinalizeModalParams } from './Return-Finalize-Modal/returnFinalizeModal.component';

interface TaskMapping {
    active: string;
    next: string;
    title: string;
    action: () => Promise<void>;
    confirm: boolean;
}

@Component({
    selector: 'return-task',
    templateUrl: './returnTask.component.html'
})
export class ReturnTaskComponent implements OnInit, OnDestroy, IMutexServiceHandler {
    constructor(
        private readonly _returnService: ReturnService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _modalService: WeissmanModalService,
        private readonly _messageModalService: MessageModalService,
        private readonly _busyService: BusyIndicatorService) { }

    @Output() taskError: EventEmitter<TaskError> = new EventEmitter<TaskError>();

    currentTask: TaskMapping;
    nextTask: TaskMapping;
    canEdit: boolean;
    currentTaskId: number;

    private _tasksSub: Subscription;
    private _taskMap = new Map<TaskTypes, TaskMapping>(
        [
            [TaskTypes.PPBatchReturnLoadData, { title: 'Mark Load Data Complete', next: 'and requests data load', active: 'Completes the load data task', action: this._completeTask.bind(this), confirm: true }],
            [TaskTypes.PPBatchReturnPrepareReturns, { title: 'Mark Preparation Complete (Lock)', next: 'allowing return preparation to begin', active: 'Locks the returns, completes the prepare returns task', action: this._prepareReturns.bind(this), confirm: false }],
            [TaskTypes.PPBatchReturnReviewReturns, { title: 'Mark Review Complete', next: 'and requests review', active: 'Completes the review task', action: this._completeTask.bind(this), confirm: true }],
            [TaskTypes.PPBatchReturnApproveReturns, { title: 'Approve Returns for Filing', next: 'and requests approval', active: 'Completes the approve returns task', action: this._completeTask.bind(this), confirm: true }],
            [TaskTypes.PPBatchReturnDigitiallySignReturns, { title: 'Apply Applicable Digital Signatures', next: 'and requests digital signature be applied', active: 'Applies digital signature', action: this._digitallySignReturns.bind(this), confirm: false }],
            [TaskTypes.PPBatchReturnFileReturns, { title: 'Mark Return Filing Complete', next: 'and requests returns be filed', active: 'Completes the file PP returns task', action: this._completeTask.bind(this), confirm: true }],
            [TaskTypes.PPBatchReturnFinalizeReturns, { title: 'Finalize Returns (No Undo)', next: 'and requests finalization', active: 'Finalizes returns optionally copying return content to the individual filings under parcel accounts. This process cannot be undone', action: this._finalizeReturns.bind(this), confirm: false }],
            [TaskTypes.PPReturnFiling, { title: 'Mark Return Filing Complete', next: 'and requests the return be filed', active: 'Completes the file PP return task', action: this._completeTask.bind(this), confirm: true }]
        ]
    );

    get canEnterEditMode(): boolean { return this._mutexService.canAcquire(this._returnService.editGroup); }
    get isLongRunningProcessActive(): boolean { return this._returnService.isLongRunningProcessActive; }

    get currentTaskTooltip(): string {
        if (!this.currentTask) {
            return '';
        }

        if (!this.canEnterEditMode) {
            return 'Not available in edit mode.  Save changes or cancel.';
        }

        if (this.isLongRunningProcessActive) {
            return 'Not available because a process is busy.';
        }

        if (!this.canEdit) {
            return 'Not available to complete.';
        }

        return `${this.currentTask.active}${this.nextTask ? ` ${  this.nextTask.next}` : ''}.`;
    }

    ngOnInit(): void {
        this._refreshTasks();
        this._tasksSub = this._returnService.tasks$.subscribe(() => this._refreshTasks());
    }

    ngOnDestroy(): void {
        this._tasksSub && this._tasksSub.unsubscribe();
    }

    async completeCurrentTask(): Promise<void> {
        if (this.currentTask.confirm) {
            try {
                await this._messageModalService.prompt(`Are you sure you wish to ${this.currentTask.title.toLowerCase()}?`, 'Confirm');
            } catch (e) {
                return;
            }
        }

        try {
            await this.currentTask.action();
        } catch (e2) {
            this.taskError.next({
                error: e2,
                message: `Failed to ${this.currentTask.title.toLowerCase()}.`
            } as TaskError);
        }
    }

    wsMutexRelease(groupId: string): Promise<void> {
        return Promise.resolve();
    }

    private _refreshTasks(): void {
        // set the current task
        let currentTask: TaskMapping = null;
        let canEdit: boolean = false;
        let currentTaskId: number = null;

        if (this._returnService.currentTask) {
            currentTask = this._taskMap.get(this._returnService.currentTask.taskTypeID);
            canEdit = this._returnService.currentTask.taskAuthorizationInfo.completeTask;
            currentTaskId = this._returnService.currentTask.taskID;
        }

        this.currentTask = currentTask;
        this.canEdit = canEdit;
        this.currentTaskId = currentTaskId;

        // set the next task
        let nextTask: TaskMapping = null;

        if (this._returnService.nextTask) {
            nextTask = this._taskMap.get(this._returnService.nextTask.taskTypeID);
        }

        this.nextTask = nextTask;
    }

    private async _completeTask(): Promise<void> {
        const busyRef = this._busyService.show({ message: 'Completing Task' });

        try {
            const taskUpdateModel = {
                taskId: this.currentTaskId
            } as Compliance.ReturnTaskUpdateModel;

            await this._returnService.completeTask(taskUpdateModel);
        } finally {
            await busyRef.hide();
        }
    }

    private async _skipTask(): Promise<void> {
        const busyRef = this._busyService.show({ message: 'Skipping Task' });

        try {
            const taskUpdateModel = {
                taskId: this.currentTaskId
            } as Compliance.ReturnTaskUpdateModel;

            await this._returnService.skipTask(taskUpdateModel);
        } finally {
            await busyRef.hide();
        }
    }

    private async _prepareReturns(): Promise<void> {
        const params: ReturnLockModalParams = {
            filingBatchId: this._returnService.filingBatchId,
            companyId: this._returnService.companyId,
            returnTaskUpdate: {
                taskId: this.currentTaskId
            } as Compliance.ReturnTaskUpdateModel
        };

        const result = await this._modalService.showAsync(ReturnLockModalComponent, params, 'modal-lg');

        if (!result) {
            return Promise.resolve();
        }

        const busyRef = this._busyService.show({ message: 'Locking' });

        try {
            await this._returnService.startLocking(result);
            this._returnService.notifyProcessStatusChange(Compliance.FilingBatchProcessStatusEnum.Locking);
        } finally {
            await busyRef.hide();
        }

        return Promise.resolve();
    }

    private async _digitallySignReturns(): Promise<void> {
        const result = await this._modalService.showAsync(ReturnSignModalComponent, null, 'modal-xl');

        if (!result) {
            return Promise.resolve();
        }

        if (result.action === ReturnSignActions.Skip) {
            await this._skipTask();
        } else {
            const returnSignRequestModel: Compliance.ReturnSignRequestModel =
            {
                filingBatchId: this._returnService.filingBatchId,
                companyId: this._returnService.companyId,
                returnTaskUpdate: {
                    taskId: this.currentTaskId
                }
            };

            const busyRef = this._busyService.show({ message: 'Signing Applicable Returns' });

            try {
                await this._returnService.startSigning(returnSignRequestModel);
                this._returnService.notifyProcessStatusChange(Compliance.FilingBatchProcessStatusEnum.Signing);
            } finally {
                await busyRef.hide();
            }
        }
    }

    private async _finalizeReturns(): Promise<void> {
        const nextYearBatch = await this._returnService.nextYearInfo(this._returnService.filingBatchId);

        const params: ReturnFinalizeModalParams = {
            filingBatchId: this._returnService.filingBatchId,
            companyId: this._returnService.companyId,
            taskUpdateModel: {
                taskId: this.currentTaskId
            },
            nextYearBatch: nextYearBatch
        };

        const result = await this._modalService.showAsync(ReturnFinalizeModalComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        let busyRef = this._busyService.show({ message: 'Finalizing' });

        try {
            await this._returnService.startFinalizing(result);
            busyRef.hide();

            this._returnService.notifyProcessStatusChange(Compliance.FilingBatchProcessStatusEnum.Finalizing);
        } catch (e) {
            busyRef.hide();

            // service returns a 422 and a message if user confirmation needed
            if (e && e.status !== 422) {
                return Promise.reject(e);
            }

            try {
                await this._messageModalService.confirm(e.error.message, 'Confirm');
            } catch (e2) {
                return Promise.resolve();
            }

            busyRef = this._busyService.show({ message: 'Finalizing' });

            // force
            result.force = true;
            await this._returnService.startFinalizing(result);
            busyRef.hide();

            this._returnService.notifyProcessStatusChange(Compliance.FilingBatchProcessStatusEnum.Finalizing);
        } finally {
            await busyRef.hide();
        }
    }

}
