import { Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
    FilterTimeFrame,
    GridSourceOptionEnum,
    StateJurisdictionCommandCenterService
} from './stateJurisdiction.service';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { AgGridOptionsBuilder } from '../AgGrid';
import { ColDef, ColumnApi, GridApi, GridReadyEvent } from 'ag-grid-community';
import {
    BASE_DYNAMIC_DEFINITIONS,
    BASE_VERIFICATION_COLUMN,
    DeadlineInfo,
    STATE_JURISDICTION_LIST_COLUMNS
} from './stateJurisdiction.columns';
import { AgGridMultiSelectTracker } from '../AgGrid/MultiSelectTracker';
import { IMutexServiceHandler, WeissmanMutexService } from '../WeissmanMutexService';
import { StateJurisdictionCommandCenterAgGridDataSource } from './agGridDataSource';
import { AgGridExportOptions, AgGridExportStartLRP } from '../AgGrid/ToolPanel/models';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';
import { WeissmanDateFormat } from '../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';
import { DatepickerCellEditorParams } from '../AgGrid/CellEditors/agGridDatePickerCellEditor.component';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import {
    StateJurisdictionBulkUpdateComponent,
    StateJurisdictionBulkUpdateParams
} from './Bulk-Update/stateJurisdictionBulkUpdate.component';
import { WeissmanModalService } from '../WeissmanModalService';
import { NavigationService } from '../../Layout/Navigation.Service.upgrade';
import { HelpService } from '../../UI-Lib/Help-Tooltip';
import { STATE_JURISDICTION_COMMAND_CENTER_HELP } from './stateJurisdiction.component.help';
import { BusyIndicatorService } from '../../Busy-Indicator';
import { DropdownCellEditorParams } from '../AgGrid/CellEditors/agGridDropdownCellEditor.component';
import { ToastrService } from 'ngx-toastr';
import LongRunningProcessTypeEnum = Compliance.LongRunningProcessTypeEnum;

@Component({
    selector: 'state-jurisdiction-command-center',
    templateUrl: './stateJurisdiction.component.html',
    styleUrls: ['./stateJurisdiction.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class StateJurisdictionCommandCenterComponent implements OnInit, OnDestroy, IMutexServiceHandler {
    constructor(
        private readonly _stateJurisdictionService: StateJurisdictionCommandCenterService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _messageModalService: MessageModalService,
        private readonly _restrictService: RestrictService,
        private readonly _modalService: WeissmanModalService,
        private readonly _navigationService: NavigationService,
        private readonly _helpService: HelpService,
        private readonly _busyService: BusyIndicatorService,
        private readonly _toastr: ToastrService) { }

    @HostBinding('class.state-jurisdiction-command-center') hostClass = true;

    selectedGridSourceOption: Compliance.NameValuePair<GridSourceOptionEnum>;
    gridSourceOptions: Compliance.NameValuePair<GridSourceOptionEnum>[];

    canGetRows: boolean = false;
    canEdit: boolean = false;
    editing: boolean = false;
    isBulkUpdateVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isInitialized: boolean = false;
    gridId: System.Guid = 'c0899ab1-6634-4a4c-b9cc-f148cb781959';
    gridTracker: AgGridMultiSelectTracker;
    columnApi: ColumnApi;
    gridOptions = new AgGridOptionsBuilder({
        onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
        onSortChanged: () => this.gridTracker.onGridSortChanged(),
        rowClassRules: {
            'clickable': (params) => false,
            'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as Compliance.AssessorCommandCenterModel).assessorId)
        },
        singleClickEdit: true
    })
        .withCellEditingStarted(x => {
            this.startEditMode();
        })
        .withCellEditingStopped(x => {
            this.endEditMode();
        })
        .withoutCellEditingStoppedOnGridLostFocus()
        .withColumnPinning()
        .buildDefault(this);

    exportOptions: AgGridExportOptions = {
        start: async (columnsToReturn: Compliance.NameValuePair<string>[]): Promise<AgGridExportStartLRP> => {
            const searchModel = this._gridDataSource.getSearchParamsWithoutPagination();
            searchModel.selectedRows = this.gridTracker.getSelectedRowsModel();

            if (!searchModel.selectedRows.selectAllRows && searchModel.selectedRows.selectedRows.length === 0) {
                searchModel.selectedRows.selectAllRows = true;
            }

            const exportModel: Compliance.AssessorCommandCenterExportModel = {
                columnsToReturn: columnsToReturn,
                searchModel: searchModel
            };
            const longRunningProcessId = await this._stateJurisdictionService.exportList(exportModel);
            return { longRunningProcessId, longRunningProcessTypeId: LongRunningProcessTypeEnum.ExportAssessorCommandCenterAssessors };
        },
        disabled: true,
        canCancel: true
    };

    verificationStatus: Compliance.AssessorCommandCenterModelPropertyEnum;

    private _gridApi: GridApi;
    private _gridDataSource: StateJurisdictionCommandCenterAgGridDataSource;
    private _highlightDate: FilterTimeFrame;
    private _destroy$: Subject<void> = new Subject();

    get inEditMode(): boolean {
        return !this._mutexService.canAcquire(this._stateJurisdictionService.editGroup);
    }

    get refreshHelpContentId(): string {
        if (!this.canGetRows) {
            return 'state-jurisdiction-command-center.filter-disabled';
        }

        return 'app.refresh';
    }

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(STATE_JURISDICTION_COMMAND_CENTER_HELP);

        const busyRef = this._busyService.show({ message: 'Loading' });

        try {
            await this._stateJurisdictionService.start();

            this.gridSourceOptions = this._stateJurisdictionService.gridSourceOptions;

            this.canEdit = this._restrictService.isInRole(Roles.ASSESSOREDIT);

            this._stateJurisdictionService.selectedGridSourceOption$.pipe(takeUntil(this._destroy$))
                .subscribe(option => this.selectedGridSourceOption = option);

            this._stateJurisdictionService.currentSearchModel$.pipe(takeUntil(this._destroy$)).subscribe(() => {
                this.canGetRows = this._stateJurisdictionService.canGetRows;
                this.exportOptions.disabled = !this.canGetRows;
                this._refreshDataSource();
            });

            this._stateJurisdictionService.currentFilterModel$
                .pipe(takeUntil(this._destroy$)).subscribe((model) => {
                this.verificationStatus = model && model.property;
                this._refreshDataSource();
            });

            this._stateJurisdictionService.highlightChanges$.pipe(takeUntil(this._destroy$)).subscribe(model => {
                this._highlightDate = model;
                if (this._gridApi) {
                    this._gridApi.redrawRows();
                }
            });

            this._stateJurisdictionService.updatingValue$.pipe(takeUntil(this._destroy$)).subscribe(updating => {
                if (this._gridApi) {
                    if (updating) {
                        this._gridApi.showLoadingOverlay();
                    } else {
                        this._gridApi.hideOverlay();
                    }
                }
            });

            this.isInitialized = true;
        } finally {
            busyRef.hide();
        }
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this.gridTracker.selectedRows$.pipe(takeUntil(this._destroy$)).subscribe(() => {
            const isBulkUpdateVisible = this.canEdit && this.gridTracker.hasSelectedRows();
            this.isBulkUpdateVisible$.next(isBulkUpdateVisible);
        });

        this._setColumnDefinitions();

        const defaultSortModel = [
            {
                colId: 'assessorAbbr',
                sort: 'asc'
            }
        ];

        this._gridApi.setSortModel(defaultSortModel);
        this._setDataSource();
    }

    wsMutexRelease(groupId: string): Promise<void> {
        return Promise.resolve();
    }

    setSelectedGridSourceOption(gso: Compliance.NameValuePair<GridSourceOptionEnum>): void {
        this._stateJurisdictionService.setSelectedGridSourceOption(gso);
    }

    startEditMode(): void {
        this.editing = true;
        this._mutexService.acquire(this, this._stateJurisdictionService.editGroup);
        this._navigationService.enableNavWarning('Editing is in progress.  Are you sure you wish to leave?');
    }

    endEditMode(): void {
        this.editing = false;
        this._gridApi.stopEditing();
        this._mutexService.release(this, this._stateJurisdictionService.editGroup);
        this._navigationService.disableNavWarning();
    }

    refresh(): void {
        this._refreshDataSource();
    }

    async bulkUpdate(): Promise<void> {
        const params: StateJurisdictionBulkUpdateParams = {
            selection: this.gridTracker.getSelectedRowsModel(),
            selectedCount: this.gridTracker.getSelectedRowsCount(),
            descriptorInfo: this._stateJurisdictionService.descriptorInfo
        };

        const result = await this._modalService.showAsync(StateJurisdictionBulkUpdateComponent, params, 'modal-lg');
        if (!result) {
            return;
        }

        if (result.warnings && result.warnings.length) {
            await this._messageModalService.alertList(result.warnings, 'Bulk update notice');
        }

        this._refreshDataSource();
    }

    async updateDefaultAddressType(params: DropdownCellEditorParams, updatedValue: string): Promise<void> {
        const rowData = params.data as Compliance.AssessorCommandCenterModel;

        let correspondenceType: number;
        switch (updatedValue) {
            case 'Appeal Filing':
                correspondenceType = Core.CorrespondenceTypes.AppealFiling;
                break;
            case 'General':
                correspondenceType = Core.CorrespondenceTypes.General;
                break;
            case 'PP Return':
                correspondenceType = Core.CorrespondenceTypes.PPReturn;
        }

        const updateParams: Compliance.AssessorCommandCenterDefaultAddressUpdateModel = {
            assessorId: rowData.assessorId,
            correspondenceType,
            searchModel: this._gridDataSource.getSearchParamsWithoutPagination()
        };

        try {
            await this._stateJurisdictionService.updateDefaultAddressType(updateParams);
        } catch (err) {
            this._toastr.error(err.error.message);
        } finally {
            this._refreshDataSource();
        }
    }

    async updateDeadline(params: DatepickerCellEditorParams, updatedDate: string): Promise<void> {
        const rowModel = params.data as Compliance.AssessorCommandCenterModel;
        if (!rowModel) {
            return Promise.resolve();
        }

        const deadlineInfo = params.args as DeadlineInfo;
        if (!deadlineInfo) {
            return Promise.resolve();
        }

        const initialDate = params.value && WeissmanDateFormat(params.value, true);
        if (updatedDate === initialDate) {
            return Promise.resolve();
        }

        const updateModel: Compliance.AssessorCommandCenterDeadlineUpdateModel = {
            assessorId: rowModel.assessorId,
            deadlineDate: (updatedDate) ? new Date(updatedDate) : null,
            force: false,
            searchModel: this._stateJurisdictionService.currentSearchModel,
            propertyType: deadlineInfo.propertyType,
            deadlineType: deadlineInfo.deadlineType
        };

        this._stateJurisdictionService.loading(true);

        let result: Compliance.AssessorCommandCenterModel = null;

        try {
            result = await this._stateJurisdictionService.updateDeadline(updateModel);
        } catch (e) {
            // service returns a 422 and a message if the user confirmation needed to force update
            if (e && e.status !== 422) {
                return Promise.reject(e);
            }
            this._stateJurisdictionService.loading(false);
            const deadlineInfo = params.args as DeadlineInfo;

            try {
                await this._messageModalService.confirm(e.error.message, `Confirm ${deadlineInfo ? deadlineInfo.name : 'Date'} Exception`);
            } catch (cancel) {
                // revert the cell value
                params.node.setDataValue(params.column.getColId(), params.value);
                return Promise.resolve();
            }

            this._stateJurisdictionService.loading(true);

            updateModel.force = true;
            result = await this._stateJurisdictionService.updateDeadline(updateModel);
        } finally {
            this._stateJurisdictionService.loading(false);
        }

        if (!result) {
            return Promise.resolve();
        }

        await this._gridDataSource.updateRow(y => {
                const rowModel = y.data as Compliance.AssessorCommandCenterModel;
                return rowModel && rowModel.assessorId === result.assessorId;
            },
            () => {
                return Promise.resolve(result);
            });

        return Promise.resolve();
    }

    onIncludeCompletedChange(): void {
        this._refreshDataSource();
    }

    private async _setColumnDefinitions(): Promise<void> {
        const columns = STATE_JURISDICTION_LIST_COLUMNS(this);

        const pickList = this._stateJurisdictionService.descriptorInfo.pickList;

        this._stateJurisdictionService.descriptorInfo.descriptors.reduce((acc, descriptor) => {
            const column: ColDef = BASE_DYNAMIC_DEFINITIONS[descriptor.fieldType](descriptor, pickList);
            column.editable = () => this.canEdit;
            column.cellEditorParams.cellFocusLost = (params, updatedValue) => this._updateDescriptor(params, updatedValue, descriptor);
            column.cellClass = (params) => {
                const model = params.data as Compliance.AssessorCommandCenterModel;
                if (!model) { return ''; }
                return this._getCellStyle(model, descriptor);
            };
            column.cellRendererParams = {
                getTooltipText: (params) => {
                    const model = params.data as Compliance.AssessorCommandCenterModel;
                    if (!model) { return ''; }
                    return this._getCellWarning(model, descriptor);
                }
            };
            acc.push(column);

            const verifiedColumn: ColDef = BASE_VERIFICATION_COLUMN(descriptor);
            acc.push(verifiedColumn);

            return acc;
        }, columns);

        this._gridApi.setColumnDefs(columns);
    }

    getAddressCellStyle(verifications: Compliance.AssessorCommandCenterVerificationElementModel[], elementId: number, address: string, changeDate: Date, addressCount: number): string {
        let style = '';
        const verification = verifications.find(v => v.elementId === elementId);

        // verification style
        if (verification && verification.verifiedDate && verification.qcDate) {
            style += 'cell-qc';
        } else if (verification && verification.verifiedDate) {
            if (this.verificationStatus === Compliance.AssessorCommandCenterModelPropertyEnum.IsPendingQC) {
                style += 'cell-pending-qc';
            }
        } else if (address && addressCount > 1) {
            style += 'cell-validation-warning';
        }

        // highlight styles
        let isRecent = false;
        if (this._highlightDate && changeDate) {
            const highlightDate = this._highlightDate.date.getTime();
            const changed = changeDate.getTime();
            isRecent = (this._highlightDate.direction === 'gt')
                ? (changed < highlightDate)
                : (changed > highlightDate);
        }
        style += (isRecent) ? ' cell-highlight-recent-changes' : '';

        return style;
    }

    private _getCellStyle(model: Compliance.AssessorCommandCenterModel, descriptor: Core.DescriptorInfoModel): string {
        let style = '';
        const verification = model.verifications.find(v => v.elementId === descriptor.descriptorId);
        let changeDate: Date = null;

        // verification styles
        if (model && model.descriptorValues && model.descriptorValues.length) {
            const found = model.descriptorValues.find(val => val.descriptorId === descriptor.descriptorId);
            const descriptorValue = found && found.value;
            changeDate = found && found.changeDate;
            if (!(descriptorValue === null || descriptorValue === undefined)) {
                if (verification && verification.verifiedDate && verification.qcDate) {
                    style += 'cell-qc';
                } else if (verification && verification.verifiedDate) {
                    if (this.verificationStatus === Compliance.AssessorCommandCenterModelPropertyEnum.IsPendingQC) {
                        style += 'cell-pending-qc';
                    }
                } else if (verification) {
                    style += 'cell-validation-warning';
                }
            }
        }

        // highlight styles
        let isRecent = false;
        if (this._highlightDate && changeDate) {
            const highlightDate = this._highlightDate.date.getTime();
            const changed = changeDate.getTime();
            isRecent = (this._highlightDate.direction === 'gt')
                ? (changed < highlightDate)
                : (changed > highlightDate);
        }
        style += (isRecent) ? ' cell-highlight-recent-changes' : '';

        return style;
    }

    private _getCellWarning(model: Compliance.AssessorCommandCenterModel, descriptor: Core.DescriptorInfoModel): string {
        const verification = model.verifications.find(v => v.elementId === descriptor.descriptorId);

        // verification messages
        if (model && model.descriptorValues && model.descriptorValues.length) {
            const found = model.descriptorValues.find(val => val.descriptorId === descriptor.descriptorId);
            const descriptorValue = found && found.value;
            if (!(descriptorValue === null || descriptorValue === undefined)) {
                if (verification && verification.verifiedDate) {
                    return (verification.qcDate) ? null : 'Pending QC';
                } else if (verification) {
                    return 'Verification Required';
                }
            }
        }

        return null;
    }

    private _refreshDataSource(): void {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }

        this.gridTracker.clear();

        this._gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }

        this.gridTracker.clear();

        this._gridDataSource = new StateJurisdictionCommandCenterAgGridDataSource(
            this._gridApi,
            this._stateJurisdictionService);

        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        return this._gridDataSource.getRowIdsInternal(skip, take);
    }

    private async _updateDescriptor(params: DatepickerCellEditorParams, updatedValue: any, descriptor: Core.DescriptorInfoModel): Promise<void> {
        const rowModel = params.data as Compliance.AssessorCommandCenterModel;

        if (!rowModel) {
            return Promise.resolve();
        }

        const initialValue = params.value;
        if (updatedValue === initialValue) {
            return Promise.resolve();
        }

        const updateModel: Compliance.AssessorCommandCenterDescriptorUpdateModel = {
            assessorId: rowModel.assessorId,
            force: false,
            searchModel: this._stateJurisdictionService.currentSearchModel,
            descriptorId: descriptor.descriptorId,
            value: updatedValue
        };

        this._stateJurisdictionService.loading(true);

        let result: Compliance.AssessorCommandCenterModel = null;

        try {
            result = await this._stateJurisdictionService.updateDescriptor(updateModel);
        } catch (e) {
            // service returns a 422 and a message if the user confirmation needed to force update
            if (e && e.status !== 422) {
                return Promise.reject(e);
            }
            this._stateJurisdictionService.loading(false);

            try {
                await this._messageModalService.confirm(e.error.message, `Confirm ${descriptor.name} Exception`);
            } catch (cancel) {
                // revert the cell value
                params.node.setDataValue(params.column.getColId(), params.value);
                return await Promise.reject(e);
            }

            this._stateJurisdictionService.loading(true);

            updateModel.force = true;
            result = await this._stateJurisdictionService.updateDescriptor(updateModel);
        } finally {
            this._stateJurisdictionService.loading(false);
        }

        if (!result) {
            return Promise.resolve();
        }

        await this._gridDataSource.updateRow(
            y => {
                const rowModel = y.data as Compliance.AssessorCommandCenterModel;
                return rowModel && rowModel.assessorId === result.assessorId;
            },
            () => {
                return Promise.resolve(result);
            });

        return Promise.resolve();
    }
}
