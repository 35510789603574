(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.controller('DocumentProcessingExceptionModalController', DocumentProcessingExceptionModalController);

	DocumentProcessingExceptionModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'exceptionData',
        'processingService',
        'toastr',
        'DocumentExceptionActions',
        'ProcessingExceptionStatus',
        'optionalArgs',
        'searchTimestamp',
        'teamsService',
        'instanceId',
        'sd.Account.User.Service',
		'RyanInstanceId'
	];

	function DocumentProcessingExceptionModalController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit($scope, $uibModalInstance, exceptionData, processingService, toastr, DocumentExceptionActions,
							   ProcessingExceptionStatus, optionalArgs, searchTimestamp, teamsService, instanceId, userService,
							   RyanInstanceId) {
	    $scope.DocumentExceptionActions = DocumentExceptionActions;
		//$scope.documentExceptionActions = {
		//	AddComment: 1,
		//	ReassignItem: 2,
		//	EscalateItem: 3,
		//	RejectItem: 4,
		//	CloseItem: 5,
		//	RequestResearch: 6,
		//	ClearException: 7,
		//	WK-5431 - Assign a rejected item back to the user who rejected it
		//	BounceRejected = 8
		//  RequestTaxRateSetups = 9
		//};
		$scope.commentBody = '';
		$scope.loading = false;
        $scope.instanceId = instanceId;

		activate();

		////////////////

		async function activate() {
			$scope.actionType = null;
			$scope.disableBounceRejected = false;
			$scope.disableClearException = false;
			$scope.reassign = {};
			$scope.escalate = {};
			$scope.request = {};
			$scope.taxRateSetups = {};
			$scope.multipleTasks = !!exceptionData.taskIDs;
			$scope.hasExceptionStatus = false;
			$scope.rejectedByUser = null;
			$scope.contactUser = null;
            $scope.clientInstanceId = null;
            $scope.intakeItem = null;

			if ($scope.multipleTasks) {
			    $scope.taskIDs = exceptionData.taskIDs;
				$scope.loading = true;

				try {
					const extraData = await processingService.getBulkDocumentExceptionData(exceptionData.taskIDs)
					$scope.disableBounceRejected = !extraData.showBounceRejectedOption;
					$scope.disableClearException = !extraData.showClearExceptionOption;
					if (extraData.rejectorLastName) {
						$scope.rejectedByUser = {
							firstName: extraData.rejectorFirstName,
							lastName: extraData.rejectorLastName
						}
					}
					if (extraData.contactLastName) {
						$scope.contactUser = {
							firstName: extraData.contactFirstName,
							lastName: extraData.contactLastName
						}
					}
                    $scope.clientInstanceId = extraData.clientInstanceId;

                    if ($scope.clientInstanceId) {
                        $scope.escalate = {
                            userID: extraData.contactUserId,
                            teamID: extraData.contactTeamId
                        };
                    }
				} finally {
					$scope.loading = false;
				}
			}
            else {
                $scope.intakeItem = exceptionData.selectedRow.intakeDocumentInfo;
                $scope.hasExceptionStatus = !!exceptionData.selectedRow.intakeDocumentInfo.intakeItem.exceptionStatusID;
				if ($scope.intakeItem &&
					$scope.intakeItem.intakeItem &&
					$scope.intakeItem.intakeItem.rejectedByUserID &&
					$scope.intakeItem.intakeItem.exceptionStatusID == ProcessingExceptionStatus.REJECTED) {
						teamsService.getAssignableUserTeams($scope.intakeItem.intakeItem.rejectedByUserID).then(function (userTeams) {
							$scope.rejectedByUser = _.find(userTeams, function (ut) { return ut.isDefault; });
						});
				}

                if ($scope.intakeItem && $scope.intakeItem.intakeBatch.clientInstanceId) {
                    $scope.escalate = {
                        userID: $scope.intakeItem.contactUser.userID,
                        teamID: $scope.intakeItem.contactTeam.teamID
                    };
                }

                $scope.clientInstanceId = $scope.intakeItem.intakeBatch.clientInstanceId;
			}

			$scope.isTaxBill = optionalArgs.isTaxBill;

            if (!userService.isRyanInstanceSelected() && $scope.clientInstanceId) {
                $scope.instanceId = $scope.clientInstanceId;
				$scope.escalate = {};
            }

			$scope.showRequestTaxRateSetups = $scope.isTaxBill && userService.isRyanInstanceSelected();
		}

		$scope.cancel = function () {
			$uibModalInstance.dismiss();
		};

		$scope.save = function () {
		    if (optionalArgs.parentCallsService) {
		        $uibModalInstance.close(function () {
		            return saveShared();
		        });
		    }
		    else {
		        $scope.loading = true;
		        // I'm pretty sure $scope.multipleTasks will always be falsey at this point since action views
		        // now pass in parentCallsService; that said, we really should just refactor this mess so the
		        // processing modal doesn't depend on parts of the grid
		        if ($scope.multipleTasks) {
                    saveShared().then(function (result) {
                        $uibModalInstance.close(result);
                    }).catch(function (error) {
                        $uibModalInstance.dismiss();
                    });
		        }
		        else {
                    saveShared().then(function () {
                        toastr.success('Item Updated', 'Item was successfully updated');
                        $uibModalInstance.close($scope.actionType);
                    }).catch(function () { $scope.loading = false; });
		        }
			}
		};

		$scope.isValid = function () {
		    if (!$scope.actionType) {
		        return false;
		    }
            else if (($scope.actionType === DocumentExceptionActions.REASSIGN_ITEM && !$scope.reassign.userID)
					|| ($scope.actionType === DocumentExceptionActions.ESCALATE_ITEM && !($scope.escalate.userID || $scope.intakeItem && $scope.intakeItem.clientInstanceId || $scope.clientInstanceId))
					|| ($scope.actionType === DocumentExceptionActions.REQUEST_TAX_RATE_SETUPS && !$scope.taxRateSetups.userID)
                    || ($scope.actionType === DocumentExceptionActions.REQUEST_RESEARCH && !$scope.request.userID)) {
                return false;
            }

		    return true;
		};

		$scope.createComment = function() {
			$scope.commentBody = '';

			switch($scope.actionType) {
				case DocumentExceptionActions.REASSIGN_ITEM:
					if($scope.reassign && !_.isEmpty($scope.reassign.assignee)) {
						$scope.commentBody = 'Reassigned item to ' + $scope.reassign.assignee.firstName + ' ' + $scope.reassign.assignee.lastName + '.';
					}
					break;
				case DocumentExceptionActions.ESCALATE_ITEM:
					if($scope.escalate && !_.isEmpty($scope.escalate.assignee)) {
						$scope.commentBody = 'Escalated item to ' + $scope.escalate.assignee.firstName + ' ' + $scope.escalate.assignee.lastName + '.';
					}
					break;
				case DocumentExceptionActions.REQUEST_RESEARCH:
					if($scope.request && !_.isEmpty($scope.request.assignee)) {
						$scope.commentBody = 'Research requested from ' + $scope.request.assignee.firstName + ' ' + $scope.request.assignee.lastName + '.';
					}
					break;
				case DocumentExceptionActions.REQUEST_TAX_RATE_SETUPS:
					if($scope.taxRateSetups && !_.isEmpty($scope.taxRateSetups.assignee)) {
						$scope.commentBody = 'Tax rate setups requested from ' + $scope.taxRateSetups.assignee.firstName + ' ' + $scope.taxRateSetups.assignee.lastName + '.';
					}
					break;
				case DocumentExceptionActions.REJECT_ITEM:
                    if ($scope.multipleTasks) {
                        $scope.commentBody = 'Rejected item to ' + ($scope.contactUser ? ($scope.contactUser.firstName + ' ' + $scope.contactUser.lastName) : 'contact user') + '.';
                    }
                    else {
                        $scope.commentBody = 'Rejected item to ' + $scope.intakeItem.contactUser.firstName + ' ' + $scope.intakeItem.contactUser.lastName + '.';
                    }
					break;
				case DocumentExceptionActions.CLOSE_ITEM:
					$scope.commentBody = 'Closed item (added in error)';
					break;
			}
		}

		function saveShared() {
			if ($scope.multipleTasks) {
			    return processingService.processDocumentBulkException({
			        taskIDs: $scope.taskIDs,
			        documentExceptionAction: $scope.actionType,
			        newAssignment: getNewAssignment(),
			        commentBody: $scope.commentBody,
                    searchTimestamp: searchTimestamp

			    })/*.then(function (result) {
			        $uibModalInstance.close(result);
			    }).catch(function (error) {
			        $uibModalInstance.dismiss();
			    })*/;
			}
			else {
				checkForChangesInParcel();

                var documentIntakeID = $scope.intakeItem.intakeItem.intakeItemID;
                var taskID = exceptionData.selectedRow.n10908;
                return processingService.processDocumentException(documentIntakeID, taskID, exceptionData.selectedEntity,
                    $scope.actionType, getNewAssignment(), $scope.commentBody, exceptionData.newDocumentTypeID,
                    exceptionData.dataEntryJSON,
                    $scope.intakeItem.intakeItem.rowVersion, $scope.intakeItem.parcelChanges)
                    /*.then(function () {
                        toastr.success('Item Updated', 'Item was successfully updated');
                        $uibModalInstance.close($scope.actionType);
                    }).catch(function () { $scope.loading = false; })*/;
			}
		}

		function checkForChangesInParcel() {
			if(!isOverridden('assesseeName') && !isOverridden('parcelAddress') && !isOverridden('collectorAcctNum')) {
				$scope.intakeItem.parcelChanges = null;
			}
		}

		function isOverridden(field) {
			if(!$scope.intakeItem.parcelChanges) {
				return false;
			}

			if(field === 'parcelAddress') {
				var addressFields = ['address1', 'address2', 'city', 'zip'];
				return !_.isEqual(_.pick($scope.intakeItem.parcelChanges.parcelAddress, addressFields), _.pick(exceptionData.entityDetails.extendedData.parcelAddress, addressFields));
			} else {
				return !_.isEqual($scope.intakeItem.parcelChanges[field], exceptionData.entityDetails.extendedData[field]);
			}
		}

		function getNewAssignment() {
			switch($scope.actionType) {
				case DocumentExceptionActions.REASSIGN_ITEM:
					return {
						userID: $scope.reassign.userID,
						teamID: $scope.reassign.teamID
					};
				case DocumentExceptionActions.ESCALATE_ITEM:
					return {
						userID: $scope.escalate.userID,
						teamID: $scope.escalate.teamID
					};
				case DocumentExceptionActions.REQUEST_RESEARCH:
					return {
						userID: $scope.request.userID,
						teamID: $scope.request.teamID
					};
				case DocumentExceptionActions.REQUEST_TAX_RATE_SETUPS:
					return {
						userID: $scope.taxRateSetups.userID,
						teamID: $scope.taxRateSetups.teamID
					};
				case DocumentExceptionActions.REJECT_ITEM:
					if(!$scope.multipleTasks) {
						return {
							userID: $scope.intakeItem.contactUser.userID,
							teamID: $scope.intakeItem.contactTeam.teamID
						}
					} else {
						return null;
					}
				case DocumentExceptionActions.BOUNCE_REJECTED:
					if(!$scope.multipleTasks) {
						return {
							userID: $scope.rejectedByUser.userID,
							teamID: $scope.rejectedByUser.teamID
						}
					} else {
						return null;
					}
				default:
					return null;
			}
		}
	}
})();
