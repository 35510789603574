(function () {
    'use strict';
    var permissionErrorMessage = 'User does not have permission on these items.';
    var genericErrorMessage = 'An error has occured processing these tasks.';
    var concurrencyErrorMessage = 'Items have been modified since the search was performed. Please refresh the search result.';

    angular
        .module('weissmanApp')
        .factory('actionViewContextMenuService', actionViewContextMenuService);

    actionViewContextMenuService.$inject = [
        '$state',
        '$q',
        'sd.Http.Service',
        'actionViewPersistenceService',
        '$uibModal',
        'CommentsService',
        'toastr',
        'processingService',
        'taskService',
        'messageBoxService',
        'TransmittalService',
        'appealApplicationService',
        'appealService',
        'TaskActionViewContextOption',
        'retrievalStatusModalService',
        'ReassignModes',
        'reportingService',
        'appealRecommendationModalService',
        'addPaymentsToBatchModalService',
        'CommentsModalService',
        'productAnalyticsService'
    ];

    function actionViewContextMenuService(
        $state,
        $q,
        sdHttp,
        actionViewPersistenceService,
        $uibModal,
        CommentsService,
        toastr,
        processingService,
        taskService,
        messageBoxService,
        transmittalService,
        appealApplicationService,
        appealService,
        TaskActionViewContextOption,
        retrievalStatusModalService,
        ReassignModes,
        reportingService,
        appealRecommendationModalService,
        addPaymentsToBatchModalService,
        CommentsModalService,
        productAnalyticsService
    ) {
        let _errors = [];

        return {
            getContextMenu: getContextMenu,
            hasErrors: hasErrors,
            clearErrors: clearErrors
        };

        // showChangeDueDateModal is a hack; in order to show an Angular2+ modal at the moment, we need a reference
        // to its show method, which is returned as an event to a directive. This is not a terribly nice solution,
        // but it does work. If you want to open the actionViewContextMenu, you must provide the
        // showChangeDueDateModal method from the modal's event
        function getContextMenu(grid, showChangeDueDateModal, loadingHandler, searchTimestamp) {
            var warningLimit = 50;

            var deferred = $q.defer();

            function getSortedSelection(grid) {
                var rows = [];
                grid.gridOptions.api.forEachNodeAfterFilterAndSort(function(row) {
                    if(row.selected) {
                        rows.push(row.data);
                    }
                });
                return rows;
            }

            if (grid.selectionData.length > 0) {
                productAnalyticsService.logEvent('select-action-view-tasks', { avTasksSelected: grid.selectionData.length });
                var menuItems = [{
                    id: TaskActionViewContextOption.ProcessDocument,
                    function: function () {
                        updateChangedGridRows();
                        $state.go('documentProcessing', {
                            selectedRows: getSortedSelection(grid),
                            searchTimestamp: searchTimestamp
                        });
                    }
                }, {
                    id: TaskActionViewContextOption.ChangeContact,
                    limitMessage: 'Are you sure you want to change contacts for #taskcount tasks?',
                    function: function (taskIDs, instanceId) {
                        $uibModal.open({
                            templateUrl: 'app/Task/_reassignTaskModal.html',
                            controller: 'ReassignTaskModalController',
                            controllerAs: 'vm',
                            windowClass: 'show',
                            backdropClass: 'show',
                            resolve: {
                                taskIDs: function () { return taskIDs; },
                                reassignMode: ReassignModes.ChangeContact,
                                searchTimestamp: searchTimestamp,
                                instanceId: instanceId
                            }
                        }).result.then(function (result) {
                            runModalResult(result, "Processing contact change...");
                        });
                    }
                }, {
                    id: TaskActionViewContextOption.Reassign,
                    limitMessage: 'Are you sure you want to reassign #taskcount tasks?',
                    function: function (taskIDs, instanceId) {
                        $uibModal.open({
                            templateUrl: 'app/Task/_reassignTaskModal.html',
                            controller: 'ReassignTaskModalController',
                            controllerAs: 'vm',
                            windowClass: 'show',
                            backdropClass: 'show',
                            resolve: {
                                taskIDs: function () { return taskIDs; },
                                reassignMode: ReassignModes.Default,
                                searchTimestamp: searchTimestamp,
                                instanceId: instanceId
                            }
                        }).result.then(function (result) {
                            runModalResult(result, "Reassigning...");
                        });
                    }
                }, {
                    id: TaskActionViewContextOption.AddComment,
                    function: async function (taskIDs) {
                        let title = 'Add comment to ';
                        if (grid.selectionData.length === 1) {
                            title += ' task';
                        } else {
                            title += grid.selectionData.length + ' tasks';
                        }
                        const entityData = await CommentsService.getEntityDataForTasks(taskIDs);
                        const years = _.uniq(_.map(entityData, function (entity) { return entity.year; }));
                        let defaultYear = years[0];
                        const multipleYears = years.length > 1;
                        if (multipleYears) { defaultYear = "(multiple)"; }
                        const comment = await CommentsModalService.openAddCommentModal({
                            title: title,
                            allowBlank: true,
                            yearIsRelevant: CommentsService.yearIsRelevantForEntityTypeID(entityData[0].entityTypeID),
                            showFull: true,
                            defaultYear: defaultYear
                        });
                        if (comment) {
                            let entityYearOverrides = null;
                            if (multipleYears) {
                                entityYearOverrides = _.map(entityData, function(entity) {
                                    return {
                                        entityID: entity.entityID,
                                        entityTypeID: entity.entityTypeID,
                                        year: entity.year
                                    };
                                });
                                comment.year = null;
                            }
                            loadingHandler(true, "Saving...");
                            return CommentsService.sendTaskComments(taskIDs, comment, entityYearOverrides)
                                // TODO: We should change the comment API endpoint to return bulk operation data; ideally,
                                // it would even include new fields so the UI can automatically update the comment column.
                                // I think that the next four lines would look like:
                                // .then(processResult);
                                .then(function() {
                                    loadingHandler(false);
                                    toastr.success("Comment" + (grid.selectionData.length !== 1 ? "s" : "") + " added");
                                    updateChangedGridRows();
                                }).catch(function() {
                                    loadingHandler(false);
                                    toastr.error('An unexpected error has occurred');
                                });
                            // });
                        }
                    }
                }, {
                    id: TaskActionViewContextOption.Duplicate,
                    limitMessage: true,
                    function: function (taskIDs) {
                        var duplicateItem = {
                            taskIDs: taskIDs,
                            duplicateCount: 1,
                            searchTimestamp: searchTimestamp
                        };

                        $uibModal.open({
                            templateUrl: 'app/Processing/Documents/Modals/_duplicateIntakeItemModal.html',
                            controller: 'DuplicateIntakeItemModalController',
                            controllerAs: 'vm',
                            windowClass: 'show',
                            backdropClass: 'show',
                            resolve: {
                                isBulk: true,
                                duplicateItem: duplicateItem,
                                optionalArgs: { parentCallsService: true }
                            }
                        }).result.then(function (result) {
                            runModalResult(result, "Duplicating...");
                        });
                    }
                }, {
                    id: TaskActionViewContextOption.AppealWarranted,
                    limitMessage: true,
                    function: function (taskIDs) {
                        $uibModal.open({
                            templateUrl: 'app/Processing/processAppealWarrantedModal.html',
                            controller: 'ProcessAppealWarrantedModalController',
                            controllerAs: 'vm',
                            windowClass: 'show',
                            backdropClass: 'show',
                            resolve: {
                                taskIDs: function() { return taskIDs; },
                                searchTimestamp: searchTimestamp,
                                optionalArgs: { parentCallsService: true }
                            }
                        }).result.then(function (result) {
                            // This is "experimental" at the moment; I'm leaving in the ability to set a processingBufferOverride in
                            // a console in case we run into any obscure problems here and for instance need to set it to 1 to clear
                            // things out. Once this has been in place for a while we can probably get rid of the override thing.
                            runModalResultWithBuffer(result, "Processing", taskIDs, window.processingBufferOverride || 20);
                        });
                    }
                }, {
                    id: TaskActionViewContextOption.AppealNotWarranted,
                    limitMessage: true,
                    function: function (taskIDs) {
                        $uibModal.open({
                            templateUrl: 'app/Processing/processNoAppealModal.html',
                            controller: 'ProcessNoAppealModalController',
                            controllerAs: 'vm',
                            windowClass: 'show',
                            backdropClass: 'show',
                            resolve: {
                                taskIDs: function() { return taskIDs; },
                                searchTimestamp: searchTimestamp,
                                optionalArgs: { parentCallsService: true }
                            }
                        }).result.then(function (result) {
                            runModalResult(result, "Processing...");
                        });
                    }
                }, {
                    id: TaskActionViewContextOption.CompleteFileAppeal,
                    limitMessage: true,
                    function: function (taskIDs) {
                        $uibModal.open({
                            templateUrl: 'app/Processing/complete-file-appeal.modal.html',
                            controller: 'CompleteFileAppealModalController',
                            controllerAs: 'vm',
                            windowClass: 'show',
                            backdropClass: 'show',
                            resolve: {
                                taskIDs: function() { return taskIDs; },
                                searchTimestamp: searchTimestamp,
                                optionalArgs: { parentCallsService: true }
                            }
                        }).result.then(function (result) {
                            runModalResult(result, "Processing...");
                        });
                    }
                }, {
                    id: TaskActionViewContextOption.DocumentException,
                    limitMessage: true,
                    function: function (taskIDs, instanceId) {
                        var isTaxBill = _.every(grid.selectionData, {f9003: 'Tax Bill'});

                        $uibModal.open({
                            templateUrl: 'app/Processing/Documents/Modals/_documentProcessingException.html',
                            controller: 'DocumentProcessingExceptionModalController',
                            windowClass: 'show',
                            backdropClass: 'show',
                            resolve: {
                                exceptionData: { taskIDs: taskIDs },
                                searchTimestamp: searchTimestamp,
                                optionalArgs: { parentCallsService: true, isTaxBill: isTaxBill },
                                instanceId: instanceId
                            }
                        }).result.then(function (result) {
                            runModalResult(result, "Processing...");
                        });
                    }
                },
                { id: TaskActionViewContextOption.ChangeAppealDeadline, function: function () { changeDueDates(9); } },
                { id: TaskActionViewContextOption.ChangeFilingDeadline, function: function () { changeDueDates(10); } },
                {
                    id: TaskActionViewContextOption.AddPrepareApplicationTasks,
                    limitMessage: 'Are you sure you want to add up to #taskcount tasks?',
                    function: function (taskIDs) {
                        loadingHandler(true, "Adding prepare application tasks...");
                        appealService.addPrepareApplicationTasks(taskIDs).then(function (result) {
                            processResult(result);
                            loadingHandler(false);
                        }, function () {
                            loadingHandler(false);
                        });
                    }
                }, {
                    id: TaskActionViewContextOption.CreatePreviewAppealApplication,
                    function: function (taskIDs) {
                        loadingHandler(true, 'Loading appeal applications...');
                        appealApplicationService.searchByTaskIds(taskIDs).then(function (searchResult) {
                            var newKey = (new Date()).getTime();

                            var errorResults = _.filter(searchResult, function (r) { return r.errorMessage; });
                            searchResult = _.filter(searchResult, function (r) { return !r.errorMessage; });

                            var taskIdsNeedingApplications = _.map(_.filter(searchResult, function (r) {
                                return !r.applicationExists;
                            }), function (r) {
                                return r.taskID;
                            });

                            var appealIds = _.map(searchResult, function (r) { return r.appealId; });

                            var callback = function () {
                                sessionStorage['AppealApplicationBatch' + newKey] = JSON.stringify(appealIds);
                                updateChangedGridRows();
                                $state.go('appealApplicationBatch', { appealBatchId: newKey });
                                loadingHandler(false);
                            };

                            var errorCallback = function (errorResults) {
                                taskService.showErrorNotificationModal(_.uniq(_.map(errorResults, function (er) {
                                        return er.errorMessage;
                                    })), [],
                                    'The following error or errors were encountered attempting to create appeal applications',
                                    'The following warning or warnings were encountered attempting to create appeal applications');
                                loadingHandler(false);
                            };

                            // Kind of a hack; if we detect that there are some appeals where the user doesn't have permission,
                            // bail out by creating a result that looks like it came from a bulk API endpoint. This ensures
                            // the appropriate rows get highlighted in red and that the error message is displayed in a consistent
                            // way.
                            errorResults = errorResults.concat(_.map(_.filter(searchResult, function (r) {
                                return !r.hasPermission;
                            }), function (r) {
                                return {
                                    taskID: r.taskID,
                                    errorMessage: 'You do not have permission to edit this appeal'
                                };
                            }));

                            if (errorResults.length > 0) {
                                processResultPromise(errorResults).then(null, function (finalResult) {
                                    errorCallback(finalResult.errors);
                                });
                                return;
                            }

                            if (taskIdsNeedingApplications.length > 0) {
                                appealApplicationService.createNewApplicationBulk(taskIdsNeedingApplications).then(function (processingResult) {
                                    processResultPromise(processingResult).then(function (finalResult) {
                                        if (finalResult.errorResults.length < 1) {
                                            callback();
                                        } else {
                                            errorCallback(finalResult.errorResults);
                                        }

                                    }, function (finalResult) {
                                        errorCallback(finalResult.errors);
                                    });
                                });
                            } else {
                                callback();
                            }
                        });
                    }
                },
                { id: TaskActionViewContextOption.ChangeSubmitEvidenceDate, function: function () { changeDueDates(11); } },
                { id: TaskActionViewContextOption.ChangeInformalHearingDate, function: function () { changeDueDates(12); } },
                { id: TaskActionViewContextOption.ChangeFormalHearingDate, function: function () { changeDueDates(13); } },
                { id: TaskActionViewContextOption.ChangePaymentDueDate, function: function () { changeDueDates(14); } },
                { id: TaskActionViewContextOption.ChangeIntakeItemDueDate, function: function () { changeDueDates(15); } },
                { id: TaskActionViewContextOption.ChangeComplianceFilingDueDate, function: function () { changeDueDates(16); } },
                {
                    id: TaskActionViewContextOption.Transmit,
                    limitMessage: true,
                    function: function (taskIDs) {
                        loadingHandler(true, "Creating payment packages...");
                        transmittalService.CreatePaymentPackage(taskIDs).then(function (result) {
                            processResultPromise(result.operationResult, true).then(function () {
                                loadingHandler(false);
                                var newKey = (new Date()).getTime();
                                sessionStorage['PaymentPackageDrafts' + newKey] = JSON.stringify(result.packageIDs);
                                $state.go('paymentPackagesDraft', { draftID: newKey, isTransmittal: false, paymentBatchId: null, taskId: null })
                            }, function (errorResult) {
                                loadingHandler(false);
                                var errors = _.uniq(_.map(_.filter(errorResult.errors, function (error) {
                                    return error.errorMessage;
                                }), 'errorMessage'));
                                var warnings = _.uniq(_.map(_.filter(errorResult.errors, function (error) {
                                    return !error.errorMessage;
                                }), 'warningMessage'));
                                taskService.showErrorNotificationModal(errors, warnings,
                                    'The following error or errors were encountered attempting to create bill ' +
                                    'payment packages; please attempt to correct these errors and try again',
                                    'The following issue or issues were encountered attempting to create bill ' +
                                    'payment packages; tasks which had issues will require re-review before ' +
                                    'they can be processed');
                                //toastr.error(errorResult.message, 'Error!');
                            });
                        }).catch(err => {
                            console.error(err);
                            toastr.error('An unexpected error has occurred.');
                            loadingHandler(false);
                        });
                    }
                }, {
                    id: TaskActionViewContextOption.ReviewPayment,
                    limitMessage: 'Are you sure you want to complete #taskcount tasks?',
                    function: function (taskIDs) {
                        loadingHandler(true, "Updating payment review status...");
                        // This should probably all be part of the processPaymentReviewed API call, but we want a specific message
                        // for missing attachment IDs. In the future, we should probably make this message part of the bulk operation
                        // result and look for it there as part of the payment reviewed call. This works fine for now, but is slightly
                        // slower.
                        transmittalService.CheckForMissingAttachments(taskIDs).then(function (result) {
                            if (result.length > 0) {
                                loadingHandler(false);
                                toastr.error("One or more payments do not have a bill image associated. A payment must have a bill image associated to be reviewed in an Action View.");
                                _.forEach(result, function (taskID) {
                                    var i = _.findIndex(grid.gridOptions.rowData, { n10908: taskID });
                                    if (i >= 0) {
                                        grid.gridOptions.rowData[i].error = true;
                                    }
                                });
                                actionViewPersistenceService.selectionData = grid.selectionData;
                                grid.gridOptions.api.refreshView();
                            } else {
                                processingService.processPaymentReviewed({
                                    taskIDs: taskIDs,
                                    searchTimestamp: searchTimestamp
                                }).then(function (result) {
                                    loadingHandler(false);
                                    processResult(result);
                                }, function () {
                                    loadingHandler(false);
                                });
                            }
                        }, function () {
                            loadingHandler(false);
                        });
                    }
                }, {
                    id: TaskActionViewContextOption.ReReviewPayment,
                    function: function (taskIDs) {
                        // TODO: Maybe shorten this message to less than book length
                        messageBoxService.confirmYesNo('Payment Transmittal is blocked for any payments which have completed review tasks ' +
                            'where the parcel or collector has changed since the task was complete. Once you have verified that the changes ' +
                            'do not impact the payment you can mark the Transmit Payment task as reviewed, which will clear the error. Are ' +
                            'you sure you want to mark ' + ((grid.selectionData.length === 1) ? 'this task' : ('these ' + grid.selectionData.length + ' tasks')) +
                            ' as reviewed?').then(function () {
                            loadingHandler(true, "Updating payment review status...");

                            transmittalService.ReReview({
                                taskIDs: taskIDs,
                                searchTimestamp: searchTimestamp
                            }).then(function (result) {
                                loadingHandler(false);
                                processResult(result);
                            }, function () { loadingHandler(false); });
                        });
                    }
                }, {
                    id: TaskActionViewContextOption.CreateInvoice,
                    function: function (taskIDs) {
                        messageBoxService.confirmYesNo((grid.selectionData.length === 1) ?
                            'Selected Invoice Appeal Task will be marked complete and the related appeal savings converted into a saved ' +
                            'contingency invoice ready to process as a draft. Proceed?' :
                            'Selected Invoice Appeal Tasks will be marked complete and their related appeal savings converted into saved ' +
                            'contingency invoices ready to process as drafts. Proceed?').then(function () {
                            loadingHandler(true, "Creating invoices...");

                            // TODO: Move this to the invoice service
                            sdHttp.post('/api/invoice', taskIDs).then(function (result) {
                                loadingHandler(false);
                                var callback = function (resultObject) {
                                    var showToast = true;
                                    if (resultObject.errorResults && resultObject.errorResults.length > 0) {
                                        // Get unique warningMessage properties out of the resultObject.errorResults array and add them to the errors array
                                        var warnings = _.uniq(_.map(_.filter(resultObject.errorResults, function (error) {
                                            return !error.errorMessage;
                                        }), 'warningMessage'));

                                        // If we got error messages but no user-friendly warning messages, don't bother with the
                                        // error-notification modal; the toast will be sufficient for that case
                                        if (_.some(warnings)) {
                                            var errors = [];
                                            // If results have 'warningMessage' properties, the API has sent a user-readable message in
                                            // that field; otherwise, there will be an 'errorMessage' property (that we surpress here)
                                            if (_.some(resultObject.errorResults, function (errorResult) { return !errorResult.warningMessage; })) {
                                                errors.push('An unexpected error has occurred');
                                            }

                                            taskService.showErrorNotificationModal(errors, warnings,
                                                'The following error or errors were encountered attempting to create invoices');
                                            showToast = _.some(resultObject.successfulResults);
                                        }
                                    }

                                    if (showToast) {
                                        toastProcessResult(resultObject);
                                    }
                                };

                                processResultPromise(result.operationResult, true).then(function (resultObject) {
                                    callback(resultObject);
                                }, function (errorResult) {
                                    callback({
                                        errorResults: errorResult.errors
                                    });
                                });

                            }, function () { loadingHandler(false); });
                        });
                    }
                },
                { id: TaskActionViewContextOption.PrepareDraftInvoice, function: showInvoicePage },
                { id: TaskActionViewContextOption.ReviewDraftInvoice, function: showInvoicePage },
                { id: TaskActionViewContextOption.PreviewThenTransferInvoice, function: showInvoicePage },
                { id: TaskActionViewContextOption.PreviewThenTransferUBR, function: showInvoicePage },
                { id: TaskActionViewContextOption.RequestReliefOfUBR, function: showInvoicePage },
                {
                    id: TaskActionViewContextOption.MarkInvoiceUBR,
                    limitMessage: true,
                    function: function (taskIDs) {
                        messageBoxService.confirmYesNo("Selected Invoices will have their UBR flag set. Proceed?").then(function () {
                            loadingHandler(true, "Editing invoices...");
                            // TODO: Move this to the invoice service
                            sdHttp.post('/api/invoice/bulkmarkubr', taskIDs).then(function (result) {
                                loadingHandler(false);
                                var handleErrors = function (errorResult) {
                                    var validationErrors = _.map(_.filter(errorResult, "warningMessage"), "warningMessage");

                                    if (_.some(validationErrors)) {
                                        _.forEach(validationErrors, function(err, key) {
                                            toastr.warning(err);
                                            });
                                    }
                                };
                                processResultPromise(result).then(function (processingResult) {
                                    handleErrors(processingResult.errorResults);
                                    toastProcessResult(processingResult);
                                }, function (errorResult) {
                                    handleErrors(errorResult.errors);
                                    if (_.some(errorResult.errors, "errorMessage")) {
                                        toastr.error(errorResult.message, 'Error!');
                                    }
                                });
                            }, function () { loadingHandler(false); });
                        });
                    }
                }, {
                    id: TaskActionViewContextOption.TransferInvoice,
                    limitMessage: true,
                    function: function (taskIDs) { bulkTransferToRIBS("Invoice", taskIDs); }
                }, {
                    id: TaskActionViewContextOption.TransferUBR,
                    limitMessage: true,
                    function: function (taskIDs) { bulkTransferToRIBS("UBR", taskIDs); }
                }, { id: TaskActionViewContextOption.ChangeInvoiceDueDate, function: function () { changeDueDates(25); }},
                {
                    id: TaskActionViewContextOption.Skip,
                    limitMessage: true,
                    function: function (taskIDs) {
                        messageBoxService.confirmYesNo('Skipping a task completes it and marks it as "skipped"; no further operations ' +
                            'will be allowed on any skipped task. Are you sure you want to skip ' +
                            (taskIDs.length === 1 ? 'this task' : ('these ' + taskIDs.length + ' tasks')) + '?').then(function () {
                            loadingHandler(true, "Skipping...");
                            taskService.skipMany(taskIDs).then(function (result) {
                                loadingHandler(false);
                                processResult(result);
                            }, function () {
                                loadingHandler(false);
                                toastr.error("An unexpected error has ocurred");
                            })
                        });
                    }
                }, {
                    id: TaskActionViewContextOption.PPReturnDataLoaded,
                    limitMessage: true,
                    function: function (taskIDs) {
                        messageBoxService.confirmYesNo('Are you sure you want to update ' +
                            (taskIDs.length === 1 ? 'this task' : ('these ' + taskIDs.length + ' tasks')) + '?').then(function () {
                            loadingHandler(true, "Updating Data Loaded tasks...");
                            taskService.completeMany(taskIDs).then(function (result) {
                                loadingHandler(false);
                                processResult(result);
                            }, function () {
                                loadingHandler(false);
                                toastr.error("An unexpected error has ocurred");
                            })
                        });
                    }
                }, {
                    id: TaskActionViewContextOption.SetDocumentRetrievalStatus,
                    limitMessage: true,
                    function: function (taskIDs, instanceId) {
                        retrievalStatusModalService.launchSetDocumentRetrievalStatusModal(taskIDs, instanceId).then(function (result) {
                            runModalResult(result, "Processing...");
                        });
                    }
                }, {
                    id: TaskActionViewContextOption.ChangeConfirmHearingDate,
                    function: function () { changeDueDates(35); }
                }, {
                    id: TaskActionViewContextOption.AddObtainPaymentReceiptTasks,
                    limitMessage: 'Are you sure you want to add up to #taskcount tasks?',
                    function: function (taskIDs) {
                        loadingHandler(true, "Adding obtain payment receipt tasks...");
                        addObtainPaymentreceiptTasks(taskIDs).then(function (result) {
                            processResult(result);
                            loadingHandler(false);
                        }, function () {
                            loadingHandler(false);
                        });
                    }
                }, {
                    id: TaskActionViewContextOption.CompleteObtainPaymentReceiptTasks,
                    limitMessage: 'Are you sure you want to complete up to #taskcount tasks?',
                    function: function(taskIDs){
                        loadingHandler(true, "Completing obtain payment receipt tasks...");
                        CompleteObtainPaymentReceiptTasks(taskIDs).then(function (result){
                            processResult(result);
                            loadingHandler(false);
                        }, function(){
                            loadingHandler(false);
                        });
                    }
                },{
                    id: TaskActionViewContextOption.InsertObtainWorkpapersTaskBefore, function: function (taskIDs) {
                            loadingHandler(true, "Adding obtain workpapers tasks...");
                            addObtainWorkpapersTasks(taskIDs).then(function(result){
                               processResult(result);
                               loadingHandler(false);
                            });
                        }
                },{
                    id: TaskActionViewContextOption.GenerateAppealListReport,
                    function: function(taskIDs) {
                        let request = {
                            taskIDs: taskIDs
                        }
                        reportingService.runAppealListReport(request).then(function(result){
                            loadingHandler(false);
                        });
                    }
                }, {
                    id: TaskActionViewContextOption.AppealRecommendation,
                    function: function (taskIDs, instanceId) {
                        const rows = getSortedSelection(grid);
                        const keyValues = ['Parcel Acct Num', 'Site Name', 'Site State', 'Parcel Assessor', 'Site Class', 'Prop Type', 'Appeal Deadline'].reduce((acc, x) => {
                            const col = grid.gridOptions.columnDefs.find(c => c.headerName === x);
                            acc[x] = col ? rows.map(x => x[col.field]) : [];
                            return acc;
                        }, {});
                        productAnalyticsService.logEvent('right-click-AV-appeal-recommendation', {
                            noOfParcels: _.uniq(keyValues['Parcel Acct Num']).length,
                            noOfSites: _.uniq(keyValues['Site Name']).length,
                            siteState: _.uniq(keyValues['Site State']),
                            siteJuris: _.uniq(keyValues['Parcel Assessor']),
                            siteClass: _.uniq(keyValues['Site Class']),
                            propertyType: _.uniq(keyValues['Prop Type']),
                            daysToAppealDeadline: _.uniq(keyValues['Appeal Deadline']),
                        });
                        appealRecommendationModalService.launchModal(taskIDs, instanceId).then(function (result) {
                            runModalResult(result, "Processing...");
                        })
                    }
                }, {
                    id: TaskActionViewContextOption.AddToPaymentBatch,
                    function: function (taskIDs) {
                        const topLevelCompanyIds = _.chain(grid.selectionData)
                            .map('n110')
                            .uniq()
                            .value();

                        if(topLevelCompanyIds.length > 1) {
                            toastr.error("Selected Payments must belong to the same Top Level Company.");
                            return
                        } else if (topLevelCompanyIds.length == 0) {
                            toastr.error("Unable to determine Top Level Company of selected Payments.");
                        } else {
                            addPaymentsToBatchModalService.launchModal(topLevelCompanyIds[0], taskIDs)
                                .then(function(result) {
                                    if(result) {
                                        runModalResult(result, "Adding Payments...")
                                    }
                                });
                        }
                    }
                }
            ];


                var itemPayload = _.map(grid.selectionData, 'n10908');

                sdHttp.post('/api/TaskActionView/TaskContextOptions', itemPayload).then(function (results) {
                    var rightClickMenuItems = _.map(results.options, function (contextOption) {
                        var menuHandler = _.find(menuItems, { id: contextOption.optionId });
                        return [
                            contextOption.text,
                            function () {
                                productAnalyticsService.logEvent('select-action-view-right-click-option', { avAction: contextOption.text });
                                // Shouldn't happen; this will be if we add an operation to the API and don't handle
                                // it in the UI
                                if (!menuHandler) {
                                    toastr.error('Error: processing is disabled for this operation.');
                                } else if (menuHandler.limitMessage && grid.selectionData.length >= warningLimit) {
                                    var message;
                                    if (menuHandler.limitMessage === true) {
                                        message = 'Are you sure you want to process ' + grid.selectionData.length + ' tasks?';
                                    } else {
                                        // Use the magic string #taskcount to insert the number of selected items into the message
                                        var message = menuHandler.limitMessage.replace('#taskcount', grid.selectionData.length);
                                    }
                                    messageBoxService.confirmYesNo(message).then(function () { menuHandler.function(itemPayload); });
                                } else {
                                    menuHandler.function(itemPayload, results.singleInstanceId);
                                }
                            }
                        ];
                    });

                    deferred.resolve(rightClickMenuItems);
                });
            } else {
                deferred.resolve([]);
            }

            return deferred.promise;

            function changeDueDates(optionID) {
                var fn = function () {
                    showChangeDueDateModal(_.map(grid.selectionData, 'n10908'), optionID, searchTimestamp, true)
                        .then(function (result) {
                            runModalResult(result, "Processing date change...");
                        }).catch(function () { });
                }
                if (grid.selectionData.length >= warningLimit) {
                    messageBoxService.confirmYesNo('Are you sure you want to change the due dates of ' + grid.selectionData.length + ' tasks?').then(function () {
                        fn();
                    });
                } else {
                    fn();
                }
            }

            function addObtainPaymentreceiptTasks(taskIds) {
                return sdHttp.put('api/tasks/AddObtainPaymentReceiptTasks', taskIds);
            }

            function addObtainWorkpapersTasks(taskIds) {
                return sdHttp.post('api/tasks/AddObtainWorkpapersTasks', taskIds);
            }

            function CompleteObtainPaymentReceiptTasks(taskIds){
                //make a new object with taskIds and searchTimestamp
                let payload = {taskIDs: taskIds, searchTimestamp: searchTimestamp}
                return sdHttp.put('api/tasks/completeobtainpaymentreceipt', payload);
            }

            function showInvoicePage() {
                var taskIds =_.map(grid.selectionData, 'n10908');
                updateChangedGridRows();
                var newKey = (new Date()).getTime();
                sessionStorage['InvoiceDrafts' + newKey] = JSON.stringify(taskIds);
                $state.go("processInvoice", { draftId: newKey });
            }

            function bulkTransferToRIBS(label, taskIDs) {
                messageBoxService.confirmYesNo("Selected " + label + "s will be transferred to RIBS. Proceed?").then(function () {
                    loadingHandler(true, "Transferring invoices...");
                    // TODO: Move this to the invoice service
                    sdHttp.post('/api/invoice/bulktransfer', taskIDs).then(function (result) {
                        loadingHandler(false);
                        var handleErrors = function (errorResult) {
                            var validationErrors = _.map(_.filter(errorResult, "warningMessage"), "warningMessage");

                            if (_.some(validationErrors)) {
                                _.forEach(validationErrors, function(err, key) {
                                    toastr.warning(err);
                                    });
                            }
                        };
                        processResultPromise(result).then(function (processingResult) {
                            handleErrors(processingResult.errorResults);
                            toastProcessResult(processingResult);
                        }, function (errorResult) {
                            handleErrors(errorResult.errors);
                            if (_.some(errorResult.errors, "errorMessage")) {
                                toastr.error(errorResult.message, 'Error!');
                            }
                        });
                    }, function () { loadingHandler(false); });
                });
            }

            /* Example bulk processing payload (reassign in this case):
            [{
                "taskID": 23254880,
                "entityID": 29522,
                "entityTypeID": 15,
                "errorMessage": null,
                "isAuthorized": true,
                "authorizationReason": "Assigned User",
                "changedFields": {
                    "n10900": "5b1d7e74-771b-4619-a54c-f2b135fb515d",
                    "n10901": 72,
                    "f10002": "Rann, Jeremy",
                    "f10003": "Team Project Weissman"
                },
                "markStale": false // Typically there's either a changedFields object or markStake is true
            }]*/
            function processResultPromise(processingResult) {
                return $q(function (resolve, reject) {
                    var successfulResults = _.filter(processingResult, function (result) {
                        return !result.errorMessage && !result.warningMessage && !result.isConcurrencyCheckFailed;
                    });
                    var errorResults = _.filter(processingResult, function (result) {
                        return result.errorMessage || result.warningMessage || result.isConcurrencyCheckFailed;
                    });

                    markErrorResults(successfulResults, errorResults);

                    if (successfulResults.length < 1) {
                        if (errorResults.length < 1) {
                            console.log('Processing result successfully retrieved from server but could not be parsed');
                            reject({
                                message: genericErrorMessage,
                                errors: errorResults
                            });
                        } else if (_.every(errorResults, function (result) { return !result.isAuthorized; })) {
                            // All results have authorization errors
                            reject({
                                message: permissionErrorMessage,
                                errors: errorResults
                            });
                        } else if (_.every(errorResults, function (result) { return result.isConcurrencyCheckFailed; })) {
                            // All results have concurrency errors
                            reject({
                                message: concurrencyErrorMessage,
                                errors: errorResults
                            });
                        } else {
                            console.log('Processing result successfully retrieved from server, but processing indicated errors (taskID: error):\n' +
                                _.map(processingResult, function (result) {
                                    return result.taskID + ': ' + (result.errorMessage || result.warningMessage);
                                }).join('\n'));
                            reject({
                                message: genericErrorMessage,
                                errors: errorResults
                            });
                        }
                    } else {
                        var authReasons = [];
                        _.forEach(successfulResults, function (result) {
                            authReasons.push(result.taskID + ': ' + result.authorizationReason);

                            var rowIndex = _.findIndex(grid.gridOptions.rowData, { n10908: result.taskID });
                            var persistIndex = null;
                            if (actionViewPersistenceService && actionViewPersistenceService.detailResults
                                    && actionViewPersistenceService.detailResults.dataTable) {
                                persistIndex = _.findIndex(actionViewPersistenceService.detailResults.dataTable, { n10908: result.taskID });
                            }

                            // It is valid that a result may come back for a row that isn't in the results; at least one example
                            // is a dismiss intake item operation, which may affect both an Identify/Data Enter task and a
                            // Perform QC task where only one of those is actually in the grid
                            if (rowIndex >= 0) {
                                // If the actionResult has a column named n10908 (which is the taskID), that's a flag that
                                // this row contains search result data that sould be live-updated; if this is not present,
                                // simply mark the corresponding row as stale
                                if (result.markStale) {
                                    // Note that row options such as .stale are processed in SD.AgGrid.Directive.js (getRowStyle function)
                                    grid.gridOptions.rowData[rowIndex].stale = true;
                                } else {
                                    _.remove(grid.selectionData, { n10908: result.taskID });
                                    _.assign(grid.gridOptions.rowData[rowIndex], result.changedFields);
                                    if (persistIndex !== null && persistIndex >= 0) {
                                        _.assign(actionViewPersistenceService.detailResults.dataTable[persistIndex], result.changedFields);
                                    }
                                }
                            }
                        });
                        grid.gridOptions.api.refreshView();

                        if (authReasons.length > 0) {
                            console.log('Task authorization reasons (taskID: reason):\n', authReasons.join('\n'));
                        }

                        resolve({
                            successfulResults: successfulResults,
                            errorResults: errorResults
                        });
                    }
                });
            }

            function markErrorResults(successfulResults, errorResults) {
                var consoleErrors = [];

                _.forEach(successfulResults, function (result) {
                    var i = _.findIndex(grid.gridOptions.rowData, { n10908: result.taskID });
                    if (i >= 0) {
                        grid.gridOptions.rowData[i].warning = false;
                        grid.gridOptions.rowData[i].error = false;
                    }

                    _errors = _.reject(_errors, {taskID: result.taskID});
                })

                _.forEach(errorResults, function (result) {
                    consoleErrors.push(result.taskID + ': ' + (result.errorMessage || result.warningMessage));
                    _errors.push(result);

                    var i = _.findIndex(grid.gridOptions.rowData, { n10908: result.taskID });
                    if (i >= 0) {
                        if (!result.errorMessage && result.warningMessage) {
                            grid.gridOptions.rowData[i].warning = true;
                        } else {
                            grid.gridOptions.rowData[i].error = true;
                        }
                    }
                });

                if (consoleErrors.length > 0) {
                    console.log('Processing errors (taskID: error):\n' + consoleErrors.join('\n'));
                }

                actionViewPersistenceService.selectionData = grid.selectionData;
                grid.gridOptions.api.refreshView();
            }

            function correctForAsyncError(result) {
                if (result.asyncState !== undefined && result.result !== undefined) {
                    console.log('WARNING: An asyncronous task result appears to have come back from the API; please check for a Task return and correct if necessary');
                    return result.result;
                }
                else {
                    return result;
                }
            }

            function runModalResult(modalResultFunction, loadingText, callback) {
                loadingHandler(true, loadingText);
                // Most of the time "callback" won't be specified, and we'll just want to go right to the default
                // "processResult" function
                modalResultFunction().then(function (result) {
                    loadingHandler(false);
                    result = correctForAsyncError(result);
                    if (callback) {
                        callback(result);
                    } else {
                       // processResult(result);
                       processAddPaymentsToBatchResult(result);
                    }
                }).catch(function () {
                    loadingHandler(false);
                    toastr.error('An unexpected error has occurred');
                });
            }

            function runModalResultWithBuffer(modalResultFunction, loadingText, taskIDs, bufferSize, callback) {
                var chunks = _.chunk(taskIDs, bufferSize);
                var index = 1;
                var result = [];

                var processChunk = function () {
                    if (chunks.length) {
                        var chunk = chunks.shift();
                        var currentLoadingText;
                        if (bufferSize === 1) {
                            currentLoadingText = loadingText + " Task " + index + " of " + taskIDs.length + "...";
                        } else {
                            currentLoadingText = loadingText + " Tasks " + index + " - " + (index + chunk.length - 1) + " of " + taskIDs.length + "...";
                        }

                        var isActive = loadingHandler(true, currentLoadingText);
                        // The loadingHandler returns true if the directive we started this on still exists, false otherwise;
                        // check the result so we can just bail out if the user navigated away (maybe we should toast something
                        // about the results if they navigate away or something?)
                        if (isActive) {
                            modalResultFunction(chunk).then(function (tempResult) {
                                tempResult = correctForAsyncError(tempResult);
                                result = result.concat(tempResult);
                                index += chunk.length;
                                processChunk();
                            }).catch(function () {
                                loadingHandler(false);
                                toastr.error('An unexpected error has occurred');
                            });
                        }
                    } else {
                        loadingHandler(false);
                        if (callback) {
                            callback(result);
                        } else {
                            processResult(result);
                        }
                    }
                };
                processChunk();
            }

            function processResult(processingResult) {
                processResultPromise(processingResult).then(toastProcessResult, function (errorResult) {
                    toastr.error(errorResult.message, 'Error!');
                });
            }

            function processAddPaymentsToBatchResult(processingResult) {
                processResultPromise(processingResult).then(toastProcessResult, function (errorResult) {
                    var errors = _.uniq(_.map(_.filter(errorResult.errors, function (error) {
                        return error.errorMessage;
                    }), 'errorMessage'));
                    var warnings = _.uniq(_.map(_.filter(errorResult.errors, function (error) {
                        return !error.errorMessage;
                    }), 'warningMessage'));
                    taskService.showErrorNotificationModal(errors, warnings,
                        'The following error(s) or warning(s) were encountered attempting to add payments ' +
                        'to a batch; please attempt to correct these errors and try again');
                    //toastr.error(errorResult.message, 'Error!');
                });
            }

            function toastProcessResult(resolution) {
                if (resolution.errorResults.length < 1) {
                    toastr.success('Successfully processed ' + resolution.successfulResults.length + ' tasks');
                } else if (!resolution.successfulResults || resolution.successfulResults.length < 1) {
                    toastr.error('An unexpected error has occurred');
                } else {
                    toastr.warning('Only some tasks successfully processed; ' + resolution.successfulResults.length + ' succeeded and ' + resolution.errorResults.length + ' failed');
                }
            }

            function updateChangedGridRows() {
                angular.forEach(grid.selectionData, function (row) {
                    var i = _.findIndex(grid.gridOptions.rowData, { n10908: row.n10908 });

                    if (i >= 0) {
                        grid.gridOptions.rowData[i].stale = true;
                    }
                });

                actionViewPersistenceService.selectionData = grid.selectionData;

                grid.gridOptions.api.refreshView();
            }
        }

        function hasErrors() {
            return _errors.length > 0;
        }

        function clearErrors() {
            _errors = [];
        }
    }
})();
