import { NgModule } from '@angular/core';
import { SDHttpService } from '../Common/Routing/sd-http.service';
import { BuildTimestampService, UtilitiesService } from '../UI-Lib/Utilities';
import { TaskService } from '../Task/task.service.upgrade';
import { MessageBoxService } from '../UI-Lib/Message-Box/messagebox.service.upgrade';
import { NavigationService } from '../Layout/Navigation.Service.upgrade';
import { ParcelActivityModalService } from '../Entity/Parcel/Info/Activity/parcel-activity-modal.service.upgrade';
import { UpgradeNavigationServiceHandler } from '../Common/Routing/upgrade-navigation-handler.service';
import { EventAdapterService } from '../Common/Services/event-adapter.service.upgrade';
import { ProcessingService } from '../Processing/processing.service.upgrade';
import { ActionViewPersistenceService } from '../Task/ActionView/Action.View.Persistence.Service.upgrade';
import { UserGroupService } from '../User-Group/user-group-service.upgrade';
import { AssessorService } from '../Assessor-Collector/Assessor/assessor-service';
import { ActionViewService} from '../Task/ActionView/action-view-service.upgrade';
import { AuthenticationService} from '../Account/authentication-service.upgrade';
import { PropertyTypeService } from '../Common/Services/propertyType.service.upgrade';
import { CollectorService } from '../Assessor-Collector/Collector/collector.service.upgrade';
import { SearchOperatorsService } from '../Search/search.operators.service.upgrade';
import { DocumentServiceUpgrade } from '../Documents/document.service.upgrade';
import { ContactModalService } from '../Contact/contactModal.service';
import { ContactsRoleAssociationServiceUpgrade } from '../Contact/contactsRoleAssociation.service.upgrade';
import { StartupService } from './startup.service.upgrade';

@NgModule({
    providers: [
        { provide: '$location', useFactory: (i: any) => i.get('$location'), deps: ['$injector'] },
        { provide: UtilitiesService, useFactory: (i: any) => i.get('utils'), deps: ['$injector'] },
        { provide: '$http', useFactory: (i: any) => i.get('$http'), deps: ['$injector'] },
        { provide: SDHttpService, useFactory: (i: any) => i.get('sd.Http.Service'), deps: ['$injector'] },
        { provide: TaskService, useFactory: (i: any) => i.get('taskService'), deps: ['$injector'] },
        { provide: ActionViewPersistenceService, useFactory: (i: any) => i.get('actionViewPersistenceService'), deps: ['$injector'] },
        { provide: MessageBoxService, useFactory: (i: any) => i.get('messageBoxService'), deps: ['$injector'] },
        { provide: NavigationService, useFactory: (i: any) => i.get('sd.Navigation.Service'), deps: ['$injector'] },
        { provide: ParcelActivityModalService, useFactory: (i: any) => i.get('ParcelActivityModalService'), deps: ['$injector'] },
        { provide: UpgradeNavigationServiceHandler, useFactory: (i: any) => i.get('UpgradeNavigationServiceHandler'), deps: ['$injector'] },
        { provide: EventAdapterService, useFactory: (i: any) => i.get('eventAdapterService'), deps: ['$injector'] },
        { provide: ProcessingService, useFactory: (i: any) => i.get('processingService'), deps: ['$injector'] },
        { provide: BuildTimestampService, useFactory: (i: any) => i.get('BuildTimestampService'), deps: ['$injector'] },
        { provide: PropertyTypeService, useFactory: (i: any) => i.get('sd.Property.Type.Service'), deps: ['$injector'] },
        { provide: CollectorService, useFactory: (i: any) => i.get('CollectorService'), deps: ['$injector'] },
        { provide: UserGroupService, useFactory: (i: any) => i.get('sd.User.Group.Service'), deps: ['$injector'] },
        { provide: AssessorService, useFactory: (i: any) => i.get('sd.Assessor.Service'), deps: ['$injector'] },
        { provide: ActionViewService, useFactory: (i: any) => i.get('actionViewService'), deps: ['$injector'] },
        { provide: AuthenticationService, useFactory: (i: any) => i.get('sd.Authentication.Service'), deps: ['$injector'] },
        { provide: '$rootScope', useFactory: (i: any) => i.get('$rootScope'), deps: ['$injector'] },
        { provide: '$location', useFactory: (i: any) => i.get('$location'), deps: ['$injector'] },
        { provide: SearchOperatorsService, useFactory: (i: any) => i.get('searchOperators'), deps: ['$injector'] },
        { provide: DocumentServiceUpgrade, useFactory: (i: any) => i.get('DocumentService'), deps: ['$injector'] },
        { provide: ContactModalService, useFactory: (i: any) => i.get('sd.Contacts.Service.old'), deps: ['$injector'] },
        { provide: ContactsRoleAssociationServiceUpgrade, useFactory: (i: any) => i.get('ContactRoleAssociationService'), deps: ['$injector'] },
        { provide: StartupService, useFactory: (i: any) => i.get('StartupService'), deps: ['$injector'] },
        { provide: 'routes', useFactory: (i: any) => i.get('routes'), deps: ['$injector'] },
    ]
})
export class Ng1UpgradeModule {
}
