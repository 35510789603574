
<div class="card panel-flat-color secondary-panel">
    <div class="panel-flat-header" style="display: flex; align-items: center; justify-content: space-between;">
        <h3>Deadlines</h3>
        <div style="display: flex; align-items: center;">
            <div [hidden]="editMode && isStatePage" style="display: flex; align-items: center;">
                <span>Tax Year:</span>
                <select class="form-select form-select-sm all"
                        style="width: auto !important; margin-left: 5px;"
                        [disabled]="serverAction"
                        (change)="yearChanged()"
                        [(ngModel)]="selectedYear">
                            <option *ngFor="let year of years" [ngValue]="year">{{year}}</option>
                </select>
            </div>
            <div class="header-button-container">
                <button *ngIf="!editMode" class="flat-button icon-button primary-button"
                    [hidden]="!hasEditPermission"
                    (click)="edit()"
                    [disabled]="serverAction"
                    [hidden]="!hasEditPermission">
                        <i class="fa fa-pencil"></i>
                </button>
                <span *ngIf="editMode">
                    <button class="flat-button icon-button success-button" [disabled]="serverAction" (click)="save()">
                        <i class="fa fa-save"></i>
                    </button>
                    <button class="flat-button icon-button warning-button" [disabled]="serverAction" (click)="cancel()">
                        <i class="fa fa-close"></i>
                    </button>
                </span>
            </div>
        </div>
    </div>
    <div class="panel-flat-body">
        <loading-spinner [show]="serverAction" [size]="4" [hasOverlay]="true"></loading-spinner>
        <div *ngIf="editMode" style="margin-bottom: 20px;">
            <ws-select  placeholder="Add Deadline"
                        [(ngModel)]="chosenDeadlineProptype"
                        (selectedOptionChanged)="addDeadline()"
                        [options]="unassignedDeadlinePropTypes"
                        labelProperty="display"
                        [returnEntireOption]="true">
            </ws-select>
        </div>
        <table class="deadline-table">
            <tbody>
                <ng-container *ngFor="let deadlineType of deadlinesByDeadlineType">
                    <ng-container *ngIf="deadlineType.deadlines.length">
                        <tr>
                            <td colspan="4" class="deadline-type-heading">
                                <h4 *ngIf="deadlineType.appealDeadlineTypeID > 0">{{deadlineType.deadlineType}} Deadlines <span *ngIf="(!isStatePage) && editMode">(Assessor Overrides)</span></h4>
                                <h4 *ngIf="deadlineType.appealDeadlineTypeID < 0">{{deadlineType.deadlineType}}</h4>
                            </td>
                        </tr>
                        <ng-container *ngFor="let deadline of deadlineType.deadlines">
                            <ng-container *ngIf="deadline.efAction !== 'delete'">
                                <tr *ngIf="!editMode">
                                    <td>{{deadline.propertyTypeName}}</td>
                                    <td>
                                        <span *ngIf="deadline.deadlineYearID !== null">{{getDeadlineDateStr(deadline)}}</span>
                                        <span *ngIf="deadline.deadlineYearID === null">{{deadline.deadlineDate | date:'M/d/yyyy':'utc'}}</span>
                                        <span *ngIf="deadline.earliestPossible">^</span>
                                    </td>
                                    <td>
                                        <a class="pointer"
                                            *ngIf="isStatePage && deadlineHasExceptions(deadline)"
                                            (click)="openAssessorExceptions(deadline)"
                                            title="Click to see the list of assessors where deadline varies">
                                                (Exceptions)
                                        </a>
                                        <a *ngIf="!isStatePage && deadline.entityID != assessorId"
                                            anchor-route
                                            [sref]="{ target: 'state', options: { stateId: stateId } }"
                                            title="Deadline taken from the state setups.  There is no specific deadline set for this assessor.">
                                                (per state setup)
                                        </a>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr *ngIf="editMode">
                                    <td style="white-space: nowrap;">{{deadline.propertyTypeName}}</td>
                                    <td>
                                        <div *ngIf="deadline.deadlineYearID != null" style="display: flex;">
                                            <input type="text"
                                                [required]="deadline.deadlineYearID != null"
                                                class="form-control sd-validate-input-text"
                                                [(ngModel)]="deadline.deadlineMonthDay"
                                                (ngModelChange)="setDirty(deadline)"
                                                style="width:60px;">
                                            <select class="form-select form-select-sm" style="width: auto;" [(ngModel)]="deadline.deadlineYearID" (change)="setDirty(deadline)">
                                                <option *ngFor="let type of YEAR_TYPES" [ngValue]="type.id">{{type.name}}</option>
                                            </select>
                                        </div>
                                        <weissman-datetime *ngIf="deadline.deadlineYearID == null"
                                            [(inputDate)]="deadline.deadlineDate"
                                            [dateOnly]="true"
                                            (inputDateChange)="setDirty(deadline)"
                                            [required]="true">
                                        </weissman-datetime>
                                    </td>
                                    <td>
                                        <div *ngIf="deadlineType.appealDeadlineTypeID > 0" class="checkbox">
                                            <label style="white-space: nowrap;">
                                                <input type="checkbox" [(ngModel)]="deadline.earliestPossible" (change)="setDirty(deadline)"> Earliest Possible
                                            </label>
                                        </div>
                                    </td>
                                    <td>
                                        <button *ngIf="deadlineType.appealDeadlineTypeID > 0" class="flat-button icon-button warning-button" (click)="delete(deadline)">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr class="from-date" *ngIf="deadline.efAction && !deadline.earliestPossible && deadlineType.appealDeadlineTypeID > 0">
                                    <td>From Date:</td>
                                    <td><weissman-datetime [(inputDate)]="deadline.fromDate" [dateOnly]="true"></weissman-datetime></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </tbody>
        </table>

        <div style="margin-top: 20px;" *ngIf="checkUseEarliestPossible">
            <span>^Not available. Projected as earliest possible date.</span>
        </div>
    </div>
</div>
