import { Component, EventEmitter, OnDestroy, OnInit, Output, Input } from '@angular/core';
import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { BehaviorSubject, Subject, Subscription, lastValueFrom, takeUntil } from 'rxjs';
import { AgGridOptionsBuilder } from '../../../Compliance/AgGrid';
import { AgGridMultiSelectTracker } from '../../../Compliance/AgGrid/MultiSelectTracker';
import { BusyIndicatorRef, BusyIndicatorService } from '../../../Busy-Indicator';
import { HelpService } from 'src/app/UI-Lib/Help-Tooltip';
import { UpgradeNavigationServiceHandler } from 'src/app/Common/Routing/upgrade-navigation-handler.service';
import { DeleteConfirmationComponent, DeleteConfirmationComponentParams } from 'src/app/Common/Delete-Confirmation/deleteConfirmation.component';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { WeissmanModalService } from 'src/app/Compliance/WeissmanModalService';
import { PaymentBatchService } from '../../paymentBatch.service';
import { PAYMENT_BATCH_PAYMENTS_COLUMNS, TRANSMITTAL_PACKAGE_COLUMN } from './paymentBatchPayments.columns';
import { PaymentBatchPaymentsAgGridDataSource, PaymentBatchPaymentsDataSourceParams } from './agGridDataSource';
import { ICellRendererParamsForPaymentBatchPaymentsGridAction } from './agGridActionCellRenderer.component';
import { MessageModalService } from 'src/app/UI-Lib/Message-Box/messageModal.service';
import { PaymentBatchRepository } from 'src/app/Core-Repositories/paymentBatch.repository';
import { TransmittalService } from 'src/app/Processing/Transmittal/transmittal.service';
import { PAYMENT_BATCH_DETAILS_HELP } from '../paymentBatchDetails.component.help';
import { chain, cloneDeep, findIndex} from 'lodash';
import * as _ from 'lodash';
import { ICellRendererParamsForPaymentBatchPaymentsGridPaymentErrors } from './agGridPaymentErrorsCellRenderer.component';
import { MessageBoxButtons, MessageBoxService, MessageBoxResult } from '../../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { WeissmanKeyValueDisplayPipe } from 'src/app/UI-Lib/Pipes';

@Component({
    selector: 'payment-batch-payments-grid',
    templateUrl: 'paymentBatchPaymentsGrid.component.html',
    styles: [`
        :host { display: contents; }
    `]
})

export class PaymentBatchPaymentsGridComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _helpService: HelpService,
        private readonly _paymentBatchRepository: PaymentBatchRepository,
        private readonly _paymentBatchService: PaymentBatchService,
        public readonly decimalPipe: DecimalPipe,
        public readonly currencyPipe: CurrencyPipe,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _modalService: WeissmanModalService,
        public readonly busyIndicatorService: BusyIndicatorService,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _messageModalService: MessageModalService,
        private readonly _keyValueDisplayPipe: WeissmanKeyValueDisplayPipe,
        public readonly transmittalService: TransmittalService) { }

    @Input() paymentBatch: Core.PaymentBatchModel;
    @Input() workflowStatus: Core.PaymentBatchDetailsWorkflowStatusModel;
    @Output() gridDataChanged: EventEmitter<void> = new EventEmitter();
    @Output() rowCount: EventEmitter<number> = new EventEmitter();

    isBulkDeleteVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isBulkUpdateVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    gridId: System.Guid = 'E83F889C-9538-4E06-BCDF-BD463B3B9FCC';
    busyRefId: string = this.busyIndicatorService.generateUniqueMessageIdentifier();
    paymentBatchId: number;
    totalsLoading: any;

    gridTracker: AgGridMultiSelectTracker;

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        suppressScrollOnNewData: true,
        onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
        onSortChanged: () => this.gridTracker.onGridSortChanged(),
        rowClassRules: {
            'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected(params.data.paymentId),
            'selected-totals-row': (params) => params.data && params.data.selectedTotalsRow
        },
        rowModelType: 'infinite',
        onModelUpdated: () => this.rowCount.emit(this._gridDataSource.totalRows)
    }).withInfiniteScroll(100)
        .withLoadingOverlay()
        .withColumnResize()
        .withMultipleColumnSort()
        .withColumnPinning()
        .build();

    // exportOptions: AgGridExportOptions = {
    //     start: async (columnsToReturn: Compliance.NameValuePair<string>[]): Promise<AgGridExportStartLRP> => {
    //         const searchModel = this._gridDataSource.getSearchParamsWithoutPagination();

    //         const exportModel: Core.ClientServiceResponsibilityCommandCenterExportModel = {
    //             columnsToReturn: columnsToReturn,
    //             searchModel: searchModel,
    //             companyId: this.entityTree.subsidiaryCompany.entityId
    //         };

    //         const longRunningProcessId = await this._ccService.exportList(exportModel);
    //         return { longRunningProcessId, longRunningProcessTypeId: LongRunningProcessTypeEnum.ExportClientServiceResponsibilityCommandCenter };
    //     },
    //     disabled: false,
    //     canCancel: true
    // };

    private _gridApi: GridApi;
    private _gridMultiSelectSub: Subscription;
    private _destroy$: Subject<void> = new Subject();
    private _busyRef: BusyIndicatorRef;
    private _gridDataSource: PaymentBatchPaymentsAgGridDataSource;
    private _paymentErrors: Core.BulkOperationResult[] = [];
    private _lastFetchedTotals: Core.PaymentBatchPaymentTotalsModel;

    get refreshingGrid(): boolean {
        return this._gridDataSource && this._gridDataSource.isRefreshing;
    }

    ngOnInit() {
        this._helpService.setContent(PAYMENT_BATCH_DETAILS_HELP);
        this.paymentBatchId = parseInt(this._routerService.getQuerystringParam('paymentBatchId'));
    }

    ngOnDestroy(): void {
        this._gridMultiSelectSub && this._gridMultiSelectSub.unsubscribe();
        this._destroy$.next();
        this._destroy$.complete();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this._gridApi.showLoadingOverlay();
        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        const columns = PAYMENT_BATCH_PAYMENTS_COLUMNS(this);
        if(this.paymentBatch?.batchType === Core.PaymentBatchTypeEnum.Transmittal) {
            const billAmountIdx = findIndex(columns, {field: 'billAmount'});
            columns.splice(billAmountIdx, 0, TRANSMITTAL_PACKAGE_COLUMN(this));
        }

        this._gridApi.setColumnDefs(columns);

        this._gridMultiSelectSub = this.gridTracker.selectedRows$.subscribe(async () => {
            // if (!this.longRunningUpdateUnderway) {
            //     this.isBulkUpdateVisible$.next(this.gridTracker.hasSelectedRows());
            //     this.isRemoveExceptionsVisible$.next(this.gridTracker.hasSelectedRows());
            // }

            const isBulkDeleteVisible = this.workflowStatus?.canAddDeletePayments && this.gridTracker.hasSelectedRows();

            this.isBulkDeleteVisible$.next(isBulkDeleteVisible);
            const isBulkUpdateVisible = this.workflowStatus?.canAdvanceTask && this.gridTracker.hasSelectedRows();

            this.isBulkDeleteVisible$.next(isBulkDeleteVisible);
            this.isBulkUpdateVisible$.next(isBulkUpdateVisible);

            this._reloadTotals();
        });

        this._setDataSource();

    }

    addPaymentErrors(paymentErrors: Core.BulkOperationResult[]) {
        this._paymentErrors = paymentErrors;
    }

    async refresh(): Promise<void> {
        this._paymentErrors = [];

        return this._refreshDataSource();
    }

    async bulkUpdate(): Promise<void> {
        const answer = await this._messageBoxService.open({
            title: 'Mark Rereviewed',
            message: `Task advancement is blocked for any payments which have completed review tasks 
             where the parcel or collector has changed since the task was complete. Once you have verified that the changes 
            do not impact the payment you can mark the task as reviewed, which will clear the error. Are 
            you sure you want to mark the selected record/s as reviewed?`,
            buttons: MessageBoxButtons.OKCancel
        });
        if (answer === MessageBoxResult.Cancel) {
            return;
        }
        this._showBusyIndicator('Rereview Payments', 'Rereviewing Payments from Batch', null, false, false);

        const model: Core.PaymentBatchDetailsPaymentBulkReReviewModel = {
            paymentBatchId: this.paymentBatchId,
            selectAllRows: this.gridTracker.getSelectedRowsModel().selectAllRows,
            selectedRows: this.gridTracker.getSelectedRowsModel().selectedRows,
        };

        try {
             await this._paymentBatchService.paymentBatchDetailsPaymentBulkReReview(model);
             this.refresh();
        }
        catch (e) {
            await this._hideBusyIndicator();
            return Promise.reject(e);
        }
        await this._hideBusyIndicator();
        return Promise.resolve();
    }

    async bulkDelete(): Promise<void> {
        const params: DeleteConfirmationComponentParams = {
            item: 'Payment Batch Payments',
            message: 'Are you sure you want to remove selected Payments from the Batch?'
        };

        const result = await this._modalService.showAsync(DeleteConfirmationComponent, params, 'modal-md');

        if (result) {
            this._showBusyIndicator('Bulk Delete', 'Removing Payments from Batch', null, false, true);

            const model: Core.PaymentBatchDetailsPaymentBulkDeleteModel = {
                paymentBatchId: this.paymentBatchId,
                selectAllRows: this.gridTracker.getSelectedRowsModel().selectAllRows,
                selectedRows: this.gridTracker.getSelectedRowsModel().selectedRows,
            };

            try {
                const longRunningProcessId = await this._paymentBatchService.startPaymentBatchDetailsPaymentBulkDelete(model);
                await this._busyRef.setLongRunningProcessId(longRunningProcessId);
            }
            catch (e) {
                await this._hideBusyIndicator();
                return Promise.reject(e);
            }
        }

        return Promise.resolve();
    }

    async deleteRow(params: ICellRendererParamsForPaymentBatchPaymentsGridAction): Promise<void> {
        const row = params.data as Core.PaymentBatchDetailsEntryModel;
        if (!row) {
            return;
        }

        const deleted = await this._deletePaymentBatchPaymentsRow(row);
        if (deleted) {
            this._refreshAndNotifyParent();
        }
    }

    canEdit(params) {
        return this.workflowStatus.canAddDeletePayments;
    }

    canDelete() {
       return this.workflowStatus.canAddDeletePayments;
    }

    getErrors(params: ICellRendererParamsForPaymentBatchPaymentsGridPaymentErrors): string[] {
        if(!params.data) {
            return [];
        }

        const row = params.data as Core.PaymentBatchDetailsEntryModel;
        const errorMessages = chain(this._paymentErrors)
            .filter({entityID: row.paymentId})
            .map(error => _.values(_.pick(error, ['errorMessage', 'warningMessage'])))
            .flatten()
            .filter(message => message !== null && message !== undefined)
            .value() as string[];

        return errorMessages;
    }

    formatCurrency(value: number): string {
        if(value == null) {
            return '';
        }

        const formattedVal = this.currencyPipe.transform(Math.abs(value), 'USD', 'symbol-narrow');
        return value < 0 ? `(${formattedVal})` : formattedVal;
    }

    handleTotalsUpdate = (totals: Core.PaymentBatchPaymentTotalsModel, isLoading: boolean = false) => {
        const model = {};
        const paymentRow: any = {
            model,
            getModel: () => { return model; },
            displayPipe: this._keyValueDisplayPipe,
            index: 0,
            paymentAmount: totals && totals.paymentAmountTotal,
            installmentName: 'SELECTED',
            selectedTotalsRow: true
        };

        if (isLoading) {
            this.totalsLoading = this._gridDataSource.getLoadingMessage(msg => {
                paymentRow.installmentName = msg;
                this._gridApi.setPinnedBottomRowData([paymentRow]);
            });
            return;
        } else if (!isLoading && this.totalsLoading >= 0) {
            clearInterval(this.totalsLoading);
            this.totalsLoading = null;
        }

        const totalRows = this.gridTracker.hasAnythingSelected() ? [paymentRow] : [];

        setTimeout(() => this._gridApi.setPinnedBottomRowData(totalRows), 100);
    };

    private async _getGridRowIds(skip: number, take: number): Promise<Compliance.QueryResultModel<number>> {
        return this._gridDataSource.getRowIdsInternal(skip, take);
    }

    private async _refreshDataSource(): Promise<void> {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success)  {
                return;
            }
        }

        this.gridTracker.clear(false);

        this.isBulkDeleteVisible$.next(false);
        this.isBulkUpdateVisible$.next(false);
        this._gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return false;
        }

        this.gridTracker.clear(false);

        this.isBulkDeleteVisible$.next(false);
        this.isBulkUpdateVisible$.next(false);
        const dataSourceParams = (): PaymentBatchPaymentsDataSourceParams => {
            return {
                paymentBatchId: this.paymentBatchId
            };
        };

        this._gridDataSource = new PaymentBatchPaymentsAgGridDataSource(
            this._gridApi,
            this._paymentBatchService,
            dataSourceParams,
        );

        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }

    private async _deletePaymentBatchPaymentsRow(row: Core.PaymentBatchDetailsEntryModel): Promise<boolean> {
        try {
            await this._messageModalService.confirm('Are you sure you wish to remove the Payment from the Batch?', 'Confirm Delete');
        }
        catch (e) {
            return Promise.resolve(false);
        }

        const busyRef = this.busyIndicatorService.show({ message: 'Deleting' });

        try {
            await lastValueFrom(this._paymentBatchRepository.deletePaymentRow(row.paymentBatchId, row.paymentId));
        }
        finally {
            busyRef.hide();
        }

        return Promise.resolve(true);
    }

    private _showBusyIndicator(title: string, message: string = 'Working on it...', lrpId: number, canDismiss = true, hasProgressBar = true): void {
        if (this._busyRef) {
            this._busyRef.updateMessage(message, this.busyRefId);
            this._busyRef.setLongRunningProcessId(lrpId);
            return;
        }

        this._busyRef = this.busyIndicatorService.show({
            identifier: this.busyRefId,
            longRunningProcessId: lrpId,
            title: title ? title : 'Processing',
            message: message,
            hasProgressBar: hasProgressBar,
            canDismiss
        });

        this._busyRef.onProgressBarComplete().pipe(takeUntil(this._destroy$)).subscribe(async (success) => {
            await this._hideBusyIndicator();
            if (success) {
                this._refreshAndNotifyParent();
            }
        });
    }

    private async _hideBusyIndicator(): Promise<void> {
        if (this._busyRef) {
            await this._busyRef.hide();
            this._busyRef = null;
        }
        this._destroy$.next();
    }

    private _refreshAndNotifyParent() {
        this.refresh();
        this.gridDataChanged.emit();
    }

    private async _reloadTotals(): Promise<void> {
        if (!this._gridDataSource) {
            return;
        }

        const result = await this._gridDataSource.getSelectedRowTotals(this.gridTracker.getSelectedRowsModel());
        this.handleTotalsUpdate(result);
    }
}
