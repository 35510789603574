import { Injectable } from '@angular/core';
import { AccountService } from './account.service';
import { StorageTokenKeys } from './authStorage.service';
import { WeissmanModalService } from '../Compliance/WeissmanModalService';
import { UpgradeNavigationServiceHandler } from '../Common/Routing/upgrade-navigation-handler.service';

@Injectable(
    { providedIn: 'root' }
)
export class UserInstanceService {
    constructor(
        private readonly _accountService: AccountService,
        private readonly _modalService: WeissmanModalService,
        private readonly _routerService: UpgradeNavigationServiceHandler
    ) {
    }

    public readonly RyanInstanceId: number = 1;
    public readonly SystemInstanceId: number = 10000;
    public readonly AllInstancesId: number = -1;

    public readonly allInstancesItem: Core.InstanceViewModel = {
        name: 'All Instances',
        instanceId: this.AllInstancesId,
        inactive: false,
        isHidden: false,
        isHomeInstance: false,
        contactId: null,
        offersConsultingServices: false
    };

    //returns a list of user's instance memberships retrieved in GetUserInfo api call
    getUserInstanceMembership(includeHidden: boolean = true): Array<Core.InstanceViewModel> {
        if (this._accountService.userData !== undefined && this._accountService.userData.instanceMembership !== undefined) {
            return this._accountService.userData.instanceMembership.filter(i => i.inactive === false && (i.isHidden === false || includeHidden));
        } else {
            //NOTE: too early in the process to get instance membership - no user data loaded yet"
            return [];
        }
    }

    getInstanceContactId() {
        if (this.isSingleInstanceSelected()) {
            //get current user's contact id in the currently selected instance
            return this.getUserInstanceMembership().filter(i => i.instanceId === this.getSelectedInstance().instanceId)[0].contactId;
        } else {
            //no instance selected - return home instance
            return this._accountService.userData.contactId;
        }
    }

    // //returns membership instances the user has the specified role in
    // //used for displaying instance list in forms when a specific instance is required to take an action - add company,group,team
    // getUserInstancesWithRole(role:string) {
    //     return this.getUserInstanceMembership().filter(i=> this.instanceIdsWithRight(role).indexOf(i.instanceId) > -1);
    // }

    // // returns list of instance ids that the user has the role/right
    // // needed to filter instance list when taking action that requires instance - add company/group/site
    // private instanceIdsWithRight(role) {
    //     return this.userService.instanceIdsWithRight(role);
    // }

    //helper method to show/hide instance selector in forms
    userHasMultipleInstances(): boolean {
        return this.getUserInstanceMembership().length > 1;
    }

    //returns the instance ids selected from local storage - used when sending up selected instances in request header
    getSelectedInstance(): Core.InstanceViewModel {
        this._ensureSelectedInstancesKeyExistsWithDefaults();
        if (localStorage[StorageTokenKeys.selectedInstances]) {
            const selectedInstanceId = localStorage[StorageTokenKeys.selectedInstances];
            return this.getUserInstanceMembership().filter(i => i.instanceId === parseInt(selectedInstanceId) && i.isHidden === false)[0] || this.allInstancesItem;
        } else {
            return this.allInstancesItem;
        }
    }

    isCurrentInstanceRyan() {
        return this.getSelectedInstance().instanceId === this.RyanInstanceId;
    }

    isCurrentInstanceSystem() {
        return this.getSelectedInstance().instanceId === this.SystemInstanceId;
    }

    isInstanceImplicit(instanceId: number) {
        return !this.isInstanceExplicit(instanceId);
    }

    isInstanceExplicit(instanceId: number) {
        const instance = this.getUserInstanceMembership().find(i => i.instanceId === instanceId);
        return (instance !== undefined && instance.contactId !== null && instance.inactive === false);
    }

    isCrossInstancePerspective(instanceId: number) {
        //single instance selected and same as entity instance
        if (this.isSingleInstanceSelected()) {
            if (this.getSelectedInstance().instanceId === instanceId) {
                //same instance - single
                return false;
            } else {
                //cross instance - single
                return true;
            }
        }
        //all instances selected
        else {
            return this.isInstanceImplicit(instanceId);
        }
    }

    getInstanceById(instanceId: number) {
        return this.getUserInstanceMembership().filter(i => i.instanceId === instanceId)[0];
    }

    getInstancesForHeader(): string {
        var selectedInstance = this.getSelectedInstance();
        if (selectedInstance.instanceId === this.AllInstancesId) {
            return this.getUserInstanceMembership().map(i => i.instanceId.toString()).toString();
        } else {
            return selectedInstance.instanceId.toString();
        }
    }

    //updates the selected instances stored in local storage
    saveSelectedInstance(instance: { instanceId: number, name: string }) {
        //save selected item
        localStorage[StorageTokenKeys.selectedInstances] = JSON.stringify(instance.instanceId);
    }

    //checks to see if a single instance is selected for the user
    isSingleInstanceSelected() {
        return +this.getSelectedInstance().instanceId !== this.AllInstancesId;
    }

    showManageMyInstances(): void {
        this._routerService.go('invites', {});
    }

    isRyanInstanceSelected(): boolean {
        const selectedInstance = this.getSelectedInstance();

        if (selectedInstance.instanceId === this.AllInstancesId) {
            return this.getUserInstanceMembership().some(i => i.instanceId === this.RyanInstanceId);
        }

        return selectedInstance.instanceId == this.RyanInstanceId;
    }

    //ensure default selections are populated
    private _ensureSelectedInstancesKeyExistsWithDefaults() {
        if (localStorage.getItem(StorageTokenKeys.selectedInstances) === null) {
            if (this._accountService.userData !== undefined && this._accountService.userData.instanceMembership !== undefined) {
                if (this._accountService.userData.instanceMembership.length > 1) {
                    this.saveSelectedInstance(this.allInstancesItem);
                } else {
                    this.saveSelectedInstance(this._accountService.userData.instanceMembership[0]);
                }
            }
        }
    }
}
