import { Injectable } from '@angular/core';
import { AgGridColumns, AgGridFilterParams } from '../../../Compliance/AgGrid';
import { CellClickedEvent, ColDef, RowNode, ValueGetterParams } from 'ag-grid-community';
import { AgGridLinkCellRenderer, AgGridLinkCellRendererParams } from '../../../Compliance/AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { SiteService } from '../../../Entity/Site/Site.Service.upgrade';
import { ParcelService } from '../../../Entity/Parcel/parcel.service.upgrade';
import { AssessorCollectorService } from '../../../Assessor-Collector/assessor.collector.service';
import { NavigationService } from '../../../Layout/Navigation.Service.upgrade';
import { NubService } from '../../../Navigation/Nub/nub.service';
import { AdvancedSearchPersistenceService } from '../advancedSearchPersistence.service';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { SmartSearchService } from '../smartSearch.service';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { CommentsService } from '../../../Comments/comments.service';
import { WeissmanDateFormat } from '../../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';
import { EntityTypeIds } from '../../../constants.new';
import { DocumentServiceUpgrade } from '../../../Documents/document.service.upgrade';
import { PopUpService } from '../../../Common/Popup/popup.service';
import { ProductAnalyticsService } from '../../../Common/Amplitude/productAnalytics.service';
import { CommentsModalService } from '../../../Comments/commentsModal.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment-timezone';
import AdvancedSearchField = Core.AdvancedSearchField;
import { AttachmentService } from 'src/app/Attachment/attachment.service';

@Injectable()
export class AdvancedSearchColumnBuilder {
    constructor(
        private readonly _siteService: SiteService,
        private readonly _parcelService: ParcelService,
        private readonly _assessorCollectorService: AssessorCollectorService,
        private readonly _navigationService: NavigationService,
        private readonly _advancedSearchPersistenceService: AdvancedSearchPersistenceService,
        private readonly _navigationHandler: UpgradeNavigationServiceHandler,
        private readonly _smartSearchService: SmartSearchService,
        private readonly _commentsService: CommentsService,
        private readonly _commentsModalService: CommentsModalService,
        private readonly _documentService: DocumentServiceUpgrade,
        private readonly _attachmentService: AttachmentService,
        private readonly _popUpService: PopUpService,
        private readonly _productAnalyticsService: ProductAnalyticsService,
        private readonly _toastr: ToastrService,
        private readonly _nubService: NubService
    ) {}

    getColumnDefinition(columnId: string, field: Core.AdvancedSearchField): ColDef {
        // Setup common column properties used by all column types
        // Depending on the column the cell renderer could get replaced
        let baseDefinition: any = {
            colId: columnId,
            headerName: field.resultsColumnName,
            field: columnId,
            hide: !(field.visible)
        };

        // If it is a navigation field, provide the correct renderer and nav link
        if (field.navigation) {
            const navigationRoute = this._getNavigationRoute(field.navigation, columnId);
            baseDefinition.cellRendererFramework = AgGridLinkCellRenderer;
            baseDefinition.cellRendererParams = {
                newWindow: false,
                isHelpDisabled: true,
                getLinkAsync: async (params: AgGridLinkCellRendererParams) => {
                    const entry = params.data;
                    if (!entry) {
                        return '';
                    }
                    return await navigationRoute(entry);
                },
                onClick: (params: AgGridLinkCellRendererParams) => {
                    this._setPersistentState(params.node);
                }
            } as AgGridLinkCellRendererParams;
        }

        // If formatting is provided by the back end, apply it
        const suppliedFormatting = {};
        if (field.advancedSearchDataType && field.advancedSearchDataType.formatting) {
            const dataTypeCustomFormatting = JSON.parse(field.advancedSearchDataType.formatting);
            Object.keys(dataTypeCustomFormatting).forEach((key: string) => {
                suppliedFormatting[key] = dataTypeCustomFormatting[key];
            });
        }

        // Get and apply the type based column settings
        baseDefinition = { ...baseDefinition, ...this._getTypeColumnProperties(field), ...suppliedFormatting };

        // Check to see if a field specific click event is needed
        const clickHandler = this._fieldSpecificClickEvents(field);
        if (clickHandler) {
            baseDefinition.onCellClicked = (params: CellClickedEvent) => {
                const rowData = params.data;
                clickHandler(rowData);
            };
        }

        // Set renderer if comment type
        switch (field.advancedSearchFieldID) {
            case 9007:		// Document Intake Number of Comments
            case 1916:		// Invoice Number of Comments
            case 1444:		// Payment Number of Comments
            case 1044:		// Revision Number of Comments
            case 1052:		// Assessment DRTT Number of Comments
            case 1456:		// Tax Bill DRTT Number of Comments
                baseDefinition = { ...baseDefinition, ...this._getCommentCell() };
                break;
        }

        // Ensure there is only one cell renderer applied since some fields will be both a navigation property and have
        // its own cell renderer, the regular cell renderer takes precedence, dropping the navigation renderer
        if (baseDefinition.cellRendererFramework && baseDefinition.cellRenderer) {
            delete baseDefinition.cellRendererFramework;
            delete baseDefinition.cellRendererParams;
        }

        return baseDefinition;
    }

    private _getNavigationRoute(navigationFieldName: string, columnId: string): (rowData) => Promise<{ stateName: string, params: any }> {
        switch (navigationFieldName) {
            case 'CompanyID':
            case 'Company_ParentCompanyID':
                return async (rowData) => {
                    return {
                        stateName: 'company',
                        params: {
                            companyId: this._getNavigationalFieldValueByName(rowData, navigationFieldName)
                        }
                    };
                };

            case 'SiteID':
            case 'Master SiteID':
                return async (rowData) => {
                    const fieldValue = this._getNavigationalFieldValueByName(rowData, navigationFieldName);

                    if(!fieldValue) {
                        this._toastr.warning('Site output column must be included for navigation.');
                        return null;
                    }

                    return this._siteService.getNavigationInfo(fieldValue).then(result => {
                        return {
                            stateName: 'site',
                            params: {
                                siteId: fieldValue,
                                companyId: result.companyID
                            }
                        };
                    });
                };

            case 'ParcelID':
            case 'Master ParcelID':
            case 'PP Reporting ParcelID':
                return async (rowData) => {
                    const fieldValue = this._getNavigationalFieldValueByName(rowData, navigationFieldName);

                    if(!fieldValue) {
                        this._toastr.warning('Parcel output column must be included for navigation.');
                        return null;
                    }

                    return await this._parcelService.getNavigationInfo(fieldValue).then(result => {
                        return {
                            stateName: 'parcel',
                            params: {
                                parcelId: fieldValue,
                                siteId: result.siteID,
                                companyId: result.companyID
                            }
                        };
                    });
                };

            case 'AssessorID':
                return async (rowData) => {
                    const fieldValue = this._getNavigationalFieldValueByName(rowData, navigationFieldName);
                    return this._assessorCollectorService.getNavigationInfo('assessor', fieldValue).then(result => {
                        return {
                            stateName: 'assessor',
                            params: {
                                id: this._getNavigationalFieldValueByName(rowData, navigationFieldName),
                                stateId: result.stateID
                            }
                        };
                    });
                };


            case 'CollectorID':
                return async (rowData) => {
                    const fieldValue = this._getNavigationalFieldValueByName(rowData, navigationFieldName);
                    return this._assessorCollectorService.getNavigationInfo('collector', fieldValue).then(result => {
                        return {
                            stateName: 'collector',
                            params: {
                                stateCollectorId: this._getNavigationalFieldValueByName(rowData, navigationFieldName),
                                stateId: result.stateID
                            }
                        };
                    });
                };

            case 'CompanyContactID':
            case 'SiteContactID':
            case 'ParcelContactID':
                return async (rowData) => {
                    return {
                        stateName: 'contacts',
                        params: {
                            contactId: this._getNavigationalFieldValueByName(rowData, navigationFieldName)
                        }
                    };
                };

            case 'StateID':
                return async (rowData) => {
                    return {
                        stateName: 'state',
                        params: {
                            // todo: get state id from central storage/ cached data?
                            stateId: this._getNavigationalFieldValueByName(rowData, navigationFieldName)
                        }
                    };
                };

            case 'AnnualYearID':
                return async (rowData) => {
                    const fieldValue = this._getNavigationalFieldValueByName(rowData, navigationFieldName);
                    return this._navigationService.getNavigationInfo('AnnualYear', fieldValue).then(result => {
                        return {
                            stateName: 'parcel',
                            params: {
                                annualYearID: fieldValue,
                                siteId: result.siteID,
                                companyId: result.companyID,
                                parcelId: result.parcelID
                            }
                        };
                    });
                };

            case 'AnnualAssessmentID':
                return async (rowData) => {
                    const fieldValue = this._getNavigationalFieldValueByName(rowData, navigationFieldName);
                    return this._navigationService.getNavigationInfo('AnnualAssessment', fieldValue).then(result => {
                        return {
                            stateName: 'parcel',
                            params: {
                                annualAssessmentID: fieldValue,
                                siteId: result.siteID,
                                companyId: result.companyID,
                                annualYearID: result.annualYearID,
                                parcelId: result.parcelID
                            }
                        };
                    });
                };

            case 'AppealID':
                return async (rowData) => {
                    const fieldValue = this._getNavigationalFieldValueByName(rowData, navigationFieldName);
                    return this._navigationService.getNavigationInfo('Appeal', fieldValue).then(result => {
                        return {
                            stateName: 'parcel',
                            params: {
                                appealID: fieldValue,
                                annualAssessmentID: result.annualAssessmentID,
                                siteId: result.siteID,
                                companyId: result.companyID,
                                annualYearID: result.annualYearID,
                                parcelId: result.parcelID
                            }
                        };
                    });
                };

            case 'FilingID':
                return async (rowData) => {
                    let fieldName = 'Filing';
                    let fieldValue = this._getNavigationalFieldValueByName(rowData, navigationFieldName);

                    if (!fieldValue) {
                        fieldValue = this._getNavigationalFieldValueByName(rowData, 'FilingBatchID');
                        fieldName = 'FilingBatch';
                    }

                    return this._navigationService.getNavigationInfo(fieldName, fieldValue).then(result => {
                        if (fieldName === 'Filing') {
                            return {
                                stateName: 'parcel',
                                params: {
                                    filingId: fieldValue,
                                    siteId: result.siteID,
                                    companyId: result.companyID,
                                    annualYearID: result.annualYearID,
                                    parcelId: result.parcelID
                                }
                            };
                        } else {
                            return {
                                stateName: 'filingBatchDetails',
                                params: {
                                    companyId: result.companyID,
                                    filingBatchId: fieldValue
                                }
                            };
                        }
                    });
                };

            case 'FilingBatchID':
                return async (rowData) => {
                    const fieldValue = this._getNavigationalFieldValueByName(rowData, navigationFieldName);
                    return this._navigationService.getNavigationInfo('FilingBatch', fieldValue).then(result => {
                        return {
                            stateName: 'filingBatchDetails',
                            params: {
                                companyId: result.companyID,
                                filingBatchId: fieldValue
                            }
                        };
                    });
                };

            case 'RefundID':
                return async (rowData) => {
                    const fieldValue = this._getNavigationalFieldValueByName(rowData, navigationFieldName);
                    return this._navigationService.getNavigationInfo('Refund', fieldValue).then(result => {
                        return {
                            stateName: 'parcel',
                            params: {
                                refundID: fieldValue,
                                siteId: result.siteID,
                                companyId: result.companyID,
                                annualYearID: result.annualYearID,
                                parcelId: result.parcelID
                            }
                        };
                    });
                };

            case 'BillClusterID':
                return async (rowData) => {
                    const fieldValue = this._getNavigationalFieldValueByName(rowData, navigationFieldName);
                    return this._navigationService.getNavigationInfo('BillCluster', fieldValue).then(result => {
                        return {
                            stateName: 'parcel',
                            params: {
                                billClusterID: fieldValue,
                                siteId: result.siteID,
                                companyId: result.companyID,
                                annualYearID: result.annualYearID,
                                parcelId: result.parcelID
                            }
                        };
                    });
                };

            case 'PaymentID':
                return async (rowData) => {
                    return {
                        stateName: 'paymentRedirect',
                        params: {
                            entityID: this._getNavigationalFieldValueByName(rowData, navigationFieldName)
                        }
                    };
                };

            case 'InvoiceID':
                return async (rowData) => {
                    const fieldValue = this._getNavigationalFieldValueByName(rowData, navigationFieldName);
                    return this._navigationService.getNavigationInfo('Invoice', fieldValue).then(result => {
                        if (result.siteID) {
                            return {
                                stateName: 'siteInvoice',
                                params: {
                                    invoiceID: fieldValue,
                                    siteID: result.siteID,
                                    companyID: result.companyID
                                }
                            };
                        } else {
                            return {
                                stateName: 'companyInvoice',
                                params: {
                                    invoiceID: fieldValue,
                                    companyID: result.companyID
                                }
                            };
                        }
                    });
                };

            default:
                console.log('advanced search - unimplemented navigation - ', navigationFieldName);
                return async () => ({ stateName: '', params: {} });
        }
    }

    private _getTypeColumnProperties(field: Core.AdvancedSearchField): ColDef {
        const minimumFractionDigits = field.advancedSearchDataType.precision || 0;
        switch (field.advancedSearchDataTypeID) {
            case Core.AdvancedSearchDataTypeEnum.money:
            case Core.AdvancedSearchDataTypeEnum.Currency_0:
            case Core.AdvancedSearchDataTypeEnum.Currency_2:
                return {
                    width: AgGridColumns.numericColumnWidth,
                    filter: 'agNumberColumnFilter',
                    filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                    floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                    valueFormatter: (params: ValueFormatterParams) => {
                        const valueToFormat = params.value;

                        if (!valueToFormat) { return ''; }

                        const formatter = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: minimumFractionDigits,
                            maximumFractionDigits: minimumFractionDigits
                        });

                        return formatter.format(valueToFormat);
                    }
                };
            case Core.AdvancedSearchDataTypeEnum.Date:
            case Core.AdvancedSearchDataTypeEnum.DateTime:
            case Core.AdvancedSearchDataTypeEnum.datetime2:
            case Core.AdvancedSearchDataTypeEnum.UTCDate:
            case Core.AdvancedSearchDataTypeEnum.UTCDateTime:
            case Core.AdvancedSearchDataTypeEnum.DateText:
                let dateFormatter;

                // For all other date types, the time coming from the server has already been
                // converted and is ready for display; we use UTC for transmission between the
                // server and client so that JavaScript doesn't convert the time to a local time.
                // So we use .utc() to retrieve the raw unprocessed date/time the server had
                // before transmission (see Utilities.Service.js convertDateStringsToDates
                // function and related functions for more info)
                if (field.advancedSearchDataType.name.indexOf('Date') >= 0) {
                    dateFormatter = (inputDateTime) => {
                        return moment(inputDateTime).utc().format('M/DD/YYYY');
                    };
                }

                if (field.advancedSearchDataType.name.indexOf('DateTime') >= 0) {
                    dateFormatter = (inputDateTime) => {
                        return moment(inputDateTime).utc().format('M/DD/YYYY h:mm A');
                    };
                }

                // For UTCDateTime and UTCDate type fields, the time coming from the server is
                // really a moment in time specified in UTC, and we need to convert it to Central
                // time before displaying
                if (field.advancedSearchDataType.name.indexOf('UTCDate') >= 0) {
                    dateFormatter = (inputDateTime) => {
                        return moment(inputDateTime).tz('America/Chicago').format('M/DD/YYYY');
                    };
                }

                if (field.advancedSearchDataType.name.indexOf('UTCDateTime') >= 0) {
                    dateFormatter = (inputDateTime) => {
                        return moment(inputDateTime).tz('America/Chicago').format('M/DD/YYYY h:mm A');
                    };
                }

                return {
                    width: AgGridColumns.dateColumnWidth,
                    filter: 'agDateColumnFilter',
                    filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                    floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                    valueGetter: (params: ValueGetterParams) => {
                        const valueToFormat = params.data[params.colDef.field];

                        if (!valueToFormat) { return ''; }

                        if (valueToFormat === 'N/A') {
                            return valueToFormat;
                        }

                        return dateFormatter ? dateFormatter(valueToFormat) : WeissmanDateFormat(valueToFormat);
                    },
                    comparator: (valueA, valueB) => {
                        let result = 0;
                        if (valueA == valueB) {
                            return 0;
                        }

                        if (valueA == null && valueB == null) {
                            return 0;
                        }

                        const cA = this._constructDateIfAbsent(valueA);
                        const cB = this._constructDateIfAbsent(valueB);

                        if (cA > cB) {
                            result = 1;
                        }

                        if (cA < cB) {
                            result = -1;
                        }

                        if (cA == cB) {
                            result = 0;
                        }

                        return result;
                    }
                };
            case Core.AdvancedSearchDataTypeEnum.Percent_0:
            case Core.AdvancedSearchDataTypeEnum.Percent_1:
            case Core.AdvancedSearchDataTypeEnum.Percent_2:
            case Core.AdvancedSearchDataTypeEnum.Percent_3:
            case Core.AdvancedSearchDataTypeEnum.Percent_4:
            case Core.AdvancedSearchDataTypeEnum.Percent_5:
            case Core.AdvancedSearchDataTypeEnum.Percent_6:
                return {
                    width: AgGridColumns.numericColumnWidth,
                    filter: 'agNumberColumnFilter',
                    filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                    floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                    valueFormatter: (params: ValueFormatterParams) => {
                        const valueToFormat = params.value;

                        if (!valueToFormat) { return ''; }

                        const formatter = new Intl.NumberFormat('en-US', {
                            style: 'percent',
                            minimumFractionDigits: minimumFractionDigits,
                            maximumFractionDigits: minimumFractionDigits
                        });

                        return formatter.format(valueToFormat);
                    }
                };
            case Core.AdvancedSearchDataTypeEnum.Number_0:
            case Core.AdvancedSearchDataTypeEnum.Number_0_NoGrouping:
            case Core.AdvancedSearchDataTypeEnum.Number_1:
            case Core.AdvancedSearchDataTypeEnum.Number_2:
            case Core.AdvancedSearchDataTypeEnum.Number_3:
            case Core.AdvancedSearchDataTypeEnum.bit:
            case Core.AdvancedSearchDataTypeEnum.smallint:
            case Core.AdvancedSearchDataTypeEnum.intType:
            case Core.AdvancedSearchDataTypeEnum.decimalType:
            case Core.AdvancedSearchDataTypeEnum.Year:
                return {
                    width: AgGridColumns.numericColumnWidth,
                    filter: 'agNumberColumnFilter',
                    filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                    floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                    valueFormatter: (params: ValueFormatterParams) => {
                        const valueToFormat = params.value;

                        if (field.customFormatting.gridShowValueAsZeroIfZero && valueToFormat == 0) { return '0'; }
                        if (field.customFormatting.gridShowValueAsZeroIfNull && valueToFormat == null) { return '0'; }

                        if (!valueToFormat) { return ''; }

                        const formatter = new Intl.NumberFormat('en-US', {
                            style: 'decimal',
                            minimumFractionDigits: minimumFractionDigits,
                            maximumFractionDigits: minimumFractionDigits,
                            useGrouping: (field.advancedSearchDataType.name.indexOf('NoGrouping') < 0)
                        });

                        return formatter ? formatter.format(valueToFormat) : valueToFormat;
                    }
                };
            case Core.AdvancedSearchDataTypeEnum.picklist:
                return {
                    width: AgGridColumns.selectionColumnWidth,
                    filter: 'agTextColumnFilter',
                    filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                    floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                };
            case Core.AdvancedSearchDataTypeEnum.Text_Align_Left:
            case Core.AdvancedSearchDataTypeEnum.Text_Align_Right:
            case Core.AdvancedSearchDataTypeEnum.Text_Align_Center:
            case Core.AdvancedSearchDataTypeEnum.varchar:
                let textAlign = 'left';
                if (field.advancedSearchDataTypeID === Core.AdvancedSearchDataTypeEnum.Text_Align_Right) {
                    textAlign = 'right';
                } else if (field.advancedSearchDataTypeID === Core.AdvancedSearchDataTypeEnum.Text_Align_Center) {
                    textAlign = 'center';
                }
                return {
                    width: AgGridColumns.textColumnWidth,
                    filter: 'agTextColumnFilter',
                    filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                    floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                    cellStyle: {
                        textAlign: textAlign
                    }
                };
            case Core.AdvancedSearchDataTypeEnum.YesNo:
            case Core.AdvancedSearchDataTypeEnum.YesNoNullable:
            case Core.AdvancedSearchDataTypeEnum.YesNoEmpty:
                return {
                    width: AgGridColumns.checkboxColumnMinWidth,
                    filter: 'agTextColumnFilter',
                    filterParams: AgGridFilterParams.booleanFilterWithBlankOptionsParams,
                    floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
                    valueFormatter: (params) => {
                        if (params.value || params.value === false) {
                            return (params.value && !(params.value === 'no' || params.value === 'No')) ? 'Yes' : 'No';
                        } else {
                            return '';
                        }
                    }
                };
            case Core.AdvancedSearchDataTypeEnum.AttachmentIconOpenPreview:
                return {
                    width: AgGridColumns.checkboxColumnMinWidth,
                    filter: 'agTextColumnFilter',
                    filterParams: AgGridFilterParams.booleanFilterWithBlankOptionsParams,
                    floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
                    cellRenderer: (params) => {
                        if (params.value) {
                            if (params.colDef.pdfLoading && params.colDef.pdfLoading === params.value) {
                                return `<div class="text-center" style="margin-top: 0;">
                                            <i class="fa fa-spinner fa-pulse fa-1x"></i>
                                        </div>`;
                            } else {
                                return '<i class="fa fa-file-text-o" aria-hidden="true"></i>';
                            }
                        }
                        return '';
                    },
                    onCellClicked: async (params: CellClickedEvent) => {
                        // Anything using square bracket notation to access or assign a value is not supported by the
                        // Typing assigned to it, although it was part of the old JS code so I'm leaving it in place

                        const rowData = params.data;

                        params.colDef['pdfLoading'] = params.value;
                        params.api.redrawRows();

                        const attachmentId = rowData[`f${  field.advancedSearchFieldID.toString()}`];

                        const response = await this._documentService.getDocumentBody(attachmentId);
                        const fileName = this._attachmentService.getFileNameFromHttpResponse(response, true);
                        const fileExtension = fileName.split('.')[1].substring(0, 3);
                        const attachmentBlob = new Blob([response.data], { type: fileExtension });
                        const fileInfo = this._attachmentService.getMimeTypeByFileExtension(fileExtension);

                        if (fileInfo['previewSupported']) {
                            const popUpData = {
                                attachmentInfo: {
                                    attachmentID: attachmentId,
                                    fileName: fileName,
                                    fileExtension: fileExtension
                                }
                            };

                            let attchmentGroupName = this._popUpService.StaticAliases.Attachment;

                            // ctrl-click - open a new window
                            if (params.event['ctrlKey']) {
                                attchmentGroupName = attchmentGroupName + new Date().getTime();
                            }

                            const globalAttachmentsPopUpRef = this._popUpService.getByAliasGroup(attchmentGroupName)[0];
                            if (globalAttachmentsPopUpRef && globalAttachmentsPopUpRef.isOpen()) {
                                globalAttachmentsPopUpRef.publish(this._popUpService.Topics.Attachment.AttachmentChanged, popUpData);
                            } else {
                                const popUpRef = await this._popUpService.openAttachmentPreview(attchmentGroupName, globalAttachmentsPopUpRef);
                                popUpRef.publish(this._popUpService.Topics.Attachment.AttachmentChanged, popUpData);
                            }
                        } else {
                            await this._documentService.saveFile(attachmentBlob, fileName);
                        }

                        params.colDef['pdfLoading'] = undefined;
                        params.api.redrawRows();
                    }
                };
            default:
                return {};
        }
    }

    private _getCommentCell(): ColDef {
        return {
            cellRenderer: (params) => {
                if (params.value != 0) {
                    return '<i class="fa fa-commenting" aria-hidden="true" ></i>';
                }
                return '';
            },
            cellClass: 'text-align-center',
            headerName: '',
            width: 40,
            minWidth: 40,
            maxWidth: 40,
            hide: false
        };
    }

    private _getNavigationalFieldValueByName(rowData, fieldName) {
        const navigationField = this._smartSearchService.getFieldByDisplayName(fieldName);
        // get value for navigational field
        return rowData[`n${  navigationField.advancedSearchFieldID}`];
    }

    private _setPersistentState(rowNode: RowNode): void {
        this._advancedSearchPersistenceService.scrollLocationIndex = rowNode ? rowNode.rowIndex : 0;
        this._nubService.showAdvancedSearchNub();
    }

    private _constructDateIfAbsent(data: any): Date {
        switch (data) {
            case null:
                return new Date('01-01-2200');
            case 'N/A':
                return new Date('01-01-2300');
            default:
                return new Date(data);
        }
    }

    private _fieldSpecificClickEvents(field: AdvancedSearchField) {
        switch (field.advancedSearchFieldID) {
            // Document Intake Number of Comments
            case 9007:
                return (rowData) => {
                    this._commentsService.getEntityDataForTasks([rowData.n10908]).then((data) => {
                        this._commentsModalService.openCommentModal(data[0]);
                    });
                };
            // Assessment DRTT Number of Comments
            case 1052:
                return (rowData) => {
                    console.log('opening DRTT comment modal ');
                    // this field can be displayed in either AV or SMART
                    // if the invoiceID is available use that, otherwise see if the taskID is available (taskID would not be available in SMART)
                    if (rowData.n1000) {		// SMART, we have assesmentID
                        this._commentsService.getEntityData(rowData.n1000, EntityTypeIds.ASSESSMENT).then((data) => {
                            console.log('opening comment modal using entity, data:', data);
                            data.commentTypeID = 4;
                            this._commentsModalService.openCommentModal(data);
                        });
                    } else {
                        this._commentsService.getEntityDataForTasks([rowData.n10908]).then((data) => {		// n10908 == SubjectTaskID
                            console.log('opening comment modal using task, data:', data);
                            data[0].commentTypeID = 4;
                            this._commentsModalService.openCommentModal(data[0]);
                        });
                    }
                };

            // Bill DRTT Number of Comments
            case 1456:
                return (rowData) => {
                    console.log('opening Bill DRTT comment modal ');
                    // this field can be displayed in either AV or SMART
                    // if the invoiceID is available use that, otherwise see if the taskID is available (taskID would not be available in SMART)
                    if (rowData.n1498) {		// SMART, we have bill ID
                        this._commentsService.getEntityData(rowData.n1498, EntityTypeIds.TAX_BILL).then((data) => {
                            console.log('opening comment modal using entity, data:', data);
                            data.commentTypeID = 4;
                            this._commentsModalService.openCommentModal(data);
                        });
                    } else {
                        this._commentsService.getEntityDataForTasks([rowData.n10908]).then((data) => {		// n10908 == SubjectTaskID
                            console.log('opening comment modal using task, data:', data);
                            data[0].commentTypeID = 4;
                            this._commentsModalService.openCommentModal(data[0]);
                        });
                    }
                };

            // Invoice Number of Comments
            case 1916:
                return (rowData) => {
                    // this field can be displayed in either AV or SMART
                    // if the invoiceID is available use that, otherwise see if the taskID is available (taskID would not be available in SMART)
                    if (rowData.n1900) {
                        this._commentsService.getEntityData(rowData.n1900, EntityTypeIds.INVOICE).then((data) => {
                            this._commentsModalService.openCommentModal(data);
                        });
                    } else {
                        this._commentsService.getEntityDataForTasks([rowData.n10908]).then((data) => {
                            this._commentsModalService.openCommentModal(data[0]);
                        });
                    }
                };

            case 1444:		// TAX_BILL Number of Comments
                return (rowData) => {
                    // this field can be displayed in either AV or SMART
                    // if the BILLID is available use that, otherwise see if the taskID is available (taskID would not be available in SMART)
                    if (rowData.n1498) {
                        this._commentsService.getEntityData(rowData.n1498, EntityTypeIds.TAX_BILL).then((data) => {
                            this._commentsModalService.openCommentModal(data);
                        });
                    } else {		// use task
                        this._commentsService.getEntityDataForTasks([rowData.n10908]).then((data) => {
                            this._commentsModalService.openCommentModal(data[0]);
                        });
                    }
                };

            case 1044:		// Revision Number of Comments
                return (rowData) => {
                    // this field can be displayed in either AV or SMART
                    // if the RevisionID is available use that, otherwise see if the taskID is available (taskID would not be available in SMART)
                    if (rowData.n1000) {
                        this._commentsService.getEntityData(rowData.n1000, EntityTypeIds.ASSESSMENT).then((data) => {
                            this._commentsModalService.openCommentModal(data);
                        });
                    } else {		// use task
                        this._commentsService.getEntityDataForTasks([rowData.n10908]).then((data) => {
                            this._commentsModalService.openCommentModal(data[0]);
                        });
                    }
                };
        }
    }
}
