<div class="d-flex flex-column w-100 h-100">
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title page-title">Forms</h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item" *ngIf="canEdit">
                    <button type="button" [disabled]="!selectedState" class="flat-button small-button primary-button" (click)="createNewForm()" title="Create New Form">New Form</button>
                </div>
                <div class="ws-section__header__actions__item" *ngIf="canExport">
                    <button type="button" class="flat-button small-button secondary-button" (click)="exportFormsLibrary()" title="Export Form library">Export Form Library</button>
                </div>
                <div class="ws-section__header__actions__item" *ngIf="canExport">
                    <button type="button" class="flat-button small-button secondary-button" (click)="exportFormList()" title="Export Form list">Export Form List</button>
                </div>
                <div class="ws-section__header__actions__item" *ngIf="canExport">
                    <button type="button" class="flat-button small-button secondary-button" (click)="exportFormsSetup()" title="Export Forms setup">Export Form Setup</button>
                </div>
                <div class="ws-section__header__actions__item" *ngIf="canEdit">
                    <button type="button" class="flat-button small-button secondary-button" (click)="importFormsSetup()" title="Import Forms setup">Import Form Setup</button>
                </div>
                <div class="ws-section__header__actions__item">
                    <button type="button" class="flat-button small-button secondary-button" (click)="refresh()" [disabled]="refreshing" title="Refresh"><i class="fa fa-refresh"></i></button>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex flex-column w-100 h-100">
        <div class="d-flex filter-row">
                <div class="d-flex filter-group align-items-center">
                    <label class="ws-flex-auto my-0" for="state">State:</label>
                    <div class="ws-flex-auto">
                        <ws-select labelProperty="fullName"
                                   [returnEntireOption]="true"
                                   placeholder="Nothing selected"
                                   [canDeselect]="true"
                                   deselectLabel="Nothing selected"
                                   [deselectValue]="null"
                                   [valueFormatter]="stateFormatter"
                                   [(ngModel)]="selectedState"
                                   (ngModelChange)="criteriaChanged()"
                                   [options]="states">
                        </ws-select>
                    </div>
                </div>
                <div class="d-flex filter-group align-items-center">
                    <label class="ws-flex-auto my-0" for="form-type">Form Type:</label>
                    <div class="ws-flex-auto">
                        <select class="form-select form-select-sm" id="form-type" name="formType" [(ngModel)]="selectedFormType" (ngModelChange)="criteriaChanged()">
                            <option [ngValue]="null">-- Select --</option>
                            <option *ngFor="let formType of formTypes" [ngValue]="formType">{{formType.name}}</option>
                        </select>
                    </div>
                </div>
        </div>

        <div class="d-flex flex-column w-100 h-100">
            <div class="form-manager-container ws-flex-auto d-flex flex-column" *ngIf="formList">
                <div class="form-list-holder" *ngIf="formList.length; else noRows">
                    <ul>
                        <li *ngFor="let form of formList; index as i">
                            <div [ngClass]="i % 2 ? 'tree-line-holder-odd' : 'tree-line-holder-even'">
                                <span class="fa node-collapsed" *ngIf="!isExpanded(form); else collapse" (click)="expandForm(form)"></span>
                                <ng-template #collapse>
                                    <span class="fa node-expanded" (click)="collapseForm(form)"></span>
                                </ng-template>
                                <span>{{ form.name }}</span>
                                <span class="text-muted small" *ngIf="form.description">{{ form.description }}</span>
                                <span class="button-group" *ngIf="canEdit || canView">
                                    <button type="button" (click)="editForm(form)" class="btn flat-button icon-button" title="Edit">
                                        <i class="fa fa-pencil"></i>
                                    </button>
                                    <button type="button" (click)="createRevision(form)" class="btn flat-button icon-button" title="New Revision" *ngIf="canEdit">
                                        <i class="fa fa-plus"></i>
                                    </button>
                                    <button type="button" (click)="deleteForm(form)" class="btn flat-button icon-button warning-button" title="Delete" *ngIf="canEdit">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </span>
                            </div>
                            <ul *ngIf="isExpanded(form)">
                                <li *ngFor="let revision of form.formRevisions; index as j">
                                    <div class="tree-line-level-2" [ngClass]="(i + j + 1) % 2 ? 'tree-line-holder-odd' : 'tree-line-holder-even'">
                                        <span class="fa node-collapsed" *ngIf="isRevisionCollapsed(revision); else collapseRevision" (click)="expandFormRevision(revision)"></span>
                                        <ng-template #collapseRevision>
                                            <span class="fa node-expanded" [ngClass]="revision.supplementalForms.length > 0 ? '' : 'empty-tree-node'" (click)="collapseFormRevision(revision)"></span>
                                        </ng-template>
                                        <span>
                                            <i *ngIf="revision.isFormConsolidated"
                                               class="consolidated-icon fa fa-sitemap"
                                               helpTooltip
                                               helpContentId="form.is-form-consolidated">
                                            </i>
                                            <i *ngIf="revision.electronicFilingTypeId != null"
                                                class="fa fa-cloud-arrow-up"
                                                helpTooltip
                                                helpContentId="form.electronic-filing-is-supported">
                                            </i>
                                            {{ revision.name }}
                                        </span>
                                        <span class="text-muted small" *ngIf="revision.description">{{ revision.description }}</span>
                                        <span *ngIf="revision.yearInfo" class="text-muted small"> {{ revision.yearInfo }}</span>
                                        <span *ngIf="revision.priorFormRevisionName" class="text-muted small"> (Prior: {{ revision.priorFormRevisionName }})</span>
                                        <span class="button-group" *ngIf="canEdit || canView">
                                            <button type="button" (click)="configureAssociations(revision)" class="btn flat-button icon-button" title="Configure">
                                                <i class="fa fa-wrench"></i>
                                            </button>
                                            <button type="button" (click)="editRevision(form, revision)" class="btn flat-button icon-button" title="Edit">
                                                <i class="fa fa-pencil"></i>
                                            </button>
                                            <button type="button" (click)="createSupplement(form, revision)" class="btn flat-button icon-button" title="New Supplemental" *ngIf="canEdit">
                                                <i class="fa fa-plus"></i>
                                            </button>
                                            <button type="button" (click)="deleteRevision(revision)" class="btn flat-button icon-button warning-button" title="Delete" *ngIf="canEdit">
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </span>
                                    </div>
                                    <ul *ngIf="!isRevisionCollapsed(revision)">
                                        <li *ngFor="let supplemental of revision.supplementalForms; index as k">
                                            <div class="tree-line-level-3" [ngClass]="(i + j + k + 2) % 2 ? 'tree-line-holder-odd' : 'tree-line-holder-even'">
                                                <span>
                                                    <i *ngIf="supplemental.electronicFilingTypeId != null"
                                                        class="fa fa-cloud-arrow-up"
                                                        helpTooltip
                                                        helpContentId="form.electronic-filing-is-supported">
                                                    </i>
                                                    {{ supplemental.name }}
                                                </span>
                                                <span class="text-muted small" *ngIf="supplemental.description">{{ supplemental.description }}</span>
                                                <span *ngIf="supplemental.yearInfo" class="text-muted small"> {{ supplemental.yearInfo }}</span>
                                                <span *ngIf="supplemental.priorFormRevisionName" class="text-muted small"> (Prior: {{ supplemental.priorFormRevisionName }})</span>
                                                <span class="button-group" *ngIf="canEdit || canView">
                                                    <button type="button" (click)="configureAssociations(supplemental)" class="btn flat-button icon-button" title="Configure">
                                                        <i class="fa fa-wrench"></i>
                                                    </button>
                                                    <button type="button" (click)="editSupplement(form, revision, supplemental)" class="btn flat-button icon-button" title="Edit">
                                                        <i class="fa fa-pencil"></i>
                                                    </button>
                                                    <button type="button" (click)="deleteSupplement(supplemental)" class="btn flat-button icon-button warning-button" title="Delete" *ngIf="canEdit">
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #noRows>
    <div class="d-flex w-100 h-100 ws-flex-justify-content-center align-items-center">
        <div>No Forms to Show</div>
    </div>
</ng-template>
