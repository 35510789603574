import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    AgGridEvent,
    BodyScrollEvent,
    CellClickedEvent,
    ColumnResizedEvent,
    FilterChangedEvent,
    GridOptions,
    GridReadyEvent,
    SortChangedEvent
} from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { AgGridExportOptions, AgGridToolPanelAction, AgGridToolPanelButton } from '../ToolPanel/models';
import { BulkEditButtonOptions } from '../ToolPanel/agGridToolPanel.component';

@Component({
    selector: 'ws-ag-grid-angular',
    templateUrl: './wsAgGridWrapper.component.html',
    styles: [
        ':host { height: 100%; width: 100%; overflow: auto }'
    ]
})
export class WsAgGridWrapperComponent implements OnInit {

    // Ag-Grid
    @Input() gridOptions: GridOptions;
    @Input() rowSelection: string;
    @Input() gridClass: string;
    @Input() wrapperClass: string;
    @Input() pinnedTopRowData: any[];

    // Tool panel
    @Input() companyId: number;
    @Input() gridId: string | System.Guid;
    @Input() isBulkUpdateVisible$: BehaviorSubject<boolean | BulkEditButtonOptions>;
    @Input() isBulkDeleteVisible$: BehaviorSubject<boolean>;
    @Input() isCustomButtonVisible$: BehaviorSubject<boolean>;
    @Input() exportOptions: AgGridExportOptions;
    @Input() showConfigureLayout: boolean = true;
    @Input() showResizeColumns: boolean = true;
    @Input() defaultSizeToFit: boolean = true;
    @Input() defaultSizeToContent: boolean = false;
    @Input() defaultColorizeHeaders: boolean = false;
    @Input() exportIcon: string = 'fa-file-excel-o';
    @Input() exportHelpContentId: string = 'ag-grid-tool-panel.export';
    @Input() additionalActions: AgGridToolPanelAction[];
    @Input() additionalButtons: AgGridToolPanelButton[];
    @Input() forceShowToolPanel: boolean = false;

    // Ag-Grid outputs
    @Output() gridReady: EventEmitter<GridReadyEvent> = new EventEmitter<GridReadyEvent>();
    @Output() filterChanged: EventEmitter<FilterChangedEvent> = new EventEmitter<FilterChangedEvent>();
    @Output() selectionChanged: EventEmitter<AgGridEvent> = new EventEmitter<AgGridEvent>();
    @Output() cellClicked: EventEmitter<CellClickedEvent> = new EventEmitter<CellClickedEvent>();
    @Output() columnResized: EventEmitter<ColumnResizedEvent> = new EventEmitter<ColumnResizedEvent>();
    @Output() bodyScroll: EventEmitter<BodyScrollEvent> = new EventEmitter<BodyScrollEvent>();
    @Output() sortChanged: EventEmitter<SortChangedEvent> = new EventEmitter<SortChangedEvent>();

    // Tool panel outputs
    @Output() bulkUpdate: EventEmitter<void> = new EventEmitter<void>();
    @Output() bulkDelete: EventEmitter<void> = new EventEmitter<void>();
    @Output() customButtonClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() filtersEnabled = new EventEmitter<boolean>();
    @Output() sizeColumnsToFit = new EventEmitter<void>();
    @Output() sizeColumnsToContent = new EventEmitter<void>();

    agGridReady: boolean;
    hasToolbar: boolean;

    private _gridReadyEvent: GridReadyEvent;

    ngOnInit(): void {
        this.hasToolbar = !!(this.companyId || this.gridId || this.forceShowToolPanel);
    }

    toolPanelReady(): void {
        this.gridReady.emit(this._gridReadyEvent);
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridReadyEvent = event;
        if (this.hasToolbar) {
            this.agGridReady = true;
            if (!this.showConfigureLayout) {
                this.toolPanelReady();
            }
            return;
        }
        this.gridReady.emit(this._gridReadyEvent);
    }
}
