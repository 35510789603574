import { UIRouter } from '@uirouter/angular';
import { AmplitudeEvent, ProductAnalyticsService } from '../../Common/Amplitude/productAnalytics.service';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { ActionViewService } from '../../Task/ActionView/action-view-service.upgrade';
import { ActionViewPersistenceService } from '../../Task/ActionView/Action.View.Persistence.Service.upgrade';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';
import { ActionViewCategoryComponent } from '../User-Save/Modals/Category/actionViewCategory.component';
import { ActionViewDefaultDisplayComponent } from '../User-Save/Modals/Default-Display/actionViewDefaultDisplay.component';
import { ActionViewRenameComponent } from '../User-Save/Modals/Rename/actionViewRename.component';
import { SearchCategoryType } from '../User-Save/userSave.service';
import {
    ContextMenuItem,
    SaveCategory,
    SaveListItem,
    SaveTabColumn, SaveTabColumnType,
    UserSavePage
} from '../User-Save/userSavePage.model';

export class SavedActionViewService extends UserSavePage<Core.ActionViewListDTO> {
    constructor(private readonly _actionViewService: ActionViewService,
                private readonly _messageModalService: MessageModalService,
                private readonly _modalService: WeissmanModalService,
                private readonly _actionViewPersistenceService: ActionViewPersistenceService,
                private readonly _router: UIRouter,
                private readonly _productAnalyticsService: ProductAnalyticsService,
                private readonly _restrictService: RestrictService) {
        super();
    }

    protected _columns: SaveTabColumn[] = [
        {
            name: 'Name',
            key: 'name',
            type: SaveTabColumnType.String
        },
        {
            name: 'Change Date',
            key: 'changeDate',
            type: SaveTabColumnType.Date
        }
    ];

    get tabTitle(): string {
        return 'Saved Action Views';
    }

    contextMenu(category: SaveCategory<Core.ActionViewListDTO>, index: number): ContextMenuItem<Core.ActionViewListDTO>[] {
        return category.categoryType === Core.SearchCategoryType.System
            ? this._systemActionViewMenu(category, index) : this._customActionViewMenuWithPermissions(category, index);
    }

    async getList(): Promise<void> {
        if (!this._dirty) {
            return;
        }
        const response = await this._actionViewService.getCategories();

        const categoryList: SaveCategory<Core.ActionViewListDTO>[] = response.map(x => {
            const list: SaveListItem<Core.ActionViewListDTO>[] = x.actionViewList.map(y => {
                return {
                    id: y.actionViewID,
                    name: y.actionViewName,
                    isFavorite: y.isFavorite,
                    changeDate: y.changeDate,
                    canFavorite: true,
                    originalModel: y,
                    amplitudeEvent: this._getAmplitudeEvent(x, y)
                };
            });
            return {
                categoryId: x.categoryId,
                categoryName: x.categoryName,
                categoryType: x.categoryType,
                savedItemList: list
            };
        });

        this._separateByCategoryType(categoryList);
    }

    navigateTo(listItem: SaveListItem<Core.ActionViewListDTO>, categoryType: number): void {
        this._actionViewPersistenceService.clearSavedData();
        const params = {
            actionViewId: listItem.id,
            actionViewType: categoryType,
            displayType: listItem.originalModel.defaultDisplay
        };
        this._router.stateService.transitionTo('actionview', params);
    }

    async toggleFavorite(search: SaveListItem<Core.ActionViewListDTO>): Promise<void> {
        this.dirty = true;
        this._productAnalyticsService.logEvent('click-act-favorite', { actFavoriteToggle: search.isFavorite ? 'on' : 'off' });
        await this._actionViewService.toggleFavorite(search.id, search.isFavorite);
    }

    private async _shareActionView(actionViewItem: SaveListItem<Core.ActionViewListDTO>, searchType: Core.SearchCategoryType): Promise<void> {
        // Go update the search to mark it as "Shared" if this is a custom search
        if (searchType === Core.SearchCategoryType.Custom) {
            await this._actionViewService.share(actionViewItem.originalModel);
        }
        await this.getList();
        this._actionViewPersistenceService.clearSavedData();
        const params = {
            actionViewId: actionViewItem.id,
            actionViewType: searchType,
            displayType: actionViewItem.originalModel.defaultDisplay
        };
        const route = this._router.stateService.href('actionview', params, { absolute: true });
        this._showEmailWindow(route, actionViewItem.name, 'Action View');
    }

    private _systemActionViewMenu(category: SaveCategory<Core.ActionViewListDTO>, index: number): ContextMenuItem<Core.ActionViewListDTO>[] {
        // User doesn't have permission to edit system action views
        if (!this._restrictService.isInRole(Roles.SYSTEMSEARCHESEDIT)) {
            return [];
        }
        return [
            {
                name: 'Share',
                onClick: (item, searchType) => {
                    this._shareActionView(item, searchType);
                },
                isDisabled: () => {
                    return this._currentSelectedFilter === SearchCategoryType.Favorite;
                }
            },
            {
                name: 'Rename',
                onClick: (item, actionViewType) => {
                    this._modalService.showAsync(ActionViewRenameComponent, { actionView: item.originalModel, actionViewType }, 'modal-lg');
                },
                isDisabled: (item) => {
                    return item.originalModel.isSystemSeeded
                        || this._currentSelectedFilter === SearchCategoryType.Favorite;
                }
            },
            {
                name: 'Change Category',
                onClick: (item, actionViewType) => {
                    this._modalService.showAsync(ActionViewCategoryComponent, { actionView: item.originalModel, actionViewType }, 'modal-lg');
                },
                isDisabled: (item) => {
                    return item.originalModel.isSystemSeeded
                        || this._currentSelectedFilter === SearchCategoryType.Favorite;
                }
            },
            {
                name: 'Change Display Type',
                onClick: (item, actionViewType) => {
                    this._modalService.showAsync(ActionViewDefaultDisplayComponent, { actionView: item.originalModel, actionViewType }, 'modal-lg');
                },
                isDisabled: (item) => {
                    return item.originalModel.isSystemSeeded
                        || this._currentSelectedFilter === SearchCategoryType.Favorite;
                }
            },
            {
                name: 'Delete',
                onClick: async(item) => {
                    try {
                        const favoriteCount = await this._actionViewService.getFavoriteCount(item.originalModel);
                        let confirmMessage = '';
                        if (favoriteCount > 0) {
                            if (favoriteCount === 1) {
                                confirmMessage = 'WARNING: 1 user currently has this action view favorited.\n';
                            }
                            else {
                                confirmMessage = `WARNING: ${  favoriteCount  } users currently have this action view favorited.\n`;
                            }
                        }

                        confirmMessage += `Are you sure you want to delete ${  item.name  }?`;
                        await this._messageModalService.confirm(confirmMessage);
                    } catch(e) {
                        return;
                    }
                    this.dirty = true;
                    await this._actionViewService.deleteSystemActionView(item.originalModel, Core.SearchCategoryType.Custom);
                    category.savedItemList.splice(index, 1);
                    await this.getList();
                },
                isDisabled: (item) => {
                    return item.originalModel.isSystemSeeded
                        || this._currentSelectedFilter === SearchCategoryType.Favorite;
                }
            }
        ];
    }

    private _customActionViewMenuWithPermissions(category: SaveCategory<Core.ActionViewListDTO>, index: number): ContextMenuItem<Core.ActionViewListDTO>[] {
        return [
            {
                name: 'Share',
                onClick: (item, searchType) => {
                    this._shareActionView(item, searchType);
                },
                isDisabled: () => {
                    return false;
                }
            },
            {
                name: 'Rename',
                onClick: (item, actionViewType) => {
                    this._modalService.showAsync(ActionViewRenameComponent, { actionView: item.originalModel, actionViewType }, 'modal-lg');
                },
                isDisabled: () => {
                    return false;
                }
            },
            {
                name: 'Change Category',
                onClick: (item, actionViewType) => {
                    this._modalService.showAsync(ActionViewCategoryComponent, { actionView: item.originalModel, actionViewType }, 'modal-lg');
                },
                isDisabled: () => {
                    return false;
                }
            },
            {
                name: 'Change Display Type',
                onClick: (item, actionViewType) => {
                    this._modalService.showAsync(ActionViewDefaultDisplayComponent, { actionView: item.originalModel, actionViewType }, 'modal-lg');
                },
                isDisabled: () => {
                    return false;
                }
            },
            {
                name: 'Delete',
                onClick: async(item) => {
                    try {
                        const favoriteCount = await this._actionViewService.getFavoriteCount(item.originalModel);
                        let confirmMessage = '';
                        if (favoriteCount > 0) {
                            if (favoriteCount === 1) {
                                confirmMessage = 'WARNING: 1 user currently has this action view favorited.\n';
                            }
                            else {
                                confirmMessage = `WARNING: ${  favoriteCount  } users currently have this action view favorited.\n`;
                            }
                        }

                        confirmMessage += `Are you sure you want to delete ${  item.name  }?`;
                        await this._messageModalService.confirm(confirmMessage);
                    } catch(e) {
                        return;
                    }
                    this.dirty = true;
                    await this._actionViewService.deleteSystemActionView(item.originalModel, Core.SearchCategoryType.Custom);
                    category.savedItemList.splice(index, 1);
                    await this.getList();
                },
                isDisabled: () => {
                    return false;
                }
            }
        ];
    }

    private _getAmplitudeEvent(category: Core.ActionViewSavedSearchCategoryManagementDTO, av: Core.ActionViewListDTO): AmplitudeEvent {
        const event = `click-act-${category.categoryType ? 'saved' : 'system' }`;
        let properties: { [key: string]: string };
        if (category.categoryType === Core.SearchCategoryType.System) {
            properties = { actSystemActionView: category.categoryName };
        } else if (av.isFavorite && this._currentSelectedFilter === SearchCategoryType.Favorite) {
            properties = { actSystemActionView: 'favorite' };
        }
        return { event, properties };
    }
}
