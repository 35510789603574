<table class="edit-info-table">
    <tr>
        <td>Parcel Acct Num:</td>
        <td style="display: flex; gap: 5px;">
            <parcel-icon class="ws-display-inline-block"
                [parcel]="parcel"
                [isLinkedParcel]="!!parcel.masterParcelId || !!parcel.subParcelCount"
                [isMasterParcel]="!parcel.masterParcelId && !!parcel.subParcelCount"
                propTypeParam="propertyType.propTypeAbbr"
                size="large"
                [includeIcons]="true"
                [ppReturnPreparationAllowed]="ppReturnPreparationAllowed"
                [stateAllowConsolidating]="stateAllowConsolidating">
            </parcel-icon>
            <input type="text" class="form-control" [(ngModel)]="parcel.acctNum" required="true" maxlength="50">
        </td>
        <td></td>
    </tr>
    <ng-container *ngFor="let acctNum of parcel.altAccountNumbers">
        <tr *ngIf="shouldDisplayAcctNum(acctNum)">
            <td>{{acctNum.accountNumberType.accountNumberDesc}}:</td>
            <td>
                <input type="text" class="form-control" [(ngModel)]="acctNum.acctNumber" required="true" maxlength="50">
            </td>
            <td>
                <button class="flat-button icon-button warning-button" (click)="deleteAcctNum(acctNum)">
                    <i class="fa fa-trash"></i>
                </button>
            </td>
        </tr>
    </ng-container>
    <tr *ngIf="!(acctNumToAdd || parcel.altAccountNumbers.length === acctNumTypes?.length) ">
        <td></td>
        <td>
            <button class="flat-button small-button primary-button" type="button" (click)="enterAltAcctNumToAdd()">Add Alternate #</button>
        </td>
        <td></td>
    </tr>
    <tr *ngIf="acctNumToAdd">
        <td></td>
        <td style="display: flex;">
            <select class="form-select form-select-sm" [(ngModel)]="acctNumToAdd.accountNumberTypeID">
                <ng-container  *ngFor="let acctNumType of acctNumTypes">
                    <option *ngIf="!altAcctNumAlreadyAdded(acctNumType)"
                        [ngValue]="acctNumType.accountNumberTypeID">
                            {{acctNumType.accountNumberDesc}}
                    </option>
                </ng-container>
            </select>
            <input type="text" class="form-control" [(ngModel)]="acctNumToAdd.acctNumber" maxlength="50" required="true">
        </td>
        <td>
            <div style="display: flex; gap: 5px;">
                <button class="flat-button icon-button primary-button" type="button" (click)="addAltAcctNum()">
                    <i class="fa fa-save"></i>
                </button>
                <button type="button" class="flat-button icon-button warning-button" (click)="cancelAddAcctNum()">
                    <i class="fa fa-close"></i>
                </button>
            </div>
        </td>
    </tr>
    <tr>
        <td>Assessee:</td>
        <td>
            <input type="text" class="form-control" [(ngModel)]="parcel.assesseeName" maxlength="50">
        </td>
        <td></td>
    </tr>
    <tr>
        <td>Desc:</td>
        <td>
            <input type="text" class="form-control" [(ngModel)]="parcel.description" maxlength="50">
        </td>
        <td></td>
    </tr>
    <tr>
        <td style="vertical-align: top; padding-top: 6px;">Situs:</td>
        <td>
            <address-view-edit [address]="address"
                [isEditMode]="true"
                [isSmall]="true"
                [readOnlyState]="true"
                [shouldRestrictExtended]="true">
            </address-view-edit>
        </td>
        <td></td>
    </tr>
    <tr>
        <td>Property Type:</td>
        <td>
            <select class="form-select form-select-sm"
                style="width: auto"
                [(ngModel)]="parcel.propertyTypeID"
                (change)="onPropertyTypeChange()"
                [disabled]="years?.length && !hasMoveCopyPermission">
                    <option *ngFor="let propType of propertyTypes" [ngValue]="propType.propertyTypeID">{{propType.propTypeName}}</option>
            </select>
        </td>
        <td></td>
    </tr>
    <tr>
        <td>Assessor:</td>
        <td>
            <select class="form-select form-select-sm"
                style="width: auto"
                [(ngModel)]="parcel.assessorID"
                (change)="onAssessorChanged()"
                [disabled]="years?.length && !hasMoveCopyPermission"
                helpTooltip
                [contentComponent]="assessorDetailsRenderer"
                [contentComponentValue]="parcelAssessor">
                    <option *ngFor="let assessor of assessors" [ngValue]="assessor.assessorID">{{assessor.abbr}} {{assessor.municipalityName}}</option>
            </select>
        </td>
        <td></td>
    </tr>
    <tr *ngIf="showTaxingDistrict">
        <td>Taxing District:</td>
        <td>
            <select *ngIf="parcelAssessor?.taxingDistrictModels.length"
                class="form-select form-select-sm"
                style="width: auto"
                [(ngModel)]="parcel.taxingDistrictId">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let district of parcelAssessor.taxingDistrictModels" [ngValue]="district.taxingDistrictId">{{district.displayName}}</option>
            </select>
            <em *ngIf="!parcelAssessor?.taxingDistrictModels.length">No Taxing Districts for this Assessor</em>
        </td>
        <td></td>
    </tr>
    <tr *ngIf="assessmentClasses">
        <td>Assessment Class(es):</td>
        <td>
            <ws-multi-select class="ws-stretch-width"
                labelProperty="name"
                [isDropup]="true"
                [objectIsValue]="true"
                [useSemicolons]="true"
                [(ngModel)]="parcel.parcelAssessmentClasses"
                [options]="assessmentClasses">
            </ws-multi-select>
        </td>
        <td></td>
    </tr>
    <tr>
        <td style="vertical-align: top; padding-top: 6px;">Collector(s):</td>
        <td>
            <div *ngFor="let collector of nonDeletedParcelCollectors" class="editable-collector">
                <div style="display: flex; justify-content: space-between;">
                    <span>{{collector.collectorAbbr}}<span *ngIf="collector.collectorBillingScenarioName">- {{collector.collectorBillingScenarioName}}</span></span>
                    <button class="flat-button icon-button warning-button" (click)="deleteParcelCollector(collector)">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
                <div style="display: flex; align-items: center;">
                    <div>Acct Num:</div>
                    <div>
                        <input type="text" [(ngModel)]="collector.collectorAcctNum" class="form-control" maxlength="35">
                    </div>
                </div>
                <ng-container *ngFor="let option of collector.parcelCollectorPaymentOptions">
                    <div *ngIf="getCollectorPaymentOptionInfo(collector, option)?.length"
                        style="display: flex; align-items: center;">
                        <div>
                            <span *ngIf="option.collectorPaymentOption.collectorBillName">{{ option.collectorPaymentOption.collectorBillName }} </span>Pmt Option:
                        </div>
                        <select class="form-select form-select-sm" style="width: auto;" [(ngModel)]="option.collectorPaymentOptionID" (change)="paymentOptionChanged(option)">
                            <option *ngFor="let item of getCollectorPaymentOptionInfo(collector, option)"
                                [ngValue]="item.collectorPaymentOptionID">
                                    {{item.collectorPaymentOptionName}}
                            </option>
                        </select>
                    </div>
                </ng-container>
                <div>
                    <collector-tax-rate-area
                        [trs]="getTRS(collector)"
                        [parcelCollector]="collector"
                        [parcel]="parcel"
                        [years]="years"
                        [editMode]="true">
                    </collector-tax-rate-area>
                </div>
            </div>
            <div>
                <button type="button" class="flat-button small-button primary-button" (click)="addParcelCollector()">Add Collector</button>
            </div>
        </td>
        <td></td>
    </tr>
    <tr>
        <td>Legal Desc:</td>
        <td>{{parcel.legalDescription?.substring(0, 50) || 'None'}}</td>
        <td>
            <button class="flat-button icon-button primary-button" (click)="showLegalDescriptionDialog()">
                <i class="fa fa-pencil"></i>
            </button>
        </td>
    </tr>
    <ng-container *ngIf="parcel.accrualsEnabled">
        <tr *ngIf="parcel.accrualsExclude.parentExcludedFromAccruals">
            <td>Parent Excluded From Accruals</td>
            <td></td>
            <td></td>
        </tr>
        <tr *ngIf="!parcel.accrualsExclude.parentExcludedFromAccruals">
            <td>Exclude From Accruals:</td>
            <td>
                <input type="checkbox"
                    [(ngModel)]="parcel.accrualsExclude.excludeFromAccruals">
            </td>
            <td></td>
        </tr>
    </ng-container>
    <tr>
        <td>Status:</td>
        <td>
            <select class="form-select form-select-sm"
                [(ngModel)]="parcel.activityStatusID"
                [popover]="activatedByPopover"
                triggers="mouseenter:mouseleave"
                container="body"
                style="width: auto;">
                <option *ngFor="let option of activityStatuses" [ngValue]="option.activityStatusId">{{option.status}}</option>
            </select>
        </td>
        <td></td>
    </tr>
    <tr>
        <td>Sold Date:</td>
        <td>
            <weissman-datetime [dateOnly]="true" [(inputDate)]="parcel.transaction.soldDate"></weissman-datetime>
        </td>
    </tr>
</table>
<div style="padding-top: 20px;">
    <button class="flat-button small-button warning-button" (click)="deleteParcel()">Delete</button>
    <button *ngIf="hasMoveCopyPermission"
        class="flat-button small-button primary-button"
        (click)="goToMoveCopy(true)">
        Move
    </button>
    <button *ngIf="hasMoveCopyPermission"
        class="flat-button small-button primary-button"
        (click)="goToMoveCopy(false)">
        Copy
    </button>
    <linked-parcels-edit *ngIf="(!parcel.masterParcelId) || (parcel.masterParcelId == parcel.parcelID)"
        [parcelId]="parcel.parcelID"
        [parcelAcctNum]="parcel.acctNum"
        [masterParcelId]="parcel.masterParcelId"
        [masterParcelAcctNum]="parcel.masterParcelAcctNum"
        [linkedParcelCount]="parcel.subParcelCount"
        (linkedParcelsChanged)="linkedParcelsChanged($event)">
    </linked-parcels-edit>
</div>

<ng-template #activatedByPopover>
    <div *ngIf="parcel.activatedDateTime > parcel.inactivatedDateTime">
        <div *ngIf="parcel.activatedBy">
            Activated by {{parcel.activatedBy}} <br>
            Date: {{parcel.activatedDateTime | date:'M/d/YYYY'}}
        </div>
        <div *ngIf="parcel.inactivatedBy || parcel.inactivatedDateTime" style="margin-top: 10px;">
            Inactivated <span ng-show="parcel.inactivatedBy">by {{parcel.inactivatedBy}}</span> <br>
            Date: {{parcel.inactivatedDateTime | date:'M/d/YYYY'}}
        </div>
    </div>
    <div *ngIf="parcel.activatedDateTime <= parcel.inactivatedDateTime">
        <div *ngIf="parcel.inactivatedBy || parcel.inactivatedDateTime" style="margin-top: 10px;">
            Inactivated <span ng-show="parcel.inactivatedBy">by {{parcel.inactivatedBy}}</span> <br>
            Date: {{parcel.inactivatedDateTime | date:'M/d/YYYY'}}
        </div>
        <div *ngIf="parcel.activatedBy">
            Activated by {{parcel.activatedBy}} <br>
            Date: {{parcel.activatedDateTime | date:'M/d/YYYY'}}
        </div>
    </div>
</ng-template>
