import { Component, Input, OnInit } from '@angular/core';
import { FeatureFlagsService } from '../../../Common/FeatureFlags/feature-flags-service';

@Component({
    selector: 'company-compliance',
    templateUrl: './companyCompliance.component.html',
    styleUrls: ['./companyCompliance.component.scss']
})
export class CompanyComplianceComponent implements OnInit {
    constructor(private readonly _featureFlagService: FeatureFlagsService) {}

    @Input() company;
    @Input() parentCompany;
    @Input() isTopLevelCompany: boolean;

    editMode: boolean = false;
    isShowInfo: boolean = false;
    hasAllocationFeature: boolean = false;
    hasElectronicFilingFeature: boolean = false;

    get isLoading(): boolean {
        return !this.company;
    }

    ngOnInit(): void {
        this.hasAllocationFeature = this._featureFlagService.featureFlags.enableAllocationFeature;
        this.hasElectronicFilingFeature = this._featureFlagService.featureFlags.enableElectronicFiling;
    }

    expandOrCollapse() {
        if(this.editMode) {
            return;
        }

        this.isShowInfo = !this.isShowInfo;
    }
}
