import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import { UserTeamMember } from '../../../Common/Models/common.model';
import { Constants, EntityType } from '../../../constants.new';
import { InstanceRepository } from '../../../Entity/Instance/instance.repository';
import { UserInstanceService } from '../../../User/userInstance.service';
import { ConsultingEngagementsRepository } from '../../../Consulting/consultingEngagements.repository';
import * as _ from 'lodash';
import { BulkUpdateCsrIdentifier, BulkUpdateParams } from '../csrCommandCenter.component';
import { BulkSaveExceptionResult, EntityResponsibilityAssignmentChange } from '../../Exceptions/client-service-exception.models';
import { ClientServicesExceptionService } from '../../Exceptions/client-services-exception.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'csr-bulk-update-modal',
    templateUrl: './csrBulkUpdate.component.html'
})
export class CsrBulkUpdateComponent implements OnInit, OnDestroy, IWeissmanModalComponent<BulkUpdateParams,any> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _messageModalService: MessageModalService,
        private Constants: Constants,
        private instanceRepository: InstanceRepository,
        private userInstanceService : UserInstanceService,
        private consultingEngagementsRepository: ConsultingEngagementsRepository,
        private clientServicesExceptionService: ClientServicesExceptionService,
        private toastr: ToastrService,
    ) { }

    result: boolean = false;
    workflowUserTypes: any[];
    WorkflowUserTypes = this.Constants.WorkflowUserTypes;
    updateUser: Core.UserTeamModel;
    updateWorkflowType: number;

    enableSaveButton: boolean = false;
    selectedCount: number = 0;
    isLoading: boolean = true;
    instanceId: number;
    includeAll: boolean = false;
    showDatePicker: boolean = false;
    setDateFrom: Date;
    showPopover: boolean = false;
    isRyanInstance: boolean = false;

    isCurrentInstanceRyan:boolean = false;
    consultingEngagements: Core.ConsultingEngagementResponse[] = [];
    consultantsEngagement:Core.ConsultingEngagementResponse = null;
    consultantsInstanceId:number = null;
    consultingEngagementId:number = null;
    CONSULTING_ENGAGEMENT = -1;
    isConsultantPerspective = false;

    params: BulkUpdateParams;

    isSaving: boolean = false;

    get isFormValid(): boolean {
        if (this.updateWorkflowType === undefined) {
            return false;
        }
        if (this.updateWorkflowType == this.WorkflowUserTypes['USER'].id && !this.updateUser) {
            return false;
        }
        if (this.showDatePicker && !this.setDateFrom) {
            return false;
        }
        return true;
    }


    ngOnInit(): void {
        this.workflowUserTypes = _.toArray(this.Constants.WorkflowUserTypes);
        this.isRyanInstance = this.userInstanceService.isCurrentInstanceRyan();

        Promise.all([
        //consulting engagements for entity
        this.loadConsultingEngagements(),
        this.setEntityInstanceId()
        ]).then(() => {
            this.isLoading = false;
            this.isConsultantPerspective = this.userInstanceService.isCrossInstancePerspective(this.instanceId);
        });
    }

    private async setEntityInstanceId() {
        //entity instance id
        await lastValueFrom(this.instanceRepository.getEntityInstanceId(EntityType[this.params.entityTypeId], this.params.entityId)).then(i=>{
            this.instanceId = i;
            if (i === 1) {
                this.isRyanInstance = true;
            }
        })
    }

    private async loadConsultingEngagements() {
        this.consultingEngagements = await this.consultingEngagementsRepository.getAcceptedEngagements(EntityType.Company, this.params.topLevelCompanyId);
        this.consultantsEngagement = this.consultingEngagements.find(ce=> this.userInstanceService.isInstanceExplicit(ce.instanceId));
     }

    isConsultingClient():boolean {
        return this.consultingEngagements.length && this.instanceId && !this.userInstanceService.isInstanceImplicit(this.instanceId);
    }

    //consultants are defined by implicit instance membership
    shouldUseConsultingEngagementInstanceId():boolean {
        return this.userInstanceService.isInstanceImplicit(this.instanceId);
    }

    setUser($event: any): void {
        this.determineShowDatepicker();
        this.updateUser = $event;
    }

    assignUserValue(): void {
        this.updateWorkflowType = this.WorkflowUserTypes['USER'].id;
    }


    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
    }

    cancel(): void {
        this.result = false;
        this._bsModalRef.hide();
    }

    async save(): Promise<void> {
        this.isSaving = true;

        let dateToSave: Date;
        let updatedResponsibilities: EntityResponsibilityAssignmentChange[] = new Array<EntityResponsibilityAssignmentChange>();

        dateToSave = this.showDatePicker ? this.setDateFrom : null;

        //reset the consulting engagementId if final selection is not a CE or changes being made by a non-consultant
        if(this.updateWorkflowType !== this.CONSULTING_ENGAGEMENT && !this.isConsultantPerspective)  {
            this.consultingEngagementId = null;
        }

        //if CE then mark workflow as consultant
        if (this.updateWorkflowType === this.CONSULTING_ENGAGEMENT) {
            this.updateWorkflowType = this.WorkflowUserTypes['CONSULTANT'].id;
        }

        let assignee = this.updateWorkflowType === this.WorkflowUserTypes['USER'].id ? this.updateUser : null;

        //if consultant selects account handler then need to set workflow user type to Consultant, otherwise leave it alone
        let workflowUserType = this.isConsultantPerspective && this.updateWorkflowType === this.WorkflowUserTypes['ACCOUNTHANDLER'].id ? this.WorkflowUserTypes['CONSULTANT'].id : this.updateWorkflowType;
        let enagagementId = ((workflowUserType  === this.WorkflowUserTypes['CONSULTANT'].id) || this.isConsultantPerspective) && this.consultantsEngagement ? this.consultantsEngagement.consultingEngagementId : this.consultingEngagementId


        _.forEach(this.params.csrs, (resp: BulkUpdateCsrIdentifier) => {
            let changeData: EntityResponsibilityAssignmentChange;
            changeData = new EntityResponsibilityAssignmentChange(
                resp.entityId,
                resp.entityTypeId,
                resp.clientServiceId,
                resp.propertyTypeId,
                workflowUserType,
                dateToSave,
                assignee,
                enagagementId);

            updatedResponsibilities.push(changeData);
        });

        this.isLoading = true;
        this.clientServicesExceptionService.saveClientServiceExceptions(updatedResponsibilities).then((response) => {
            const areNotAuthorized = (x: BulkSaveExceptionResult) => !x.isAuthorized;
            const haveErrors = (x: BulkSaveExceptionResult) => !!x.errorMessage;
            const result = response.results;

            this.isSaving = false;
            if (result.length && _.every(result, areNotAuthorized)) {
                this.toastr.error('You are not authorized to perform this operation');
            }
            else if (result.length && _.some(result, areNotAuthorized)) {
                this.toastr.error('Some exceptions could not be saved because you are not authorized to perform this operation');
            }

            if (result.length && _.every(result, haveErrors)) {
                this.toastr.error('There was an unexpected error processing your request');
            }
            else if (result.length && _.some(result, haveErrors)) {
                this.toastr.error('Some exceptions could not be saved because there was an error processing your request');
            }
            else if (response.longRunningProcessId) {
                this.params.longRunningProcessId = response.longRunningProcessId;
                this.toastr.success('You requested a large number of updates.  They are being run in the background, and you\'ll be notified when they are done')
            }
            else {
                this.toastr.success('Responsibilties assigned successfully');
                this.result = true;
            }
            this.isLoading = false;
            this.isSaving = false;
            this._bsModalRef.hide();
        }).catch(() => {
            this.isSaving = false;
            this.isLoading = false;
        });

        return Promise.resolve();
    }


    determineShowDatepicker(): void {

         this.isSaving = false;

         //Because of the inifinite scroll nature of the grid, we can't determine whether or not it needs to be shown.  So always show it if assigning to anything other than n/a
         this.showDatePicker = this.updateWorkflowType !== this.WorkflowUserTypes['NA'].id;

    }

    changeConsultingEngagement(eventTarget: EventTarget) {   // Dummy function to fix template error. Probably needs to be implemented

    }
}
