import { lastValueFrom, merge as observableMerge, Subject } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { WeissmanModalService } from '../../../../WeissmanModalService';
import { BusyIndicatorService } from '../../../../../Busy-Indicator';
import { FormRepository, ReturnAssetRepository } from '../../../../Repositories';
import { ReturnAssetsService, ScheduleAndFactorFilter, AssessorFactorsEnum, ReturnAssetsServiceSharedState } from '../returnAssets.service';
import { AssessorTablePickerComponent, AssessorTablePickerParams, LockedAssessorAssignments, AssessorTablePickerResult } from '../Assessor-Table-Picker/assessorTablePicker.component';
import { IReturnPartComponent, ReturnPartServiceBase } from '../../../Models/returnPartServiceBase';
import { ReturnServiceSharedState, ReturnService } from '../../../return.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { ToastrService } from 'ngx-toastr';
import { ReturnAssetDragAndDropService } from '../Drag-And-Drop/returnAssetDragAndDrop.service';

import * as _ from 'lodash';

interface IAggregatedSchedule {
    schedule: Compliance.ReturnAssetScheduleDetailsAssignedItemModel;
    scheduleDetails: Compliance.ReturnAssetScheduleDetailsAssignedItemModel[];
}

@Component({
    selector: 'return-asset-form-details',
    templateUrl: './returnAssetFormDetails.component.html'
})
export class ReturnAssetFormDetailsComponent implements OnInit, OnDestroy, IReturnPartComponent {
    constructor(
        private readonly _modalService: WeissmanModalService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _returnService: ReturnService,
        private readonly _returnAssetsService: ReturnAssetsService,
        private readonly _returnAssetRepository: ReturnAssetRepository,
        private readonly _returnAssetDragAndDropService: ReturnAssetDragAndDropService,
        private readonly _toastr: ToastrService
    ) { }

    private _localReturnServiceSharedState: ReturnServiceSharedState;
    private _localReturnAssetsServiceSharedState: ReturnAssetsServiceSharedState;
    private _scheduleData: Compliance.ReturnAssetScheduleDetailsAssignedItemModel[] = [];
    private _unassignedScheduleData: Compliance.ReturnAssetScheduleDetailsUnassignedItemModel[] = [];
    private _destroy$: Subject<void> = new Subject();

    get hasSchedules(): boolean {
        return this._scheduleData && !!this._scheduleData.length;
    }

    scheduleAndFactorFilter: ScheduleAndFactorFilter = {
        showOnlyNotAssignedToASchedule: false,
        showOnlyAssignedToNonReportableSchedule: false,
        showOnlyAssignedToReportableSchedule: false,
        formRevisionScheduleIds: [],
        depreciationFactorId: null,
        scheduleAge: null
    };

    get hideEmptySchedulesFilter(): boolean { return this._returnAssetsService.sharedState.hideEmptySchedulesFilter; }

    set hideEmptySchedulesFilter(value: boolean) { this._returnAssetsService.setHideEmptySchedulesFilter(value); }

    get separateFactorTablesFilter(): boolean { return this._returnAssetsService.sharedState.separateFactorTablesFilter; }

    set separateFactorTablesFilter(value: boolean) { this._returnAssetsService.setSeparateFactorTablesFilter(value); }

    get scheduleFilterEnabled(): boolean { return !!this._returnService.sharedState.formRevisionId; }

    separateFactorTablesFilterDisabled: boolean = false;

    aggregatedSchedules: IAggregatedSchedule[] = [];
    reportableSchedule: Compliance.ReturnAssetScheduleDetailsAssignedItemModel;
    nonReportableSchedule: Compliance.ReturnAssetScheduleDetailsAssignedItemModel;
    unassignedSchedule: Compliance.ReturnAssetScheduleDetailsAssignedItemModel;

    get returnPartServiceForInterPartComponent(): ReturnPartServiceBase { return this._returnAssetsService; }

    ngOnInit(): void {
        this._returnAssetsService.subscribeToServiceActivationCycle(this);
    }

    ngOnDestroy(): void {
        this._destroy$.complete();
        this._returnAssetsService.unsubscribeFromServiceActivationCycle(this);
    }

    async onReturnPartServiceActivated(): Promise<void> {
        observableMerge(
            this._returnAssetsService.assetStatusesFilter$,
            this._returnAssetsService.assetMappingsUpdated$,
            this._returnAssetsService.assetDetailsUpdated$
        ).pipe(takeUntil(this._destroy$))
         .subscribe(() => {
             // if showing results from any assessor, the do not separate factor tables (as there will be multiple for each assessor)
             if (this._returnAssetsService.sharedState.assessorFactorsFilter.assessorFactor === AssessorFactorsEnum.AnyAssessor) {
                 this.separateFactorTablesFilter = false;
                 this.separateFactorTablesFilterDisabled = true;
             } else {
                 this.separateFactorTablesFilterDisabled = false;
             }

             this._refreshSchedules();
         });

        this._returnAssetsService.requestUpdatedSchedules$.pipe(takeUntil(this._destroy$)).subscribe(async () => {
            await this._loadSchedules();
            this._applyFilters();
        });

        this._returnAssetsService.scheduleAndFactorFilter$
            .pipe(takeUntil(this._destroy$))
            .subscribe(filter => this.scheduleAndFactorFilter = filter);
    }

    onReturnPartServiceDeactivated(): void {
        this._destroy$.next();
    }

    /**
     * Checks to see if the form details component has any filters applied.
     * This is true if:
     * option 1: it is showing only unassigned assets and no other filters are selected
     * option 2: it is showing only assigned to non-reportable schedule
     * option 3: it is not showing only unassigned assets, not showing assets assigned to non-reportable schedule, and another filter is selected
     */
    hasFilters(): boolean {
        return this.scheduleAndFactorFilter.showOnlyNotAssignedToASchedule ||
               this.scheduleAndFactorFilter.showOnlyAssignedToNonReportableSchedule ||
               this.scheduleAndFactorFilter.showOnlyAssignedToReportableSchedule ||
               this.scheduleAndFactorFilter.formRevisionScheduleIds.length > 0 ||
               this.scheduleAndFactorFilter.depreciationFactorId !== null ||
               this.scheduleAndFactorFilter.scheduleAge !== null;
    }

    separateFactorTablesFilterChange(): void {
        this._applyFilters();
    }

    hideEmptySchedulesFilterChange(): void {
        this._applyFilters();
    }

    onScheduleAndFactorFilterClick(value: ScheduleAndFactorFilter): void {
        this.scheduleAndFactorFilter = value;
        this._returnAssetsService.setScheduleAndFactorFilter(this.scheduleAndFactorFilter);
    }

    onScheduleAgeFilterClick(value: ScheduleAndFactorFilter): void {
        this.scheduleAndFactorFilter = value;
        this._returnAssetsService.setScheduleAndFactorFilter(this.scheduleAndFactorFilter);
    }

    clearScheduleAndFactorFilter(): void {
        this.scheduleAndFactorFilter = {
            showOnlyNotAssignedToASchedule: false,
            showOnlyAssignedToNonReportableSchedule: false,
            showOnlyAssignedToReportableSchedule: false,
            formRevisionScheduleIds: [],
            depreciationFactorId: null,
            scheduleAge: null
        };

        this._returnAssetsService.clearScheduleAndFactorFilter();
    }

    async onAssetsDropped(reportingAssetIds: number[], schedule: Compliance.ReturnAssetScheduleDetailsAssignedItemModel): Promise<void> {
        if (schedule.isReportable === false || schedule.isTaxable === false || schedule.isDepreciable === false) {
            await this._updateNonReportable(reportingAssetIds, schedule);
        } else {
            let allAssessors: Compliance.FormRevisionAssessorModel[] = [];

            // We need the assessors whether or not we need the modal
            const busyRef = this._busyIndicatorService.show({ message: 'Processing Schedule Change' });
            try {
                allAssessors = await this._returnAssetsService.getAssessors(reportingAssetIds);
            } finally {
                busyRef.hide();
            }

            const assessors = allAssessors.filter((assessor: Compliance.FormRevisionAssessorModel) => assessor.assessorId !== null);
            const assets = await this._returnAssetDragAndDropService.getSelectedAssets();
            const assetAssessors = assets.map(x => assessors.find(y => x.assessorAbbr === y.assessorName));
            const lockedAssessors = schedule.lockedAssessors.filter(la => la.isLocked);

            if (schedule.depreciationTableId && schedule.depreciationTableId !== null) {
                // we don't need to ask for factorId, we just need to go ahead and send the return
                await this._updateForExpandedScheduleDetail(reportingAssetIds, schedule, assessors);
            } else if (assets.length && schedule.lockedAssessors && assetAssessors.every(x => !!lockedAssessors.find(la => x.assessorId === la.assessorId))) {
                await this._updateLockedSchedule(reportingAssetIds, schedule, assessors, allAssessors);
            } else if (assets.length && schedule.lockedAssessors && assetAssessors.some(x => !!lockedAssessors.find(la => x.assessorId === la.assessorId))) {
                this._toastr.error('Cannot assign to this schedule as it contains a mix of locked and unlocked assessors.');
            } else {
                await this._askForFactorAndUpdate(reportingAssetIds, schedule, assessors, allAssessors);
            }
        }
    }

    private async _updateNonReportable(reportingAssetIds: number[], schedule: Compliance.ReturnAssetScheduleDetailsAssignedItemModel) {
        // the non reportable schedule should just get a single null assessor entry, with null depreciation and index
        const nullAssociationModel: Compliance.AssessorFactorTableAssociationModel = {
            assessorId: null,
            depreciationTableId: null,
            indexTableId: null
        };
        const updateModel: Compliance.ReturnAssetUpdateModel = {
            assessorFactorTableAssociations: [nullAssociationModel],
            formRevisionScheduleId: schedule.formRevisionScheduleId,
            reportingAssetIds: reportingAssetIds
        };
        await this._submitAssetUpdateModel(updateModel);
    }

    private async _updateForExpandedScheduleDetail(reportingAssetIds: number[], schedule: Compliance.ReturnAssetScheduleDetailsAssignedItemModel, assessors) {
        const selection = {}; // mirrors modal structure
        assessors.forEach((assessor: Compliance.FormRevisionAssessorModel) => {
            selection[assessor.assessorId] = {
                depreciationTableId: schedule.depreciationTableId,
                indexTableId: null
            };
        });

        const result: AssessorTablePickerResult = {
            selection: selection
        };

        // save assets with all assessors associated to factor table
        await this._updateAssets(reportingAssetIds, schedule.formRevisionScheduleId, result);
    }

    private async _updateLockedSchedule(reportingAssetIds: number[], schedule: Compliance.ReturnAssetScheduleDetailsAssignedItemModel, assessors, allAssessors) {
        const reportingAssetAssessorMap = await lastValueFrom(this._returnAssetRepository.getReportingAssetAssessorsByFilingBatch(this._returnService.filingBatchId, {
            reportingAssetIds: reportingAssetIds,
            formRevisionId: this._returnService.formRevisionId,
            parcelIds: []
        }));

        //Check to see if all are locked.  If so, setup the update.
        const nullAssessor = schedule.lockedAssessors.find(x => x.assessorId === null);
        const areAllLocked = reportingAssetAssessorMap.every(raam => {
            let assessorAssignment = schedule.lockedAssessors.find(la => la.assessorId === raam.assessorId);
            if (!assessorAssignment) {
                assessorAssignment = nullAssessor;
            }
            return assessorAssignment.isLocked;
        });

        const selection: { [assessorId: number]: LockedAssessorAssignments } = {}; // mirrors modal structure
        reportingAssetAssessorMap.forEach((raam: Compliance.ReportingAssetAssessorModel) => {
            let assessorAssignment = schedule.lockedAssessors.find(la => la.assessorId === raam.assessorId);
            if (!assessorAssignment) {
                assessorAssignment = nullAssessor;
            }

            if (assessorAssignment.isLocked) {
                selection[raam.assessorId] = {
                    depreciationTableId: assessorAssignment.depreciationFactorTableId,
                    indexTableId: null
                };
            }
        });

        const result: AssessorTablePickerResult = {
            selection: selection
        };

        if (areAllLocked) {
            // save assets with all assessors associated to factor table
            await this._updateAssets(reportingAssetIds, schedule.formRevisionScheduleId, result);
        } else {
            // open dialog, with locked assets already assigned.
            await this._askForFactorAndUpdate(reportingAssetIds, schedule, assessors, allAssessors, selection);
        }
    }

    private async _askForFactorAndUpdate(
        reportingAssetIds: number[],
        schedule: Compliance.ReturnAssetScheduleDetailsAssignedItemModel,
        assessors,
        allAssessors,
        lockedAssessorAssignments: { [assessorId: number]: LockedAssessorAssignments } = {}): Promise<void> {
        const model: Compliance.AssessorFactorTableSearchModel = {
            assessorIds: [].concat(assessors.map(assessor => assessor.assessorId)),
            formRevisionScheduleId: schedule.formRevisionScheduleId
        };

        let assessorFactorTableResult: Compliance.AssessorFactorTableModel[];

        const busyRef = this._busyIndicatorService.show({ message: 'Loading Factor Tables' });
        try {
            assessorFactorTableResult = await lastValueFrom(this._returnAssetRepository.getAssessorFactorTables(this._returnService.filingBatchId, model));
        } finally {
            busyRef.hide();
        }

        const assessorFactorTables = assessorFactorTableResult.filter(x => x.assessorId !== null && !x.isCompany);
        const defaultAssessorFactorTables = _.filter(assessorFactorTableResult, { assessorId: null, isCompany: false });
        const defaultFactorTables = defaultAssessorFactorTables ? _.flatMap(defaultAssessorFactorTables, x => x.factorTables) : [];
        const defaultAssessor = allAssessors.find((x: Compliance.FormRevisionAssessorModel) => x.assessorId === null);
        const companyDefaultAssessorTables = assessorFactorTableResult.filter(x => x.isCompany && x.assessorId === null);
        const companyAssessorTables = assessorFactorTableResult.filter(x => x.isCompany && x.assessorId !== null);

        const params: AssessorTablePickerParams = {
            defaultAssessor: defaultAssessor,
            reportingAssetIds: reportingAssetIds,
            assessors: assessors,
            defaultFactorTables: _.concat(_.flatMap(companyDefaultAssessorTables, x => x.factorTables), defaultFactorTables),
            assessorFactorTables: [...companyDefaultAssessorTables, ...companyAssessorTables, ...assessorFactorTables],
            lockedAssessorAssignments: lockedAssessorAssignments
        };

        const result = await this._modalService.showAsync(AssessorTablePickerComponent, params, 'modal-xl');

        if (result && result.selection) {
            await this._updateAssets(reportingAssetIds, schedule.formRevisionScheduleId, result);
        }
    }

    private async _refreshSchedules(): Promise<void> {
        const returnServiceSharedState: ReturnServiceSharedState = this._returnService.getSharedStateClone();
        const returnAssetsServiceSharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();

        // get input parameters for comparison
        const localReturnIds = (this._localReturnServiceSharedState && this._localReturnServiceSharedState.returns && this._localReturnServiceSharedState.returns.map(x => x.returnId)) || [];
        const sharedReturnIds = returnServiceSharedState.returns.map(x => x.returnId);

        const localFormRevisionId = this._localReturnServiceSharedState && this._localReturnServiceSharedState.formRevisionId;
        const sharedFormRevisionId = returnServiceSharedState.formRevisionId;

        const localAssetStatusFilter = (this._localReturnAssetsServiceSharedState && this._localReturnAssetsServiceSharedState.assetStatusesFilter) || [];
        const sharedAssetStatusFilter = returnAssetsServiceSharedState.assetStatusesFilter;

        const localAssessorFactors = this._localReturnAssetsServiceSharedState && this._localReturnAssetsServiceSharedState.assessorFactorsFilter;
        const sharedAssessorFactors = returnAssetsServiceSharedState.assessorFactorsFilter;

        const localAssetDetailsUpdatedTimestamp = this._localReturnAssetsServiceSharedState && this._localReturnAssetsServiceSharedState.assetDetailsUpdatedTimestamp;
        const sharedAssetDetailsUpdatedTimestamp = returnAssetsServiceSharedState.assetDetailsUpdatedTimestamp;

        const localAssetMappingsUpdatedTimestamp = this._localReturnAssetsServiceSharedState && this._localReturnAssetsServiceSharedState.assetMappingsUpdatedTimestamp;
        const sharedAssetMappingsUpdatedTimestamp = returnAssetsServiceSharedState.assetMappingsUpdatedTimestamp;

        // check to see if the input parameters are available
        if (!sharedReturnIds || !sharedFormRevisionId || !sharedAssetStatusFilter || !sharedAssessorFactors) {
            this._blankSchedules();
        }

        // check to see if the input parameters have changed
        if (
            !_.isEqual(localReturnIds, sharedReturnIds) ||
            !_.isEqual(localFormRevisionId, sharedFormRevisionId) ||
            !_.isEqual(localAssetStatusFilter, sharedAssetStatusFilter) ||
            !_.isEqual(localAssessorFactors, sharedAssessorFactors) ||
            !_.isEqual(localAssetDetailsUpdatedTimestamp, sharedAssetDetailsUpdatedTimestamp) ||
            !_.isEqual(localAssetMappingsUpdatedTimestamp, sharedAssetMappingsUpdatedTimestamp)
        ) {
            await this._loadSchedules();
        }

        this._applyFilters();
    }

    private _blankSchedules(): void {
        this._persistState();

        this._scheduleData = [];
        this._unassignedScheduleData = [];
    }

    private _persistState(): void {
        this._localReturnServiceSharedState = this._returnService.getSharedStateClone();
        this._localReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
    }

    private async _loadSchedules(): Promise<void> {
        this._persistState();

        const busyRef = this._busyIndicatorService.show({ message: 'Loading Schedules' });
        try {
            const responses = await Promise.all([
                this._returnAssetsService.getScheduleDetails(),
                this._returnAssetsService.getUnassignedScheduleDetails()
            ]);
            this._scheduleData = responses[0] as Compliance.ReturnAssetScheduleDetailsAssignedItemModel[];
            this._unassignedScheduleData = responses[1] as Compliance.ReturnAssetScheduleDetailsUnassignedItemModel[];
        } finally {
            busyRef.hide();
        }
    }

    private _applyFilters(): void {
        // get "schedule" for assets that are unassigned to a schedule
        const unassignedSchedule = {
            assetCount: 0,
            cost: 0,
            reportedValue: 0,
            depreciationTableId: null,
            depreciationTableName: null,
            formRevisionScheduleId: null
        } as Compliance.ReturnAssetScheduleDetailsAssignedItemModel;

        this._unassignedScheduleData.forEach(x => {
            unassignedSchedule.assetCount = x.assetCount;
            unassignedSchedule.cost = x.cost;
        });

        // get non-reportable system schedule
        const nonReportableSchedule = {
            assetCount: 0,
            cost: 0,
            reportedValue: 0,
            depreciationTableId: null,
            depreciationTableName: null,
            formRevisionId: this._localReturnServiceSharedState.formRevisionId,
            formRevisionScheduleId: null,
            isReportable: false,
            isDepreciable: false,
            isTaxable: false
        } as Compliance.ReturnAssetScheduleDetailsAssignedItemModel;

        this._scheduleData
            .filter(x => !x.isReportable)
            .forEach(x => {
                // there should only be one non-reportable schedule; make sure to set ID here as it will be needed for when assets drag-and-drop on this schedule
                nonReportableSchedule.formRevisionScheduleId = x.formRevisionScheduleId;
                nonReportableSchedule.assetCount += x.assetCount;
                nonReportableSchedule.cost += x.cost;
            });

        // get schedule groups for assets that are assigned to a schedule
        const reportableSchedules: Compliance.ReturnAssetScheduleDetailsAssignedItemModel[] = [];
        this._scheduleData
            .filter(x => x.formRevisionScheduleId !== null && x.isReportable)
            .forEach(i => {
                // filter: separate factor tables (schedule and factor information comes in as separate)
                const schedule = this.separateFactorTablesFilter
                    ? reportableSchedules.find(x => x.formRevisionScheduleId === i.formRevisionScheduleId && x.depreciationTableId === i.depreciationTableId)
                    : reportableSchedules.find(x => x.formRevisionScheduleId === i.formRevisionScheduleId);

                if (schedule) {
                    // schedule exists, roll factor table information into it
                    schedule.assetCount += i.assetCount;
                    schedule.priorReportedValue += i.priorReportedValue;
                    schedule.priorCost += i.priorCost;
                    schedule.additions += i.additions;
                    schedule.disposals += i.disposals;
                    schedule.cost += i.cost;
                    schedule.reportedValue += i.reportedValue;
                } else {
                    // schedule does not exist, add to collection without factor table information
                    reportableSchedules.push({
                        formRevisionId: i.formRevisionId,
                        formRevisionScheduleId: i.formRevisionScheduleId,
                        abbr: i.abbr,
                        name: i.name,
                        description: i.description,
                        year: i.year,
                        age: i.age,
                        isTaxable: i.isTaxable,
                        isReportable: i.isReportable,
                        isDepreciable: i.isDepreciable,
                        isSystem: i.isSystem,
                        depreciationTableId: this.separateFactorTablesFilter ? i.depreciationTableId : null,
                        depreciationTableLife: this.separateFactorTablesFilter ? i.depreciationTableLife : null,
                        depreciationTableName: this.separateFactorTablesFilter ? i.depreciationTableName : null,
                        depreciationFactor: this.separateFactorTablesFilter ? i.depreciationFactor : null,
                        assetCount: i.assetCount,
                        priorCost: i.priorCost,
                        priorReportedValue: i.priorReportedValue,
                        additions: i.additions,
                        disposals: i.disposals,
                        cost: i.cost,
                        exemptCost: i.exemptCost,
                        reportedValue: i.reportedValue,
                        lockedAssessors: i.lockedAssessors
                    });
                }
            });

        // get totals for all assets being reported
        const reportableSchedule = {
            assetCount: 0,
            cost: 0,
            reportedValue: 0,
            isReportable: true,
            depreciationTableId: null,
            depreciationTableName: null,
            formRevisionScheduleId: null
        } as Compliance.ReturnAssetScheduleDetailsAssignedItemModel;

        reportableSchedules.forEach(i => {
            reportableSchedule.assetCount += i.assetCount;
            reportableSchedule.priorReportedValue += i.priorReportedValue;
            reportableSchedule.priorCost += i.priorCost;
            reportableSchedule.additions += i.additions;
            reportableSchedule.disposals += i.disposals;
            reportableSchedule.cost += i.cost;
            reportableSchedule.reportedValue += i.reportedValue;
        });

        // filter: hide empty schedules
        if (this.hideEmptySchedulesFilter) {
            let i = reportableSchedules.length;
            while (i--) {
                if (reportableSchedules[i].assetCount === 0) {
                    reportableSchedules.splice(i, 1);
                }
            }
        }

        // set values
        this.unassignedSchedule = unassignedSchedule;
        this.reportableSchedule = reportableSchedule;
        this.nonReportableSchedule = nonReportableSchedule;
        this.aggregatedSchedules = _.chain(reportableSchedules)
                                    .map(x => ({
                                        schedule: x,
                                        scheduleDetails: this.separateFactorTablesFilter
                                            ? this._scheduleData.filter(y => y.formRevisionScheduleId === x.formRevisionScheduleId && y.depreciationTableId === x.depreciationTableId)
                                            : this._scheduleData.filter(y => y.formRevisionScheduleId === x.formRevisionScheduleId)
                                    } as IAggregatedSchedule))
                                    .orderBy([(x) => x.schedule.name, (x) => x.schedule.depreciationTableLife])
                                    .value();

        // auto-select the schedule/factor
        if (this.scheduleAndFactorFilter.showOnlyNotAssignedToASchedule) {
            this.onScheduleAndFactorFilterClick({
                showOnlyNotAssignedToASchedule: true,
                showOnlyAssignedToNonReportableSchedule: false,
                showOnlyAssignedToReportableSchedule: false,
                formRevisionScheduleIds: [],
                depreciationFactorId: null,
                scheduleAge: null
            });
        } else if (this.scheduleAndFactorFilter.showOnlyAssignedToNonReportableSchedule) {
            this.onScheduleAgeFilterClick({
                showOnlyNotAssignedToASchedule: false,
                showOnlyAssignedToNonReportableSchedule: true,
                showOnlyAssignedToReportableSchedule: false,
                formRevisionScheduleIds: this.nonReportableSchedule.formRevisionScheduleId ? [this.nonReportableSchedule.formRevisionScheduleId] : [],
                depreciationFactorId: null,
                scheduleAge: null
            });
        } else if (this.scheduleAndFactorFilter.showOnlyAssignedToReportableSchedule) {
            this.onScheduleAgeFilterClick({
                showOnlyNotAssignedToASchedule: false,
                showOnlyAssignedToNonReportableSchedule: false,
                showOnlyAssignedToReportableSchedule: true,
                formRevisionScheduleIds: [],
                depreciationFactorId: null,
                scheduleAge: null
            });
        } else {
            const aggregatedSchedule = this.aggregatedSchedules.find(x =>
                _.isEqual([x.schedule.formRevisionScheduleId], this._returnAssetsService.sharedState.scheduleAndFactorFilter.formRevisionScheduleIds) &&
                x.schedule.depreciationTableId === this._returnAssetsService.sharedState.scheduleAndFactorFilter.depreciationFactorId);

            if (aggregatedSchedule) {
                this.onScheduleAgeFilterClick({
                    showOnlyNotAssignedToASchedule: false,
                    showOnlyAssignedToNonReportableSchedule: false,
                    showOnlyAssignedToReportableSchedule: false,
                    formRevisionScheduleIds: [aggregatedSchedule.schedule.formRevisionScheduleId],
                    depreciationFactorId: aggregatedSchedule.schedule.depreciationTableId,
                    scheduleAge: null
                });
            } else {
                this.onScheduleAgeFilterClick({
                    showOnlyNotAssignedToASchedule: false,
                    showOnlyAssignedToNonReportableSchedule: false,
                    showOnlyAssignedToReportableSchedule: false,
                    formRevisionScheduleIds: this._returnAssetsService.sharedState.scheduleAndFactorFilter.formRevisionScheduleIds,
                    depreciationFactorId: null,
                    scheduleAge: null
                });
            }
        }
    }

    private async _updateAssets(assetIds: number[], formScheduleId: number, assessorPickerResult: AssessorTablePickerResult): Promise<void> {
        // build the assessor-factor association model that the API expects
        const assessorFactorTableAssociationModel: Compliance.AssessorFactorTableAssociationModel[] = [];

        for (let key in assessorPickerResult.selection) {
            if (assessorPickerResult.selection.hasOwnProperty(key)) {
                assessorFactorTableAssociationModel.push({
                    assessorId: parseInt(key),
                    depreciationTableId: assessorPickerResult.selection[key].depreciationTableId,
                    indexTableId: assessorPickerResult.selection[key].indexTableId
                });
            }
        }

        // build the update model
        const updateModel: Compliance.ReturnAssetUpdateModel = {
            formRevisionScheduleId: formScheduleId,
            reportingAssetIds: assetIds,
            assessorFactorTableAssociations: assessorFactorTableAssociationModel
        };

        this._submitAssetUpdateModel(updateModel);
    }

    private async _submitAssetUpdateModel(updateModel: Compliance.ReturnAssetUpdateModel) {
        const busyRef = this._busyIndicatorService.show({ message: 'Updating Assets' });
        try {
            await this._returnAssetsService.updateAssetMappings(updateModel);
        } finally {
            busyRef.hide();
        }
    }
}
