import { ErrorHandler, Injectable, Injector, Inject } from '@angular/core'
import { ToastrService } from 'ngx-toastr';
import { LoggingService } from '../Logging/logging.service';
import * as _ from 'lodash';
import { HandledError } from '../Models/errorModels';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(@Inject(Injector) private _injector: Injector) {
        this._debouncedErrorHandler = _.debounce(this.internalErrorHandler,250);
    }

    private readonly _translationMap = {
        "Cannot send data if the connection is not in the 'Connected' State.":"Unable to connect to the real-time update service. Functionality may be degraded."
    };

    private _debouncedErrorHandler;

    handleError(e): void {
        this._debouncedErrorHandler(e);
    }


    private internalErrorHandler(e) {
        // No idea why we can't just do e.rejection instanceof HandledError; use the name property to detect
        // this case.
        if (e instanceof HandledError || (e.rejection && e.rejection.name === HandledError.name)) {
            return;
        }

        // Status code 403 is handled in ngjsStart.js. No need to show the generic 'An unexpected error has occurred' error message
        // xhrStatus = abort when user cancels a request. No need to show error
        if (e.rejection && (e.rejection.status === 403 || e.rejection.xhrStatus === 'abort')){
            return;
        }

        const loggingService = this._injector.get(LoggingService);
        const toastr = this._injector.get(ToastrService);
        loggingService.logError(e);

        let message = 'An unexpected error has occurred';

        if (e.rejection && e.rejection.message) {
            message = e.rejection.message;
        }

        if (e.rejection && e.rejection.error && e.rejection.error.message) {
            message = e.rejection.error.message;
        }

        if (this._translationMap[message]) {
            message = this._translationMap[message];
        }

        message = message.replace('\r\n', '<br />');
        toastr.error(message, '', {enableHtml: true});
    }
}
