import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

export interface IWeissmanModalTracker {
    wsModalRef: IWeissmanModalRef;
    closedResolveFn: (value: BsModalRef) => void;
}

export interface IWeissmanModalComponent<TParams, TResult> {
    params: TParams;
    result: TResult;
}

export interface IWeissmanModalRef {
    bsModalRef: BsModalRef;
    whenClosed: Promise<BsModalRef>;
}

export interface IWeissmanModalService {
    show: (fn: (...args) => BsModalRef) => IWeissmanModalRef;
}

@Injectable()
export class WeissmanModalService implements IWeissmanModalService {
    constructor(private readonly _modalService: BsModalService) { }

    show(fn: (...args) => BsModalRef): IWeissmanModalRef {
        let whenClosed: (value?: BsModalRef | PromiseLike<BsModalRef>) => void = null;
        const wsModalTracker: IWeissmanModalTracker = {
            wsModalRef: {
                bsModalRef: fn(),
                whenClosed: new Promise<BsModalRef>((resolve) => {
                    whenClosed = resolve;
                })
            },
            closedResolveFn: null
        };
        wsModalTracker.closedResolveFn = whenClosed;

        if (this._modalTrackers.length === 0) {
            this._subscribe();
        }

        this._modalTrackers.push(wsModalTracker);

        return wsModalTracker.wsModalRef;
    }

    showAsync<TParams, TResult>(component: new(...args: any[]) => IWeissmanModalComponent<TParams, TResult>, params: TParams, modalClass: string): Promise<TResult> {
        return new Promise((resolve, reject) => {
            const showModalFn = (): BsModalRef => {
                return this._modalService.show(component, {
                    class: modalClass,
                    initialState: { params },
                    ignoreBackdropClick: true,
                    keyboard: false
                } as ModalOptions);
            }

            this.show(showModalFn.bind(this)).whenClosed.then(bsModalRef => {
                    const result = (bsModalRef.content as IWeissmanModalComponent<TParams, TResult>).result;
                    resolve(result);
                },
                reject);
        });
    }

    private _modalTrackers: IWeissmanModalTracker[] = [];

    private _onHiddenSub: Subscription;

    private _subscribe(): void {
        this._onHiddenSub = this._modalService.onHidden.subscribe(() => {
            const wsModalTracker = this._modalTrackers.pop();
            wsModalTracker.closedResolveFn(wsModalTracker.wsModalRef.bsModalRef);

            if (this._modalTrackers.length === 0) {
                this._unsubscribe();
            }
        });
    }

    private _unsubscribe(): void {
        this._onHiddenSub.unsubscribe();
        this._onHiddenSub = null;
    }
}
