import { Component, OnInit, OnDestroy } from '@angular/core';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { BusyIndicatorRef, BusyIndicatorService } from '../../../Busy-Indicator';
import { CompanyService } from '../../../Entity/Company/company.service';
import { AllocationRepository, EntityImportRepository } from '../../Repositories';
import { EntityImportProcessService, ISelectedRowsEvent } from './importProcess.service';
import { Subscription, BehaviorSubject, Subject, lastValueFrom } from 'rxjs';
import { EntityImportColumnMappingStepService } from './Column-Mapping-Step/columnMappingStep.service';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import * as _ from 'lodash';
import { DecimalPipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { WeissmanModalService } from '../../WeissmanModalService';
import { EntityImportSpecificationMappingComponent, ImportSpecificationMappingParams } from './Column-Mapping-Step/Import-Specification-Mapping/importSpecificationMapping.component';
import {HelpService} from '../../../UI-Lib/Help-Tooltip';
import {IMPORT_PROCESS_HELP} from './importProcess.component.help';
import { takeUntil } from 'rxjs/operators';
import { BreadCrumb } from '../../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { InstanceRights, RestrictService, Roles } from '../../../Common/Permissions/restrict.service';
import { EntityImportService } from '../entityImport.service';

enum ImportStep {
    Indeterminate = 0,
    ColumnMapping = 1,
    Editing = 2
}

enum ImportInitiatorEnum {
    SystemList,
    CompanyList,
    Allocation
}

@Component({
    selector: 'entity-import-process',
    templateUrl: './importProcess.component.html'
})
export class EntityImportProcessComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _entityImportRepository: EntityImportRepository,
        private readonly _entityImportProcessService: EntityImportProcessService,
        private readonly _companyService: CompanyService,
        private readonly _messageModalService: MessageModalService,
        private readonly _columnMappingStepService: EntityImportColumnMappingStepService,
        private readonly _decimalPipe: DecimalPipe,
        private readonly _toastsManager: ToastrService,
        private readonly _modalService: WeissmanModalService,
        private readonly _helpService: HelpService,
        private readonly _allocationRepository: AllocationRepository,
        private readonly _restrictService: RestrictService,
        private readonly _entityImportService: EntityImportService) { }

    private readonly _unknownImportStep = 'An unknown import step was specified.';

    private _selectedRowsSub: Subscription;
    private _fieldAssignedSub: Subscription;
    private _fieldUnassignedSub: Subscription;
    private _notValidatedUpdatesSub: Subscription;
    private _validatedSub: Subscription;
    private _evaluatingSub: Subscription;
    private _selectedRowsModel: Compliance.SelectedRowsModel;
    private _selectedRowsCount: number = 0;
    private _isEvaluated: boolean = false;
    private _isValidated: boolean = false;
    private _isModelDirty: boolean = false;
    private _importStatusSub: Subscription;
    private _importProgressSub: Subscription;
    private _busyRef: BusyIndicatorRef;
    private _selectedRowsInfo: Compliance.ImportSelectedRowsInfoModel;
    private _previousEvaluationAllSelected: boolean;
    private _destroy$: Subject<void> = new Subject();
    private _importInitiator: ImportInitiatorEnum;
    private _forceValidateSub: Subscription;

    selectedRowsInfo$: BehaviorSubject<Compliance.ImportSelectedRowsInfoModel>;

    busyRefId: string = this._busyIndicatorService.generateUniqueMessageIdentifier();
    breadcrumbs: BreadCrumb[] = [];
    isInitialized: boolean = false;
    importId: number;
    companyId: number;
    importSteps = ImportStep;
    importStep: ImportStep = ImportStep.Indeterminate;
    importFileModel: Compliance.ImportFileModel;
    allocationId?: number;
    notValidatedUpdatesCount: number = 0;

    get nextText(): string {
        if (this.importStep === ImportStep.ColumnMapping) {
            return 'Next Step: Validate';
        }
        if (this.importStep === ImportStep.Editing && !this._isEvaluated) {
            return 'Next Step: Evaluate';
        }
        if (this.importStep === ImportStep.Editing && this._isEvaluated) {
            return 'Next Step: Transfer';
        }
        return 'Next';
    }

    get isGoBackVisible(): boolean {
        switch (this.importStep) {
            case ImportStep.Indeterminate:
            case ImportStep.ColumnMapping:
                return false;
            case ImportStep.Editing:
                return this.importFileModel.transferredRows === 0;
            default:
                console.warn(this._unknownImportStep);
                return true;
        }
    }

    get isGoBackEnabled(): boolean {
        switch (this.importStep) {
            case ImportStep.Indeterminate:
            case ImportStep.ColumnMapping:
                return false;
            case ImportStep.Editing:
                return true;
            default:
                console.warn(this._unknownImportStep);
                return true;
        }
    }

    get isGoNextVisible(): boolean {
        switch (this.importStep) {
            case ImportStep.Indeterminate:
                return false;
            case ImportStep.ColumnMapping:
            case ImportStep.Editing:
                return this.importFileModel &&
                    this.importFileModel.processStatus !== Compliance.ImportFileProcessStatusEnum.Completed &&
                    this.importFileModel.totalRows !== this.importFileModel.transferredRows;
            default:
                console.warn(this._unknownImportStep);
                return true;
        }
    }

    get importFileDescription(): string {
        return this.importFileModel ? this.importFileModel.displayName + ' (' + _.get(this.importFileModel, 'importContentType.name') + ')' : '';
    }

    get isGoNextEnabled(): boolean {
        let isEnabled: boolean;

        switch (this.importStep) {
            case ImportStep.Indeterminate:
                return false;
            case ImportStep.ColumnMapping:
                isEnabled = this.importFileModel.requiredFieldsAssigned;

                if (isEnabled) {
                    const unknownMappedFields = this.importFileModel.assignedFields
                        .filter(x => !this.importFileModel.importContentType.importFields.find(y => y.importFieldId == x.importFieldId));
                    isEnabled = !unknownMappedFields.length;
                }
                return isEnabled;
            case ImportStep.Editing:
                isEnabled = this._selectedRowsModel && this._selectedRowsCount > 0 && this._selectedRowsInfo &&
                    this._selectedRowsInfo.validRowsCount !== 0 && !this.notValidatedUpdatesCount;

                if (isEnabled && this._isEvaluated){
                    isEnabled = this.importFileModel.importContentTypeId === Compliance.ImportContentTypeIdEnum.Assets ||
                        (!!this._selectedRowsInfo.evaluatedInsertCount || !!this._selectedRowsInfo.evaluatedUpdateCount ||
                            !!this._selectedRowsInfo.evaluatedDeleteCount);
                }

                return isEnabled;
            default:
                console.warn(this._unknownImportStep);
                return true;
        }
    }

    get isCloseEnabled(): boolean {
        return this.importStep !== ImportStep.Indeterminate;
    }

    get nextHelpContentId(): string {
        switch (this.importStep) {
            case ImportStep.Indeterminate:
                return "";
            case ImportStep.ColumnMapping:
                if (this.importFileModel.requiredFieldsAssigned) {
                    return "import-process.next-step-validate";
                }
                return "import-process.next-step-validate.disabled";
            case ImportStep.Editing:
                let helpId = "";

                if (this.importFileModel &&  !this._isEvaluated) {
                    if (this.notValidatedUpdatesCount) {
                        helpId = 'import-process.has-not-validated-updates';
                    } else if (this._selectedRowsModel && this._selectedRowsCount === 0 ) {
                        helpId = "import-process.next-step-evaluate.disabled.no-selection";
                    } else if (this._selectedRowsModel && this._selectedRowsInfo && this._selectedRowsInfo.validRowsCount === 0) {
                        helpId = "import-process.next-step-evaluate.disabled.invalid-selection";
                    } else {
                        helpId = "import-process.next-step-evaluate";
                    }
                } else if (this.importFileModel && this._isEvaluated){
                    if (this.importFileModel.importContentTypeId === Compliance.ImportContentTypeIdEnum.Assets ||
                        (this._selectedRowsInfo && (!!this._selectedRowsInfo.evaluatedInsertCount || !!this._selectedRowsInfo.evaluatedUpdateCount))){
                        helpId = "import-process.next-step-transfer";
                    } else{
                        helpId = "import-process.next-step-evaluate.disabled.no-nochanges";
                    }
                } else {
                    helpId = "import-process.next-step-transfer";
                }

                return helpId;
        }
    }

    get backHelpContentId(): string {
        if (this.importStep == ImportStep.Editing) {
            return "import-process.back.edit";
        }

        return "import-process.back";
    }

    get isMarkAsCompletedVisible(): boolean {
        switch (this.importStep) {
            case ImportStep.Indeterminate:
            case ImportStep.ColumnMapping:
                return false;
            case ImportStep.Editing:
                return this.importFileModel &&
                    this.importFileModel.processStatus !== Compliance.ImportFileProcessStatusEnum.Completed &&
                    this.importFileModel.totalRows !== this.importFileModel.transferredRows;
            default:
                console.warn(this._unknownImportStep);
                return true;
        }
    }

    get isValidateVisible(): boolean {
        switch (this.importStep) {
            case ImportStep.Indeterminate:
            case ImportStep.ColumnMapping:
                return false;
            case ImportStep.Editing:
                return this.importFileModel &&
                    this.importFileModel.processStatus !== Compliance.ImportFileProcessStatusEnum.Completed;
            default:
                console.warn(this._unknownImportStep);
                return true;
        }
    }

    get isMarkAsCompletedEnabled(): boolean {
        switch (this.importStep) {
            case ImportStep.Indeterminate:
            case ImportStep.ColumnMapping:
                return false;
            case ImportStep.Editing:
                return this.importFileModel &&
                    this.importFileModel.processStatus !== Compliance.ImportFileProcessStatusEnum.Completed &&
                    this.importFileModel.transferredRows !== 0 &&
                    !this.notValidatedUpdatesCount;
            default:
                console.warn(this._unknownImportStep);
                return true;
        }
    }

    get markCompletedHelpContentId(): string {
        let result = '';

        if (this.notValidatedUpdatesCount) {
            result = 'import-process.has-not-validated-updates';
        } else if (!this.isMarkAsCompletedEnabled) {
            result = 'import-process.mark-completed-disabled';
        } else {
            result = 'import-process.mark-completed';
        }

        return result;
    }

    async ngOnInit(): Promise<void> {
        let companyName: string;
        let allocationName: string;

        this._helpService.setContent(IMPORT_PROCESS_HELP);

        if (this._routerService.getQuerystringParam('allocationId')){
            this._importInitiator = ImportInitiatorEnum.Allocation;
        } else if (this._routerService.getQuerystringParam('companyId')){
            this._importInitiator = ImportInitiatorEnum.CompanyList
        } else {
            this._importInitiator = ImportInitiatorEnum.SystemList;
        }

        let busyRef = this._busyIndicatorService.show({ message: 'Loading' });

        this.selectedRowsInfo$ = new BehaviorSubject<Compliance.ImportSelectedRowsInfoModel>(null);
        try {
            try {
                this.importId = parseInt(this._routerService.getQuerystringParam('importId'));
                let instanceId: number;

                if (this._importInitiator === ImportInitiatorEnum.CompanyList) {
                    this.companyId = parseInt(this._routerService.getQuerystringParam('companyId'));

                    const company = await this._companyService.load(this.companyId, false, false);

                    if (company.companyID !== company.topLevelCompanyId) {
                        this._toastsManager.error('Data imports are not allowed on subsidiary companies.');
                        this.navigateToCompany();
                        return;
                    }

                    companyName = company.companyName;
                    instanceId = company.instanceID;
                } else if (this._importInitiator === ImportInitiatorEnum.Allocation) {
                    this.allocationId = parseInt(this._routerService.getQuerystringParam('allocationId'));
                    const allocation = await lastValueFrom(this._allocationRepository.get(this.allocationId));

                    if (!this._restrictService.hasInstanceRight(InstanceRights.COMPLIANCEFEATURESVIEW, allocation.instanceId)) {
                        this.navigateToUnauthorized();
                        return Promise.resolve();
                    }

                    allocationName = allocation.description;
                    instanceId = allocation.instanceId;
                }

                if (this._importInitiator === ImportInitiatorEnum.CompanyList ||
                    this._importInitiator === ImportInitiatorEnum.Allocation)
                {
                    if (!this._restrictService.hasInstanceRight(InstanceRights.COMPANYDATAIMPORTSVIEW, instanceId)) {
                        this.navigateToUnauthorized();
                        return Promise.resolve();
                    }
                } else {
                    if (!this._restrictService.hasInstanceRight(InstanceRights.INSTANCEDATAIMPORTS, instanceId) &&
                        !this._restrictService.isInRole(Roles.ALLOWDATAIMPORTS) &&
                        !this._restrictService.isInRole(Roles.TAXRATESETUP)){
                        this.navigateToUnauthorized();
                        return Promise.resolve();
                    }
                }

                await this._getImport(this.importId);
            } catch (e) {
                if (e && e.status === 423) {
                    try {
                        await busyRef.hide();
                        await this._messageModalService.confirm(
                            e.error.message + '  Are you sure you wish to take ownership?',
                            'Confirm Take Ownership');
                    } catch (e) {
                        this.navigateToImportsList();
                        return;
                    }

                    busyRef = this._busyIndicatorService.show({ message: 'Loading' });

                    await lastValueFrom(this._entityImportRepository.takeOwnership(this.importId));
                    await this._getImport(this.importId);
                } else {
                    throw e;
                }
            }

            await this._entityImportProcessService.initialize(this.importFileModel);

            this._fieldAssignedSub = this._columnMappingStepService.fieldAssigned$.subscribe(() => this._setModelDirty());

            this._fieldUnassignedSub = this._columnMappingStepService.fieldUnassigned$.subscribe(() => this._setModelDirty());

            this._selectedRowsSub = this._entityImportProcessService.selectedRowsChanged$.subscribe(
                async (selectedRowsEvent: ISelectedRowsEvent) => {
                    this._selectedRowsModel = {
                        selectedRows: selectedRowsEvent.selectedRowsModel.selectedRows,
                        selectAllRows: selectedRowsEvent.selectedRowsModel.selectAllRows
                    };

                    this._selectedRowsCount = selectedRowsEvent.selectedRowsCount;

                    this._isEvaluated = this._previousEvaluationAllSelected;

                    if (this._selectedRowsModel.selectAllRows || this._selectedRowsModel.selectedRows.length !== 0) {
                        const busyIndicatorRef = this._busyIndicatorService.show({ message: 'Validating selection' });
                        try{
                            this._selectedRowsInfo = await this._entityImportProcessService.getSelectedRowsInfo(this.importId, selectedRowsEvent.selectedRowsModel);
                        }
                        finally{
                            busyIndicatorRef.hide();
                        }
                    } else {
                        this._selectedRowsInfo = null;
                    }

                    this.selectedRowsInfo$.next(this._selectedRowsInfo);
                });

            this._notValidatedUpdatesSub = this._entityImportProcessService.notValidatedUpdates$.subscribe((x) => {
                this.notValidatedUpdatesCount = x;

                if (x)
                {
                    this._isEvaluated = false;
                    this._previousEvaluationAllSelected = false;
                }
            });

            this._validatedSub = this._entityImportProcessService.validated$.subscribe(
                async (promise: Promise<void>) => {
                    this._isValidated = false;
                    const busyIndicatorRef = this._busyIndicatorService.show({ message: 'Loading Validation Summary' });

                    try {
                        await promise;
                        this._isValidated = true;
                        if (this.importFileModel.processStatus !== Compliance.ImportFileProcessStatusEnum.Completed){
                            this.importFileModel.processStatus = Compliance.ImportFileProcessStatusEnum.Validated;
                        }
                    } finally {
                        await busyIndicatorRef.hide();
                    }
                });

            this._evaluatingSub = this._entityImportProcessService.evaluating$.subscribe(
                async (promise: Promise<Compliance.ImportEvaluateResultModel>) => {
                    this._previousEvaluationAllSelected = false;
                    this._isEvaluated = false;
                    const busyIndicatorRef = this._busyIndicatorService.show({ message: 'Evaluating selected rows' });

                    try {
                        await promise;
                        this._isEvaluated = true;
                        if (this._selectedRowsModel.selectAllRows) {
                            this._previousEvaluationAllSelected = true;
                        }
                    } finally {
                        busyIndicatorRef.hide();
                    }
                });

            // determine import step to go to based on the state of the import file
            // go to editing step if (1) required fields are mapped (2) transferred at least one row
            this.importStep = (this.importFileModel.requiredFieldsAssigned && this.importFileModel.transferredRows > 0 ||
                this.importFileModel.processStatus === Compliance.ImportFileProcessStatusEnum.Completed)
                ? ImportStep.Editing
                : ImportStep.ColumnMapping;

            // re-validate in case some of the lookup data is different.
            if (this.importFileModel.processStatus !== Compliance.ImportFileProcessStatusEnum.Completed
                && this.importFileModel.processStatus !== Compliance.ImportFileProcessStatusEnum.Transferring
                && this.importFileModel.processStatus !== Compliance.ImportFileProcessStatusEnum.Validating
                && this.importFileModel.transferredRows !== this.importFileModel.totalRows
                && this.importStep === ImportStep.Editing) {
                if (!busyRef.isDisposed){
                    await busyRef.hide();
                }
                await this._startValidation('Validating', false)
            }

            if (this._importInitiator === ImportInitiatorEnum.CompanyList) {
                this.breadcrumbs.push({
                    name: companyName,
                    target: 'company',
                    options: { companyId: this.companyId }
                });
                this.breadcrumbs.push({
                    name: 'Data Imports',
                    target: 'companyImports',
                    options: { companyId: this.companyId }
                });
            } else if (this._importInitiator === ImportInitiatorEnum.Allocation) {
                this.breadcrumbs.push({
                    name: allocationName,
                    target: 'allocationDetails',
                    options: { allocationId: this.allocationId }
                });
            } else {
                this.breadcrumbs.push({
                    name: 'Data Imports',
                    target: 'systemImports',
                    options: {}
                });
            }

            this._forceValidateSub = this._entityImportProcessService.forceValidate$.subscribe(async() => await this.revalidate());

            this.isInitialized = true;
        }
        finally {
            if (!busyRef.isDisposed) {
                await busyRef.hide();
            }
        }

        this._importStatusSub = this._entityImportProcessService.importStatus$.subscribe(x => this._handleStatusChange(x));

        if (this.importStep === ImportStep.Editing) {
            // get initial status and block UI if a long running process is active
            // this needs to run after the global busy indicator in the try/catch has been disposed

            setTimeout(() => this._handleStatusChange(this.importFileModel, true), 500);
        }
    }

    async ngOnDestroy(): Promise<void> {
        this._destroy$.next();
        this._destroy$.complete();
        this._selectedRowsSub && this._selectedRowsSub.unsubscribe();
        this._fieldAssignedSub && this._fieldAssignedSub.unsubscribe();
        this._fieldUnassignedSub && this._fieldUnassignedSub.unsubscribe();
        this._notValidatedUpdatesSub && this._notValidatedUpdatesSub.unsubscribe();
        this._validatedSub && this._validatedSub.unsubscribe();
        this._evaluatingSub && this._evaluatingSub.unsubscribe();
        this._entityImportProcessService && this._entityImportProcessService.destroy();
        this._importStatusSub && this._importStatusSub.unsubscribe();
        this._importProgressSub && this._importProgressSub.unsubscribe();
        this._forceValidateSub && this._forceValidateSub.unsubscribe();
        await this._hideBusyIndicator();
    }

    goBack() {
        this.importStep--;
        if (this.importStep === ImportStep.ColumnMapping) {
            this._isValidated = false;
            this._isEvaluated = false;
            this._previousEvaluationAllSelected = false;
        }
    }

    async goNext(): Promise<void> {
        if (this.importStep === ImportStep.ColumnMapping) {
            if (this._isModelDirty && this.importFileModel.importFileSpecificationId
                && (!this.importFileModel.importFileSpecificationIsPrivate || this.importFileModel.importFileSpecificationUserId === this.importFileModel.userId)) {

                await this.saveImportSpecification();
            }

            if (this.importFileModel.processStatus !== Compliance.ImportFileProcessStatusEnum.Completed &&
                this.importFileModel.transferredRows !== this.importFileModel.totalRows) {
                await this._startValidation('Validating', true);
            } else {
                this.importStep++;
            }
        } else {
            await this._goNext();
        }
    }

    onCloseClick(): void {
        this.navigateToImportsList();
    }

    navigateToUnauthorized(): void {
        this._routerService.go('unauthorizedAccess', {});
    }

    navigateToImportsList(): void {
        if (this._importInitiator === ImportInitiatorEnum.CompanyList){
            this._routerService.go('companyImports', { 'companyId': this.companyId });
        } else if (this._importInitiator === ImportInitiatorEnum.Allocation) {
            this._routerService.go('allocationDetails', { 'allocationId': this.allocationId });
        } else{
            this._routerService.go('systemImports', {});
        }
    }

    async saveImportSpecification(): Promise<void> {
        const params: ImportSpecificationMappingParams = {
            importFileId: this.importFileModel.importFileId,
            companyId: this.importFileModel.companyId,
            importFileSpecificationId: this.importFileModel.importFileSpecificationId,
            importFileSpecificationName: this.importFileModel.importFileSpecificationName,
            allowNoSave: true
        };

        const result = await this._modalService.showAsync(EntityImportSpecificationMappingComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        this._toastsManager.success('Specification saved');

        return Promise.resolve();
    }

    async markAsCompleted(): Promise<void>{
        try {
            await this._messageModalService.confirm(
                'Are you sure you wish to mark the data import as completed?',
                'Confirm'
            );
        } catch (e) {
            return Promise.resolve();
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Completing Data Import' });
        try {
            this.importFileModel = await lastValueFrom(this._entityImportRepository.markAsCompleted(this.importFileModel.importFileId));
            this.navigateToImportsList();
            return Promise.resolve();
        } finally {
            busyRef.hide();
        }
    }

    get isRevalidateVisible(): boolean {
        switch (this.importStep) {
            case ImportStep.Indeterminate:
            case ImportStep.ColumnMapping:
                return false;
            case ImportStep.Editing:
                return this.importFileModel &&
                    this.importFileModel.processStatus !== Compliance.ImportFileProcessStatusEnum.Completed &&
                    this.importFileModel.totalRows !== this.importFileModel.transferredRows;
            default:
                console.warn(`this._unknownImportStep: ${this.importStep}`);
                return true;
        }
    }

    async revalidate(): Promise<void> {
        const model: Compliance.ImportValidateModel = {
            validateNotValidatedUpdatesOnly: !!this.notValidatedUpdatesCount
        };
        await this._startValidation('Revalidating', false, model);
    }

    get assetEffectiveDate(): Date {
        return this._entityImportProcessService.assetEffectiveDate;
    }

    get assetOwnershipTypeName(): string {
        return this._entityImportProcessService.assetOwnershipTypeName;
    }

    get accrualAdjustmentAmountValueType(): string {
        return this._entityImportProcessService.accrualAdjustmentAmountValueType;
    }

    navigateToCompany(): void {
        this._routerService.go('company', { 'companyId': this.companyId });
    }

    get isDeleteVisible(): boolean {
        switch (this.importStep) {
            case ImportStep.Indeterminate:
                return false;
            case ImportStep.ColumnMapping:
                return true;
            case ImportStep.Editing:
                return this.importFileModel &&
                    this._entityImportProcessService.canDelete(this.importFileModel) &&
                    !this.importFileModel.transferredRows;
            default:
                console.warn(this._unknownImportStep);
                return true;
        }
    }

    get isDeleteEnabled(): boolean {
        return this._entityImportProcessService.canDelete(this.importFileModel);
    }

    get deleteHelpContentId(): string {
        if (!this.isDeleteEnabled) {
            return 'import-process.delete-disabled';
        }

        return 'import-process.delete';
    }

    get isSiteUpdatesOnlyImport(): boolean {
        return this._entityImportProcessService.importContentTypeId === Compliance.ImportContentTypeIdEnum.SitesUpdatesOnly;
    }

    get sitesUpdatesOnlyMatchBy(): string {
        return this._entityImportProcessService.sitesUpdatesOnlyMatchBy;
    }

    get isTaxPaymentsImport(): boolean {
        return this._entityImportProcessService.importContentTypeId === Compliance.ImportContentTypeIdEnum.TaxPayments;
    }

    get taxPaymentsMatchOverall(): string {
        return this._entityImportProcessService.taxPaymentsMatchOverall;
    }

    get isTaxPaymentsImportMatchByPaymentSystemId(): boolean {
        return this._entityImportProcessService.isTaxPaymentsImportMatchByPaymentSystemId;
    }

    get taxPaymentsMatchParcel(): string {
        return this._entityImportProcessService.taxPaymentsMatchParcel;
    }

    get taxPaymentsMatchCollector(): string {
        return this._entityImportProcessService.taxPaymentsMatchCollector;
    }

    get incomeStatementsEndingDate(): Date {
        return this._entityImportProcessService.incomeStatementsEndingDate;
    }

    get rentRollEndingDate(): Date {
        return this._entityImportProcessService.rentRollEndingDate;
    }

    get glAccountsIntendedUse(): string {
        return  this._entityImportProcessService.glAccountsIntendedUse;
    }

    async delete(): Promise<boolean> {
        try {
            await this._messageModalService.confirm(
                'Are you sure you wish to delete the data import?',
                'Confirm'
            );
        } catch (e) {
            return Promise.resolve(false);
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Deleting' });
        try {
            await lastValueFrom(this._entityImportRepository.deleteImport(this.importId));
            this.navigateToImportsList();
            return Promise.resolve(true);
        } finally {
            busyRef.hide();
        }
    }

    private async _getImport(id: number): Promise<void> {
        this.importFileModel = await lastValueFrom(this._entityImportRepository.getImport(id));
    }

    private async _setModelDirty(): Promise<void> {
        this._isModelDirty = true;
        await this._getImport(this.importId);
    }

    private async _goNext(): Promise<void> {
        // evaluate
        if (this._isValidated && !this._isEvaluated) {
            await this._entityImportProcessService.evaluate(this.importId, this._selectedRowsModel);
            this._messageModalService.alert('The selected rows have been evaluated and are now ready to transfer. Review the estimated database transfer action for each selected row before proceeding.');
            return;
        }

        // transfer
        if (this._isValidated && this._isEvaluated) {
            const confirm = await this._entityImportProcessService.transfer();

            if (confirm) {
                this._showBusyIndicator('', 'Transferring selected rows', null, !this.allocationId);
                try {
                    const transfer = await this._entityImportProcessService.startTransfer(this.importId, this._selectedRowsModel);
                    this._busyRef.setLongRunningProcessId(transfer.longRunningProcessId);
                } catch (errResp) {
                    this._toastsManager.error(errResp.error.message);
                    this._hideBusyIndicator();
                }
            }
        }
    }

    private async _handleStatusChange(importFile: Compliance.ImportFileModel, isFirstLoad: boolean = false): Promise<void> {
        this.importFileModel = importFile;

        let message: string;
        const status = importFile.processStatus;
        const lrpId = importFile.longRunningProcessId;

        switch (status) {
            case Compliance.ImportFileProcessStatusEnum.None:
            case Compliance.ImportFileProcessStatusEnum.ProcessingCompleted:
            case Compliance.ImportFileProcessStatusEnum.BulkUpdated:
                return;
            case Compliance.ImportFileProcessStatusEnum.Validating:
                this._showBusyIndicator('', 'Validating', lrpId, false, false);
                return;
            case Compliance.ImportFileProcessStatusEnum.Validated:
                if (isFirstLoad && this.importStep === ImportStep.Indeterminate) {
                    this.importStep = ImportStep.Editing;
                }
                return;
            case Compliance.ImportFileProcessStatusEnum.Processing:
            case Compliance.ImportFileProcessStatusEnum.Transferring:
                message = 'Transferring selected rows';
                this._showBusyIndicator('', message, lrpId);
                return;
            case Compliance.ImportFileProcessStatusEnum.TransferError:
                message = `Transfer failed. ${this.importFileModel.lastTransferInfo.errorMessage}`;
                break;
            case Compliance.ImportFileProcessStatusEnum.RevertingError:
                message = `Revert failed. ${this.importFileModel.lastTransferInfo.errorMessage}`;
                break;
            case Compliance.ImportFileProcessStatusEnum.Transferred:
            case Compliance.ImportFileProcessStatusEnum.TransferCompleted:
            case Compliance.ImportFileProcessStatusEnum.Completed:
                if (this.importFileModel.lastTransferInfo){
                    const rowsCount: number = this.importFileModel.lastTransferInfo.insertedCount +
                        this.importFileModel.lastTransferInfo.updatedCount +
                        this.importFileModel.lastTransferInfo.deletedCount +
                        this.importFileModel.lastTransferInfo.unchangedCount;
                    const errorsCount = this.importFileModel.lastTransferInfo.errorsCount;

                    message = `${this._decimalPipe.transform(rowsCount || 0, '1.0-0')} row(s) were transferred.`;

                    if (this.importFileModel.importContentTypeId === Compliance.ImportContentTypeIdEnum.Assets &&
                        this.importFileModel.lastTransferInfo.unchangedCount !== 0){
                        message += ' (included unchanged assets just recorded as appearing on import)';
                    }

                    if (errorsCount){
                        message += ` Transfer failed for ${this._decimalPipe.transform(errorsCount, '1.0-0')} row(s). Transfer error(s) are reflected in the Errors and Warnings panel.`;
                    }
                }

                this._entityImportProcessService.transferCompletedEventEmitter.emit(Promise.resolve());
                break;
            default:
                console.log('Unknown return status.', status);
                throw new Error('Unknown return status.');
        }

        if (isFirstLoad) {
            return;
        }

        let resultTitle = 'Success';
        if (this.importFileModel && this._entityImportProcessService.importProcessStatusError) {
            resultTitle = 'Error';
            message = this._entityImportProcessService.importProcessStatusError;
        }

        if (this._busyRef) {
            await this._busyRef.hide();
        }

        try {
            await this._messageModalService.alert(message, resultTitle);
            this._busyRef = null;

            if (this.importFileModel.totalRows === this.importFileModel.transferredRows){
                await this.navigateToImportsList();
            }
        } catch (e) {
            return;
        }
    }

    private async _startValidation(message: string, goToNextStep: boolean, validationModel?: Compliance.ImportValidateModel): Promise<void> {
        this._showBusyIndicator('', message, null, false, false);

        this._busyRef.onProgressBarComplete().pipe(takeUntil(this._destroy$)).subscribe(async (success: boolean) => {
            if (!success) {
                return;
            }

            this._isEvaluated = false;
            this.notValidatedUpdatesCount = 0;
            this._entityImportProcessService.notifyCellUpdated(0);

            if (this._selectedRowsModel && (this._selectedRowsModel.selectAllRows || this._selectedRowsModel.selectedRows && this._selectedRowsModel.selectedRows.length !== 0)) {
                const selectedRowsModel = {
                    estimatedActionFilter: null,
                    validationErrors: [],
                    selectAllRows: this._selectedRowsModel.selectAllRows,
                    selectedRows: this._selectedRowsModel.selectedRows
                } as Compliance.ImportBulkOperationModel;
                this._selectedRowsInfo = await this._entityImportProcessService.getSelectedRowsInfo(this.importId, selectedRowsModel);

                this.selectedRowsInfo$.next(this._selectedRowsInfo);
            }

            if (goToNextStep && this.importFileModel.processStatus !== Compliance.ImportFileProcessStatusEnum.ProcessingError) {
                this.importStep++;
            }

            await this._hideBusyIndicator();
            await this._entityImportProcessService.getValidationSummary(this.importId);
        });

        try {
            await this._entityImportProcessService.validate(this.importId, validationModel);
            await this._busyRef.setLongRunningProcessId(this._entityImportProcessService.longRunningProcessId);
        } catch (errResp) {
            this._toastsManager.error(errResp.error.message);
            await this._hideBusyIndicator();
        }
    }

    private _showBusyIndicator(title: string, message: string = 'Working on it...', lrpId: number, canDismiss = true, hasProgressBar = true): void {
        if (this._busyRef) {
            this._busyRef.updateMessage(message, this.busyRefId);
            this._busyRef.setLongRunningProcessId(lrpId);
            return;
        }

        this._busyRef = this._busyIndicatorService.show({
            identifier: this.busyRefId,
            longRunningProcessId: lrpId,
            title: title ? title : 'Processing',
            message: message,
            hasProgressBar: hasProgressBar,
            canDismiss
        });

        this._busyRef.onProgressBarComplete().pipe(takeUntil(this._destroy$)).subscribe(async () => {
            await this._hideBusyIndicator();
        });

        this._busyRef.onDismiss().pipe(takeUntil(this._destroy$)).subscribe(async () => {
            await this._hideBusyIndicator();
            this._routerService.go('companyImports', {
                'companyId': this.importFileModel.companyId
            });
        });
    }

    private async _hideBusyIndicator(): Promise<void> {
        if (this._busyRef && !this._busyRef.isDisposed) {
            await this._busyRef.hide();
            this._busyRef = null;
        }
        this._entityImportProcessService.longRunningProcessId = null;
        this._destroy$.next();
    }
}
