import { Injectable } from '@angular/core';

export enum ScriptLocations { GoogleMaps, IAMComponent }

@Injectable({
    providedIn: 'root'
})
export class ScriptLoaderService {
    loadScript(scriptLocation: ScriptLocations, isModule: boolean): Promise<void> {
        return new Promise<void>(res => {
            let src: string;

            switch (scriptLocation) {
                case ScriptLocations.GoogleMaps:
                    src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDXD_xiLW3lNKvfl8ZnZGKPld-f2TExBuQ&callback=gmapsLoaded';
                    break;
                case ScriptLocations.IAMComponent:
                //     src = 'https://ryanaceassets.blob.core.windows.net/components/v5/latest/ace/ace.esm.js';

                    // Latest dev build:
                    //src = 'https://ryanaceassets.blob.core.windows.net/components-develop/ace/ace.esm.js';
                    break;
                default:
                    throw `Unrecognized script location: ${scriptLocation}`;
            }

            if (!src) {
                res();
                return;
            }

            const newTag = document.createElement('script');
            if (isModule) {
                newTag.setAttribute('type', 'module');
            }
            newTag.setAttribute('src', src);
            newTag.onload = () => { res(); };
            document.head.appendChild(newTag);
        });
    }
}
