import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Constants } from '../../../constants.new';
import {
    PaymentPackage,
    TransmittalOutputType,
    TransmittalOutputTypeButtonLabel,
    TransmittalOutputTypeOption
} from '../transmittal.models';
import { TransmittalService } from '../transmittal.service';
import { AttachmentInfo } from '../../../Attachment/attachment.model';
import { ToastrService } from 'ngx-toastr';
import { AttachmentService } from 'src/app/Attachment/attachment.service';
import { PaymentBatchService } from 'src/app/Payment-Batch/paymentBatch.service';
import { UpgradeNavigationServiceHandler } from 'src/app/Common/Routing/upgrade-navigation-handler.service';
import { BusyIndicatorService } from '../../../Busy-Indicator';


declare const _: any;

@Component({
    selector: 'finalize-payment-package-modal',
    templateUrl: './payment-packages-finalize.modal.html'
})
export class FinalizePaymentPackageModal implements OnInit {
    @ViewChild('FinalizePaymentPackageModal', { static: true }) finalizePaymentPackageModal: ModalDirective;
    @Input() packageList: PaymentPackage[];
    @Input() setParentLoading: (loading: boolean, message: string, hideCancelHyperlink?: boolean) => void;
    @Input() isFromPaymentBatch: boolean;
    @Input() paymentBatchId: number;
    @Input() taskId: number;
    @Output() hasPackageFinalized: EventEmitter<boolean> = new EventEmitter<boolean>();
    selectedPackages: PaymentPackage[];
    originalSelectedPackages: PaymentPackage[];
    tooManyOutputsError: boolean = false;
    outputTypeOptions: TransmittalOutputTypeOption[];
    buttonLabel: string;
    selectedOutputType: TransmittalOutputType;
    loading: boolean = false;
    addButtonLabel: string = 'Add';
    @Input() cancelExecutionLoop: boolean = false;
    finalizeHasErrors: boolean = false;


    constructor(private constants: Constants,
                private transmittalService: TransmittalService,
                private attachmentService: AttachmentService,
                private paymentBatchService: PaymentBatchService,
                private readonly busyIndicatorService: BusyIndicatorService,
                private upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
                private toastr: ToastrService) {
    }

    ngOnInit(): void {

    }

    close(): void {
        this.tooManyOutputsError = false;
        this.finalizePaymentPackageModal.hide();
    }

    show(): void {
        this.loading = false;
        this.cancelExecutionLoop = false;
        this.originalSelectedPackages = this.selectedPackages;
        this.finalizePaymentPackageModal.show();
        this.prepareOutputTypes();
    }

    prepareOutputTypes(): void {
        let outputType: TransmittalOutputType;

        //clear old value, if any, and initialize
        this.outputTypeOptions = [];

        //load selected packages
        this.selectedPackages = _.filter(this.packageList, (packageItem: PaymentPackage) => {
            return packageItem.isSelected === true;
        });

        //check if there are multiple output types in this selection
        const outputTypes: TransmittalOutputType[] = _.uniq(_.map(this.selectedPackages, 'transmittalOutputType'));
        if(outputTypes.length > 1) {
            this.tooManyOutputsError = true;
            return;
        } else if(outputTypes.length === 0) {
            //throw error?
            return;
        }

        //should only be one item by now
        outputType = outputTypes[0];
        this.selectedOutputType = outputType;
        this.selectedOutputChanged(this.selectedOutputType);

        //generate dropdown options based on current default output types
        if(parseInt(outputType.toString()) === TransmittalOutputType.PaperTransmittal ||
            parseInt(outputType.toString()) === TransmittalOutputType.DigitalTransmittal) {
            this.outputTypeOptions.push(new TransmittalOutputTypeOption(TransmittalOutputType.PaperTransmittal, 'Paper Transmittal'));
            this.outputTypeOptions.push(new TransmittalOutputTypeOption(TransmittalOutputType.DigitalTransmittal, 'Digital Transmittal'));
        }

        if(parseInt(outputType.toString()) === TransmittalOutputType.APFeed) {
            this.outputTypeOptions.push(new TransmittalOutputTypeOption(TransmittalOutputType.APFeed, 'AP Feed'));
        }

        if(parseInt(outputType.toString()) === TransmittalOutputType.BillPay) {
            this.outputTypeOptions.push(new TransmittalOutputTypeOption(TransmittalOutputType.BillPay, 'Bill Pay'));
        }
    }

    /* The save function used to work by setting the modal's loading flag while processing, but for WK-4736 we determined that
    * we don't want the modal to be dismissable while processing. As a more robust solution the loading indicator was moved to the
    * parent page, however that now means the code is in a bit of a strange ordering. If this becomes a maintanence headache, we
    * should consider moving all the code that deals with finalizing into payment-packages.component and altering
    * payment-packages-finalize.modal so that the show function returns a promise.
    */
    save(): void {
        this.finalizeHasErrors = false;
        this.cancelExecutionLoop = false;
        this.finalizePaymentPackageModal.hide();

        this.originalSelectedPackages = _.cloneDeep(this.selectedPackages);
        this.selectedPackages = _.map(this.selectedPackages, (packageItem: PaymentPackage) => {
            packageItem.transmittalOutputType = this.selectedOutputType;
            return packageItem;
        });

        const successMessage = 'Payment package(s) finalized.';
        const warningMessaage = 'One or more payment packages could not be processed. Please try again.';

        const busyRef = this.busyIndicatorService.show({ message: 'Finalizing package ...' });

        if (this.isFromPaymentBatch) {
              this.paymentBatchService.finalizeTransmittalPackage(this.paymentBatchId, this.taskId, this.originalSelectedPackages).then(() => {
              this.setParentLoading(false, null);
              this.toastr.success(successMessage);
              this.upgradeNavigationServiceHandler.transitionTo('paymentBatchDetailsWithTabIndex', {paymentBatchId: this.paymentBatchId, tabIndex: 1});
            }).catch(() => {
                this.finalizeHasErrors = true;
                this.toastr.warning(warningMessaage);
            }).finally(() => {
                busyRef.hide();
            });
        }

        else {
        this.transmitPackages().then(() => {
            this.setParentLoading(false, null);

            if(this.finalizeHasErrors) {
                this.toastr.warning(warningMessaage);
            } else {
                this.toastr.success(successMessage);
            }

            this.hasPackageFinalized.emit(true);
        }).catch(() => {
            //restore original packages since they weren't changed, but we altered them for transmittal
            this.selectedPackages = _.cloneDeep(this.originalSelectedPackages);
            this.setParentLoading(false, null);
        }).finally(() => {
            busyRef.hide();
        });
     }

    }

    // Loop over all payment packages and send individually. This is to try and mitigate performance issues
    transmitPackages(): Promise<any> {
        const vm = this;
        return new Promise<void>((resolve) => {
            const paymentPackagesToFinalize = _.cloneDeep(this.selectedPackages);

            const executionLoop = function () {
                if (paymentPackagesToFinalize.length > 0 && vm.cancelExecutionLoop === false) {
                    const nextPackage: PaymentPackage = paymentPackagesToFinalize.shift();

                    vm.setParentLoading(true, `Finalizing Draft #${  nextPackage.packageNumber  }...`, paymentPackagesToFinalize.length === 0);

                    vm.transmitPackage(nextPackage).then(function () {
                        executionLoop();
                    }).catch(() => {
                        vm.finalizeHasErrors = true;
                        executionLoop();
                    });
                }
                else {
                    resolve();
                }
            };

            executionLoop();
        });
    }

    // Function to save a specific package and set up and map the return object so the actual package items are preserved.
    // Part of this is so we don't have to refetch the entire package list to get the new data as that's a heavy call
    transmitPackage(packageToFinalize: PaymentPackage): Promise<any> {
        return this.transmittalService.FinalizePaymentPackages([packageToFinalize]).then((result: PaymentPackage[]) => {

            if(parseInt(this.selectedOutputType.toString()) === TransmittalOutputType.PaperTransmittal) {
                this.downloadAttachments(result);
            }

            //Old way of mapping the response to the bound items. This is necessary for now because it returns an array
            //but the logic is much simpler in theory
            _.map(this.packageList, (packageItem: PaymentPackage) => {

                const newPackage: PaymentPackage = _.find(result, { paymentPackageID: packageItem.paymentPackageID });

                if(newPackage) {
                    //todo map items
                    packageItem.completedDateTime = newPackage.completedDateTime;
                    packageItem.completedByUserID = newPackage.completedByUserID;
                    packageItem.packageNumber = newPackage.packageNumber;
                    packageItem.emailText = newPackage.emailText;
                    packageItem.emailSubject = newPackage.emailSubject;
                    packageItem.attachments = newPackage.attachments;
                    packageItem.apFeedAttachments = newPackage.apFeedAttachments;
                }

                packageItem.isSelected = false;
                packageItem.isFinalized = true;

                return packageItem;
            });

        });
    }

    downloadAttachments(packageList: PaymentPackage[]): void {
        _.forEach(packageList, (packageItem: PaymentPackage) => {
            const attachmentInfo: AttachmentInfo = {
                attachmentID: null,
                entityTypeID: 19,
                entityID: packageItem.paymentPackageID,
                fileName: null,
                fileExtension: null
            };

            if (packageItem.attachments) {
                attachmentInfo.attachmentID = packageItem.attachments[0].attachmentID;
                attachmentInfo.fileName = packageItem.attachments[0].fileName;
                attachmentInfo.fileExtension = packageItem.attachments[0].fileExtension;
            }

            this.attachmentService.downloadAttachmentFile(attachmentInfo, true).subscribe();
        });
    }

    selectedOutputChanged(event) {

        switch(Number(event)){
            case TransmittalOutputType.PaperTransmittal:
                this.addButtonLabel = TransmittalOutputTypeButtonLabel.PaperTransmittal;
                break;
            case TransmittalOutputType.DigitalTransmittal:
                this.addButtonLabel = TransmittalOutputTypeButtonLabel.DigitalTransmittal;
                break;
            case TransmittalOutputType.APFeed:
                this.addButtonLabel = TransmittalOutputTypeButtonLabel.APFeed;
                break;
            case TransmittalOutputType.BillPay:
                this.addButtonLabel = TransmittalOutputTypeButtonLabel.BillPay;
                break;
            default:
                this.addButtonLabel = 'Add';
                break;
        }
    }
}
