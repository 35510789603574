import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { TabsetComponent, TabDirective } from 'ngx-bootstrap/tabs';
import { ReturnOverviewService } from './Overview/returnOverview.service';
import { ReturnAssetsService, ReturnAssetsServiceSharedState } from './Assets/returnAssets.service';
import { ReturnPreviewService, ReturnPreviewServiceSharedState } from './Preview/returnPreview.service';
import { from, Subscription } from 'rxjs';
import { ReturnOutputService } from './Output/returnOutput.service';
import { ReturnSettingsService } from './Settings/returnSettings.service';
import { ReturnService } from '../return.service';
import { WeissmanMutexService } from '../../WeissmanMutexService';
import { TimerService } from '../../../UI-Lib/Utilities/timer.service';

import * as _ from 'lodash';
import { ProductAnalyticsService } from "../../../Common/Amplitude/productAnalytics.service";

interface TabInfo {
    heading: string;
    activationParam?: any;
    select?: (param: any) => void;
    activate: (param: any) => void;
    deactivate: () => void;
    initialize: () => void;
}

@Component({
    selector: 'return-parts',
    templateUrl: './returnParts.component.html',
    styleUrls: ['./returnParts.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ReturnPartsComponent implements OnInit, OnDestroy {
    // Components in this page follow a special lifecycle. The tabs activate the individual pages, and the returnServices coordinates these pages.
    // OnReturnPartActivated / Deactivated contains code that would normally be in the init and destroy methods.
    // Components subscribe to activation lifecycle - a base method, and then have their individual logic called, so that when their tab is clicked away from they
    // can be passive, without needing to be destroyed and re-created. A system of shared state is used to determine whether the variables involved in the
    //  return as a whole have changed. As an example, the returnAssetList's method : _refreshAssets().
    // As in other parts of the app each tab/page has its own service method to handle the data flow, but in this case they extend a base class
    //  "ReturnPartServiceBase", so that they have the needed mechanism to be unregistered.
    constructor(
        private readonly _returnService: ReturnService,
        private readonly _returnOverviewService: ReturnOverviewService,
        private readonly _returnAssetsService: ReturnAssetsService,
        private readonly _returnPreviewService: ReturnPreviewService,
        private readonly _returnOutputService: ReturnOutputService,
        private readonly _returnSettingsService: ReturnSettingsService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _timer: TimerService,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) { }

    private _returnAssetsSelectedSub: Subscription;
    private _returnPreviewSelectedSub: Subscription;
    private _returnServiceStartSub: Subscription;

    @ViewChild('tabset') tabset: TabsetComponent;

    overviewTab: TabInfo = {
        heading: 'Overview',
        activate: () => this._returnOverviewService.notifyServiceActivationChange(true),
        deactivate: () => this._returnOverviewService.notifyServiceActivationChange(false),
        initialize: () => this._returnOverviewService.initialize()
    };

    assetsTab: TabInfo = {
        heading: 'Assets',
        select: (sharedState: ReturnAssetsServiceSharedState) => {
            this.assetsTab.activationParam = sharedState;

            // set the activation parameters and the tab control "select" will pass them to the service
            this._timer.setTimeout(() => this.tabset.tabs[1].active = true, 100);
        },
        activate: (sharedState: ReturnAssetsServiceSharedState) => this._returnAssetsService.notifyServiceActivationChange(true, sharedState),
        deactivate: () => this._returnAssetsService.notifyServiceActivationChange(false),
        initialize: () => this._returnAssetsService.initialize()
    };

    previewTab: TabInfo = {
        heading: 'Returns',
        select: (sharedState: ReturnPreviewServiceSharedState) => {
            this.previewTab.activationParam = sharedState;

            // set the activation parameters and the tab control "select" will pass them to the service
            this._timer.setTimeout(() => this.tabset.tabs[2].active = true, 100);
        },
        activate: (sharedState: ReturnPreviewServiceSharedState) => this._returnPreviewService.notifyServiceActivationChange(true, sharedState),
        deactivate: () => this._returnPreviewService.notifyServiceActivationChange(false),
        initialize: () => this._returnPreviewService.initialize()
    };

    settingsTab: TabInfo = {
        heading: 'Settings',
        activate: () => this._returnSettingsService.notifyServiceActivationChange(true),
        deactivate: () => this._returnSettingsService.notifyServiceActivationChange(false),
        initialize: () => this._returnSettingsService.initialize()
    }

    outputTab: TabInfo = {
        heading: 'Output',
        activate: () => this._returnOutputService.notifyServiceActivationChange(true),
        deactivate: () => this._returnOutputService.notifyServiceActivationChange(false),
        initialize: () => this._returnOutputService.initialize()
    }

    tabs = [
        this.overviewTab,
        this.assetsTab,
        this.previewTab,
        this.settingsTab,
        this.outputTab
    ];

    isInitialized: boolean = false;

    get canEnterEditMode(): boolean {
        return this._mutexService.canAcquire(this._returnService.editGroup);
    }

    ngOnInit(): void {
        this._returnServiceStartSub = this._returnService.start$.subscribe(() => this._initialize());
    }

    ngOnDestroy(): void {
        this._returnServiceStartSub && this._returnServiceStartSub.unsubscribe();
        this._returnAssetsSelectedSub && this._returnAssetsSelectedSub.unsubscribe();
        this._returnPreviewSelectedSub && this._returnPreviewSelectedSub.unsubscribe();
        this.tabs.forEach(x => x.deactivate());
    }

    tabDeselected(tab: TabDirective): void {
        if (!tab) {
            return;
        }

        const control = this.tabs.find(x => x.heading === tab.heading);
        control && control.deactivate();
    }

    tabSelected(tab: TabDirective, fromClick: boolean = true): void {
        if (!tab) {
            return;
        }

        const control = this.tabs.find(x => x.heading === tab.heading);
        if (control) {
            if (fromClick) {
                let analyticsEvent;
                switch (control.heading) {
                    case 'Overview':
                        analyticsEvent = 'click-access-overview-tab';
                        break;
                    case 'Assets':
                        analyticsEvent = 'click-assets-tab';
                        break;
                    case 'Returns':
                        analyticsEvent = 'click-returns-tab';
                        break;
                    case 'Settings':
                        analyticsEvent = 'click-settings-tab';
                        break;
                    case 'Output':
                        analyticsEvent = 'click-output-tab';
                        break;
                }
                this._productAnalyticsService.logEvent(analyticsEvent, {});
            }
            control.activate(control.activationParam);
            delete control.activationParam;
        }
    }

    private _initialize(): void {
        _.forEach(this.tabs, x => x.initialize());

        if (this._returnAssetsSelectedSub) { this._returnAssetsSelectedSub.unsubscribe() }
        if (this._returnPreviewSelectedSub) { this._returnPreviewSelectedSub.unsubscribe() }

        this._returnAssetsSelectedSub = this._returnOverviewService.returnAssetsSelected$.subscribe(x => this.assetsTab.select(x));
        this._returnPreviewSelectedSub = this._returnOverviewService.returnPreviewSelected$.subscribe(x => this.previewTab.select(x));

        if (!this.isInitialized) {
            // the tab component takes an extra cycle to evaluate the headings and set the "heading" property; setTimeout() takes care of that
            this._timer.setTimeout(() => this.tabSelected(this.tabset.tabs[0], false), 0);
        }

        this.isInitialized = true;
    }
}
