<div class="card panel-flat-color secondary-panel" *ngIf="canView">
    <div class="panel-flat-header pointer" (click)="expandOrCollapse()">
        <panel-chevron [showBody]="showBody"></panel-chevron>
        <h3>Compliance Lien Date Configuration</h3>
    </div>
    <div class="card-body" *ngIf="showBody">
        <ws-busy-indicator *ngIf="busyMessageManager.count()" [message]="busyMessageManager.message"></ws-busy-indicator>
        <form [formGroup]="configurationForm" *ngIf="isInitialized">
            <div class="mb-3 row">
                <div class="col-lg-2">Tax Year:</div>
                <div class="col-lg-2">
                    <ws-select
                        [returnEntireOption]="true"
                        [isPrimitive]="true"
                        formControlName="taxYear"
                        [canSearch]="false"
                        [options]="taxYears"
                        (selectedOptionChanged)="onTaxYearChange($event)">
                    </ws-select>
                </div>
            </div>
            <div class="mb-3 row">
                <div class="col-lg-2">Lien Date:</div>
                <div class="col-lg-2">{{lienDate}}</div>
                <div *ngIf="stateLienDateHasException">
                    <div class="btn btn-link pointer" (click)="showExceptions('Lien Date', stateLienDateInfo.ppLienDateDisplayExceptions, exceptionGetLienDate)">(Exceptions)</div>
                </div>
                <div *ngIf="assessorId && !assessorLienDateHasException">
                    <div class="btn btn-link pointer" (click)="navigateToState()">(per state setup)</div>
                </div>
            </div>
            <div class="mb-3 row">
                <div class="col-lg-2">Asset Cut Off Date:</div>
                <div class="col-lg-2">{{assetCutOffDate}}</div>
                <div *ngIf="stateAssetCutOffDateHasException">
                    <div class="btn btn-link pointer" (click)="showExceptions('Asset Cut Off Date', stateLienDateInfo.ppLienDateExceptions, exceptionGetAssetCutOffDate)">(Exceptions)</div>
                </div>
                <div *ngIf="assessorId && !assessorAssetCutOffDateHasException">
                    <div class="btn btn-link pointer" (click)="navigateToState()">(per state setup)</div>
                </div>
            </div>
            <div class="mb-3 row">
                <div class="col-lg-2">Age 0 Range:</div>
                <div class="col-lg-2">{{age0Range}}</div>
                <div *ngIf="stateAge0RangeHasException">
                    <div class="btn btn-link pointer" (click)="showExceptions('Age 0 Range', stateLienDateInfo.ppLienDateAgeBeginExceptions, exceptionGetAge0Range)">(Exceptions)</div>
                </div>
                <div *ngIf="assessorId && !assessorAge0RangeHasException">
                    <div class="btn btn-link pointer" (click)="navigateToState()">(per state setup)</div>
                </div>
            </div>
        </form>
    </div>
</div>
