export abstract class NavigationService {
    // Note that the following functions are not called from anywhere in our TypeScript code at the moment,
    // so these definitions have not been tested; please correct them if you find problems
    abstract registerQuickSearch(callback: (string) => void): void;
    abstract openQuickSearch(resetCriteriaList: boolean, navigatingFrom?: string): void;

    abstract postLogin(): void;
    abstract goToLogin(isLogout: boolean): void;
    abstract registerChildWindow(childWindow: any): void;
    // End untested definitions

    abstract enableNavWarning(message: string): void;
    abstract disableNavWarning(): void;

    abstract getNavigationInfo(entityName: string, id: number): Promise<any>;
}
