<div class="ws-flex-container-vertical ws-flex-auto" *ngIf="isInitialized">
    <ws-bread-crumbs [breadcrumbs]="breadcrumbs" currentPageTitle="Assets" *ngIf="!isEmbeddedMode"></ws-bread-crumbs>
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title">
                Assets
                <small>
                    {{ isLienDatePrior ? ' Assets present on or carried forward to ' + (lienDate.date | weissmanDateFormat) : ''}}
                    {{ isLienDateExact ? ' Assets present or confirmed present on ' + (lienDate.date | weissmanDateFormat) : ''}}
                    {{ lienDate && lienDate.useStateLienDate ? 'only states utilizing ' + (lienDate.date | weissmanMonthDayDateFormat) + ' lien date' : ''}}
                </small>
                <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions"></ag-grid-row-count>
            </h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item" *ngIf="!isEmbeddedMode">
                    <div><small>View:</small></div>
                    <div dropdown container="body" placement="bottom right">
                        <button id="button-basic"
                                dropdownToggle
                                type="button"
                                class="btn btn-link btn-sm dropdown-toggle"
                                aria-controls="dropdown-basic"
                                [disabled]="refreshing">
                            {{ selectedGridSourceOption.name }}
                            <span class="caret"></span>
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-end" role="menu" aria-labelledby="button-basic">
                            <li role="menuitem" *ngFor="let gso of gridSourceOptions">
                                <a class="pointer" (click)="setSelectedGridSourceOption(gso)" helpTooltip [helpContentId]="getListViewHelpContentId(gso.name)" position="right" >{{ gso.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="ws-section__header__actions__item" *ngIf="canEdit && !isEmbeddedMode">
                    <button type="button" class="flat-button primary-button small-button" (click)="addAsset()" helpTooltip helpContentId="asset-list.new-asset" position="bottom" [disabled]="!lienDate">New Asset</button>
                </div>
                <div class="ws-section__header__actions__item" *ngIf="isEmbeddedMode">
                    <a [href]="filterHref">View Full Asset List</a>
                </div>
                <div class="ws-section__header__actions__item">
                    <button type="button" class="flat-button small-button secondary-button" (click)="refresh()" helpTooltip helpContentId="app.refresh" position="bottom" [disabled]="refreshing"><i class="fa fa-refresh"></i></button>
                </div>
            </div>
        </div>
        <div class="asset-import-in-progress" *ngIf="activeImportLongRunningProcessId" (click)="openAssetImportInProgressModal()">
            <div class="message"><i class="fa fa-info-circle" style="padding-right: 5px;"></i>Asset import in progress</div>
            <div *ngIf="activeImportLongRunningProcessId && !activeImportSelectedRowsCount" class="loadingMetadata"><i class="fa fa-spinner fa-pulse"></i></div>
            <div *ngIf="activeImportLongRunningProcessId && activeImportSelectedRowsCount">Importing: {{activeImportProcessedRowsCount}} of {{this.activeImportSelectedRowsCount}}</div>
            <div *ngIf="activeImportLongRunningProcessId && activeImportSelectedRowsCount" class="timeRemaining">Time left: {{activeImportTimeRemaining}}</div>
        </div>
        <div class="ws-section__filters">
            <div class="ws-section__filters__filter-group" [hidden]="!importFilterOptionVisible">
                <label for="importFilteredBy">Filter By:</label>
                <select id="importFilteredBy"
                        class="form-select form-select-sm"
                        [ngModel]="selectedImportGridFilterOption"
                        (ngModelChange)="onImportGridFilterChanged($event)">
                    <option *ngFor="let gfo of importGridFilterOptions" [ngValue]="gfo">{{ gfo.name }}</option>
                </select>
            </div>
            <div class="ws-section__filters__filter-group" [hidden]="!lienDateVisible">
                <div class="ws-flex-container-horizontal ws-flex-align-items-center">
                    <label class="ws-flex-none"
                           for="assetLienDate"
                           helpTooltip
                           helpContentId="asset-list.lien-date"
                           position="bottom">Lien Date:</label>
                    <asset-lien-date id="assetLienDate"
                                     [useSavedLienDate]="true"
                                     [saveLienDate]="true"
                                     [showFilters]="true"
                                     size="md"
                                     [companyId]="companyId"
                                     (dateChange)="onLienDateChanged($event)"
                                     style="margin-left: 8px">
                    </asset-lien-date>
                </div>
            </div>
            <div class="ws-section__filters__filter-group" [hidden]="isEmbeddedMode">
                <div class="ws-flex-container-horizontal ws-flex-align-items-center">
                    <label class="ws-flex-none"
                           for="assetAdvanced"
                           helpTooltip
                           helpContentId="asset-list.asset-changes-filter"
                           position="bottom">Show:</label>
                    <asset-advanced id="assetAdvanced" [assetDescriptors]="assetDescriptors" (optionChange)="onAdvancedOptionChanged($event)"></asset-advanced>
                </div>
            </div>
            <div class="ws-section__filters__filter-group" [hidden]="!furtherLimitVisible">
                <div class="ws-flex-container-horizontal ws-flex-align-items-center">
                    <label class="ws-flex-none"
                           for="assetLimit"
                           helpTooltip
                           helpContentId="asset-list.further-limit-to"
                           position="bottom">Further Limit To:</label>
                    <asset-limit id="assetLimit" [assetDescriptors]="assetDescriptors" (optionChange)="onLimitOptionChanged($event)"></asset-limit>
                </div>
            </div>
            <div class="ws-section__filters__filter-group" [hidden]="!importFileListVisible">
                <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                    <label for="selectedImportFile" class="ws-flex-none">Import File:</label>
                    <select id="selectedImportFile"
                            class="form-select form-select-sm"
                            [ngModel]="selectedImportFileId"
                            (ngModelChange)="onSelectedImportFileChanged($event)">
                        <option *ngFor="let importFile of importFiles" [ngValue]="importFile.importFileId">{{ importFile.displayName }}</option>
                    </select>
                </div>
            </div>
            <div class="ws-section__filters__filter-group">
                <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                    <button type="button"
                            class="flat-button small-button primary-button"
                            (click)="refresh()"
                            helpTooltip
                            helpContentId="asset-list.apply-filters"
                            position="bottom"
                            [disabled]="refreshing">
                        Apply
                    </button>
                </div>
            </div>
            <div *ngIf="gridId" class="ws-section__filters__filter-group right-align-layout-name">
                <ag-grid-current-layout-applied [gridId]="gridId"></ag-grid-current-layout-applied>
            </div>
        </div>
    </div>
    <ws-ag-grid-angular (gridReady)="onAgGridReady($event)"
                        [companyId]="companyId"
                        [gridOptions]="gridOptions"
                        [gridId]="gridId"
                        [isBulkUpdateVisible$]="isBulkUpdateVisible$"
                        [isBulkDeleteVisible$]="isBulkDeleteVisible$"
                        [exportOptions]="exportOptions"
                        [defaultSizeToFit]="false"
                        (bulkUpdate)="bulkUpdate()"
                        (bulkDelete)="bulkDelete()">
    </ws-ag-grid-angular>
</div>
