import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import { RestrictService, Roles } from '../Common/Permissions/restrict.service';
import { ToastrService } from 'ngx-toastr';
import { WeissmanModalService } from '../Compliance/WeissmanModalService';
import { JurisdictionSpecialist, ProtocolService } from './protocol.service';
import { PropertyTypeService } from '../Common/Services/propertyType.service.upgrade';
import { SiteClassService } from '../Entity/Site/siteClass.service';
import { map, sortBy } from 'lodash/fp';
import { StateAssessorExceptionsParams, StateAssessorExceptionsModalComponent } from './stateAssessorExceptionsModal.component';
import { NavigationService } from '../Layout/Navigation.Service.upgrade';
import { EmptyGuid } from '../constants.new';

class JurisdictionSpecialistUI extends JurisdictionSpecialist {
    propertyTypeName: string;
    availableSiteClasses: SiteClassUI[];
    chosenSiteClass: SiteClassUI;
    isNoneAssigned: boolean;
    disablePicker: boolean;
    get undeletedExceptions() {
        return _.reject(this.siteClassExceptions, {efAction: 'delete'});
    }
    get showNoneAssigned() {
        return !this.undeletedExceptions.length
    }
}

interface SiteClassUI extends Weissman.Model.Domain.SiteClassSecondary {
    name: string;
}

@Component({
    selector: 'protocol-list-panel',
    templateUrl: './protocolListPanel.component.html',
    styles: [`
        .exceptions-table td {
            padding: 3px 8px;
        }
    `]
})
export class ProtocolListPanelComponent {
    constructor(
        private readonly _restrictService: RestrictService,
        private readonly _protocolService: ProtocolService,
        private readonly _toastrService: ToastrService,
        private readonly _modalService: WeissmanModalService,
        private readonly _propertyTypeService: PropertyTypeService,
        private readonly _siteClassService: SiteClassService,
        private readonly _navigationService: NavigationService
    ) {}

    @Input() state: Weissman.Model.Misc.State;
    @Input() assessor: Core.AssessorModel;

    private _availableSiteClasses: SiteClassUI[];
    private _originalJurisdictionSpecialists: JurisdictionSpecialistUI[];

    hasEditPermission: boolean = this._restrictService.isInRole(Roles.STATEEDIT);
    showBody: boolean = false;
    editMode: boolean = false;
    serverAction: boolean = false;
    jurisdictionSpecialists: JurisdictionSpecialistUI[];
    exceptionToEdit: Weissman.Model.Workflow.JurisdictionSpecialist;

    private get _entityIdString(): string {
        return this.assessor ? 'assessorID' : 'stateID';
    }

    async expandOrCollapse(): Promise<void> {
        if(this.editMode) {
            return;
        }

        this.showBody = !this.showBody;

        if(this.showBody) {
            this.serverAction = true;
            try {
                await this._loadSpecialists();
            } finally {
                this.serverAction = false;
            }
        }
    }

    getJSDisplay(js: JurisdictionSpecialistUI): string {
        if(js.isNoneAssigned) {
            return 'None Assigned';
        } else {
            return this.getAssigneeDisplay(js.jurisdictionSpecialist)
        }
    }

    getAssigneeDisplay(entity: any): string {
        if (entity.user && entity.team) {
            return `${entity.user.lastName}, ${entity.user.firstName}  (${entity.team.name})`;
        } else {
            return ''
        }
    }

    async goToEditMode(): Promise<void> {
        if(!this._availableSiteClasses) {
            const availableSiteClasses = await this._siteClassService.getSecondaries();
            this._availableSiteClasses = _.flow([
                map(x => {
                    const name = `${x.siteClassPrimary.siteClassPrimaryDesc} - ${x.siteClassDesc}`
                    return { name, ...x };
                }),
                sortBy('name')
            ])(availableSiteClasses);

            this.jurisdictionSpecialists = _.map(this.jurisdictionSpecialists, js => {
                js.availableSiteClasses = this._getJSAvailableSiteClasses(js)
                return js;
            })
        }

        this._originalJurisdictionSpecialists = _.cloneDeep(this.jurisdictionSpecialists);
        this.editMode = true;
        this._navigationService.enableNavWarning('Editing is in progress.  Are you sure you wish to leave?');
    }

    cancel() {
        this.jurisdictionSpecialists = this._originalJurisdictionSpecialists;
        this.editMode = false;
        this._navigationService.disableNavWarning();
    }

    editException(exception: Weissman.Model.Workflow.JurisdictionSpecialist): void {
        this.exceptionToEdit = _.cloneDeep(exception)
    }

    jsUserChanged(user: Core.UserTeamModel, jsUI: JurisdictionSpecialistUI): void {
        if(this._setNewUser(user, jsUI.jurisdictionSpecialist)) {
            if(jsUI[this._entityIdString] && jsUI.jurisdictionSpecialist.jurisdictionSpecialistID) {
                if (!jsUI.jurisdictionSpecialist.efAction) {
                    jsUI.jurisdictionSpecialist.efAction = 'update';
                }
                if (this.assessor && jsUI.jurisdictionSpecialist.assessorID !== this.assessor.assessorID) {
                    jsUI.jurisdictionSpecialist.efAction = 'add';
                    jsUI.jurisdictionSpecialist.assessorID = this.assessor.assessorID;
                }
            } else {
                jsUI.jurisdictionSpecialist.efAction = 'add';
                jsUI.jurisdictionSpecialist.stateID = jsUI.stateID;
                jsUI.jurisdictionSpecialist.assessorID = jsUI.assessorID;
                jsUI.jurisdictionSpecialist.propertyTypeID = jsUI.propertyTypeID;
            }
        }
    }

    noneAssignedChanged(js: JurisdictionSpecialistUI): void {
        if(js.isNoneAssigned) {
            if(js.jurisdictionSpecialist.efAction === 'add') {
                js.jurisdictionSpecialist = this._getBlankJS();
            } else {
                js.jurisdictionSpecialist.efAction = 'delete';
            }
        } else {
            if(js.jurisdictionSpecialist[this._entityIdString]) {
                js.jurisdictionSpecialist.efAction = 'update';
            }
        }
    }

    exceptionUserChanged(user: Core.UserTeamModel, exception: Weissman.Model.Workflow.JurisdictionSpecialist) : void{
        if(this._setNewUser(user, exception)) {
            this.exceptionToEdit = undefined;
        }
    }

    deleteException(exception: Weissman.Model.Workflow.JurisdictionSpecialist, js: JurisdictionSpecialistUI): void {
        if(exception.efAction === 'add') {
            _.remove(js.siteClassExceptions, exception)
        } else {
            exception.efAction = 'delete';
        }

        js.availableSiteClasses = this._getJSAvailableSiteClasses(js);
    }

    isExceptionInEditMode(exception: Weissman.Model.Workflow.JurisdictionSpecialist): boolean {
        return this.exceptionToEdit && exception.jurisdictionSpecialistID === this.exceptionToEdit.jurisdictionSpecialistID;
    }

    cancelUserChange(exception: Weissman.Model.Workflow.JurisdictionSpecialist, js: JurisdictionSpecialistUI): void {
        if(exception.efAction === 'add') {
            _.remove(js.siteClassExceptions, exception)
        }

        this.exceptionToEdit = undefined;
    }

    addSiteClass(js: JurisdictionSpecialistUI): void {
        if(!js.siteClassExceptions) {
            js.siteClassExceptions = [];
        }

        js.siteClassExceptions.push({
            stateID: this.state.stateID,
            propertyTypeID: js.propertyTypeID,
            assessorID: js.assessorID,
            efAction: 'add',
            siteClassSecondaryID: js.chosenSiteClass.siteClassSecondaryID,
            siteClassName: js.chosenSiteClass.name
        } as Weissman.Model.Workflow.JurisdictionSpecialist);

        js.availableSiteClasses = this._getJSAvailableSiteClasses(js);

        this.editException(_.last(js.siteClassExceptions));

        setTimeout(() => {
            js.chosenSiteClass = undefined;
        })
    }

    async save() {
        const specialistsToSave = _.cloneDeep(this.jurisdictionSpecialists);

        let changedSpecialists = _.reduce(specialistsToSave, (changedJSs: Weissman.Model.Workflow.JurisdictionSpecialist[], js: JurisdictionSpecialistUI) => {
            if(js.jurisdictionSpecialist.efAction) {
                changedJSs.push(js.jurisdictionSpecialist)
            }

            const changedExceptions = _.filter(js.siteClassExceptions, 'efAction')

            return _.union(changedJSs, changedExceptions);
        }, []);

        changedSpecialists = _.map(changedSpecialists, x => {
            const mapped = _.omit(x, 'team', 'user', 'siteClassName', 'siteClassSecondary');
            // Saving this bit of code for when Assessor is refactored
            // if(this.assessor) {
            //     mapped.assessorID = this.assessor.assessorID;
            // }

            return mapped as Weissman.Model.Workflow.JurisdictionSpecialist;
        });

        this.serverAction = true;
        try {
            this.assessor
                ? await this._protocolService.saveAssessorSpecialists(changedSpecialists, this.assessor.assessorID)
                : await this._protocolService.saveStateSpecialists(changedSpecialists);

            this.editMode = false;
            this._navigationService.disableNavWarning();

            await this._loadSpecialists();
        }
        finally {
            this.serverAction = false;
        }
    }

    async showExceptionsModal(js: JurisdictionSpecialistUI): Promise<void> {
        const params: StateAssessorExceptionsParams = {
            stateId: this.state.stateID,
            propertyTypeId: js.propertyTypeID,
            propertyTypeName: js.propertyTypeName
        };

        await this._modalService.showAsync(StateAssessorExceptionsModalComponent, params, 'modal-md');
    }

    private _getJSAvailableSiteClasses(js: JurisdictionSpecialistUI): SiteClassUI[] {
        return _.reject(this._availableSiteClasses, sc => {
            return _.some(js.undeletedExceptions, {siteClassSecondaryID: sc.siteClassSecondaryID});
        });
    }

    private _setNewUser(user: Core.UserTeamModel, js: Weissman.Model.Workflow.JurisdictionSpecialist): boolean {
        if(!user) {
            return false;
        }

        js.userID = user.userID;
        js.teamID = user.teamID;
        js.user = {
            firstName: user.firstName,
            lastName: user.lastName,
            userID: user.userID,
            efAction: null
        };
        js.team = {
            teamID: user.teamID,
            name: user.teamName,
            efAction: null
        } as Weissman.Model.Workflow.Team;
        js.efAction = js.efAction || 'update';

        return true;
    }

    private _getBlankJS(): Weissman.Model.Workflow.JurisdictionSpecialist {
        return {
            assessorID: null,
            efAction: null,
            isException: false,
            jurisdictionSpecialistID: 0,
            propertyTypeID: 0,
            siteClassName: null,
            siteClassSecondary: null,
            siteClassSecondaryID: null,
            stateID: 0,
            team: null,
            teamID: 0,
            user: null,
            userID: EmptyGuid
        } as unknown as Weissman.Model.Workflow.JurisdictionSpecialist
    }

    private async _getAssessorSpecialists(propertyTypes: Weissman.Model.Assessments.PropertyType[]): Promise<JurisdictionSpecialist[]> {
        const assessorSpecialists = await this._protocolService.getByAssessor(this.assessor.assessorID);

        _.forEach(propertyTypes, x => {
            if(!_.some(assessorSpecialists, {propertyTypeID: x.propertyTypeID})) {
                assessorSpecialists.push({
                    assessorID: this.assessor.assessorID,
                    propertyTypeID: x.propertyTypeID,
                    stateID: this.state.stateID,
                    propertyTypeXStateHasChildExceptions: false,
                    siteClassExceptions: null,
                    jurisdictionSpecialist: {
                        assessorID: null,
                        isException: false,
                        propertyTypeID: x.propertyTypeID,
                        stateID: this.state.stateID,
                        teamID: null,
                        team: {} as Weissman.Model.Workflow.Team,
                        user: {} as Core.ContactShortDTO,
                        userID: null
                    } as Weissman.Model.Workflow.JurisdictionSpecialist
                })
            }
        })

        return assessorSpecialists;
    }

    private async _loadSpecialists(): Promise<void> {
        const propertyTypes: Weissman.Model.Assessments.PropertyType[] = await this._propertyTypeService.get();

        const stateSpecialists = await this._protocolService.getByState(this.state.stateID);
        const jurisdictionSpecialists = this.assessor ? await this._getAssessorSpecialists(propertyTypes) : stateSpecialists;

        this.jurisdictionSpecialists = _(jurisdictionSpecialists)
            .map(js => {
                const newJS = new JurisdictionSpecialistUI();
                _.assign(newJS, js);

                newJS.propertyTypeName = _.find(propertyTypes, {propertyTypeID: js.propertyTypeID}).propTypeName
                newJS.siteClassExceptions = _.sortBy(js.siteClassExceptions, 'siteClassName');
                newJS.isNoneAssigned = !js.jurisdictionSpecialist.team
                    || !js.jurisdictionSpecialist.user
                    || _.isEmpty(js.jurisdictionSpecialist.team)
                    || _.isEmpty(js.jurisdictionSpecialist.user)

                if(this.assessor) {
                    newJS.disablePicker = !_.some(stateSpecialists, stateSpecialist => {
                        return stateSpecialist.propertyTypeID === js.propertyTypeID &&
                            stateSpecialist.jurisdictionSpecialist.user !== null;
                    })
                }

                return newJS;
            })
            .orderBy(x => x.propertyTypeID)
            .value();
    }
}
